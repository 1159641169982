
//Progress bar general style

//*** scss local variable ***
$progress-bar-wrapper-bg: var(--#{$vudTokenPrefix}progress-bar-wrapper-bg);
$progress-bar-bg:         var(--#{$vudTokenPrefix}progress-bar-bg);
$progress-bar-label:      var(--#{$vudTokenPrefix}progress-bar-label);

//*** element/component code ***
.horizontal-loading,
.progress {
    overflow: visible;
    position: relative;
    height: rem(20px);
    margin-bottom: rem(32px);

    vertical-align: middle;

    border-radius: rem(10px);
    background: $progress-bar-wrapper-bg;

    .loading-bar,
    .progress-bar {
        width: 0%;
        height: rem(20px);
        padding-right: 0;

        color: transparent;
        text-align: right;
        font-weight: $normal;

        border-radius: rem(10px);
        background-color: $progress-bar-bg;
        box-shadow: none;
        transition: width .6s ease;
    }

    .loading-bar-label,
    .progress-label {
        overflow: hidden;
        display: block;
        position: relative;
        padding-right: rem(32px);

        color: $progress-bar-label;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .progress-label {
        top: rem(8px);
    }
    
    .percentage {
        position: absolute;
        top: rem(28px);
        right: 0;
        color: $progress-bar-label;
        z-index: 1;
    }

    &.horizontal-loading-xs,
    &.progress-xs {
        height: rem(5px);
        border-radius: rem(3px);

        .loading-bar,
        .progress-bar { height: rem(5px); }

        .percentage { top: rem(13px); }
    }

    &.horizontal-loading-sm,
    &.progress-sm {
        height: rem(10px);
        border-radius: rem(5px);

        .loading-bar,
        .progress-bar { height: rem(10px); }

        .percentage { top: rem(18px); }
    }

    .progress-bar[aria-valuenow="0"] { min-width: 0; }
}
