
//# General select tag styles

//*** scss local variable ***
$select-border-color:               var(--#{$vudTokenPrefix}select-border-color);
$select-hover-border-color:         var(--#{$vudTokenPrefix}select-hover-border-color);
$select-focus-border-color:         var(--#{$vudTokenPrefix}select-focus-border-color);
$select-pressed-border-color:       var(--#{$vudTokenPrefix}select-pressed-border-color);
$select-disabled-border-color:      var(--#{$vudTokenPrefix}select-disabled-border-color);
$select-error-border-color:         var(--#{$vudTokenPrefix}input-error-border-color);

$select-border-radius:              var(--#{$vudTokenPrefix}select-border-radius);

$select-bg:                         var(--#{$vudTokenPrefix}select-bg);
$select-hover-bg:                   var(--#{$vudTokenPrefix}select-hover-bg);
// $select-hover-background-color:     var(--#{$vudTokenPrefix}button-hover-bg);
$select-pressed-bg:                 var(--#{$vudTokenPrefix}select-pressed-bg);
$select-disabled-bg:                var(--#{$vudTokenPrefix}select-disabled-bg);

$select-shadow:                     var(--#{$vudTokenPrefix}button-shadow);

$select-text-size:                  var(--#{$vudTokenPrefix}font-size-sm);

$select-text-color:                 var(--#{$vudTokenPrefix}select-text-color);
$select-disabled-text-color:        var(--#{$vudTokenPrefix}select-disabled-text-color);


$select-arrow-bg-img:               var(--#{$vudTokenPrefix}select-arrow);
$select-disabled-arrow-bg-img:      var(--#{$vudTokenPrefix}select-arrow-disabled);


// $select-menu-bg:                    var(--#{$vudTokenPrefix}select-item-menu-bg);
// $select-menu-border-color:          var(--#{$vudTokenPrefix}select-item-menu-border-color);
// $select-menu-shadow:                var(--#{$vudTokenPrefix}select-menu-shadow);

// $select-item-text-size:                      var(--#{$vudTokenPrefix}font-size-sm);
$select-item-text-color:                     var(--#{$vudTokenPrefix}select-item-menu-text-color);
// $select-item-disabled-text-color:            var(--#{$vudTokenPrefix}select-item-menu-disabled-text-color);

// $select-item-focus-border-color:             var(--#{$vudTokenPrefix}select-item-menu-focus-border-color);

// $select-item-border-radius:                  var(--#{$vudTokenPrefix}select-item-menu-border-radius);

$select-item-bg:                             var(--#{$vudTokenPrefix}select-item-menu-bg);
$select-item-hover-bg:                       var(--#{$vudTokenPrefix}select-item-menu-hover-bg);
$select-item-selected-bg:                    var(--#{$vudTokenPrefix}select-item-menu-selected-bg);


// *** element/component code ***
// Base 'select' tag style
select {
    &, &.#{$vudClassPrefix}form-control {
        cursor: pointer;
        min-width: rem(160px);
        max-width: 100%;
        height: $spacing-32;
        padding: $spacing-4 $spacing-16;

        color: $select-text-color;

        font: {
            size: $select-text-size;
            weight: $normal;
        }

        line-height: $spacing-16;

        text: {
            align: left;
            overflow: ellipsis;
            shadow: none;
        }

        vertical-align: middle;
        white-space: nowrap;

        border: rem(1px) solid $select-border-color;
        border-radius: $select-border-radius;
        background: {
            color: $select-bg;
            image: $select-arrow-bg-img;
            repeat: no-repeat;
            position: right rem(18px) top (13px);
        }
        box-shadow: $select-shadow;

        appearance: none;

        &[data-multiple] {
            height: auto;
            height: unset;
        }

        // hide arrow in IE/Edge
        &::-ms-expand {
            display: none;
        }

        //### cta button hover state
        &:hover {
            border-color: $select-hover-border-color;
            background-color: $select-hover-bg;
        }

        //### cta button focus state
        &:focus:not(:active) {
            outline: 0;
            border-color: $select-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $select-focus-border-color, $select-shadow;
        }

        //### cta button active/pressed state
        &:active {
            outline: 0;
            border-color: $select-pressed-border-color;
            box-shadow: none;
        }

        //### cta button disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            pointer-events: none;
            color: $select-disabled-text-color;

            border-color: $select-disabled-border-color;
            background-color: $select-disabled-bg;
            background-image: $select-disabled-arrow-bg-img;
            box-shadow: none;
            opacity: 1;
        }

        .has-error &, &.has-error {
            border-color: $select-error-border-color;

            &:focus:not(:active) {
                border-color: $select-error-border-color;
                box-shadow: inset 0 0 0 rem(1px) $select-error-border-color, $select-shadow;
            }
        }
    }
}

option {
    padding: $spacing-4 $spacing-16;
    background-color: $select-item-bg;

    &:hover {
        background-color: $select-item-hover-bg;
    }

    &[selected] {
        background-color: $select-item-selected-bg;
    }
}

// full width for select when in grid
select {
    &, &.#{$vudClassPrefix}form-control {
        [class*="col-xs-"] > &,
        [class*="col-sm-"] > &,
        [class*="col-md-"] > &,
        [class*="col-lg-"] > & {
            width: 100%;
        }
    }
}
