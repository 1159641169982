
// Button Group

//*** scss local variable ***


//*** element/component code ***
// Vertically stacked group buttons
.#{$vudClassPrefix}btn-group-vertical {
    display: inline-block;
    vertical-align: top;

    //# Button base style
    .#{$vudClassPrefix}btn {
        display: block;
        width: 100%;
        max-width: 100%;

        &:not(:first-child):not(:last-child) { border-radius: 0; }

        &:not(:last-child) {
            margin-bottom: 0;

            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        //# Button base style for the second and up button in the group
        + .#{$vudClassPrefix}btn {
            border-top: 0;
        } // END '.btn-group-vertical .btn + .btn'
    } // END '.btn-group-vertical .btn'
} // END '.btn-group-vertical'

