@font-face {
  font-family: 'Open Sans Bold';
  src: url('./OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'Open Sans BoldItalic';
  src: url('./OpenSans-BoldItalic.ttf');
}
@font-face {
  font-family: 'Open Sans ExtraBold';
  src: url('./OpenSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'Open Sans ExtraBoldItalic';
  src: url('./OpenSans-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Open Sans Light';
  src: url('./OpenSans-Light.ttf');
}
@font-face {
  font-family: 'Open Sans LightItalic';
  src: url('./OpenSans-LightItalic.ttf');
}
@font-face {
  font-family: 'Open Sans Regular';
  src: url('./OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'Open Sans RegularItalic';
  src: url('./OpenSans-RegularItalic.ttf');
}
@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('./OpenSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Open Sans SemiBoldItalic';
  src: url('./OpenSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Ubuntu Bold';
  src: url('./Ubuntu-Bold.ttf');
}
@font-face {
  font-family: 'Ubuntu Light';
  src: url('./Ubuntu-Light.ttf');
}
@font-face {
  font-family: 'Ubuntu Medium';
  src: url('./Ubuntu-Medium.ttf');
}
@font-face {
  font-family: 'Ubuntu Regular';
  src: url('./Ubuntu-Regular.ttf');
}
@font-face {
  font-family: 'Ubuntu Thin';
  src: url('./Ubuntu-Thin.ttf');
}
