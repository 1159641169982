
// local variable
// only the light version
$drag-drop-path: $image-path + '/drag-drop' !default;

$drag-drop-border-mask-normal: "#{$drag-drop-path}/drag-drop.svg";
$drag-drop-border-mask-focus: "#{$drag-drop-path}/drag-drop-focus.svg";
$drag-drop-border-mask-highlight: "#{$drag-drop-path}/drag-drop-highlight.svg";
$drag-drop-border-mask-disabled: "#{$drag-drop-path}/drag-drop-disabled.svg";

$drag-drop-border-mask-sm-normal: "#{$drag-drop-path}/drag-drop-sm.svg";
$drag-drop-border-mask-sm-focus: "#{$drag-drop-path}/drag-drop-sm-focus.svg";
$drag-drop-border-mask-sm-highlight: "#{$drag-drop-path}/drag-drop-sm-highlight.svg";
$drag-drop-border-mask-sm-disabled: "#{$drag-drop-path}/drag-drop-sm-disabled.svg";

// $drag-drop-icon: "#{$drag-drop-path}/drag-drop-icon.svg";

// $drag-drop-sm-icon: "#{$drag-drop-path}/drag-drop-sm-icon.svg";

$preview-item-close-icon: "#{$drag-drop-path}/drag-drop-sm-icon.svg";


.drop-area {
    display: flex;
    flex-direction: column;
    position: relative;
    content: "";
    margin: 0 auto rem(24px);
    padding: rem(144px) rem(24px) rem(24px);

    text-align: center;
    border-radius: $border-radius-24;
    background-color: transparent;
    z-index: 1;

    &.drop-area-sm {
        padding: rem(48px) 0 0;
        border-radius: $border-radius-16;

        .drop-area-upload {
            &::before {
                border: {
                    radius: $border-radius-16;
                    width: rem(24px);
                }
                border-image: url($drag-drop-border-mask-sm-normal) 40% space;
            }

            // &::after {
            //     width: 100%;
            //     height: 100%;
            //     top: 0;
            //     left: 0;
            //     padding: rem(8px);

            //     mask-image: url($drag-drop-sm-icon);
            //     mask-position: center center;
            // }

            &:not(.#{$vudClassPrefix}btn) {
                &:focus {
                    &::before {
                        border-image: url($drag-drop-border-mask-sm-focus) 21 stretch;
                    }
                }
            }

            .vismaicon {
                top: rem(8px);
                left: calc(#{'50% - ' rem(12px)});
        
                &:before {
                    width: rem(24px);
                    height: rem(24px);
                }
            }
        }

        &.highlight,
        &:hover {
            .drop-area-upload {
                &::before {
                    border-image: url($drag-drop-border-mask-sm-highlight) 40% space;
                }
            }
        }

        .drop-area-input {
            &:disabled, &.disabled {
                + .drop-area-upload {
                    &:not(.#{$vudClassPrefix}btn) {
                        &::before {
                            border-image: url($drag-drop-border-mask-sm-disabled) 21 space;
                        }
                    }
                }
            }
        }

        .#{$vudClassPrefix}spinner {
            top: calc(#{'50% - ' rem(24px)});
        }
    }

    &.highlight,
    &:hover {
        .drop-area-upload {
            &::before {
                background-color: var(--#{$vudTokenPrefix}drop-area-highlight-bg);
                border-image: url($drag-drop-border-mask-highlight) 37% space;
            }
        }
    }

    .drop-area-input {
        display: none;

        &:disabled, &.disabled {
            + .drop-area-upload {
                pointer-events: none;
                cursor: default;
    
                &::after {
                    pointer-events: none;
                    background-color: var(--#{$vudTokenPrefix}button-disabled-text-color);
                }
    
                &:not(.#{$vudClassPrefix}btn) {
                    &::before {
                        pointer-events: none;
                        border-image: url($drag-drop-border-mask-disabled) 37 space;
                    }
                }
            }
        }
    }

    .drop-area-upload {
        &::before, &::after {
            display: block;
            position: absolute;
            cursor: pointer;
            content: '';

            background-color: transparent;
        }

        &::before {
            cursor: pointer;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            border: {
                radius: $border-radius-24;
                style: solid;
                width: rem(33px);
                color: var(--#{$vudTokenPrefix}drop-area-border-color);
                //image: url($drag-drop-border-mask-normal) 37 space;
                image: url($drag-drop-border-mask-normal) 37% space; 
            }
            z-index: -1;
        }

        // &::after {
        //     width: rem(64px);
        //     height: rem(64px);
        //     top: rem(48px);
        //     left: calc(#{'50% - ' rem(32px)});
        //     padding: rem(12px);

        //     background-color: var(--#{$vudTokenPrefix}icon-bg);
        //     mask: {
        //         image: url($drag-drop-icon);
        //         size: auto;
        //         repeat: no-repeat;
        //         position: 0 0;
        //     }
        // }

        &:focus {
            outline: none;
        }

        &:not(.#{$vudClassPrefix}btn) {
            display: block;
            position: absolute;
            cursor: pointer;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: transparent;
            text-indent: -9999px;

            &:focus {
                &::before {
                    border: {
                        image: url($drag-drop-border-mask-focus) 37 stretch;
                    }
                }
            }
        }

        &.#{$vudClassPrefix}btn {
            margin: 0 auto rem(8px);

            &::before, &::after {
                pointer-events: none;
            }
        }

        .vismaicon {
            position: absolute;
            cursor: pointer;
            top: rem(48px);
            left: calc(#{'50% - ' rem(32px)});
    
            &:before {
                width: rem(64px);
                height: rem(64px);
            }
        }
    }

    .#{$vudClassPrefix}spinner {
        display: none;
        position: absolute;
        pointer-events: none;
        top: calc(#{'50% - ' rem(44px)});
        left: calc(#{'50% - ' rem(24px)});

        &.show {
            display: block;
        }
    }

    p {
        &, &:last-child {
            display: inline-block;
            margin: 0 auto rem(8px);
        }
    }

    *:not(.drop-area-upload) {
        z-index: 1;
    }

    .drop-area-preview {
        max-width: fit-content;
        margin-top: rem(40px);
        margin-bottom: 0;
        text-align: left;

        &.drop-area-preview-list {
            max-width: auto;
            max-width: unset;
        }
    }

    :disabled &, .disabled &,
    &:disabled, &.disabled {
        .drop-area-upload {
            pointer-events: none;
            cursor: default;

            &::after {
                pointer-events: none;
                background-color: var(--#{$vudTokenPrefix}button-disabled-text-color);
            }

            &:not(.#{$vudClassPrefix}btn) {
                &::before {
                    pointer-events: none;
                    border-image: url($drag-drop-border-mask-disabled) 37 space;
                }
            }
        }

        &.drop-area-sm {
            .drop-area-upload {
                &:not(.#{$vudClassPrefix}btn) {
                    &::before {
                        border-image: url($drag-drop-border-mask-sm-disabled) 21 space;
                    }
                }
            }
        }

        .drop-area-preview {
            a, button {
                pointer-events: none;
                cursor: default;
                color: var(--#{$vudTokenPrefix}button-disabled-text-color);
            }

            .preview-item, .preview-item-img {
                box-shadow: none;

                a {
                    height: 100%;
                    display: block;
                }

               .btn-close-icon {
                    display: none;
               }
            }
        }
    }
}

.drop-area-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem(16px);
    position: relative;
    margin-bottom: rem(24px);

    &.drop-area-preview-list {
        flex-direction: column;
        gap: unset;
        padding: 0 rem(24px);

        border-radius: $border-radius-8;
        background-color: var(--#{$vudTokenPrefix}module-container-bg-color);
        box-shadow: var(--#{$vudTokenPrefix}module-container-shadow);

        .preview-item {
            display: block;
            height: auto;
            margin: 0;
            padding: $spacing-12 $spacing-32 $spacing-12 $spacing-2;
            border-radius: 0;
            border-bottom: rem(1px) dashed #C1C3C5;
            background-color: transparent;
            box-shadow: none;
            width: 100%;

            a {
                box-shadow: none;
                padding: rem(7px) 0;
                border-radius: 0;
                max-width: 100%;
            }

            &:last-child {
                border: 0;
            }

            .#{$vudClassPrefix}btn-link {
                top: calc(50% - rem(15px));
                right: 0;
            }
        }
    }

    &.drop-area-preview-alt {
        &.drop-area-preview-list {
            background-color: var(--#{$vudTokenPrefix}drop-area-preview-item-bg);
            
            > a {
                background-color: var(--#{$vudTokenPrefix}drop-area-preview-item-bg);
                //box-shadow: none;
            }
        }

        .preview-item, .preview-item-img {
            a {
                background-color: var(--#{$vudTokenPrefix}drop-area-preview-item-bg);
                //box-shadow: none;
            }
        }
    }

    .preview-item {
        display: inline-block;
        // overflow: hidden;
        position: relative;
        height: rem(48px);

        @media (max-width: $screen-sm-min) {
            width: 100%;
        }

        > a {
            display: block;
            height: 100%;
            padding: rem(14px) rem(58px) $spacing-16 $spacing-16;
            white-space: nowrap;
            text-overflow: ellipsis;
            border-radius: $border-radius-8;
            background-color: var(--#{$vudTokenPrefix}module-container-bg-color);
            box-shadow: var(--#{$vudTokenPrefix}module-container-shadow);
            max-width: rem(360px);
            overflow: hidden;

            @media (max-width: $screen-sm-min) {
                width: 100%;
                max-width: 100%;
            }
        }

        .#{$vudClassPrefix}btn-link {
            position: absolute;
            top: calc(#{'50% - ' rem(16px)});
            right: rem(12px);
            cursor: pointer;
            padding: $spacing-6;
            outline: 0;
            border: rem(2px) solid transparent;
            background-color: transparent;
            box-shadow: none;

            .vismaicon {
                display: block;
                top: 0;
                z-index: 1;
            }

            &:after {
                content:'';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &:hover {
                &:after {
                    background-color: var(--#{$vudTokenPrefix}module-container-hover-bg-color);
                    @include img-squerkle();
                }

                .vismaicon:before {
                    background-color: var(--#{$vudTokenPrefix}icon-bg);
                }
            }

            &:focus {
                &:after {
                    background-color: var(--#{$vudTokenPrefix}anchor-focus-outline);
                    @include img-squerkle-border-thick();
                }
            }

        }

        a .vismaicon {
            &::before { top: rem(1px); }
        }
    }

    .preview-item-img {
        display: inline-block;
        margin: rem(8px) rem(8px) 0 0;
        width: rem(128px);
        height: rem(128px);
        vertical-align: middle;
        position: relative;
        box-sizing: border-box;
        
        > a {
            width: 100%;
            height: 100%;
            padding: rem(16px);
            border-radius: $border-radius-16;
            border: rem(1px) solid transparent;
            background-color: var(--#{$vudTokenPrefix}module-container-bg-color);
            box-shadow: var(--#{$vudTokenPrefix}module-container-shadow);
            display: block;

            &:hover {
                border-color: var(--#{$vudTokenPrefix}anchor-color);
                background-color: var(--#{$vudTokenPrefix}module-container-hover-bg-color);
                box-shadow: var(--#{$vudTokenPrefix}shadow-20);
            }

            &:focus {
                border-color: transparent;
                box-shadow: var(--#{$vudTokenPrefix}shadow-10);
                outline-offset: rem(-1px);
            }

            &.active,
            &:active {
                border-color: var(--#{$vudTokenPrefix}button-pressed-border-color);
                background-color: var(--#{$vudTokenPrefix}button-pressed-bg);
                box-shadow: var(--#{$vudTokenPrefix}shadow-10);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-color: transparent;
            opacity: var(--#{$vudTokenPrefix}drop-area-preview-item-op);
        }

        .#{$vudClassPrefix}btn-link {
            position: absolute;
            cursor: pointer;
            display: flex;
            width: rem(24px);
            height: rem(24px);
            top: rem(-12px);
            right: rem(-12px);
            margin: 0;
            padding: 0;
            text-align: center;
            border-radius: $border-radius-8;
            border: rem(2px) solid transparent;
            outline: 0;
            background-color: var(--#{$vudTokenPrefix}drop-area-preview-item-border-color);
            box-shadow: 0 rem(5px) rem(10px) rgba(0, 135, 224, 0.15);

            &.btn-close-icon {
                background-color: var(--#{$vudTokenPrefix}icon-bg);
                @include img-squerkle();

                &:after {
                    content:'';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .vismaicon {
                    width: 100%;
                    height: 100%;

                    &::before {
                        background-color: $white;
                        top: calc(50% - rem(8px));
                    }
                }

                &:hover {
                    border: 0; 

                    &:after {
                        @include img-squerkle();
                        background-color: var(--#{$vudTokenPrefix}navigation-default-item-hover-bg);
                    }
                }

                &:focus {
                    &:after {
                        @include img-squerkle-border-thick();
                        background-color: var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);
                    }
                }
            }

            &:focus {
                border-color: var(--#{$vudTokenPrefix}drop-area-focus-border-color);
            }
        }
    }
}