
// Button Default Theme

//*** scss local variable ***
//*** Button - confirmatory
$button-default-border-color:                      var(--#{$vudTokenPrefix}button-border-color);
$button-default-hover-border-color:                var(--#{$vudTokenPrefix}button-hover-border-color);
$button-default-focus-border-color:                var(--#{$vudTokenPrefix}button-focus-border-color);
$button-default-pressed-border-color:              var(--#{$vudTokenPrefix}button-pressed-border-color);
$button-default-active-border-color:               var(--#{$vudTokenPrefix}button-active-border-color);
$button-default-disabled-border-color:             var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-default-bg:                                var(--#{$vudTokenPrefix}button-bg);
$button-default-hover-bg:                          var(--#{$vudTokenPrefix}button-hover-bg);
$button-default-pressed-bg:                        var(--#{$vudTokenPrefix}button-pressed-bg);
$button-default-active-bg:                         var(--#{$vudTokenPrefix}button-selected-bg);
$button-default-disabled-bg:                       var(--#{$vudTokenPrefix}button-disabled-bg);

$button-default-shadow:                            var(--#{$vudTokenPrefix}button-shadow);

$button-default-icon-color:                        var(--#{$vudTokenPrefix}button-icon-color);
$button-default-disabled-icon-color:               var(--#{$vudTokenPrefix}button-disabled-icon-color);

$button-default-text-color:                        var(--#{$vudTokenPrefix}button-text-color);
$button-default-disabled-text-color:               var(--#{$vudTokenPrefix}button-disabled-text-color);

//*** Button - destructive
$button-default-destructive-border-color:          var(--#{$vudTokenPrefix}button-destructive-border-color);
$button-default-destructive-hover-border-color:    var(--#{$vudTokenPrefix}button-destructive-hover-border-color);
$button-default-destructive-focus-border-color:    $button-default-focus-border-color;
$button-default-destructive-disabled-border-color: $button-default-disabled-border-color;

$button-default-destructive-bg:                    var(--#{$vudTokenPrefix}button-destructive-bg);
$button-default-destructive-hover-bg:              var(--#{$vudTokenPrefix}button-destructive-hover-bg);
$button-default-destructive-pressed-bg:            var(--#{$vudTokenPrefix}button-destructive-pressed-bg);
$button-default-destructive-active-bg:             var(--#{$vudTokenPrefix}button-destructive-pressed-bg);
$button-default-destructive-disabled-bg:           $button-default-disabled-bg;

$button-default-destructive-text-color:            var(--#{$vudTokenPrefix}button-destructive-text-color);
$button-default-destructive-hover-text-color:      var(--#{$vudTokenPrefix}button-destructive-hover-text-color);
$button-default-destructive-disabled-text-color:   $button-default-disabled-text-color;


//*** element/component code ***
//*** Button - confirmatory
.#{$vudClassPrefix}btn {
    &, &.#{$vudClassPrefix}btn-default {
        color: $button-default-text-color;

        border-color: $button-default-border-color;
        background-color: $button-default-bg;
        box-shadow: $button-default-shadow;

        .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
            &::before {
                background-color: $button-default-icon-color;
            }
        }

        //### '.btn-default' hover state
        &:hover, &.hover, &.hover:hover, &.active:hover {
            border-color: $button-default-hover-border-color;
            background-color: $button-default-hover-bg;
        }

        //### '.btn-default' focus state
        &:focus, &.focus, &.active:focus {
            border-color: $button-default-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $button-default-focus-border-color, $button-default-shadow;
        }

        //### '.btn-default' active/selected state
        &.active {
            border-color: $button-default-active-border-color;
            background-color: $button-default-active-bg;
            box-shadow: none;
        }

        //### '.btn-default' pressed state
        &:active, &.active:active, &.pressed, &.pressed:active {
            border-color: $button-default-pressed-border-color;
            background-color: $button-default-pressed-bg;
            box-shadow: none;
        }

        //### '.btn-default' disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            &, &.active {
                color: $button-default-disabled-text-color;

                border-color: $button-default-disabled-border-color;
                background-color: $button-default-disabled-bg;
                box-shadow: none;

                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-default-disabled-icon-color;
                    }
                }
            }
        }
    }

    //*** Button - destructive
    &.#{$vudClassPrefix}btn-destructive {
        color: $button-default-destructive-text-color;

        border-color: $button-default-destructive-border-color;
        background-color: $button-default-destructive-bg;

        //### '.btn-destructive' hover state
        &:hover, &.hover, &.hover:hover {
            color: $button-default-destructive-hover-text-color;

            border-color: $button-default-destructive-hover-border-color;
            background-color: $button-default-destructive-hover-bg;

            // '.btn-link.btn-destructive' hover state for icon
            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $button-default-destructive-hover-text-color;
                    box-shadow: none;
                }
            }
        }

        //### '.btn-destructive' focus state
        &:focus, &.focus {
            border-color: $button-default-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $button-default-focus-border-color, $button-default-shadow;
        }

        //### '.btn-destructive' pressed/active/selected state
        &:active, &.active, &.active:active, &.pressed, &.pressed:active {
            color: $button-default-destructive-hover-text-color;

            border-color: $button-default-destructive-border-color;
            background-color: $button-default-destructive-pressed-bg;
            box-shadow: none;

            // '.btn-link.btn-destructive' pressed/active/selected state for icon
            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $button-default-destructive-hover-text-color;
                    box-shadow: none;
                }
            }
        }

        //### '.btn-destructive' disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            &, &.active {
                color: $button-default-destructive-disabled-text-color;

                border-color: $button-default-destructive-disabled-border-color;
                background-color: $button-default-destructive-disabled-bg;
                box-shadow: none;
            }
        }

        //### '.btn-destructive .vismaicon'
        .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
            &::before {
                background-color: $button-default-destructive-text-color;
            }
        }
    }
}
