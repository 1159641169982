
// Top navigation base styles

//*** scss local variable ***
$tabs-height:                    rem(41px);

$tabs-text-color:                var(--#{$vudTokenPrefix}tabs-text-color);
$tabs-focus-text-color:          var(--#{$vudTokenPrefix}tabs-focused-text-color);
$tabs-disabled-text-color:       var(--#{$vudTokenPrefix}tabs-disabled-text-color);

$tabs-content-bg:                var(--#{$vudTokenPrefix}tabs-content-bg);
$tabs-content-shadow:            var(--#{$vudTokenPrefix}tabs-content-shadow-color);

$tabs-dropdown-item-hover-bg:    var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
$tabs-dropdown-item-pressed-bg:  var(--#{$vudTokenPrefix}button-pressed-bg);
$tabs-dropdown-item-selected-bg: var(--#{$vudTokenPrefix}dropdown-menu-selected-bg);

//*** element/component code ***
// Tabs definition
.#{$vudClassPrefix}tab-content {
    display: block;
    content: '';
    background-color: $tabs-content-bg;
    box-shadow: $tabs-content-shadow;
}


// Tabs general style
.#{$vudClassPrefix}nav-tabs:not(.#{$vudClassPrefix}navbar-nav) {
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;

    padding: 0 rem(54px) 0 rem(4px);
    margin: 0;

    border: 0;
    background: transparent;
    box-shadow: none;

    list-style: none;
    z-index: 1;

    // tabs items basic style
    .#{$vudClassPrefix}nav-item {
        display: inline-flex;
        position: relative;
        margin: 0;
        padding: 0;

        a {
            display: flex;
            position: relative;
            cursor: pointer;
            justify-content: center;
            line-height: 1;

            color: $tabs-text-color;
            white-space: nowrap;
            text-align: center;
            text-decoration: none;

            outline: 0;
            z-index: 1;

            // tabs items on hover
            &:hover, &.hover {
                color: $tabs-focus-text-color;
            }

            // tabs items on focus
            &:focus:not(:active), &.focus:not(:active) {
                color: $tabs-focus-text-color;
            }
        }

        // tabs items on layer order
        &:nth-last-child(24) { z-index: 24; }
        &:nth-last-child(23) { z-index: 23; }
        &:nth-last-child(22) { z-index: 22; }
        &:nth-last-child(21) { z-index: 21; }
        &:nth-last-child(20) { z-index: 20; }
        &:nth-last-child(19) { z-index: 19; }
        &:nth-last-child(18) { z-index: 18; }
        &:nth-last-child(17) { z-index: 17; }
        &:nth-last-child(16) { z-index: 16; }
        &:nth-last-child(15) { z-index: 15; }
        &:nth-last-child(14) { z-index: 14; }
        &:nth-last-child(13) { z-index: 13; }
        &:nth-last-child(12) { z-index: 12; }
        &:nth-last-child(11) { z-index: 11; }
        &:nth-last-child(10) { z-index: 10; }
        &:nth-last-child( 9) { z-index:  9; }
        &:nth-last-child( 8) { z-index:  8; }
        &:nth-last-child( 7) { z-index:  7; }
        &:nth-last-child( 6) { z-index:  6; }
        &:nth-last-child( 5) { z-index:  5; }
        &:nth-last-child( 4) { z-index:  4; }
        &:nth-last-child( 3) { z-index:  3; }
        &:nth-last-child( 2) { z-index:  2; }
        &:last-child         { z-index:  1; }

        // tabs items selected
        &.active {
            cursor: default;
            pointer-events: none;
            z-index: 29;
        }

        // &.pressed, &:active:not(.disabled) {
        //     a { }
            
        // }

        // tabs items on diusabled state
        &.disabled a, &:disabled a,
        a.disabled, a:disabled {
            &, &:focus {
                cursor: default;
                pointer-events: none;

                color: $tabs-disabled-text-color;
            }
        }
    }


    // tabs overflow dropdown
    .tabdrop {
        overflow: visible;
        position: absolute;
        right: rem(2px);
        margin: 0;
        padding: 0;

        border-right: 0;
        box-shadow: none;
        z-index: 30;

        &.hide {
            display: none;
        }

        .#{$vudClassPrefix}caret { display: none; }

        // tabs overflow dropdown trigger
        .#{$vudClassPrefix}dropdown-toggle {
            display: block;
            content: '';
            overflow: hidden;
            padding: 0;
            width: rem(40px);
            height: $tabs-height;

            text-indent: 9999px;

            outline: 0;
            background: none;
            box-shadow: none;
            mask: unset;
            transition: unset;
            transform: unset;

            &::before, &::after {
                display: none;
                mask: unset;
                -webkit-mask: unset;
            }

            &:hover { background-color: transparent; }

            .icon-align-justify { display: none; }
        }

        // tabs overflow dropdown menu
        .#{$vudClassPrefix}dropdown-menu {
            content: '';
            width: auto;
            width: unset;
            min-width: unset;
            margin: 0;
            top: rem(38px);
            right: rem(0);
            left: auto;

            // tabs overflow dropdown menu items
            li {
                float: unset;
                height: unset;
                padding: rem(2px) 0;
                margin: 0;


                a {
                    width: 100%;
                    height: unset;
                    padding: rem(8px) rem(18px);
                    line-height: $line-height-base;

                    text-align: left;
                    outline: 0;
                    border: 0;
                    background-image: none;

                    &::before, &::after {
                        display: none;
                    }

                    &:hover {
                        background-color: $tabs-dropdown-item-hover-bg;
                    }

                    &:focus {
                        outline: 0;
                    }

                    &:active {
                        border: 0;
                        background-color: $tabs-dropdown-item-pressed-bg;
                    }
                }

                &.active, &:active {
                    background-color: transparent;

                    a {
                        outline: 0;
                        background-color: $tabs-dropdown-item-selected-bg;
                    }
                }
            }
        }
    }

// /* Justified tabs */
    &.#{$vudClassPrefix}nav-justified {
        width: 100%;

        &.hide-tabdrop { padding-right: rem(17px); }

        // tabs items style for justify tabs
        .#{$vudClassPrefix}nav-item {
            flex: 1;

            // tabs overflow for justify tabs
            &.tabdrop {
                right: 0;

                > a {
                    width: rem(44px);
                    height: $tabs-height;
                }
            }

            a {
                width: 100%;
                justify-content: center;
            }
        }
    }
}