
// custom scrollbar
//* Works for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--#{$vudTokenPrefix}scrollbar-thumb-bg) var(--#{$vudTokenPrefix}scrollbar-track-bg);
}

//* Works for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: rem(8px);
  height: rem(8px);
}

*::-webkit-scrollbar-track { background: var(--#{$vudTokenPrefix}scrollbar-track-bg); }

*::-webkit-scrollbar-thumb {
  border-radius: rem(16px);
  border: rem(2px) solid var(--#{$vudTokenPrefix}scrollbar-track-bg);
  background-color: var(--#{$vudTokenPrefix}scrollbar-thumb-bg);

  &:hover {
      border-width: 0;
  }

  &:active {
    background-color: var(--#{$vudTokenPrefix}scrollbar-thumb-drag-bg);
  }
}

html {
  /* 1rem = 10px */
  font-size: var(--#{$vudTokenPrefix}html-font-size);
  background-color: var(--#{$vudTokenPrefix}root-background-color);
}

body {
  font-size: var(--#{$vudTokenPrefix}body-font-size);

  /* added for the fixed footer - footer should always be  */
  padding-bottom: rem(86px);
  position: relative;
  min-height: 100vh;
}

footer {
  width: 100%;
  height: rem(86px);
  position: absolute;
  bottom: 0;
  padding: $spacing-32 0;
  text-align: center;

  img { margin: 0 auto; }
}
