@use '@vismaux/vud/src/scss/settings/config';
@import 'ag-grid-community/styles/ag-grid.css';

@mixin base() {
  --ag-icon-font-family: 'agGridBalham';
  --ag-font-family: var(--font-family-base);
  --ag-font-size: 14px;
  --ag-icon-size: 16px;

  --ag-grid-size: 4px; // Used in basic size/padding/margin computations

  --ag-background-color: var(--table-bg);
  --ag-foreground-color: var(--body-font-color);
  --ag-border-color: var(--neutral-40);
  --ag-secondary-border-color: var(--neutral-20);

  // Header
  --ag-header-height: 48px;
  --ag-header-background-color: #f8f8f8;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-height: 30%;
  --ag-header-column-resize-handle-color: var(--neutral-30);

  // Row
  --ag-row-height: 48px; // Regular 40px, Comfortable 48px, Relaxed 52px
  --ag-row-border-color: var(--neutral-30);
  --ag-row-hover-color: var(--table-hover-bg);
  --ag-odd-row-background-color: var(--table-bg);
  --ag-selected-row-background-color: var(--table-selected-bg);

  // Inputs
  --ag-input-focus-border-color: var(--blue-50);
  --ag-input-focus-box-shadow: 0 0 2px 0.1rem var(--blue-50);
  --ag-input-border-color: var(--input-border-color);
  --ag-disabled-foreground-color: var(--input-disabled-text-color);

  // Checkbox
  --ag-checkbox-background-color: var(--bg-default);
  --ag-checkbox-border-radius: 3px;
  --ag-checkbox-checked-color: var(--blue-50);
  --ag-checkbox-unchecked-color: var(--neutral-30);

  --ag-list-item-height: 20px;

  --ag-subheader-background-color: var(--bg-default);

  // Rows
  --ag-range-selection-border-color: var(--table-active-border-color);
  --ag-range-selection-background-color: var(--table-active-bg);
  --ag-card-shadow: 0 1px 4px 1px var(--table-active-border-color); // puts a box shadow around input when cell in edit mode

  --ag-side-bar-panel-width: 220px;

  // Firefox
  scrollbar-color: var(--scrollbar-thumb-bg) var(--table-bg);

  // Chrome, Edge, and Safari
  *::-webkit-scrollbar-track {
    background: var(--table-bg);
  }

  // When using external header component
  &.has-external-header .ag-root-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
