
//# Pictograms

//*** scss local variable ***
$pictogram-plus-width:       rem(128px);
$pictogram-plus-height:      rem(128px);

$pictogram-plus-default-img: url('#{$image-path}/pictograms_plus.svg');


//*** element/component code ***
//=== Basic pictogram-plus implementation
.visma-pictogram-plus {
    display: inline-block;
    position: relative;
    user-select: none;

    &::before { 
        display: block;
        content: "" !important;
        position: relative;
        width: $pictogram-plus-width;
        height: $pictogram-plus-height;
        margin: 0 auto;

        background: {
            background-color: transparent;
            size: cover; 
            repeat: no-repeat;
        };
        -webkit-mask-image: unset;
        -webkit-mask-image: none;
        mask-image: unset;
        mask-image: none;
    }

    // disabled state
    .disabled &, &.disabled,
    :disabled &, &:disabled {
        pointer-events: none;

        &::before { background-color: transparent !important; }
    }
}

