
// Button group filter type / Segmented controls

//*** scss local variable ***
//*** Button - confirmatory
$segmented-control-bg:                           var(--#{$vudTokenPrefix}segmented-control-bg);
$segmented-control-divider-bg:                   var(--#{$vudTokenPrefix}segmented-control-divider-color);
$segmented-control-border-radius:                var(--#{$vudTokenPrefix}radius-md);
$segmented-control-sm-border-radius:             var(--#{$vudTokenPrefix}radius-sm);

$segmented-control-button-height:                rem(36px);
$segmented-control-sm-button-height:             $spacing-24;

$segmented-control-button-text-color:            var(--#{$vudTokenPrefix}segmented-control-button-text-color);
$segmented-control-button-disabled-text-color:   var(--#{$vudTokenPrefix}button-disabled-text-color);

$segmented-control-button-hover-border-color:    var(--#{$vudTokenPrefix}segmented-control-button-hover-border-color);
$segmented-control-button-focus-border-color:    var(--#{$vudTokenPrefix}segmented-control-button-focus-border-color);
$segmented-control-button-pressed-border-color:  var(--#{$vudTokenPrefix}segmented-control-button-pressed-border-color);
$segmented-control-button-selected-border-color: var(--#{$vudTokenPrefix}segmented-control-button-selected-border-color);

$segmented-control-button-border-radius:         var(--#{$vudTokenPrefix}radius-sm);
$segmented-control-sm-button-border-radius:      rem(4px);

$segmented-control-button-bg:                    var(--#{$vudTokenPrefix}segmented-control-button-bg);
$segmented-control-button-hover-bg:              var(--#{$vudTokenPrefix}segmented-control-button-hover-bg);
$segmented-control-button-pressed-bg:            var(--#{$vudTokenPrefix}segmented-control-button-pressed-bg);
$segmented-control-button-selected-bg:           var(--#{$vudTokenPrefix}segmented-control-button-selected-bg);


//*** element/component code ***
.#{$vudClassPrefix}btn-group {
    &.#{$vudClassPrefix}btn-group-tabs {
        position: inherit;
        margin-bottom: $spacing-8;
        padding: $spacing-4;

        border-radius: $segmented-control-border-radius;
        background-color: $segmented-control-bg;

        .#{$vudClassPrefix}btn {
            &, &.btn-primary {
                position: relative;
                height: $segmented-control-button-height;
                min-width: none;
                min-width: unset;
                margin: 0 $spacing-16 0 0;
                padding: $spacing-8 $spacing-12;

                color: $segmented-control-button-text-color;

                border-radius: $segmented-control-button-border-radius;
                border: 0;
                background-color: $segmented-control-button-bg;
                box-shadow: none;

                &::before {
                    display: inline-block;
                    content: "";
                    position: absolute;
                    width: rem(1px);
                    height: rem(20px);
                    top: $spacing-8;
                    left: rem(-8px);

                    background-color: $segmented-control-divider-bg;
                    box-shadow: none;
                } // END '.btn-group.btn-group-tabs .btn::before'

                &:first-child {
                    &::before { display: none; }
                }

                &:last-child { margin-right: 0; }

                &:hover, &.active:hover {
                    background-color: $segmented-control-button-hover-bg;
                    box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color;
                } // END '.btn-group.btn-group-tabs .btn:hover'

                &:focus:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color;
                } // END '.btn-group.btn-group-tabs .btn:focus'

                &:active, &.active:active {
                    background-color: $segmented-control-button-pressed-bg;
                    box-shadow: none;
                }

                &.active {
                    background-color: $segmented-control-button-selected-bg;
                    box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-selected-border-color;

                    &::before {
                        display: none;
                    }

                    + .#{$vudClassPrefix}btn {
                        &::before {
                            display: none;
                        }
                    } 
                } // END '.btn-group.btn-group-tabs .btn.active'

                &.disabled, &:disabled {
                    &, &:hover, &:focus, &:active {
                        color: $segmented-control-button-disabled-text-color;

                        background-color: transparent;
                        box-shadow: none;
                    }

                    + .#{$vudClassPrefix}btn {
                        box-shadow: none;

                        &:hover, &.active:hover {
                            box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color;
                        }

                        &:focus:not(:active) {
                            box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color;
                        }

                        &:active, &.active:active {
                            box-shadow: none;
                        }

                        &.disabled, &:disabled {
                            &, &:hover, &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }

                .vismaicon {
                    margin: 0;

                    // &.vismaicon-sm {
                    //     + * {
                    //         margin-left: $spacing-4;
                    //     }
                    // }

                    + * {
                        margin-left: $spacing-8;
                    }
                }
            }
        } // END '.btn-group.btn-group-tabs .btn'


        &.#{$vudClassPrefix}btn-group-tabs-sm {
            border-radius: $segmented-control-sm-border-radius;

            .#{$vudClassPrefix}btn {
                &, &.btn-primary {
                    height: $segmented-control-sm-button-height;
                    padding: $spacing-4 $spacing-8;

                    font-size: var(--#{$vudTokenPrefix}font-size-xs); // 13px
                    line-height: 1.38462em;

                    border-radius: $segmented-control-sm-button-border-radius;

                    &::before {
                        height: $spacing-16;
                        top: $spacing-4;
                    }
                }
            }
        }

        &.#{$vudClassPrefix}btn-group-animation {
            &::before {
                display: block;
                position: absolute;
                content: '';
                height: $segmented-control-button-height;
                min-width: 0;
                width: var(--btn-grp-animation-width, 0);

                border-radius: $segmented-control-button-border-radius;
                background-color: $segmented-control-button-selected-bg;
                box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-selected-border-color;

                transform: translateX(var(--btn-grp-animation-offset, 0));
                transition: transform .3s ease-in-out, width .3s ease-in-out;
            }

            &.#{$vudClassPrefix}btn-group-tabs-sm {
                &::before {
                    height: $segmented-control-sm-button-height;

                    border-radius: $segmented-control-sm-button-border-radius;
                }
            }

            .#{$vudClassPrefix}btn {
                &.active, &:active {
                    background-color: transparent;
                    box-shadow: none;
                }

                &.active {
                    &:hover {
                        background-color: $segmented-control-button-hover-bg;
                        box-shadow: inset 0 0 0 rem(1px) $segmented-control-button-hover-border-color;
                    }

                    &:focus:not(:active) {
                        box-shadow: inset 0 0 0 rem(2px) $segmented-control-button-focus-border-color;
                    }

                    &:active {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    &.disabled, &:disabled {
                        box-shadow: none;
                    }
                }
            }
        }
    } // END '.btn-group.btn-group-tabs'
} // END '.btn-group'
