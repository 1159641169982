
// Wizard component
//*** scss local variable ***
$wizard-border-color:          var(--#{$vudTokenPrefix}wizard-border-color);
$wizard-hover-border-color:    var(--#{$vudTokenPrefix}wizard-hover-border-color);
$wizard-focus-border-color:    var(--#{$vudTokenPrefix}wizard-focus-border-color);
$wizard-pressed-border-color:  var(--#{$vudTokenPrefix}button-pressed-border-color);
$wizard-selected-border-color: var(--#{$vudTokenPrefix}wizard-selected-border-color);
$wizard-disabled-border-color: var(--#{$vudTokenPrefix}button-disabled-border-color);

$wizard-bg:                    var(--#{$vudTokenPrefix}wizard-bg);
$wizard-hover-bg:              var(--#{$vudTokenPrefix}wizard-hover-bg);
$wizard-pressed-bg:            var(--#{$vudTokenPrefix}button-pressed-bg);
$wizard-selected-bg:           var(--#{$vudTokenPrefix}wizard-selected-bg);
$wizard-disabled-bg:           var(--#{$vudTokenPrefix}button-disabled-bg);

$wizard-shadow:                none; // var(--#{$vudTokenPrefix}button-shadow);

$wizard-icon-color:            var(--#{$vudTokenPrefix}wizard-icon-color);
$wizard-checkmark-icon:        var(--#{$vudTokenPrefix}wizard-checkmark-icon);
$wizard-disabled-icon-color:   var(--#{$vudTokenPrefix}button-disabled-icon-color);

$wizard-text-size:             var(--#{$vudTokenPrefix}font-size-sm);
$wizard-text-color:            var(--#{$vudTokenPrefix}button-text-color);
$wizard-disabled-text-color:   var(--#{$vudTokenPrefix}button-disabled-text-color);

$wizard-tabdrop-color:                var(--#{$vudTokenPrefix}wizard-tabdrop-color);
$wizard-tabdrop-toggle-border-radius: var(--#{$vudTokenPrefix}radius-xs);
$wizard-tabdrop-item-border-radius:   var(--#{$vudTokenPrefix}radius-sm);

//*** element/component code ***
// Wizard main wrapper
.#{$vudClassPrefix}wizard {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    width: 100%;
    margin: 0;
    padding: 0;

    // wizard item general style
    .#{$vudClassPrefix}wizard-item,
    .#{$vudClassPrefix}wizard-item.#{$vudClassPrefix}dropdown {
        display: block;
        position: relative;
        cursor: pointer;
        flex: 1;

        height: $spacing-48;
        padding: $spacing-12 $spacing-16 $spacing-12 $spacing-32;

        color: $wizard-text-color;

        font: {
            size: $wizard-text-size;
            weight: $normal;
        }
        line-height: $spacing-24;
        text: {
            align: left;
            overflow: ellipsis;
            decoration: none;
            shadow: none;
        }
        vertical-align: middle;
        white-space: nowrap;

        border: {
            width: rem(1px) 0;
            style: solid;
            color: $wizard-border-color transparent;
        }
        border-radius: 0;
        background-color: $wizard-bg;

        outline: 0;
        user-select: none;
        touch-action: manipulation;
        box-sizing: border-box;

        // wizzard arrow general style
        &::before,
        &::after {
            display: block;
            position: absolute;
            content: '';
            width: rem(28px);
            height: rem(28px);
            top: rem(9px);

            border: {
                width: rem(1px) rem(1px) 0 0;
                style: solid;
                color: transparent;
            }
            transform: rotate(45deg) skew(11deg, 11deg);
        } // END '.wizard .wizard-item::before'

        // left arrow part
        &::before {
            left: rem(-14px);

            background-color: transparent;
        } // END '.wizard .wizard-item::before'

        // right arrow part
        &::after {
            right: rem(-14px);

            border-color: $wizard-border-color;
            background-color: $wizard-bg;
        } // END '.wizard .wizard-item::after'

        // first wizard item
        &:first-child {
            border-left: rem(1px) solid $wizard-border-color;

            &::before { display: none; }
        } // END '.wizard .wizard-item:first-child'

        // last wizard item specific style
        &:last-child {
            border-right: rem(1px) solid $wizard-border-color;
            z-index: 1;

            &::after { display: none; }

        } // END '.wizard .wizard-item:last-child'

        // wizard items z-index order
        &:nth-last-child(2)  { z-index:  2; }
        &:nth-last-child(3)  { z-index:  3; }
        &:nth-last-child(4)  { z-index:  4; }
        &:nth-last-child(5)  { z-index:  5; }
        &:nth-last-child(6)  { z-index:  6; }
        &:nth-last-child(7)  { z-index:  7; }
        &:nth-last-child(8)  { z-index:  8; }
        &:nth-last-child(9)  { z-index:  9; }
        &:nth-last-child(10) { z-index: 10; }
        &:nth-last-child(11) { z-index: 11; }
        &:nth-last-child(12) { z-index: 12; }
        &:nth-last-child(13) { z-index: 13; }
        &:nth-last-child(14) { z-index: 14; }
        &:nth-last-child(15) { z-index: 15; }
        &:nth-last-child(16) { z-index: 16; }
        &:nth-last-child(17) { z-index: 17; }
        &:nth-last-child(18) { z-index: 18; }
        &:nth-last-child(19) { z-index: 19; }

        // wizard item on hover state
        &:hover {
            border-color: $wizard-hover-border-color;
            background-color: $wizard-hover-bg;

            &::after {
                border-color: $wizard-hover-border-color;
                background-color: $wizard-hover-bg;
            }
        } // END '.wizard .wizard-item:hover'

        &:has(+ .#{$vudClassPrefix}wizard-item:hover) {
            &::after {
                border-color: $wizard-hover-border-color;
            }
        }

        // wizard item on focus state
        &:focus:not(:active) {
            outline: none;

            border-color: $wizard-focus-border-color;
            box-shadow: inset 0 rem(1px) 0 0 $wizard-focus-border-color, inset 0 rem(-1px) 0 0 $wizard-focus-border-color;

            &::before {
                box-shadow: rem(1px) rem(-1px) 0 0 $wizard-focus-border-color;
            } // END '.wizard .wizard-item:focus:not(:active)::before'

            &::after {
                border-color: $wizard-focus-border-color;
                box-shadow: inset rem(-1px) rem(1px) 0 0 $wizard-focus-border-color;
            }

            &:first-child {
                box-shadow: inset rem(1px) rem(1px) 0 0 $wizard-focus-border-color, inset 0 rem(-1px) 0 0 $wizard-focus-border-color;
            }

            &:last-child {
                box-shadow: inset rem(-1px) rem(1px) 0 0 $wizard-focus-border-color, inset 0 rem(-1px) 0 0 $wizard-focus-border-color;
            }
        } // END '.wizard .wizard-item:focus:not(:active)'

        &:has(+ .#{$vudClassPrefix}wizard-item:focus) {
            &::after {
                border-color: $wizard-focus-border-color;
            }
        }

        // wizard item on pressed state
        &:active {
            border-color: $wizard-pressed-border-color;
            background-color: $wizard-pressed-bg;

            &::before {
                box-shadow: none;
            }

            &::after {
                border-color: $wizard-pressed-border-color;
                background-color: $wizard-pressed-bg;
            }
        } // END '.wizard .wizard-item:active'

        &:has(+ .#{$vudClassPrefix}wizard-item:active) {
            &::after {
                border-color: $wizard-pressed-border-color;
            }
        }

        // wizard item selected and visited general style
        &.visited, &.visited:active, &.visited:focus,
        &.active, &.active:focus {
            pointer-events: none;
            cursor: default;

            border-color: $wizard-selected-border-color;
            background-color: $wizard-selected-bg;
            box-shadow: none;

            &, &:hover, &:focus {
                &::before {
                    box-shadow: none;
                }

                &::after {
                    border-color: $wizard-selected-border-color;
                    background-color: $wizard-selected-bg;
                    box-shadow: none;
                }
            }
        }

        &:has(+ .#{$vudClassPrefix}wizard-item.active),
        &:has(+ .#{$vudClassPrefix}wizard-item.visited) {
            &:not(:hover):not(:focus) {
                &::after {
                    border-color: $wizard-selected-border-color;
                }
            }
        }

        // wizard item on disabled state
        :disabled &, .disabled &,
        &:disabled, &.disabled {
            pointer-events: none;
            cursor: default;

            color: $wizard-disabled-text-color;

            &, &:hover, &:focus:not(:active) {
                border-color: $wizard-disabled-border-color;
                background-color: $wizard-disabled-bg;
                box-shadow: none;

                &::before { box-shadow: none; }

                &::after {
                    background-color: $wizard-disabled-bg;
                    box-shadow: none;
                }
            }

            &:has(+ .#{$vudClassPrefix}wizard-item.disabled),
            &:has(+ .#{$vudClassPrefix}wizard-item:disabled) {
                &::before { box-shadow: none; }

                &::after {
                    border-color: $wizard-disabled-border-color;
                }
            }
        } // END '.wizard .wizard-item:disabled' or '.wizard .wizard-item.disabled'

        &.#{$vudClassPrefix}dropdown {
            max-width: rem(60px);
            margin: 0;
            padding: 0;

            &.open {
                background-color: $wizard-selected-bg;
            }

            &, &.open {
                .#{$vudClassPrefix}dropdown-toggle {
                    background-color: transparent;
                }
            }
        }

        &.hide {
            display: none;
        }

        &:has(.#{$vudClassPrefix}dropdown-toggle:focus:not(:active)) {
            &, &.open {
                border-color: $wizard-focus-border-color;
                box-shadow: inset 0 rem(1px) 0 0 $wizard-focus-border-color, inset 0 rem(-1px) 0 0 $wizard-focus-border-color;

                &:last-child {
                    box-shadow: inset rem(-1px) rem(1px) 0 0 $wizard-focus-border-color, inset 0 rem(-1px) 0 0 $wizard-focus-border-color;
                }

                &::before {
                    box-shadow: rem(1px) rem(-1px) 0 0 $wizard-focus-border-color;
                }
            }
        }

        .#{$vudClassPrefix}dropdown-toggle {
            display: flex;
            content: '';
            position: relative;
            width: 100%;
            height: 100%;

            // dropdown arrow
            &::before {
                display: none;
            }

            &:focus {
                outline: none;
            }

            i {
                position: absolute;
                margin: 0;
                top: rem(21px);
                right: rem(20px);

                &, &::before, &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    width: $wizard-tabdrop-toggle-border-radius;
                    height: $wizard-tabdrop-toggle-border-radius;
                    padding: 0;

                    border-radius: $wizard-tabdrop-toggle-border-radius;
                    background:  $wizard-tabdrop-color;
                }

                &::before {
                    top: 0;
                    left: rem(-10px);
                }

                &::after {
                    top: 0;
                    left: rem(10px);
                }
            }
        }

        .#{$vudClassPrefix}dropdown-menu {
            width: auto;
            margin-top: rem(1px);
            right: 0;

            .#{$vudClassPrefix}wizard-item {
                height: rem(36px);
                padding: 0 rem(18px);
                line-height: rem(36px);

                border: 0;
                border-radius: $wizard-tabdrop-item-border-radius;
                box-shadow: none;

                &::before, &::after {
                    display: none;
                }

                &:hover {
                    box-shadow: inset 0 0 0 rem(1px) $wizard-hover-border-color;
                }

                &:focus:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) $wizard-focus-border-color;
                }

                &:active {
                    box-shadow: inset 0 0 0 rem(1px) $wizard-pressed-border-color;
                }

                &:disabled, &.disabled {
                    background-color: $wizard-disabled-bg;
                    box-shadow: none;
                }

                + .#{$vudClassPrefix}wizard-item {
                    margin-top: $spacing-4;
                }
            }
        }
    } // END '.wizard .wizard-item'
} // END '.wizard'
