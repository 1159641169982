//=== Inverted/secondary style (gray navigation)

//*** scss local variable ***
$vertical-nav-inverted-bg:                          var(--#{$vudTokenPrefix}vertical-nav-secondary-bg);
$vertical-nav-inverted-shadow:                      var(--#{$vudTokenPrefix}vertical-nav-primary-shadow);

$vertical-nav-inverted-border-color:                var(--#{$vudTokenPrefix}vertical-nav-secondary-border-color);
$vertical-nav-inverted-collapsed-border-color:      var(--#{$vudTokenPrefix}vertical-nav-secondary-collapsed-border-color);

$vertical-nav-inverted-text-color:                  var(--#{$vudTokenPrefix}vertical-nav-secondary-item-text);

$vertical-nav-inverted-icon-color:                  var(--#{$vudTokenPrefix}vertical-nav-inverted-icon-color);
$vertical-nav-inverted-icon-disabled-color:         var(--#{$vudTokenPrefix}vertical-nav-inverted-icon-disabled-color);
$vertical-nav-inverted-item-carret-color:           var(--#{$vudTokenPrefix}vertical-nav-secondary-item-text);

$vertical-nav-inverted-item-hover-bg:               var(--#{$vudTokenPrefix}vertical-nav-secondary-item-hover-bg);
$vertical-nav-inverted-item-active-bg:              var(--#{$vudTokenPrefix}vertical-nav-secondary-item-active-bg);
$vertical-nav-inverted-item-active-text-color:      var(--#{$vudTokenPrefix}vertical-nav-inverted-item-active-text-color);
$vertical-nav-inverted-sublvl-open-bg:              var(--#{$vudTokenPrefix}vertical-nav-secondary-item-open-bg);
$vertical-nav-inverted-item-focus-color:            var(--#{$vudTokenPrefix}neutral-05);

$vertical-nav-inverted-collapse-btn-bg:             var(--#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-bg);
$vertical-nav-inverted-collapse-btn-hover-bg:       var(--#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-hover-bg);
$vertical-nav-inverted-collapse-btn-hover-border:   var(--#{$vudTokenPrefix}vertical-nav-inverted-item-hover-border-color);
$vertical-nav-inverted-close-collapse-btn-hover-bg: var(--#{$vudTokenPrefix}vertical-nav-secondary-collapsed-btn-hover-bg);
$vertical-nav-inverted-collapse-btn-focus-color:    var(--#{$vudTokenPrefix}neutral-05);
$vertical-nav-inverted-collapse-icon-bg:            var(--#{$vudTokenPrefix}vertical-nav-primary-collapse-arrow);

$vertical-nav-inverted-badge-bg:                    var(--#{$vudTokenPrefix}vertical-nav-inverted-badge-bg);
$vertical-nav-inverted-active-badge-bg:             var(--#{$vudTokenPrefix}vertical-nav-inverted-item-active-badge-bg);
$vertical-nav-inverted-collapse-badge-bg:           var(--#{$vudTokenPrefix}icon-informative-warning-bg);
$vertical-nav-inverted-collapse-disabled-badge-bg:  var(--#{$vudTokenPrefix}neutral-60);

$vertical-nav-inverted-divider-bg:                  var(--#{$vudTokenPrefix}vertical-nav-inverted-divider-bg);

$vertical-nav-inverted-badge-info-color:            var(--#{$vudTokenPrefix}badge-info-text);
$vertical-nav-inverted-badge-info-bg:               var(--#{$vudTokenPrefix}badge-info-bg);

//*** element/component code ***
.#{$vudClassPrefix}vertical-nav {
    &.#{$vudClassPrefix}vertical-nav-secondary,
    &.#{$vudClassPrefix}vertical-nav-inverted {
        background: $vertical-nav-inverted-bg;
        box-shadow: $vertical-nav-inverted-shadow;

        *::-webkit-scrollbar-track { background-color: $vertical-nav-inverted-bg; }

        *::-webkit-scrollbar-thumb {
            background-color: var(--#{$vudTokenPrefix}scrollbar-track-bg);
            border-color: $vertical-nav-inverted-bg;
        }

        &.hidden-menu {
            border-right: rem(4px) solid $vertical-nav-inverted-border-color;

            &.#{$vudClassPrefix}vertical-nav-collapse { border: 0; }

            li, .menu-item {
                &.active {
                    a {
                        .#{$vudClassPrefix}badge {
                            &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                                background-color: $vertical-nav-inverted-collapse-badge-bg;
                            }
                        }
                    }

                    .disabled {
                        a {
                            .#{$vudClassPrefix}badge {
                                &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                                    background-color: $vertical-nav-inverted-collapse-disabled-badge-bg;
                                }
                            }
                        }
                    }
                }

                &.show-nav {
                    &, &.show-nav-primary {
                        > a {
                            background-color: $vertical-nav-inverted-border-color;

                            &:after { background-color: $vertical-nav-inverted-text-color; }

                            &:hover { background-color: var(--#{$vudTokenPrefix}vertical-nav-secondary-collapsed-btn-hover-bg); }
                        }
                    }
                }
            }
        }

        li, .menu-item {
            &.active {
                &:not(.has-children) {
                    > a {
                        color: $vertical-nav-inverted-item-active-text-color;
                        background-color: $vertical-nav-inverted-item-active-bg;

                        &:hover {
                            background-color: $vertical-nav-inverted-item-active-bg;
                            box-shadow: none;
                        }
                    }

                    .vismaicon {
                        &::before { background-color: $vertical-nav-inverted-item-active-text-color; }
                    }

                    .#{$vudClassPrefix}badge {
                        &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                            color: $vertical-nav-inverted-item-active-text-color;
                            background-color: $vertical-nav-inverted-active-badge-bg;
                        }

                        &.#{$vudClassPrefix}badge-info {
                            color: $vertical-nav-inverted-badge-info-color;
                            background-color: $vertical-nav-inverted-badge-info-bg;
                        }
                    }
                }
            }

            &.active-child{
                > a { background-color: $vertical-nav-inverted-sublvl-open-bg; }
            }


            &.has-children {
                &.active, &.show, &.open {
                    > a {
                        background-color: $vertical-nav-inverted-sublvl-open-bg;

                        &:hover {
                            background-color: $vertical-nav-inverted-item-hover-bg;
                            box-shadow: inset rem(1px) rem(1px) 0 0 $vertical-nav-inverted-collapse-btn-hover-border, inset rem(-1px) 0 0 0 $vertical-nav-inverted-collapse-btn-hover-border;

                            + ul, + .nav {
                                background-color: $vertical-nav-inverted-item-hover-bg;
                                box-shadow: inset rem(1px) 0 0 0 $vertical-nav-inverted-collapse-btn-hover-border, inset rem(-1px) rem(-1px) 0 0 $vertical-nav-inverted-collapse-btn-hover-border;
                            }
                        }

                        + ul, + .nav { background-color: $vertical-nav-inverted-sublvl-open-bg; }
                    }
                }

                > a {
                    &:focus {
                        &::before { outline-color: $vertical-nav-inverted-item-focus-color; }
                    }
                }
            }

            &.disabled {
                opacity: 0.70;

                .vismaicon {
                    &::before { background-color: $vertical-nav-inverted-icon-disabled-color !important; }
                }
            }
            
            &.#{$vudClassPrefix}label { color: $vertical-nav-inverted-text-color; }

            &.divider { border-bottom-color: $vertical-nav-inverted-divider-bg; }
            
            &:not(.show-nav) {
                a {
                    &:hover {
                        background-color: $vertical-nav-inverted-item-hover-bg;
                        box-shadow: inset 0 0 0 rem(1px) $vertical-nav-inverted-collapse-btn-hover-border;
                    }
                }
            }

            &.show-nav {
                &, &.show-nav-primary {
                    > a {
                        background-color: $vertical-nav-inverted-collapse-btn-bg;
                        box-shadow: none;

                        &:after { background-color: $vertical-nav-inverted-collapse-icon-bg; }
                        &:hover { background-color: $vertical-nav-inverted-collapse-btn-hover-bg; }
                    }
                }

                &.show-nav-primary {
                    > a {
                        &:focus { outline: rem(2px) solid $vertical-nav-inverted-collapse-btn-focus-color; }
                    }
                }
            }

            a {
                color: $vertical-nav-inverted-text-color;

                &:after { background-color: $vertical-nav-inverted-item-carret-color; }

                &:focus { outline-color: $vertical-nav-inverted-item-focus-color; }

                .#{$vudClassPrefix}badge {
                    &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                        color: $vertical-nav-inverted-text-color;
                        background-color: $vertical-nav-inverted-badge-bg;
                    }
                }
            }

            .vismaicon {
                &::before { background-color: $vertical-nav-inverted-icon-color; }
            }
        }
    }
}
