//=== Default style (white navigation)

//*** scss local variable ***
$vertical-nav-default-accent-color:               var(--#{$vudTokenPrefix}vertical-nav-default-collapse-btn-bg);
$vertical-nav-default-bg:                         var(--#{$vudTokenPrefix}vertical-nav-default-bg);
$vertical-nav-default-shadow:                     var(--#{$vudTokenPrefix}vertical-nav-default-shadow);

$vertical-nav-default-text-color:                 var(--#{$vudTokenPrefix}vertical-nav-default-item-text-color);
$vertical-nav-default-text-disabled-color:        var(--#{$vudTokenPrefix}vertical-nav-default-item-disabled-text-color);
$vertical-nav-default-label-text-color:           var(--#{$vudTokenPrefix}vertical-nav-default-title-text-color);

$vertical-nav-default-icon-color:                 var(--#{$vudTokenPrefix}vertical-nav-default-icon-color);
$vertical-nav-default-icon-disabled-color:        var(--#{$vudTokenPrefix}vertical-nav-default-icon-disabled-color);
$vertical-nav-default-item-active-text-color:     var(--#{$vudTokenPrefix}vertical-nav-default-item-active-text-color);

$vertical-nav-default-item-hover-border-color:    var(--#{$vudTokenPrefix}vertical-nav-default-item-hover-border-color);
$vertical-nav-default-item-hover-bg:              var(--#{$vudTokenPrefix}vertical-nav-default-item-hover-bg);
$vertical-nav-default-item-active-bg:             var(--#{$vudTokenPrefix}vertical-nav-default-item-active-bg);
$vertical-nav-default-sublvl-open-bg:             var(--#{$vudTokenPrefix}vertical-nav-default-item-open-bg);
$vertical-nav-default-sublelvl-item-active-bg:    var(--#{$vudTokenPrefix}vertical-nav-default-sublvl-active-bg);

$vertical-nav-default-collapse-btn-bg:            var(--#{$vudTokenPrefix}vertical-nav-default-collapse-btn-bg);
$vertical-nav-default-collapse-btn-hover-bg:      var(--#{$vudTokenPrefix}vertical-nav-default-collapse-btn-hover-bg);
$vertical-nav-default-collapse-btn-focus-color:   var(--#{$vudTokenPrefix}anchor-focus-outline);
$vertical-nav-default-collapse-icon-bg:           var(--#{$vudTokenPrefix}neutral-05);

$vertical-nav-default-badge-bg:                   var(--#{$vudTokenPrefix}vertical-nav-default-badge-bg);
$vertical-nav-default-active-badge-bg:            var(--#{$vudTokenPrefix}vertical-nav-default-item-active-badge-bg);
$vertical-nav-default-collapse-badge-bg:          var(--#{$vudTokenPrefix}icon-informative-warning-bg);
$vertical-nav-default-collapse-disabled-badge-bg: var(--#{$vudTokenPrefix}neutral-60);

$vertical-nav-default-divider-bg:                 var(--#{$vudTokenPrefix}vertical-nav-default-divider-bg);

$vertical-nav-default-badge-info-color:           var(--#{$vudTokenPrefix}badge-info-alt-text);
$vertical-nav-default-badge-info-bg:              var(--#{$vudTokenPrefix}badge-info-alt-bg);

//*** element/component code ***
.#{$vudClassPrefix}vertical-nav {
    background-color: $vertical-nav-default-bg;
    box-shadow: $vertical-nav-default-shadow;

    &.hidden-menu {
        border-right: rem(4px) solid $vertical-nav-default-accent-color;

        &.#{$vudClassPrefix}vertical-nav-collapse {
            a {
                .#{$vudClassPrefix}badge {
                    &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                        background-color: $vertical-nav-default-collapse-badge-bg;
                    }
                }
            }

            .active {
                &:not(.has-children) {
                    > a {
                        .#{$vudClassPrefix}badge {
                            &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                                background-color: $vertical-nav-default-collapse-badge-bg;
                            }
                        }
                    }
                }
            }

            .disabled {
                a {
                    .#{$vudClassPrefix}badge {
                        &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                            background-color: $vertical-nav-default-collapse-disabled-badge-bg;
                        }
                    }
                }
            }
        }
    }

    ul, .nav {
        ul, .nav {
            li, .menu-item {
                &.active {
                    > a {
                        font-weight: $bold;

                        &, &:hover { background-color: $vertical-nav-default-sublelvl-item-active-bg; }
                    }
                }
            }
        }
    }

    li, .menu-item {
        &.active {
            &:not(.has-children) {
                > a {
                    color: $vertical-nav-default-item-active-text-color;

                    &:hover { background-color: $vertical-nav-default-item-active-bg; }

                    &:focus { outline: none; }

                    .vismaicon {
                        &::before { background-color: $vertical-nav-default-item-active-text-color; }
                    }

                    .#{$vudClassPrefix}badge {
                        &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                            color: $vertical-nav-default-text-color;
                            background-color: $vertical-nav-default-active-badge-bg;
                        }

                        &.#{$vudClassPrefix}badge-info {
                            color: $vertical-nav-default-badge-info-color;
                            background-color: $vertical-nav-default-badge-info-bg;
                        }
                    }
                }
            }

            > a { background-color: $vertical-nav-default-item-active-bg; }

            .vismaicon {
                &::before { background-color: $vertical-nav-default-icon-color; }
            }
        }

        &.active-child{
            > a { background-color: $vertical-nav-default-sublvl-open-bg; }
        }

        &.has-children {
            &.active, &.show, &.open {
                > a {
                    background-color: $vertical-nav-default-sublvl-open-bg;

                    + ul, + .nav { background-color: $vertical-nav-default-sublvl-open-bg; }

                    &:hover {
                        background-color: $vertical-nav-default-item-hover-bg;
                        box-shadow: inset rem(1px) rem(1px) 0 0 $vertical-nav-default-collapse-btn-focus-color, inset rem(-1px) 0 0 0 $vertical-nav-default-collapse-btn-focus-color;

                        + ul, + .nav {
                            background-color: $vertical-nav-default-item-hover-bg;
                            box-shadow: inset rem(1px) 0 0 0 $vertical-nav-default-collapse-btn-focus-color, inset rem(-1px) rem(-1px) 0 0 $vertical-nav-default-collapse-btn-focus-color;
                        }
                    }
                }
            }

            > a {
                &:hover {
                    box-shadow: inset 0 0 0 rem(1px) $vertical-nav-default-collapse-btn-focus-color;
                }
            }
        }

        &.show-nav {
            > a {
                background-color: $vertical-nav-default-collapse-btn-bg;

                &:after { background-color: $vertical-nav-default-collapse-icon-bg; }
                &:hover {
                    background-color: $vertical-nav-default-collapse-btn-hover-bg;
                    box-shadow: none;
                }
            }

            &.show-nav-primary {
                > a {
                    background-color: $vertical-nav-default-collapse-btn-bg;

                    &:focus { outline: rem(2px) solid $vertical-nav-default-collapse-btn-focus-color; }
                    &:hover {
                        background-color: $vertical-nav-default-collapse-btn-hover-bg;
                        box-shadow: none;
                    }
                }
            }
        }

        &.disabled {
            > a {
                color: $vertical-nav-default-text-disabled-color;

                .vismaicon {
                    &::before { background-color: $vertical-nav-default-icon-disabled-color; }
                }
            }
        }

        a {
            color: $vertical-nav-default-text-color;

            &:hover {
                background-color: $vertical-nav-default-item-hover-bg;
                box-shadow: inset 0 0 0 rem(1px) $vertical-nav-default-item-hover-border-color;
            }

            .#{$vudClassPrefix}badge {
                &:not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger) {
                    color: $vertical-nav-default-text-color;
                    background-color: $vertical-nav-default-badge-bg;
                }
            }
        }

        .vismaicon {
            &::before { background-color: $vertical-nav-default-icon-color; }
        }
    }

    .#{$vudClassPrefix}label { color: $vertical-nav-default-label-text-color; }

    .divider { border-bottom-color: $vertical-nav-default-divider-bg; }
}
