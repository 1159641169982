// Ubuntu - old (deprecated)
// 200 - thin
@font-face {
    font-family: "UbuntuFallback-thin";
    font-style: normal;
    font-weight: 200;
    src: url('#{$font-path}/Ubuntu-Thin.woff2') format('woff2'),
        url('#{$font-path}/Ubuntu-Thin.woff') format('woff');
    }

// 300 - light
@font-face {
    font-family: "UbuntuFallback-light";
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}/Ubuntu-Light.woff2') format('woff2'),
        url('#{$font-path}/Ubuntu-Light.woff') format('woff');
    }

// 600 - semibold
@font-face {
    font-family: "UbuntuFallback-semibold";
    font-style: normal; 
    font-weight: 600;
    src: url('#{$font-path}/Ubuntu-Medium.woff2') format('woff2'),
        url('#{$font-path}/Ubuntu-Medium.woff') format('woff');
}

// 700 - bold
@font-face {
    font-family: "UbuntuFallback-bold";
    // font-style: normal;
    // font-weight: 700;
    src: url('#{$font-path}/Ubuntu-Bold.woff2') format('woff2'),
        url('#{$font-path}/Ubuntu-Bold.woff') format('woff'),
        url('#{$font-path}/Ubuntu-Bold.ttf') format('truetype');
}

// ==== +++ ==== //



// Open Sans
// 300 - light - italic
@font-face {
    font-family: "OpenSansFallback";
    font-style: italic;
    font-weight: 300;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-Light-Italic.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-Light-Italic.woff') format('woff');
    }

// 300 - light - normal
@font-face {
    font-family: "OpenSansFallback";
    font-style: normal;
    font-weight: 300;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-Light.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-Light.woff') format('woff');
    }

// 400 - regular - italic
@font-face {
    font-family: "OpenSansFallback";
    font-style: italic;
    font-weight: 400;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-Regular-Italic.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-Regular-Italic.woff') format('woff');
    }

// 400 - regular - normal
@font-face {
    font-family: "OpenSansFallback";
    font-style: normal;
    font-weight: 400;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-Regular.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-Regular.woff') format('woff');
    }

// 600 - semibold - italic
@font-face {
    font-family: "OpenSansFallback";
    font-style: italic;
    font-weight: 600;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-SemiBold-Italic.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-SemiBold-Italic.woff') format('woff');
}

// 600 - semibold - normal
@font-face {
    font-family: "OpenSansFallback";
    font-style: normal;
    font-weight: 600;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-SemiBold.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-SemiBold.woff') format('woff');
}

// 700 - bold - italic
@font-face {
    font-family: "OpenSansFallback";
    font-style: italic;
    font-weight: 700;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-Bold-Italic.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-Bold-Italic.woff') format('woff');
}

// 700 - bold - normal
@font-face {
    font-family: "OpenSansFallback";
    font-style: normal;
    font-weight: 700;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-Bold.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-Bold.woff') format('woff');
}

// 800 - extrabold - italic
@font-face {
    font-family: "OpenSansFallback";
    font-style: italic;
    font-weight: 800;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-ExtraBold-Italic.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-ExtraBold-Italic.woff') format('woff');
}

// 800 - extrabold - normal
@font-face {
    font-family: "OpenSansFallback";
    font-style: normal;
    font-weight: 800;
    // font-display: swap;
    src: url('#{$font-path}/OpenSans-ExtraBold.woff2') format('woff2'),
        url('#{$font-path}/OpenSans-ExtraBold.woff') format('woff');
}

// ==== +++ ==== //



// Ubuntu
// 200 - thin 
@font-face {
    font-family: "UbuntuFallback";
    font-style: normal;
    font-weight: 200;
    src: url('#{$font-path}/Ubuntu-Thin.ttf') format('truetype');
    }


// 300 - light 
@font-face {
    font-family: "UbuntuFallback";
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}/Ubuntu-Light.ttf') format('truetype');
    }

// 300 - light italic
@font-face {
    font-family: "UbuntuFallback";
    font-style: italic;
    font-weight: 300;
    src: url('#{$font-path}/Ubuntu-Light-Italic.ttf') format('truetype');
    }

// 400 - regular
@font-face {
    font-family: "UbuntuFallback";
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}/Ubuntu-Regular.ttf') format('truetype');
    }

// 400 - regular italic
@font-face {
    font-family: "UbuntuFallback";
    font-style: italic;
    font-weight: 400;
    src: url('#{$font-path}/Ubuntu-Regular-Italic.ttf') format('truetype');
    }

// 500 - medium
@font-face {
    font-family: "UbuntuFallback";
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}/Ubuntu-Medium.ttf') format('truetype');
    }

// 500 - medium italic
@font-face {
    font-family: "UbuntuFallback";
    font-style: italic;
    font-weight: 600;
    src: url('#{$font-path}/Ubuntu-Medium-Italic.ttf') format('truetype');
    }

// 700 - bold
@font-face {
    font-family: "UbuntuFallback";
    font-style: normal;
    font-weight: 700;
    src: url('#{$font-path}/Ubuntu-Bold.ttf') format('truetype');
    }

// 700 - bold italic
@font-face {
    font-family: "UbuntuFallback";
    font-style: italic;
    font-weight: 700;
    src: url('#{$font-path}/Ubuntu-Bold-Italic.ttf') format('truetype');
    }
