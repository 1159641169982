
// // Top navigation - Product dropdown area

//*** scss local variable ***
$navigation-brand-text-font-family:                    $font-family-primary;
$navigation-brand-text-size:                           var(--#{$vudTokenPrefix}font-size-md);
$navigation-brand-dropdown-text-size:                  var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
$navigation-brand-open-text-color:                     var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color);
$navigation-brand-open-item-icon-bg:                   var(--#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg);

$navigation-brand-dropdown-divider-color:              var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);
$navigation-brand-hover-border-color:                  var(--#{$vudTokenPrefix}button-hover-border-color);
$navigation-brand-selected-border-accent-color:        var(--#{$vudTokenPrefix}button-group-selected-border-accent-color);

$navigation-brand-bg:                                  var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg);
$navigation-brand-hover-bg:                            var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);

$navigation-brand-dropdown-item-focus-border-color:    var(--#{$vudTokenPrefix}navbar-brand-item-focus-border-color);
$navigation-brand-dropdown-item-selected-border-color: var(--#{$vudTokenPrefix}dropdown-menu-selected-border-color);

$navigation-brand-dropdown-item-selected-bg:           var(--#{$vudTokenPrefix}dropdown-menu-selected-bg);

//*** element/component code ***
.#{$vudClassPrefix}navbar {
    .#{$vudClassPrefix}navbar-header {
        float: left;
        position: relative;

        .#{$vudClassPrefix}navbar-brand {
            &.open {
                .#{$vudClassPrefix}dropdown-toggle {
                    color: $navigation-brand-open-text-color;
                    background-color: $navigation-brand-bg;

                    &:hover {
                        background-color: $navigation-brand-hover-bg;
                        box-shadow: inset 0 0 0 rem(1px) $navigation-brand-hover-border-color;
                    }

                    &:focus:not(:active) {
                        box-shadow: inset 0 0 0 rem(2px) $navigation-brand-dropdown-item-focus-border-color;
                    }

                    .#{$vudClassPrefix}caret { background-color: $navigation-brand-open-item-icon-bg; }
                }
            }

            .#{$vudClassPrefix}dropdown-toggle {
                outline: 0;
            }

            .#{$vudClassPrefix}dropdown-menu {
                right: auto;
                left: 0;
            }
        }
    }
}

.#{$vudClassPrefix}navbar-brand {
    display: block;
    margin: 0;
    padding: 0;

    font-family: $navigation-brand-text-font-family;
    font-size: $navigation-brand-text-size;
    line-height: 3;

    > a, > button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-12;
        min-width: rem(240px);
        height: $spacing-64;
        padding: $spacing-8 $spacing-16;

        text-decoration: none;
        @include text-overflow;

        &:not(.#{$vudClassPrefix}dropdown-toggle) {
            > .#{$vudClassPrefix}caret { display: none; }
        }

        &.#{$vudClassPrefix}dropdown-toggle {
            .#{$vudClassPrefix}caret {
                position: inherit !important;
                margin-inline-start: auto;
            }

            &::before { display: none; }
        }
    }

    .#{$vudClassPrefix}dropdown-menu {
        /* show scroll if menu items do not fit into the screen */
        max-height: calc(100vh - $spacing-64);
        overflow-y: auto;
        padding: 0;

        z-index: $zindex-navbar-dropdown;

        li {
            overflow: visible;
            padding: 0;

            + li {
                box-shadow:inset 0 rem(1px) 0 0 $navigation-brand-dropdown-divider-color;
            }

            &.active a, &.active button,
            a.active, button.active {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: rem(6px);
                    height: 100%;
                    left: 0;
                    top: 0;

                    background-color: $navigation-brand-selected-border-accent-color;
                }
            }
        }

        a, button {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: $spacing-16;
            min-height: $spacing-64;
            height: auto;
            height: unset;
            padding: $spacing-8 $spacing-16;

            font: $font-md;
            color: $navigation-brand-dropdown-text-size;

            border-radius: 0;

            &.active:not(:active):not(:hover) {
                background-color: $navigation-brand-dropdown-item-selected-bg;
            }

            &.active:not(:active):not(:hover):not(:focus) {
                box-shadow: inset 0 0 0 rem(1px) $navigation-brand-dropdown-item-selected-border-color;
            }

            img {
                height: $spacing-48;
                width: $spacing-48;
            }

            > span {
                display: block;
                width: auto;
                white-space: normal;
            }

            .#{$vudClassPrefix}badge {
                &:last-child {
                    margin-inline-start: auto;
                }
            }
        }

        .disabled {
            a, button {
                img {
                    opacity: 0.55;
                }
            }
        }
    }
}
