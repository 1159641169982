// Cards list - .card-list-primary

//*** scss local variable ***
$card-list-secondary-item-bg: var(--#{$vudTokenPrefix}card-list-secondary-bg);

//*** element/component code ***
.#{$vudClassPrefix}card-list {
    &.#{$vudClassPrefix}card-list-secondary {
        .#{$vudClassPrefix}card-list-item {
            background-color: $card-list-secondary-item-bg;

            .description {
                flex-direction: column;
                align-items: initial;

                font-weight: $semibold;

                .date {
                    text-align: left;
                }
            }
        }
    }
}
