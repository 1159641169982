
//# Informative icons

//*** scss local variable ***
$vismaicon-sm-width:              rem(16px);
$vismaicon-sm-height:             rem(16px);
$vismaicon-md-width:              rem(24px);
$vismaicon-md-height:             rem(24px);
$vismaicon-lg-width:              rem(48px);
$vismaicon-lg-height:             rem(48px);

$vismaicon-error-bg:              var(--#{$vudTokenPrefix}icon-informative-error-bg);
$vismaicon-warning-bg:            var(--#{$vudTokenPrefix}icon-informative-warning-bg);
$vismaicon-success-bg:            var(--#{$vudTokenPrefix}icon-informative-success-bg);
$vismaicon-info-bg:               var(--#{$vudTokenPrefix}icon-informative-info-bg);
$vismaicon-help-bg:               var(--#{$vudTokenPrefix}icon-informative-help-bg);

//*** Informative Standard icons ***
// info
$vismaicon-standard-sm-info-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_info_sm.svg');
$vismaicon-standard-md-info-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_info_md.svg');
$vismaicon-standard-lg-info-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_info_lg.svg');

// help
$vismaicon-standard-sm-help-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_help_sm.svg');
$vismaicon-standard-md-help-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_help_md.svg');
$vismaicon-standard-lg-help-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_help_lg.svg');

// success
$vismaicon-standard-sm-success-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_success_sm.svg');
$vismaicon-standard-md-success-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_success_md.svg');
$vismaicon-standard-lg-success-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_success_lg.svg');

// warning
$vismaicon-standard-sm-warning-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_warning_sm.svg');
$vismaicon-standard-md-warning-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_warning_md.svg');
$vismaicon-standard-lg-warning-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_warning_lg.svg');

// error
$vismaicon-standard-sm-error-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_error_sm.svg');
$vismaicon-standard-md-error-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_error_md.svg');
$vismaicon-standard-lg-error-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons/informative_icon_error_lg.svg');


//*** Informative Dynamic icons ***
// info
$vismaicon-dynamic-sm-info-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_info_sm.svg');
$vismaicon-dynamic-md-info-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_info_md.svg');
$vismaicon-dynamic-lg-info-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_info_lg.svg');

// help
$vismaicon-dynamic-sm-help-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_help_sm.svg');
$vismaicon-dynamic-md-help-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_help_md.svg');
$vismaicon-dynamic-lg-help-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_help_lg.svg');

// success
$vismaicon-dynamic-sm-success-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_success_sm.svg');
$vismaicon-dynamic-md-success-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_success_md.svg');
$vismaicon-dynamic-lg-success-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_success_lg.svg');

// warning
$vismaicon-dynamic-sm-warning-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_warning_sm.svg');
$vismaicon-dynamic-md-warning-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_warning_md.svg');
$vismaicon-dynamic-lg-warning-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_warning_lg.svg');

// error
$vismaicon-dynamic-sm-error-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_error_sm.svg');
$vismaicon-dynamic-md-error-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_error_md.svg');
$vismaicon-dynamic-lg-error-mask-img:  url('#{$image-path}/informative-icons/informative-filled-icons-dynamic/informative_icon_error_lg.svg');


//*** mixins for icons group of dynamyc and standard informative icons ***
@mixin standard-informative-icons {
    &.vismaicon-error {
        &:before {
            mask-image: $vismaicon-standard-md-error-mask-img;
            -webkit-mask-image: $vismaicon-standard-md-error-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-standard-lg-error-mask-img;
            -webkit-mask-image: $vismaicon-standard-lg-error-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-standard-sm-error-mask-img;
            -webkit-mask-image: $vismaicon-standard-sm-error-mask-img;
        }
    }

    &.vismaicon-warning {
        &:before {
            mask-image: $vismaicon-standard-md-warning-mask-img;
            -webkit-mask-image: $vismaicon-standard-md-warning-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-standard-lg-warning-mask-img;
            -webkit-mask-image: $vismaicon-standard-lg-warning-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-standard-sm-warning-mask-img;
            -webkit-mask-image: $vismaicon-standard-sm-warning-mask-img;
        }
    }

    &.vismaicon-info:not(.vismaicon-menu) {
        &:before {
            mask-image: $vismaicon-standard-md-info-mask-img;
            -webkit-mask-image: $vismaicon-standard-md-info-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-standard-lg-info-mask-img;
            -webkit-mask-image: $vismaicon-standard-lg-info-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-standard-sm-info-mask-img;
            -webkit-mask-image: $vismaicon-standard-sm-info-mask-img;
        }
    }

    &.vismaicon-success {
        &:before {
            mask-image: $vismaicon-standard-md-success-mask-img;
            -webkit-mask-image: $vismaicon-standard-md-success-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-standard-lg-success-mask-img;
            -webkit-mask-image: $vismaicon-standard-lg-success-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-standard-sm-success-mask-img;
            -webkit-mask-image: $vismaicon-standard-sm-success-mask-img;
        }
    }

    &.vismaicon-help {
        &:before {
            mask-image: $vismaicon-standard-md-help-mask-img;
            -webkit-mask-image: $vismaicon-standard-md-help-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-standard-lg-help-mask-img;
            -webkit-mask-image: $vismaicon-standard-lg-help-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-standard-sm-help-mask-img;
            -webkit-mask-image: $vismaicon-standard-sm-help-mask-img;
        }
    }
}

@mixin dynamic-informative-icons {
    &.vismaicon-error {
        &:before {
            mask-image: $vismaicon-dynamic-md-error-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-md-error-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-dynamic-lg-error-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-lg-error-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-dynamic-sm-error-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-sm-error-mask-img;
        }
    }

    &.vismaicon-warning {
        &:before {
            mask-image: $vismaicon-dynamic-md-warning-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-md-warning-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-dynamic-lg-warning-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-lg-warning-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-dynamic-sm-warning-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-sm-warning-mask-img;
        }
    }

    &.vismaicon-info:not(.vismaicon-menu) {
        &:before {
            mask-image: $vismaicon-dynamic-md-info-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-md-info-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-dynamic-lg-info-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-lg-info-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-dynamic-sm-info-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-sm-info-mask-img;
        }
    }

    &.vismaicon-success {
        &:before {
            mask-image: $vismaicon-dynamic-md-success-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-md-success-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-dynamic-lg-success-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-lg-success-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-dynamic-sm-success-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-sm-success-mask-img;
        }
    }

    &.vismaicon-help {
        &:before {
            mask-image: $vismaicon-dynamic-md-help-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-md-help-mask-img;
        }

        &.vismaicon-lg:before {
            mask-image: $vismaicon-dynamic-lg-help-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-lg-help-mask-img;
        }

        &.vismaicon-sm:before {
            mask-image: $vismaicon-dynamic-sm-help-mask-img;
            -webkit-mask-image: $vismaicon-dynamic-sm-help-mask-img;
        }
    }
}

//*** element/component code ***
.vismaicon {
    &.vismaicon-filled {
        &:before {
            width: $vismaicon-md-width;
            height: $vismaicon-md-height;
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
            mask-size: auto;
            -webkit-mask-size: auto;
        }

        &.vismaicon-sm:before {
            width: $vismaicon-sm-width;
            height: $vismaicon-sm-height;
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
            mask-size: auto;
            -webkit-mask-size: auto;
        }

        &.vismaicon-lg:before {
            width: $vismaicon-lg-width;
            height: $vismaicon-lg-height;
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
            mask-size: auto;
            -webkit-mask-size: auto;
        }

        &.vismaicon-error {
            &:before { background-color: $vismaicon-error-bg; }
        }

        &.vismaicon-warning {
            &:before { background-color: $vismaicon-warning-bg; }
        }

        &.vismaicon-info:not(.vismaicon-menu) {
            &:before { background-color: $vismaicon-info-bg; }
        }

        &.vismaicon-success {
            &:before { background-color: $vismaicon-success-bg; }
        }

        &.vismaicon-help {
            &:before { background-color: $vismaicon-help-bg; }
        }

        @if $vismaicon-style != 'dynamic' {

            @include standard-informative-icons;

            &.vismaicon-dynamic {
                @include dynamic-informative-icons;
            }

        } @else {

            @include dynamic-informative-icons;

            &.vismaicon-standard,
            &.vismaicon-classic {
                @include standard-informative-icons;
            }
        }
    }
}
