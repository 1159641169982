// Cards list - .card-list-default

//*** scss local variable ***
$card-list-default-item-active-border-color:   var(--#{$vudTokenPrefix}card-list-selected-border-accent);

//*** element/component code ***
.#{$vudClassPrefix}card-list {
    &.#{$vudClassPrefix}card-list-default {
        .#{$vudClassPrefix}card-list-item {
            padding: $spacing-8 $spacing-16;

            overflow: hidden;

            &.selected, &.active {
                &:before {
                    display: block;
                    content:'';
                    position: absolute;
                    width: rem(6px);
                    height: 100%;
                    left: 0;
                    top: 0;

                    background-color: $card-list-default-item-active-border-color;
                }
            }
        }
    }
}
