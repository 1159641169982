$h-header: 13.5rem;
$h-footer: 20px;
$h-header-footer: calc(#{$h-header} + #{$h-footer});
$h-title: 69px;
$h-thead: 49px;
$h-vb-title-stepper: 96px;
$h-vb-buttons: 48px;
$h-buttons: 48px;
$h-vb-year: 23px;
$h-vb-thead-tfoot: 141px;
$h-vb-outside-tbody: calc(
  #{$h-header} + #{$h-vb-title-stepper} + #{$h-vb-buttons} + #{$h-vb-year} + #{$h-vb-thead-tfoot}
);
$h-content: calc(#{$h-header-footer} + #{$h-title});
$h-outside-tbody: calc(#{$h-content} + #{$h-buttons} + #{$h-thead});
$checkboxColMinWidth: 45px;

.h-page {
  min-height: calc(100vh - #{$h-footer} - #{$h-header});
}

.h-content {
  height: calc(100vh - #{$h-content});
}

.h-min-container {
  min-height: calc(100vh - #{$h-header-footer});
}

.h-min-vb {
  min-height: calc(100vh - #{$h-header-footer} - #{$h-vb-title-stepper});
}

.h-min-content {
  min-height: calc(100vh - #{$h-content});
}

.h-max-vb-tbody {
  max-height: #{$h-vb-outside-tbody};
}

.h-max-tbody {
  max-height: calc(100vh - #{$h-outside-tbody});
}

.mw-300 {
  max-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-self-center {
  align-self: center;
}

.font-size-16 {
  font-size: 16px;
}

.toast-transparent {
  padding: 15px 35px 15px 35px;
  margin-bottom: 25px;
  line-height: 1.6;
  position: relative;

  .vismaicon {
    position: absolute;
    display: block;
    left: 10px;
  }

  &:not(.with-title) {
    .vismaicon {
      top: calc(50% - 8px);
    }
  }

  &.with-title {
    .vismaicon {
      top: 17px;
    }
  }
}

.import-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.mw-300 {
  min-width: 300px;
}

.w-90 {
  width: 90%;
}

.w-70 {
  width: 70%;
}

.no-overflow {
  overflow: unset !important;
}

.has-errors input {
  border: 1px solid var(--input-error-text-color);
}

input.number {
  text-align: right;
}

.required-label {
  &::before {
    position: absolute;
    content: '*';
    margin-left: -7px;
    top: 5px;
    color: var(--text-error);
    font-size: 12px;
  }
  &.required-top::before {
    top: 0;
  }
}

.alert-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  z-index: 1;
}

.disabled-state {
  pointer-events: none;
  opacity: 0.4;
}

// Show tooltip on dropdown menu when it's disabled
.dropdown-menu .disabled .vismaicon {
  pointer-events: auto;
}
.dropdown-menu > li.disabled:hover {
  background: none;
}

.small {
  font-size: 80%;
}

di-employee-card {
  h2 {
    scroll-margin-top: calc(#{$h-header} + 80px); // 80px - fixed breadcrumbs and h1
  }
}

.ml-1 {
  margin-left: 1px;
}
