
//Tooltip general style

//*** scss local variable ***
$tooltip-border-radius:          var(--#{$vudTokenPrefix}radius-sm);
$tooltip-border-color:           var(--#{$vudTokenPrefix}tooltip-border-color);
$tooltip-primary-border-color:   var(--#{$vudTokenPrefix}tooltip-primary-border-color);
$tooltip-secondary-border-color: var(--#{$vudTokenPrefix}tooltip-secondary-border-color);
$tooltip-error-border-color:     var(--#{$vudTokenPrefix}tooltip-error-border-color);

$tooltip-bg:                     var(--#{$vudTokenPrefix}tooltip-bg);
$tooltip-primary-bg:             var(--#{$vudTokenPrefix}tooltip-primary-bg);
$tooltip-secondary-bg:           var(--#{$vudTokenPrefix}tooltip-secondary-bg);
$tooltip-error-bg:               var(--#{$vudTokenPrefix}tooltip-error-bg);

$tooltip-text-color:             var(--#{$vudTokenPrefix}tooltip-text);
$tooltip-primary-text-color:     var(--#{$vudTokenPrefix}tooltip-primary-text);
$tooltip-secondary-text-color:   var(--#{$vudTokenPrefix}tooltip-secondary-text);
$tooltip-error-text-color:       var(--#{$vudTokenPrefix}tooltip-error-text);

$tooltip-shadow:                 var(--#{$vudTokenPrefix}tooltip-shadow);
$tooltip-primary-shadow:         var(--#{$vudTokenPrefix}tooltip-primary-shadow);
$tooltip-secondary-shadow:       var(--#{$vudTokenPrefix}tooltip-secondary-shadow);
$tooltip-error-shadow:           var(--#{$vudTokenPrefix}tooltip-error-shadow);


//*** element/component code ***
.#{$vudClassPrefix}tooltip {
    display: none;
    position: absolute;
    min-width: rem(180px);
    max-width: rem(420px);
    padding: $spacing-16;
    top: 0;
    left: 0;

    color: $tooltip-text-color;
    text-align: left;

    border-radius: $tooltip-border-radius;
    border: {
        width: rem(1px);
        style: solid;
        color: $tooltip-border-color;
    }

    background-color: $tooltip-bg;
    box-shadow: $tooltip-shadow;

    z-index: $zindex-tooltip;

    &::before, &::after {
        display: block;
        position: absolute;
        content: '';
        width: rem(10px);
        height: rem(24px);
        top: calc(#{'50% - ' rem(12px)});
        left: rem(-10px);

        background-image: none;
    }

    &::before {
        clip-path: $tooltip-clip-path-bg;
        background-color: $tooltip-bg;
        width: rem(11px);
    }

    &::after {
        clip-path: $tooltip-clip-path-border;
        background-color: $tooltip-border-color;
    }

    &.#{$vudClassPrefix}tooltip-error {
        color: $tooltip-error-text-color;

        border-color: $tooltip-error-border-color;
        background-color: $tooltip-error-bg;
        box-shadow: $tooltip-error-shadow;

        &::before {
            background-color: $tooltip-error-bg;
        }

        &::after {
            background-color: $tooltip-error-border-color;
        }
    }

    &.#{$vudClassPrefix}tooltip-primary {
        color: $tooltip-primary-text-color;

        border-color: $tooltip-primary-border-color;
        background-color: $tooltip-primary-bg;
        box-shadow: $tooltip-primary-shadow;

        &::before {
            background-color: $tooltip-primary-bg;
        }

        &::after {
            background-color: $tooltip-primary-border-color;
        }
    }

    &.#{$vudClassPrefix}tooltip-secondary {
        color: $tooltip-secondary-text-color;

        border-color: $tooltip-secondary-border-color;
        background-color: $tooltip-secondary-bg;
        box-shadow: $tooltip-secondary-shadow;

        &::before {
            background-color: $tooltip-secondary-bg;
        }

        &::after {
            background-color: $tooltip-secondary-border-color;
        }
    }

    &.top,
    &[data-popper-placement="top"] {
        text-align: center;
        margin-bottom: $spacing-12 !important;

        &::before, &::after {
            top: unset;
            bottom: rem(-17px);
            left: calc(#{'50% - ' rem(5px)});
            transform: rotate(-90deg);
        }

        &::before {
            left: calc(#{'50% - ' rem(5.5px)});
        }
    }

    &.right,
    &[data-popper-placement="right"] {
        text-align: left;
        margin-left: $spacing-12 !important;
    }

    &.bottom,
    &[data-popper-placement="bottom"] {
        text-align: center;
        margin-top: $spacing-12 !important;

        &::before, &::after {
            top: rem(-17px);
            left: calc(#{'50% - ' rem(5px)});
            transform: rotate(90deg);
        }

        &::before {
            left: calc(#{'50% - ' rem(5.5px)});
        }
    }

    &.left,
    &[data-popper-placement="left"] {
        text-align: right;
        margin-right: $spacing-12 !important;

        &::before, &::after {
            right: rem(-10px);
            left: unset;

            transform: rotate(180deg);
        }
    }

    &.in, &.show {
        display: block;
    }

    .#{$vudClassPrefix}tooltip-inner {
        line-height: rem(18px);
    }
}