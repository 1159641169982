
// Button Primary Theme

//*** scss local variable ***
//*** Button - confirmatory
$button-primary-border-color:                      var(--#{$vudTokenPrefix}button-primary-border-color);
$button-primary-hover-border-color:                var(--#{$vudTokenPrefix}button-primary-hover-border-color);
$button-primary-focus-border-color:                var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$button-primary-active-border-color:               var(--#{$vudTokenPrefix}button-primary-active-border-color);
$button-primary-pressed-border-color:              var(--#{$vudTokenPrefix}button-primary-selected-border-color);
$button-primary-disabled-border-color:             var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-primary-bg:                                var(--#{$vudTokenPrefix}button-primary-bg);
$button-primary-hover-bg:                          var(--#{$vudTokenPrefix}button-primary-hover-bg);
$button-primary-active-bg:                         var(--#{$vudTokenPrefix}button-primary-selected-bg);
$button-primary-pressed-bg:                        var(--#{$vudTokenPrefix}button-primary-active-bg);
$button-primary-disabled-bg:                       var(--#{$vudTokenPrefix}button-disabled-bg);

$button-primary-shadow:                            var(--#{$vudTokenPrefix}button-primary-shadow);
$button-primary-focus-inner-shadow-color:          var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);

$button-primary-icon-color:                        var(--#{$vudTokenPrefix}button-primary-icon);
$button-primary-disabled-icon-color:               var(--#{$vudTokenPrefix}button-disabled-icon-color);

$button-primary-text-color:                        var(--#{$vudTokenPrefix}button-primary-text-color);
$button-primary-disabled-text-color:               var(--#{$vudTokenPrefix}button-disabled-text-color);

//*** Button - destructive
$button-primary-destructive-border-color:          var(--#{$vudTokenPrefix}button-destructive-border-color);
$button-primary-destructive-hover-border-color:    var(--#{$vudTokenPrefix}button-primary-destructive-hover-border-color);
$button-primary-destructive-focus-border-color:    $button-primary-focus-border-color;
$button-primary-destructive-active-border-color:   var(--#{$vudTokenPrefix}button-primary-destructive-active-border-color);
$button-primary-destructive-disabled-border-color: $button-primary-disabled-border-color;

$button-primary-destructive-bg:                    var(--#{$vudTokenPrefix}button-primary-destructive-bg);
$button-primary-destructive-hover-bg:              var(--#{$vudTokenPrefix}button-primary-destructive-hover-bg);
$button-primary-destructive-active-bg:             var(--#{$vudTokenPrefix}button-primary-destructive-active-bg);
$button-primary-destructive-disabled-bg:           $button-primary-disabled-bg;

$button-primary-destructive-shadow:                $button-primary-shadow;
$button-primary-destructive-focus-inner-shadow-color: $button-primary-focus-inner-shadow-color;

$button-primary-destructive-disabled-text-color:   $button-primary-disabled-text-color;


//*** element/component code ***
//*** Button - confirmatory
.#{$vudClassPrefix}btn {
    &.#{$vudClassPrefix}btn-primary {
        color: $button-primary-text-color;

        border-color: $button-primary-border-color;
        background-color: $button-primary-bg;
        box-shadow: $button-primary-shadow;

        .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
            &::before {
                background-color: $button-primary-icon-color;
            }
        }

        //### '.btn-primary' hover state
        &:hover, &.hover, &.hover:hover, &.active:hover {
            border-color: $button-primary-hover-border-color;
            background-color: $button-primary-hover-bg;
        }

        //### '.btn-primary' focus state
        &:focus, &.focus, &.active:focus {
            border-color: $button-primary-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $button-primary-focus-border-color, inset 0 0 0 rem(2px) $button-primary-focus-inner-shadow-color, $button-primary-shadow;
        }

        //### '.btn-primary' active/selected state
        &.active, &.active:active, &.pressed, &.pressed:active {
            border-color: $button-primary-active-border-color;
            background-color: $button-primary-active-bg;
            box-shadow: none;
        }

        //### '.btn-primary' pressed/active state state
        &:active, &.active:active, &.pressed, &.pressed:active {
            border-color: $button-primary-pressed-border-color;
            background-color: $button-primary-pressed-bg;
            box-shadow: none;
        }

        //### '.btn-primary' disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            &, &.active {
                color: $button-primary-disabled-text-color;

                border-color: $button-primary-disabled-border-color;
                background-color: $button-primary-disabled-bg;
                box-shadow: none;

                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-primary-disabled-icon-color;
                    }
                }
            }
        }

        //*** Button - destructive
        //### '.btn-primary.btn-destructive' normal state
        &.#{$vudClassPrefix}btn-destructive {
            border-color: $button-primary-destructive-border-color;
            background-color: $button-primary-destructive-bg;
            box-shadow: $button-primary-destructive-shadow;

            //### '.btn-primary.btn-destructive' hover state
            &:hover, &.hover, &.hover:hover {
                border-color: $button-primary-destructive-hover-border-color;
                background-color: $button-primary-destructive-hover-bg;
            }

            //### '.btn-primary.btn-destructive' focus state
            &:focus, &.focus {
                border-color: $button-primary-destructive-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $button-primary-destructive-focus-border-color, inset 0 0 0 rem(2px) $button-primary-destructive-focus-inner-shadow-color, $button-primary-destructive-shadow;
            }

            //### '.btn-primary.btn-destructive' pressed/active/selected state
            &:active, &.active, &.active:active, &.pressed, &.pressed:active {
                border-color: $button-primary-destructive-active-border-color;
                background-color: $button-primary-destructive-active-bg;
                box-shadow: none;
            }

            // //### '.btn-primary' pressed/active state
            // &:active, &.active:active, &.pressed:active { background-image: none; }

            //### '.btn-primary.btn-destructive' disabled state
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &, &.active {
                    color: $button-primary-destructive-disabled-text-color;

                    border-color: $button-primary-destructive-disabled-border-color;
                    background-color: $button-primary-destructive-disabled-bg;
                    box-shadow: none;
                }
            }
        }
    }
}
