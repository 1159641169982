
// Tabs graphics variables
// deprecated local variables - unused anymore
$tabs-default-corner-bg:                     $tabs-graph-path + '/tab-corner.svg';
$tabs-default-corner-bg-flip:                $tabs-graph-path + '/tab-corner-flip.svg';
$tabs-default-middle-bg:                     $tabs-graph-path + '/tab-middle.svg';
$tabs-default-dropdown-trigger-bg:           $tabs-graph-path + '/more-tabs-btn.svg';

$tabs-default-background-size:               rem(25px) rem(692px);
$tabs-default-background-size-corner:        rem(26px) rem(692px);
$tabs-default-overflow-icon-background-size: rem(490px) rem(24px);

$tabs-normal-Ypoition:                       var(--#{$vudTokenPrefix}tabs-normal-Ypoition); // #{rem(0px)};
$tabs-disabled-Ypoition:                     var(--#{$vudTokenPrefix}tabs-disabled-Ypoition); // #{rem(-50px)};
$tabs-hover-Ypoition:                        var(--#{$vudTokenPrefix}tabs-hover-Ypoition); // #{rem(-100px)};
$tabs-pressed-Ypoition:                      var(--#{$vudTokenPrefix}tabs-pressed-Ypoition); // #{rem(-150px)};
$tabs-focus-Ypoition:                        var(--#{$vudTokenPrefix}tabs-focus-Ypoition); // #{rem(-200px)};
$tabs-focus-hover-Ypoition:                  var(--#{$vudTokenPrefix}tabs-focus-hover-Ypoition); // #{rem(-250px)};
$tabs-selected-Ypoition:                     var(--#{$vudTokenPrefix}tabs-selected-Ypoition); // #{rem(-250px)};
$tabs-selected-focused-Ypoition:             var(--#{$vudTokenPrefix}tabs-selected-focused-Ypoition); // #{rem(-350px)};

$tabs-dropdown-Ypoition:                     var(--#{$vudTokenPrefix}tabs-dropdown-Ypoition); // #{rem(4px)};
$tabs-dropdown-normal-Xpoition:              var(--#{$vudTokenPrefix}tabs-dropdown-normal-Xpoition); // #{rem(4px)};
$tabs-dropdown-hover-Xpoition:               var(--#{$vudTokenPrefix}tabs-dropdown-hover-Xpoition); // #{rem(-45px)};
$tabs-dropdown-focus-Xpoition:               var(--#{$vudTokenPrefix}tabs-dropdown-focus-Xpoition); // #{rem(-94px)};
$tabs-dropdown-pressed-Xpoition:             var(--#{$vudTokenPrefix}tabs-dropdown-pressed-Xpoition); // #{rem(-143px)};
$tabs-dropdown-disabled-Xpoition:            var(--#{$vudTokenPrefix}tabs-dropdown-disabled-Xpoition); // #{rem(-192px)};


//*** scss local variable ***
$tabs-default-min-width:                     rem(51px);
$tabs-default-height:                        rem(41px);
$tabs-default-overflow-trigger-width:        rem(37px);
$tabs-default-overflow-trigger-height:       rem(21px);

$tabs-default-content-border-color:          var(--#{$vudTokenPrefix}tabs-content-border-color);

$tabs-default-border-color:                  var(--#{$vudTokenPrefix}tabs-border-color);
$tabs-default-hover-border-color:            var(--#{$vudTokenPrefix}tabs-hover-border-color);
$tabs-default-focus-border-color:            var(--#{$vudTokenPrefix}tabs-focus-border-color);
$tabs-default-pressed-border-color:          var(--#{$vudTokenPrefix}tabs-pressed-border-color);
$tabs-default-selected-border-color:         var(--#{$vudTokenPrefix}tabs-selected-border-color);
$tabs-default-disabled-border-color:         var(--#{$vudTokenPrefix}tabs-disabled-border-color);

$tabs-default-background-color:              var(--#{$vudTokenPrefix}tabs-bg);
$tabs-default-hover-background-color:        var(--#{$vudTokenPrefix}tabs-hover-bg);
$tabs-default-pressed-background-color:      var(--#{$vudTokenPrefix}tabs-pressed-bg);
$tabs-default-disabled-background-color:     var(--#{$vudTokenPrefix}tabs-disabled-bg);

$tabs-default-dots-color:                    var(--#{$vudTokenPrefix}tabs-dots-bg);
$tabs-default-dots-disabled-color:           var(--#{$vudTokenPrefix}tabs-dots-disabled-bg);

//*** element/component code ***
.#{$vudClassPrefix}tab-content {
    border-top: rem(2px) solid $tabs-default-content-border-color;
}

// Tabs general style
.#{$vudClassPrefix}nav-tabs:not(.#{$vudClassPrefix}navbar-nav) {
    height: $tabs-default-height;
    top: rem(3px);

    .#{$vudClassPrefix}nav-item {
        margin-right: rem(-13px);

        a {
            align-items: center;
            height: $tabs-default-height;
            min-width: $tabs-default-min-width;
            padding: 0 rem(28px);

            // tabs items corners graphics
            &::before, &::after {
                display: flex;
                content: '';
                position: absolute;
                width: 100%;
                height: $tabs-default-height;
                bottom: 0;
                left: 0;

                z-index: -1;
            }

            // tabs background path shape
            &::before {
                background-color: $tabs-default-background-color;
                clip-path: $tab-clip-path-bg;
            }

            // tabs border parth shape
            &::after {
                background-color: $tabs-default-border-color;
                clip-path: $tab-clip-path-border-thin;
            }

            
            &:hover, &.hover {
                &::before { background-color: $tabs-default-hover-background-color; }
                &::after { background-color: $tabs-default-hover-border-color; }
            }

            // tabs items on pressed
            &:active {
                &::before { background-color: $tabs-default-pressed-background-color; }
                &::after { background-color: $tabs-default-pressed-border-color; }
            }
        }

        // tabs items on focus state
        &.focus:not(:active), &:focus-within:not(:active), &:has(a.focus:not(:active)) {
            &::after {
                display: flex;
                pointer-events: none;
                content: '';
                position: absolute;
                width: calc(100% - rem(42px));
                height: rem(24px);
                top: rem(8px);
                left: rem(21px);

                border: rem(2px) solid $tabs-default-focus-border-color;
                background-color: transparent;
                z-index: 1;
            }
        }

        // tabs items selected
        &.active {
            a {
                &::before {
                    background-color: $tabs-default-background-color;
                    clip-path: $tab-clip-path-bg-selected;
                }

                &::after {
                    background-color: $tabs-default-selected-border-color;
                    clip-path: $tab-clip-path-border-selected;
                }
            }

            // tabs items selected and focused
            &.focus, a:focus, a.focus {
                &::after {
                    background-color: $tabs-default-selected-border-color;
                    clip-path: $tab-clip-path-border-selected;
                }
            }
        }

        // remove focus on tabs items on disabled state
        &.disabled, &:disabled,
        &:has(a.disabled), &:has(a:disabled) {
            &.focus, &:focus-within, &:has(a.focus), &:has(a:focus) {
                &::after { display: none;}
            }
        }

        // tabs items on disabled state
        &.disabled a, &:disabled a,
        a.disabled, a:disabled {
            pointer-events: none;

            &, &:focus, &:hover {
                &::before { background-color: $tabs-default-disabled-background-color; }
                &::after { background-color: $tabs-default-disabled-border-color; }
            }
        }
    }


    // tabs overflow dropdown
    .#{$vudClassPrefix}tabdrop {
        // tabs overflow dropdown trigger
        .#{$vudClassPrefix}dropdown-toggle {
            width: $tabs-default-overflow-trigger-width;
            height: $tabs-default-overflow-trigger-height;
            top: rem(8px);

            background-color: $tabs-default-background-color;
            clip-path: $tab-dropdown-clip-path-bg;

            &::before, &::after {
                display: flex;
                content: '';
                position: absolute;
                width: $tabs-default-overflow-trigger-width;
                height: $tabs-default-overflow-trigger-height;
                top: 0;
                left: 0;
                mask: unset;
                transition: unset;
                transform: unset;
            }

            // border shape for tabs overflow dropdown on normal state
            &::before {
                clip-path: $tab-dropdown-clip-path-border-thin;
                background-color: $tabs-default-border-color;
            }

            // inner dots shape for tabs overflow dropdown on normal state
            &::after {
                clip-path: $tab-dropdown-clip-path-dots;
                background-color: $tabs-default-dots-color;
            }

            // tabs overflow dropdown trigger on hover
            &:hover {
                background-color: $tabs-default-hover-background-color;

                &::before { background-color: $tabs-default-hover-border-color; }
            }

            // tabs overflow dropdown trigger on focus
            &:focus {
                &:not(:active) {
                    &::before {
                        background-color: $tabs-default-focus-border-color;
                        clip-path: $tab-dropdown-clip-path-border-thick;
                    }
                }

                // &:hover {
                //     background-color: $tabs-default-hover-background-color;
                // }
            }

            // tabs overflow dropdown trigger on pressed
            &:active {
                background-color: $tabs-default-pressed-background-color;

                &::before {
                    background-color: $tabs-default-pressed-border-color;
                }
            }
        }

        .dropdown-menu {
            .#{$vudClassPrefix}nav-item {
                &.focus, &:focus-within, &:has(a.focus) {
                    &::after { display: none; }
                }
            }
        }

        // tabs overflow dropdown trigger on open/show dropdown-menu
        &.open, &.show {
            .#{$vudClassPrefix}dropdown-toggle {
                background-color: $tabs-default-pressed-background-color;

                &::before {
                    background-color: $tabs-default-pressed-border-color;
                }

                // tabs overflow dropdown trigger on hover
                &:hover {
                    background-color: $tabs-default-hover-background-color;

                    &::before { background-color: $tabs-default-hover-border-color; }
                }
            }
        }

        // tabs overflow dropdown trigger on disabled state
        &.disabled .#{$vudClassPrefix}dropdown-toggle,
        &:disabled .#{$vudClassPrefix}dropdown-toggle,
        .#{$vudClassPrefix}dropdown-toggle.disabled,
        .#{$vudClassPrefix}dropdown-toggle:disabled {
            pointer-events: none;

            &, &:focus, &:hover {
                background-color: $tabs-default-disabled-background-color;

                &::before { background-color: $tabs-default-disabled-border-color; }
                &::after { background-color: $tabs-default-dots-disabled-color; }
            }
        }
    }
}
