
//# Toasts

//*** scss local variable ***
$toast-border-radius:        var(--#{$vudTokenPrefix}radius-sm);
$toast-icon-mask:            url('#{$image-path}/informative-icons/16_informative_icons_dynamic.svg');
$toast-ancor-text-color:     var(--#{$vudTokenPrefix}anchor-color);

$toast-success-bg:           var(--#{$vudTokenPrefix}alert-success-bg);
$toast-success-icon-bg:      var(--#{$vudTokenPrefix}icon-informative-success-bg);
$toast-success-text:         var(--#{$vudTokenPrefix}alert-success-text);
$toast-success-border-color: var(--#{$vudTokenPrefix}alert-success-border);
$toast-success-shadow:       var(--#{$vudTokenPrefix}alert-success-shadow);

$toast-info-bg:              var(--#{$vudTokenPrefix}alert-info-bg);
$toast-info-icon-bg:         var(--#{$vudTokenPrefix}icon-informative-info-bg);
$toast-info-text:            var(--#{$vudTokenPrefix}alert-info-text);
$toast-info-border-color:    var(--#{$vudTokenPrefix}alert-info-border);
$toast-info-shadow:          var(--#{$vudTokenPrefix}alert-info-shadow);

$toast-warning-bg:           var(--#{$vudTokenPrefix}alert-warning-bg);
$toast-warning-icon-bg:      var(--#{$vudTokenPrefix}icon-informative-warning-bg);
$toast-warning-text:         var(--#{$vudTokenPrefix}alert-warning-text);
$toast-warning-border-color: var(--#{$vudTokenPrefix}alert-warning-border);
$toast-warning-shadow:       var(--#{$vudTokenPrefix}alert-warning-shadow);

$toast-danger-bg:            var(--#{$vudTokenPrefix}alert-danger-bg);
$toast-danger-icon-bg:       var(--#{$vudTokenPrefix}icon-informative-error-bg);
$toast-danger-text:          var(--#{$vudTokenPrefix}alert-danger-text);
$toast-danger-border-color:  var(--#{$vudTokenPrefix}alert-danger-border);
$toast-danger-shadow:        var(--#{$vudTokenPrefix}alert-danger-shadow);

//*** element/component code ***
.#{$vudClassPrefix}toast {
    display: block;
    position: absolute;
    max-width: rem(450px);
    padding: $spacing-16 $spacing-40;
    margin-bottom: $spacing-16;
    top: $spacing-40;
    left: calc(#{'50% - ' rem(225px)});

    color: $toast-info-text;
    line-height: $line-height-sm;

    border: {
        radius: $toast-border-radius;
        width: rem(1px);
        style: solid;
        color: $toast-info-border-color;
    }
    background: $toast-info-bg;
    box-shadow: $toast-info-shadow;

    z-index: 1001;

    &:before {
        display: block;
        content:'';
        position: absolute;
        width: rem(16px);
        height: rem(16px);
        top: rem(17px);
        left: $spacing-16;

        background-color: $toast-info-icon-bg;
        mask: $toast-icon-mask;
        -webkit-mask: $toast-icon-mask;
        mask-position: rem(-63px) 0;
        -webkit-mask-position: rem(-63px) 0;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: auto;
        -webkit-mask-size: auto;
        }

    .vismaicon-filled { display: none; }

    &.#{$vudClassPrefix}toast-success {
        color: $toast-success-text;

        border-color: $toast-success-border-color;
        background-color: $toast-success-bg;
        box-shadow: $toast-success-shadow;

        &:before {
            background-color: $toast-success-icon-bg;
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
        }
    }

    &.#{$vudClassPrefix}toast-error,
    &.#{$vudClassPrefix}toast-danger {
        color: $toast-danger-text;

        border-color: $toast-danger-border-color;
        background-color: $toast-danger-bg;
        box-shadow: $toast-danger-shadow;

        &:before {
            background-color: $toast-danger-icon-bg;
            mask-position: rem(-21px) 0;
            -webkit-mask-position: rem(-21px) 0;
        }
    }

    &.#{$vudClassPrefix}toast-warning {
        color: $toast-warning-text;

        border-color: $toast-warning-border-color;
        background-color: $toast-warning-bg;
        box-shadow: $toast-warning-shadow;

        &:before {
            background-color: $toast-warning-icon-bg;
            mask-position: rem(-42px) 0;
            -webkit-mask-position: rem(-42px) 0;
        }
    }

    //Close button
    .#{$vudClassPrefix}close {
        position: absolute;
        top: calc(#{'50% - ' rem(6px)});
        right: rem(17px);
    }
}
