@use '@vismaux/ngx-vud';
//@import '~@vismaux/nordic-cool/dist/css/nc.light-dark.mode.min.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'assets/fonts/styles';
@import 'assets/styles/icons';
@import 'assets/styles/variables';
@import 'assets/styles/elements';
@import 'assets/styles/vud-extras';
@import 'assets/styles/tables';
@import 'assets/styles/print_settings';

@tailwind utilities;

@import '../../../libs/hrm-ag-grid/src/assests/styles/ag-theme-hrm/ag-theme-hrm';

html,
body {
  height: 100%;
  padding: 0;
  font-family: 'Open Sans', OpenSansFallback, sans-serif;
}

body {
  &.nav-space {
    padding-top: $h-header;
  }

  &.nav-space-settings {
    padding-top: calc($h-header - 64px);
  }
}

.loading {
  opacity: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  z-index: -1;
}

di-root:empty + .loading {
  opacity: 1;
  z-index: 100;
}
