// Cards list - .card-list-primary

// deprecated cards list variant
//*** element/component code ***
.#{$vudClassPrefix}card-list {
    &.#{$vudClassPrefix}card-list-primary {
        .#{$vudClassPrefix}card-list-item {
            box-shadow: none;

            .description {
                flex-direction: column;
                align-items: initial;
            }
        }
    }
}
