
// Tabs primary style

//*** scss local variable ***
$tabs-primary-height:                              rem(58px);

$tabs-primary-text-color:                          var(--#{$vudTokenPrefix}tabs-text-color);
$tabs-primary-disabled-text-color:                 var(--#{$vudTokenPrefix}tabs-disabled-text-color);

$tabs-primary-border-radius:                       var(--#{$vudTokenPrefix}radius-sm);
$tabs-primary-border-color:                        var(--#{$vudTokenPrefix}module-container-divider);
$tabs-primary-focus-border-color:                  var(--#{$vudTokenPrefix}tabs-focus-border-color);
$tabs-primary-selected-border-color:               var(--#{$vudTokenPrefix}tabs-content-border-color);

$tabs-primary-hover-text-color:                    var(--#{$vudTokenPrefix}tabs-hover-text-color);

$tabs-primary-content-border-color:                var(--#{$vudTokenPrefix}tabs-content-primary-border-color);
$tabs-primary-content-bg:                          var(--#{$vudTokenPrefix}tabs-content-bg);

$tabs-primary-dropdown-item-border-radius:         var(--#{$vudTokenPrefix}radius-sm);
$tabs-primary-dropdown-item-hover-border-color:    var(--#{$vudTokenPrefix}dropdown-menu-hover-border-color);
$tabs-primary-dropdown-item-focus-border-color:    var(--#{$vudTokenPrefix}button-focus-border-color);
$tabs-primary-dropdown-item-pressed-border-color:  var(--#{$vudTokenPrefix}button-pressed-border-color);
$tabs-primary-dropdown-item-selected-border-color: var(--#{$vudTokenPrefix}dropdown-menu-selected-border-color);

//*** element/component code ***
.#{$vudClassPrefix}nav-tabs:not(.#{$vudClassPrefix}navbar-nav) {
    &.#{$vudClassPrefix}nav-tabs-primary {
        width: 100%;
        height: $tabs-primary-height;
        padding-left: rem(16px);
        top: 0;

        border-radius: $tabs-primary-border-radius $tabs-primary-border-radius 0 0;
        border-bottom: rem(1px) solid $tabs-primary-border-color;
        background-color: $tabs-primary-content-bg;

        .#{$vudClassPrefix}nav-item {
            margin: 0;

            a {
                height: $tabs-primary-height;
                padding: rem(16px);

                &::before, &::after {
                    display: none;
                    clip-path: unset;
                }

                &::before {
                    width:  calc(#{'100% - ' rem(32px)});
                    height: rem(4px);
                    left: rem(16px);
                }

                &:hover, &.hover {
                    color: $tabs-primary-hover-text-color;
                }

                &:focus:not(:active), &.focus:not(:active) {
                    outline: 0;

                    &:after {
                        display: flex;
                        width: 100%;
                        height: rem(30px);
                        bottom: unset;

                        border: rem(2px) solid $tabs-primary-focus-border-color;
                        background-color: transparent;
                        clip-path: unset;
                    }
                }

                &:active:focus {
                    &:after { display: none; }
                }
            }

            // tabs items on pressed state
            &.active, &.pressed, &:active:not(.disabled) {
                a {
                    color: $tabs-primary-text-color;

                    &::before {
                        display: flex;
                        background-color: $tabs-primary-selected-border-color;
                    }
                }
            }

            &.focus, &:focus-within, &:has(a.focus) {
                &::after { display: none; }
            }

            &.pressed, &:active:not(.disabled) {
                a {
                    &, &::after, &::before { border-bottom: 0; }
                }
                
            }

            // tabs items on diusabled state
            &.disabled a, &:disabled a,
            a.disabled, a:disabled {
                &, &:focus { color: $tabs-primary-disabled-text-color; }
            }
        }

        .#{$vudClassPrefix}dropdown-menu {
            a {
                justify-content: start;
                border-radius: 0;
                box-shadow: none;

                &:focus:not(:active), &.focus:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) $tabs-primary-focus-border-color;
                }
            }
        }

        .#{$vudClassPrefix}dropdown {
            .#{$vudClassPrefix}dropdown-menu {
                top: rem(57px);

                .#{$vudClassPrefix}nav-item {
                    display: block;

                    &.active, &:active {
                        a {
                            box-shadow: inset 0 0 0 rem(1px) $tabs-primary-dropdown-item-selected-border-color;

                            &::before { display: none; }
                        }
                    }

                    a {
                        border-radius: $tabs-primary-dropdown-item-border-radius;

                        &:hover {
                            box-shadow: inset 0 0 0 rem(1px) $tabs-primary-dropdown-item-hover-border-color;
                        }

                        &:focus:not(:active), &.focus:not(:active) {
                            box-shadow: inset 0 0 0 rem(2px) $tabs-primary-dropdown-item-focus-border-color;

                            &:after { display: none; }
                        }

                        &:active {
                            box-shadow: inset 0 0 0 rem(1px) $tabs-primary-dropdown-item-pressed-border-color;
                        }
                    }
                }
            }
        }

        & + .#{$vudClassPrefix}tab-content {
            border-radius: 0 0 $tabs-primary-border-radius $tabs-primary-border-radius;
            border-top: rem(1px) solid $tabs-primary-content-border-color;
        }


        // tabs overflow dropdown
        .tabdrop {
            position: relative;
            right: auto;

            // tabs overflow dropdown trigger
            .#{$vudClassPrefix}dropdown-toggle {
                width: rem(58px);
                padding: rem(16px);
                top: rem(13px);
                background-color: transparent;
                clip-path: unset;

                &::before, &::after {
                    display: none;
                    background-color: transparent;
                    clip-path: unset;
                }

                .icon-align-justify {
                    left: rem(16px);

                    &, &::before, &::after {
                        display: flex;
                        content:'';
                        width: rem(5px);
                        height: rem(5px);
                        position: absolute;
                        top: calc(50% - rem(2.5px));

                        text-indent: 9999px;
                        background-color: $tabs-primary-text-color;
                        border-radius: $border-radius-circle;
                    }

                    &:before { left: rem(10px); }
                    &:after { left: rem(20px); }
                }

                &:hover {
                    .icon-align-justify {
                        &, &:after, &:before {
                            background-color: $tabs-primary-hover-text-color;
                        }
                    }
                }

                &:focus:not(:active), &.focus:not(:active) {
                    outline: 0;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: rem(30px);
                        top: calc(50% - rem(15px));
                        left: 0;

                        border: rem(2px) solid $tabs-primary-focus-border-color;
                    }
                }
            }

            &.open, &.show {
                .#{$vudClassPrefix}dropdown-toggle { background-color: transparent; }
            }

            &.disabled .#{$vudClassPrefix}dropdown-toggle,
            &:disabled .#{$vudClassPrefix}dropdown-toggle,
            .#{$vudClassPrefix}dropdown-toggle.disabled,
            .#{$vudClassPrefix}dropdown-toggle:disabled {
                .icon-align-justify {
                    &, &:after, &:before {
                        background-color: $tabs-primary-disabled-text-color;
                    }
                }
            }
        }
    }
}
