
//Search field style

//*** scss local variable ***
// search-field default
$search-field-border-color:                        var(--#{$vudTokenPrefix}input-border-color);
$search-field-hover-border-color:                  var(--#{$vudTokenPrefix}input-hover-border-color);
$search-field-focus-border-color:                  var(--#{$vudTokenPrefix}input-focus-border-color);
$search-field-disabled-border-color:               var(--#{$vudTokenPrefix}input-disabled-border-color);

$search-field-border-radius:                       var(--#{$vudTokenPrefix}radius-xl);

$search-field-bg:                                  var(--#{$vudTokenPrefix}input-bg);
$search-field-disabled-bg:                         var(--#{$vudTokenPrefix}input-disabled-bg);

$search-field-shadow:                              var(--#{$vudTokenPrefix}shadow-10);
$search-field-focus-shadow:                        var(--#{$vudTokenPrefix}input-focus-shadow);

$search-field-placeholder-color:                   var(--#{$vudTokenPrefix}input-placeholder-color);

$search-field-disabled-text-color:                 var(--#{$vudTokenPrefix}input-disabled-text-color);

$search-field-btn-pressed-border-color:            var(--#{$vudTokenPrefix}button-pressed-border-color);

$search-field-btn-bg:                               var(--#{$vudTokenPrefix}input-bg);
$search-field-btn-hover-bg:                         var(--#{$vudTokenPrefix}button-hover-bg);
$search-field-btn-pressed-bg:                       var(--#{$vudTokenPrefix}button-pressed-bg);
$search-field-btn-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);


// search-field - primary
$search-field-primary-border-color:                 var(--#{$vudTokenPrefix}input-primary-border-color);
$search-field-primary-hover-border-color:           var(--#{$vudTokenPrefix}input-primary-hover-border-color);
$search-field-primary-bg:                           var(--#{$vudTokenPrefix}input-primary-bg);
$search-field-primary-shadow:                       var(--#{$vudTokenPrefix}input-primary-shadow);

$search-field-primary-btn-bg:                       var(--#{$vudTokenPrefix}button-primary-bg);
$search-field-primary-btn-hover-bg:                 color-mix(in oklch, black 12%, #{$search-field-primary-btn-bg});
$search-field-primary-btn-pressed-bg:               var(--#{$vudTokenPrefix}button-primary-active-bg);

$search-field-primary-btn-focus-inner-shadow-color: var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);

$search-field-primary-btn-icon-color:               var(--#{$vudTokenPrefix}button-primary-icon);

$search-field-primary-disabled-button:              var(--#{$vudTokenPrefix}search-field-primary-disabled-button);
$search-field-primary-disabled-button-border:       var(--#{$vudTokenPrefix}search-field-primary-disabled-button-border);


//*** element/component code ***
.search-group {
    position: relative;

    // input basic styling
    input.#{$vudClassPrefix}form-control[type=text],
    input.#{$vudClassPrefix}form-control[type=search] {
        padding-right: $spacing-64;
        padding-left: $spacing-40;

        border-radius: $search-field-border-radius;
        background-color: $search-field-bg;
        box-shadow: $search-field-shadow;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.hover, &:hover { box-shadow: $search-field-shadow; }

        &.focus, &:focus {
            box-shadow: inset 0 0 0 rem(1px) $search-field-focus-border-color, $search-field-focus-shadow;
        }

        // search input on disabled state
        .disabled &, :disabled &,
        &.disabled, &:disabled {
            pointer-events: none;
            color: $search-field-disabled-text-color;
            box-shadow: none;

            // search icon/button on disabled state
            ~ .search-icon {
                border-color: $search-field-disabled-border-color;
                background-color: $search-field-btn-disabled-bg;
                box-shadow: none;

                &::before { background-color: $search-field-disabled-text-color; }

                &.btn { pointer-events: none; }
            }

            // clear 'x' button, on disabled state
            ~ .clear-search.show {display: none;}
        }
    }

    // search icon/button and clear 'x' button basic style
    .search-icon,
    .clear-search {
        content: '';
        position: absolute;
        min-width: auto;
        min-width: unset;
        height: rem(16px);
        width: rem(16px);
        margin: 0;
        padding: 0;
        text-indent: rem(-9999px);
        overflow: hidden;

        border: 0;
        box-shadow: none;
    }

    // search icon/button
    .search-icon {
        top: $spacing-8;
        left: $spacing-16;

        // search icon style definition
        &::before {
            display: block;
            position: absolute;
            opacity: 1;
            content: "";
            width: rem(13px);
            height: rem(13px);
            top: rem(1px);
            left: rem(1px);

            background-color: $search-field-placeholder-color;
            -webkit-mask: #{$search-icon};
            mask: #{$search-icon};
            -webkit-mask-size: cover;
            mask-size: cover;
        }

        // search button style definition
        &.#{$vudClassPrefix}btn {
            display: flex;
            cursor: pointer;
            width: rem(48px);
            height: rem(24px);
            top: $spacing-4;
            right: $spacing-4;
            left: initial;
            left: unset;
            text-indent: rem(-9999px);

            border-radius: $search-field-border-radius;
            border: rem(1px) solid $search-field-border-color;
            background-color: $search-field-btn-bg;
            box-shadow: $search-field-shadow;

            &::before {
                top: rem(5px);
                left: auto;
            }

            &:hover {
                border-color: $search-field-hover-border-color;
                background-color: $search-field-btn-hover-bg;
            }

            &:focus:not(:active) {
                border-color: $search-field-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $search-field-focus-border-color, $search-field-shadow;
            }

            &:active {
                border-color: $search-field-btn-pressed-border-color;
                background-color: $search-field-btn-pressed-bg;
                box-shadow: none;
            }

            .disabled &, :disabled &,
            &.disabled, &:disabled {
                border-color: $search-field-disabled-border-color;
                background-color: $search-field-btn-disabled-bg;
                box-shadow: none;
            }
        }
    }

    // clear 'x' button
    .clear-search {
        display: none;
        cursor: pointer;
        top: $spacing-8;
        right: rem(12px);

        // the 'x' icon style definition
        &::before {
            display: block;
            position: absolute;
            opacity: 1;
            content: "";
            width: rem(8px);
            height: rem(8px);
            top: $spacing-4;
            right: $spacing-4;

            background-color: $search-field-placeholder-color;
            -webkit-mask: #{$close-icon};
            mask: #{$close-icon};
            -webkit-mask-size: cover; 
            mask-size: cover;
        }

        &.show { display: block; }
    }

    // variant/primary style for search fields
    &.search-group-primary {
        input.#{$vudClassPrefix}form-control[type=text],
        input.#{$vudClassPrefix}form-control[type=search] {
            border-color: $search-field-primary-border-color;
            background-color: $search-field-primary-bg;
            box-shadow: $search-field-primary-shadow;

            &.hover, &:hover {
                border-color: $search-field-primary-hover-border-color;
                box-shadow: $search-field-primary-shadow;
            }

            &.focus, &:focus {
                border-color: $search-field-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $search-field-focus-border-color, $search-field-shadow;
            }

            // primary search fields on disabled state
            .disabled &, :disabled &,
            &.disabled, &:disabled {
                border-color: $search-field-disabled-border-color;
                box-shadow: none;
            }
        }

        // search button for primary search fields
        .search-icon {
            &.#{$vudClassPrefix}btn {
                border-color: $search-field-primary-btn-bg;
                background-color: $search-field-primary-btn-bg;
                box-shadow: $search-field-primary-shadow;

                &::before {
                    background-color: $search-field-primary-btn-icon-color;
                }

                &:hover {
                    border-color: $search-field-primary-btn-hover-bg;
                    background-color: $search-field-primary-btn-hover-bg;
                }

                &:focus:not(:active) {
                    border-color: $search-field-focus-border-color;
                    box-shadow: inset 0 0 0 rem(1px) $search-field-focus-border-color, inset 0 0 0 rem(2px) $search-field-primary-btn-focus-inner-shadow-color, $search-field-primary-shadow;
                }

                &:active {
                    border-color: $search-field-primary-btn-pressed-bg;
                    background-color: $search-field-primary-btn-pressed-bg;
                }

                .disabled &, :disabled &,
                &.disabled, &:disabled {
                    border-color: $search-field-primary-disabled-button-border;
                    background-color: $search-field-primary-disabled-button;
                    box-shadow: none;
                }
            }
        }
    }

    // search fields with button variant styling
    &.search-group-button {
        input.#{$vudClassPrefix}form-control[type=text],
        input.#{$vudClassPrefix}form-control[type=search] {
            padding-left: $spacing-16;
            padding-right: rem(80px);
        }

        .clear-search.show {
            right: $spacing-64;
        }

        &.search-group-lg {
            .clear-search.show {
                right: rem(84px);
            }
        }
    }

    // larger search field style
    &.search-group-lg {
        input.#{$vudClassPrefix}form-control[type=text],
        input.#{$vudClassPrefix}form-control[type=search] {
            height: $input-btn-height-lg;
            padding-right: rem(72px);
            padding-left: $spacing-64;
        }

        // search icon/button for larger search field
        .search-icon {
            top: $spacing-16;
            left: $spacing-32;

            &.#{$vudClassPrefix}btn {
                height: rem(40px);
                width: rem(64px);
                top: rem(4px);
                left: auto;

                &::before {
                    top: rem(12px);
                }
            }

            &, &.#{$vudClassPrefix}btn {
                &::before {
                    width: rem(15px);
                    height: rem(15px);
                }
            }
        }

        .clear-search {
            top: $spacing-16;
            right: $spacing-32;
        }

        // larger search field for primary variant
        &.search-group-primary {
            .search-icon {
                &.#{$vudClassPrefix}btn {
                    width: rem(64px);
                }
            }
        }

        // larger search field with search button
        &.search-group-button {
            input.#{$vudClassPrefix}form-control[type=text],
            input.#{$vudClassPrefix}form-control[type=search] {
                padding-left: $spacing-32;
            }

            &.search-group-primary {
                input.#{$vudClassPrefix}form-control[type=text],
                input.#{$vudClassPrefix}form-control[type=search] {
                    padding-right: 10rem;
                }
            }
        }
    }
}

