﻿table {
  box-shadow: none !important;

  tr {
    &.hide-mobile {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &.table-row-wrapper {
      width: 100%;

      &:not(:last-child) td.border-bottom-grey {
        border-bottom: 1px solid #f3f4f6;

        &-mobile {
          border-bottom: 1px solid #f3f4f6;
        }

        @media (max-width: 768px) {
          border: 0;
        }
      }
    }
  }

  td,
  th {
    height: 35px;
  }

  th {
    color: #007aca;
    font-weight: normal;

    &.mobile-title {
      width: 30%;
    }
  }

  td {
    &.mobile-cell {
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
}
