// Stepper - base styling

//*** scss local variable ***
$stepper-text-size:           var(--#{$vudTokenPrefix}font-size-sm);
$stepper-step-text-size:      var(--#{$vudTokenPrefix}font-size-xs);
$stepper-text-color:          var(--#{$vudTokenPrefix}stepper-text-color);
$stepper-selected-text-color: var(--#{$vudTokenPrefix}stepper-active-text-color);
$stepper-visited-text-color:  var(--#{$vudTokenPrefix}stepper-visited-text-color);

$stepper-border-color:        var(--#{$vudTokenPrefix}stepper-active-border-color);

$stepper-bg:                  var(--#{$vudTokenPrefix}stepper-bg);
$stepper-selected-bg:         var(--#{$vudTokenPrefix}stepper-active-bg);
$stepper-visited-bg:          var(--#{$vudTokenPrefix}stepper-visited-bg);

//*** element/component code ***
.#{$vudClassPrefix}stepper {
    padding: 0 rem(32px);

    > ul {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        padding: 0;

        > li {
            display: inline-block;
            cursor: default;
            position: relative;
            flex: 1 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.visited > *,
            > * {
                display: block;
                cursor: pointer;
                position: relative;
                width: 100%;

                outline: 0;

                color: $stepper-text-color;
                font: $font-md;
                text-decoration: none;

                &:before,
                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    margin: 0 auto;
                    top: auto;

                    background: $stepper-bg;
                }

                &:after {
                    width: 100%;
                    height: rem(3px);
                    right: auto;
                    bottom: rem(17px);
                    left: auto;

                    background-color: $stepper-bg;
                }

                &:before {
                    width: $spacing-32;
                    height: $spacing-32;

                    border-radius: $spacing-32;
                    z-index: 20;
                }

                > span {
                    overflow: hidden;
                    width: 100%;

                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                > span:not(.step) {
                    display: block;
                    margin: 0;

                    line-height: 1.5;
                }

                > span.step {
                    display: none;

                    color: $stepper-text-color;
                    font-size: $stepper-step-text-size;
                }

            }

            &:first-child {
                > * {
                    &:after { opacity: 0 }
                }
            }

            &.active {
                > * {
                    color: $stepper-selected-text-color;
                    font-weight: $bold;

                    &:before {
                        width: rem(48px);
                        height: rem(48px);
                        bottom: rem(-5px);

                        border-radius: rem(48px);
                        border: rem(20px) solid $stepper-border-color;
                        background: $stepper-selected-bg;
                    }
                }
            }

            &.passed {
                > * {
                    color: $stepper-visited-text-color;
                    font-weight: $semibold;

                    &:after { background: $stepper-visited-bg; }

                    &:before {
                        background: {
                            color: $stepper-visited-bg;
                            size: rem(12px) rem(10px);
                            repeat: no-repeat;
                            position: center;
                        }
                    }
                }

                + .active {
                    > * {
                        &:after {
                            background: linear-gradient(90deg, $stepper-visited-bg 0%, $stepper-border-color 50%, $stepper-bg 50%, $stepper-bg 100%);
                            background-size: 200% auto;
                            background-position-x: 0%;
                        }
                    }
                }
            }

            &.visited {
                + .passed,
                + .active {
                    > * {
                        &:after {
                            background-color: $stepper-bg;
                        }
                    }
                }
            }

            &.disabled {
                > * {
                    cursor: default;
                    pointer-events: none;
                }
            }

        }
    }

    &.no-ellipsis {
        > ul > li > * > span:not(.step) {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.3;
            align-items: center;
            justify-content: center;

            @media (min-width: $screen-md-max) {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: rem(46px);
                top: rem(-12px);

                white-space: normal;
            }
        }
    } // END .no-ellipsis
} // END '.stepper'