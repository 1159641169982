
//Popover general style

//*** scss local variable ***
$popover-text-size:     var(--#{$vudTokenPrefix}font-size-md);
$popover-border-radius: var(--#{$vudTokenPrefix}radius-sm);
$popover-border-color:  var(--#{$vudTokenPrefix}tooltip-secondary-border-color);
$popover-bg:            var(--#{$vudTokenPrefix}module-container-bg-color);
$popover-shadow:        var(--#{$vudTokenPrefix}popover-shadow);

//*** element/component code ***
.#{$vudClassPrefix}popover {
    display: none;
    position: absolute;
    width: 60vw;
    max-width: rem(400px);
    padding: $spacing-32;
    top: 0;
    left: 0;

    border-radius: $popover-border-radius;
    border: {
        width: rem(1px);
        style: solid;
        color: $popover-border-color;
    }

    background-color: $popover-bg;
    box-shadow: $popover-shadow;

    z-index: $zindex-popover;

    @media (max-width: $screen-xs-max) {
        padding: rem(30px);
    }

    &::before, &::after {
        display: block;
        position: absolute;
        content: '';
        width: rem(10px);
        height: rem(24px);
        top: calc(#{'50% - ' rem(12px)});
        left: rem(-10px);

        background-image: none;
    }

    &::before {
        clip-path: $tooltip-clip-path-bg;
        background-color: $popover-bg;
        width: rem(11px);
    }

    &::after {
        clip-path: $tooltip-clip-path-border;
        background-color: $popover-border-color;
    }

    &.in, &.show {
        display: block;
    }

    &.top,
    &[data-popper-placement="top"] {
        inset: auto auto rem(5px) rem(8px) !important;

        &::before, &::after {
            top: unset;
            bottom: rem(-17px);
            left: calc(#{'50% - ' rem(5px)});

            transform: rotate(-90deg);
        }

        &::before {
            left: calc(#{'50% - ' rem(5.5px)});
        }
    }

    &.right,
    &[data-popper-placement="right"] {
        inset: 0 auto auto rem(5px) !important
    }

    &.bottom,
    &[data-popper-placement="bottom"] {
        inset: rem(5px) auto auto rem(5px) !important; 

        &::before, &::after {
            top: rem(-17px);
            left: calc(#{'50% - ' rem(5px)});

            transform: rotate(90deg);
        }

        &::before {
            left: calc(#{'50% - ' rem(5.5px)});
        }
    }

    &.left,
    &[data-popper-placement="left"] {
        inset: 0 rem(5px) auto auto i !important;

        &::before, &::after {
            right: rem(-10px);
            left: unset;

            transform: rotate(180deg);
        }
    }

    .#{$vudClassPrefix}popover-header,
    .#{$vudClassPrefix}popover-title {
        font-weight: $bold;
        font-size: $popover-text-size;
    }

    .#{$vudClassPrefix}popover-content {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.vismaicon[data-toggle="popover"],
.vismaicon[data-bs-toggle="popover"] {
    cursor: pointer;
}