// VUD Typography

body {
    color: var(--#{$vudTokenPrefix}body-font-color);
    font-family: $font-family-base;
    text-align: left;
    line-height: $line-height-base;
}

p {
    margin: 0 0 rem(20px);
}

a {
    cursor: pointer;

    color: var(--#{$vudTokenPrefix}anchor-color);
    text-decoration: underline;

    &:hover {
        color: var(--#{$vudTokenPrefix}anchor-hover-color);
        text-decoration: none;
    }

    &:focus {
        outline: rem(2px) solid var(--#{$vudTokenPrefix}anchor-focus-outline);
        outline-offset: rem(1px);
    }
}

b, strong { font-weight: $bold; }

title, .#{$vudClassPrefix}title, h1, .#{$vudClassPrefix}h1 {
    margin: 0 0 rem(20px);
    font-size: var(--#{$vudTokenPrefix}font-size-xl);
    line-height: 1.15;
}

h2, .#{$vudClassPrefix}h2 {
    margin: 0 0 rem(15px);
    font-size: var(--#{$vudTokenPrefix}font-size-lg);
    line-height: 1.15;
}

h3, .#{$vudClassPrefix}h3 {
    margin: 0 0 rem(15px);
    font-size: var(--#{$vudTokenPrefix}font-size-md);
    line-height: 1.15;
}

h4, .#{$vudClassPrefix}h4 {
    margin: 0 0 rem(5px);
    font-size: var(--#{$vudTokenPrefix}font-size-sm);
    line-height: 1.15;
}

h5, .#{$vudClassPrefix}h5, h6, .#{$vudClassPrefix}h6 {
    margin: 0 0 rem(5px);
    font-size: var(--#{$vudTokenPrefix}font-size-xs);
    line-height: 1.15;
}


title, .#{$vudClassPrefix}title, h1, .#{$vudClassPrefix}h1, h2, .#{$vudClassPrefix}h2, h3, .#{$vudClassPrefix}h3, h4, .#{$vudClassPrefix}h4, h5, .#{$vudClassPrefix}h5, h6, .#{$vudClassPrefix}h6 {
    font: {
        family: $font-family-secondary;
        weight: normal;
    }

    &.font-weight-bold,
    &.bold,
    > b,
    > strong {
        font-family: $font-family-secondary-bold;
    }

    &.font-weight-light,
    &.light {
        font-family: $font-family-secondary-light;
    }
}


mark,
.#{$vudClassPrefix}mark {
    background-color: var(--#{$vudTokenPrefix}highlighted-text-bg-color);
    color: var(--#{$vudTokenPrefix}highlighted-text-color);
}

code {
    padding: rem(1px) rem(4px);
    font-size: 100%;
} 

.#{$vudClassPrefix}lead {
    font-weight: 200;
    font-size: rem(18px);
}

ul, ol {
    margin: 0 0 rem(18px);
    padding-left: rem(30px);

    ol, ul {
        margin-top: rem(6px);
        margin-bottom: rem(6px);
    }
}

ul li,
ol li { font: $font-sm; }

// ol { padding-left: rem(15px); }

// @media screen and (-webkit-min-device-pixel-ratio:0) {
//   /* Safari and Chrome */
//   ol { padding-left: rem(20p)x; }
// }

dl {
    margin: 0 0 rem(18px);
}

dt, dd {
    margin-left: 0;
}

dt {
    font-weight: $bold;
}

// text direction
.right-direction { direction: rtl; }
.left-direction { direction: ltr; }


hr {
    clear: both;
    display: block;
    margin-top: rem(5px);
    margin-bottom: rem(10px);

    border: 0;
    border-bottom: rem(1px) solid var(--#{$vudTokenPrefix}divider-color);

    &.#{$vudClassPrefix}hr-disabled { border-color: var(--#{$vudTokenPrefix}divider-disabled-color); }

    &.#{$vudClassPrefix}hr-dotted { border-style: dotted; }

    &.#{$vudClassPrefix}hr-nc-dotted {
        height: rem(1px);
        border: 0;

        background: {
            position: bottom left;
            repeat: repeat-x;
            size: rem(6px) rem(1px);
            image: linear-gradient(to right, var(--#{$vudTokenPrefix}fancy-divider-color) 10%, rgba(0,0,0,0) 0%);
        };

        &.#{$vudClassPrefix}hr-disabled { --#{$vudTokenPrefix}fancy-divider-color: var(--#{$vudTokenPrefix}divider-disabled-color); }
    }

}


.#{$vudClassPrefix}inline-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: rem(5px) 0 rem(10px);

  &::before, &::after {
    content: "";
    flex: 1;
    min-width: rem(30px);

    border-bottom: rem(1px) solid var(--#{$vudTokenPrefix}divider-default-color);
  }

  &::before {
    margin-right: rem(16px);
  }

  &::after {
    margin-left: rem(16px);
  }
}
