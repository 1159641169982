
/*
 * Badges/Labels
 */

//*** scss local variable ***
// Badges
$badge-text-size:                   var(--#{$vudTokenPrefix}font-size-xs);
$badge-text-color:                  var(--#{$vudTokenPrefix}badge-text-color);

$badge-border-color:                var(--#{$vudTokenPrefix}badge-border-color);
$badge-border-radius:               var(--#{$vudTokenPrefix}radius-xxl);

$badge-background-color:            var(--#{$vudTokenPrefix}badge-bg);

$badge-disabled-text-color:         var(--#{$vudTokenPrefix}badge-disabled-text-color);
$badge-disabled-border-color:       var(--#{$vudTokenPrefix}badge-disabled-border-color);
$badge-disabled-background-color:   var(--#{$vudTokenPrefix}badge-disabled-bg);

$badge-info-text-color:             var(--#{$vudTokenPrefix}badge-info-text);
$badge-info-border-color:           var(--#{$vudTokenPrefix}badge-info-border-color);
$badge-info-background-color:       var(--#{$vudTokenPrefix}badge-info-bg);
$badge-info-sm-background-color:    var(--#{$vudTokenPrefix}badge-info-sm-bg);

$badge-success-text-color:          var(--#{$vudTokenPrefix}badge-success-text);
$badge-success-border-color:        var(--#{$vudTokenPrefix}badge-success-border-color);
$badge-success-background-color:    var(--#{$vudTokenPrefix}badge-success-bg);
$badge-success-sm-background-color: var(--#{$vudTokenPrefix}badge-success-sm-bg);

$badge-warning-text-color:          var(--#{$vudTokenPrefix}badge-warning-text);
$badge-warning-border-color:        var(--#{$vudTokenPrefix}badge-warning-border-color);
$badge-warning-background-color:    var(--#{$vudTokenPrefix}badge-warning-bg);
$badge-warning-sm-background-color: var(--#{$vudTokenPrefix}badge-warning-sm-bg);

$badge-danger-text-color:          var(--#{$vudTokenPrefix}badge-danger-text);
$badge-danger-border-color:        var(--#{$vudTokenPrefix}badge-danger-border-color);
$badge-danger-background-color:    var(--#{$vudTokenPrefix}badge-danger-bg);
$badge-danger-sm-background-color: var(--#{$vudTokenPrefix}badge-danger-sm-bg);

// Labels
$label-text-size:                   var(--#{$vudTokenPrefix}label-text-size);
$label-text-color:                  var(--#{$vudTokenPrefix}label-text-color);

$label-border-color:                var(--#{$vudTokenPrefix}label-border-color);
$label-border-radius:               var(--#{$vudTokenPrefix}label-border-radius);

$label-background-color:            var(--#{$vudTokenPrefix}label-bg);

$label-disabled-text-color:         var(--#{$vudTokenPrefix}label-disabled-text-color);
$label-disabled-border-color:       var(--#{$vudTokenPrefix}label-disabled-border-color);
$label-disabled-background-color:   var(--#{$vudTokenPrefix}label-disabled-bg);

$label-info-text-color:             var(--#{$vudTokenPrefix}label-info-text-color);
$label-info-border-color:           var(--#{$vudTokenPrefix}label-info-border-color);
$label-info-background-color:       var(--#{$vudTokenPrefix}label-info-bg);

$label-success-text-color:          var(--#{$vudTokenPrefix}label-success-text-color);
$label-success-border-color:        var(--#{$vudTokenPrefix}label-success-border-color);
$label-success-background-color:    var(--#{$vudTokenPrefix}label-success-bg);

$label-warning-text-color:          var(--#{$vudTokenPrefix}label-warning-text-color);
$label-warning-border-color:        var(--#{$vudTokenPrefix}label-warning-border-color);
$label-warning-background-color:    var(--#{$vudTokenPrefix}label-warning-bg);

$label-danger-text-color:          var(--#{$vudTokenPrefix}label-danger-text-color);
$label-danger-border-color:        var(--#{$vudTokenPrefix}label-danger-border-color);
$label-danger-background-color:    var(--#{$vudTokenPrefix}label-danger-bg);

//*** element/component code ***
.#{$vudClassPrefix}badge {
    position: relative;
    min-width: rem(30px);
    top: 0;
    padding: rem(2px) rem(8px);
    margin-right: rem(1px);

    text-align: center;
    font-size: $badge-text-size;
    font-weight: $bold;
    line-height: rem(16px);

    border: rem(1px) solid $badge-border-color;
    border-radius: $badge-border-radius;

    &.#{$vudClassPrefix}badge-sm {
        display: block;
        position: absolute;
        width: rem(9px);
        min-width: 1%;
        height: rem(9px);
        margin: 0;
        padding: 0;

        top: rem(-2px);
        right: rem(-2px);

        text-indent: -9999px;
        border: 0;

        z-index: 1;

        &, &.#{$vudClassPrefix}badge-primary {
            background-color: $badge-success-sm-background-color;
        }

        &.#{$vudClassPrefix}badge-secondary,
        &.#{$vudClassPrefix}badge-success {
            background-color: $badge-success-sm-background-color;
        }

        &.#{$vudClassPrefix}badge-info {
            background-color: $badge-info-sm-background-color;
        }

        &.#{$vudClassPrefix}badge-warning {
            background-color: $badge-warning-sm-background-color;
        }

        &.#{$vudClassPrefix}badge-danger {
            background-color: $badge-danger-sm-background-color;
        }
    }

    &,
    &.#{$vudClassPrefix}badge-primary {
        color: $badge-text-color;
        background-color: $badge-background-color;
    }

    &.#{$vudClassPrefix}badge-secondary,
    &.#{$vudClassPrefix}badge-success {
        color: $badge-success-text-color;
        border-color: $badge-success-border-color;
        background-color: $badge-success-background-color;
    }

    &.#{$vudClassPrefix}badge-info {
        color: $badge-info-text-color;
        border-color: $badge-info-border-color;
        background-color: $badge-info-background-color;
    }

    &.#{$vudClassPrefix}badge-warning {
        color: $badge-warning-text-color;
        border-color: $badge-warning-border-color;
        background-color: $badge-warning-background-color;
    }

    &.#{$vudClassPrefix}badge-danger {
        color: $badge-danger-text-color;
        border-color: $badge-danger-border-color;
        background-color: $badge-danger-background-color;
    }

    a & {
        display: inline-block;
        margin-left: rem(6px);

        text-decoration: none;
    }

    .#{$vudClassPrefix}btn &, .#{$vudClassPrefix}nav-pills & {
        margin-left: rem(6px);
        box-shadow: none;
    }

    .#{$vudClassPrefix}btn:not(.#{$vudClassPrefix}btn-primary) &, .#{$vudClassPrefix}nav-pills & {
        color: $neutral-05;

        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            background-color: $primary-green;
        }
    }

    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary &,
    .#{$vudClassPrefix}nav-pills.#{$vudClassPrefix}nav-pills-primary .active & {
        background-color: $neutral-05;

        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            background-color: $neutral-05;
        }

        &.#{$vudClassPrefix}badge-success, &.#{$vudClassPrefix}badge-secondary {
            color: $primary-green;
        }

        &.#{$vudClassPrefix}badge-info {
            color: $primary-blue;
        }

        &.#{$vudClassPrefix}badge-warning {
            color: $primary-orange;
        }

        &.#{$vudClassPrefix}badge-danger {
            color: $primary-red;
        }
    }

    .#{$vudClassPrefix}nav-pills.#{$vudClassPrefix}nav-pills-primary .active & {
        &:not(.#{$vudClassPrefix}badge-secondary):not(.#{$vudClassPrefix}badge-success):not(.#{$vudClassPrefix}badge-info):not(.#{$vudClassPrefix}badge-warning):not(.#{$vudClassPrefix}badge-danger):not(.#{$vudClassPrefix}badge-sm) {
            color: $primary-neutral;
        }
    }
}

.#{$vudClassPrefix}badge {
    .disabled &, :disabled & {
        &, &.#{$vudClassPrefix}badge-success, &.#{$vudClassPrefix}badge-secondary,
        &.#{$vudClassPrefix}badge-info, &.#{$vudClassPrefix}badge-warning,
        &.#{$vudClassPrefix}badge-danger {
            color: $badge-disabled-text-color;
            border-color: $badge-disabled-border-color;
            background-color: $badge-disabled-background-color;
        }
    }

    .disabled .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary &,
    :disabled .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary &,
    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary:disabled &,
    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-primary.disabled & {
        &, &.#{$vudClassPrefix}badge-success, &.#{$vudClassPrefix}badge-secondary,
        &.#{$vudClassPrefix}badge-info, &.#{$vudClassPrefix}badge-warning,
        &.#{$vudClassPrefix}badge-danger {
            color: $badge-disabled-text-color;
            border-color: $badge-disabled-border-color;
            background-color: $badge-disabled-background-color;
        }
    }
}


.#{$vudClassPrefix}label {
    position: relative;
    margin: 0;
    padding: rem(3px) rem(12px) rem(4px);

    color: $label-text-color;
    font-size: $label-text-size;
    font-weight: $normal;
    line-height: rem(16px);

    border-radius: $label-border-radius;
    border: 0;
    background-color: $label-background-color;
    box-shadow: inset 0 0 0 rem(1px) $label-border-color;

    &.#{$vudClassPrefix}label-secondary,
    &.#{$vudClassPrefix}label-success {
        color:  $label-success-text-color;
        background-color: $label-success-background-color;
        box-shadow: inset 0 0 0 rem(1px) $label-success-border-color;
    }

    &.#{$vudClassPrefix}label-primary,
    &.#{$vudClassPrefix}label-info {
        color: $label-info-text-color;
        background-color: $label-info-background-color;
        box-shadow: inset 0 0 0 rem(1px) $label-info-border-color;
    }

    &.#{$vudClassPrefix}label-warning {
        color: $label-warning-text-color;
        background-color: $label-warning-background-color;
        box-shadow: inset 0 0 0 rem(1px) $label-warning-border-color;
    }

    &.#{$vudClassPrefix}label-danger {
        color: $label-danger-text-color;
        background-color: $label-danger-background-color;
        box-shadow: inset 0 0 0 rem(1px) $label-danger-border-color;
    }

    .disabled &, :disabled &, &.disabled {
        &, &.#{$vudClassPrefix}label-secondary, &.#{$vudClassPrefix}label-success,
        &.#{$vudClassPrefix}label-primary, &.#{$vudClassPrefix}label-info,
        &.#{$vudClassPrefix}label-warning, &.#{$vudClassPrefix}label-danger {
            color: $label-disabled-text-color;
            background-color: $label-disabled-background-color;
            box-shadow: inset 0 0 0 rem(1px) $label-disabled-border-color;
        }
    }
}
