
// Stepper compressed - base styling

//*** scss local variable ***
$stepper-sm-text-size:           var(--#{$vudTokenPrefix}font-size-sm);
$stepper-sm-active-border-color: var(--#{$vudTokenPrefix}stepper-active-border-color);

//*** element/component code ***
.#{$vudClassPrefix}stepper.#{$vudClassPrefix}stepper-sm {
    @media (min-width: ($screen-md-min + 1px)) {
        max-width: rem(800px);
        > ul {
            padding-left: 0;

            > li {
                > * {
                    font-size: $stepper-sm-text-size;

                    &:after {
                        height: rem(2px);
                        bottom: rem(11px);
                    }

                    &:before {
                        width: $spacing-16;
                        height: $spacing-16;
                        border-radius: $spacing-16;
                    }
                }

                &.active {
                    > * {
                        font-size: $stepper-sm-text-size;

                        &:before {
                            width: $spacing-24;
                            height: $spacing-24;
                            border-radius: $spacing-24;
                            border: rem(10px) solid $stepper-sm-active-border-color;
                            bottom: rem(0);
                        }
                    }
                }

                &.passed {
                    > * {
                        &:before { background-image: none; }
                    }
                }
            }
        }
    }
} // END '.stepper'
