/* Core grid CSS, always needed */
@import "@ag-grid-community/styles/ag-grid.css";
/* Optional theme CSS*/
@import "@ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-visma {
  --ag-font-family: Open Sans Regular;
  --ag-font-size: 13px;
  --ag-data-color: var(--neutral-90);
  --ag-border-color: var(--neutral-40);
  --ag-foreground-color: var(--neutral-90);
  --ag-header-foreground-color: var(--neutral-90);
  --ag-selected-row-background-color: var(--neutral-20);
  --ag-row-hover-color: var(--blue-10);
  --ag-odd-row-background-color: var(--ag-background-color);
  --ag-alpine-active-color: var(--blue-80);
}

.ag-theme-visma .ag-root-wrapper {
  border-radius: 8px;
  overflow: visible;
}
/* Remove top border radius if a grid message or toolbar is present */
.ag-theme-visma:has(.visma-ag-grid-message, .visma-ag-grid-toolbar)
  .ag-root-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ag-theme-visma .ag-root.ag-layout-normal {
  border-top-right-radius: 8px;
}

/* No highlight on row selected */
.ag-theme-visma.no-row-selection-style .ag-row-selected:not(:hover)::before {
  --ag-selected-row-background-color: white;
}

.ag-theme-visma .ag-header-row {
  font-weight: 600;
}

/* VUD style checkbox */
.ag-theme-visma .ag-checkbox-input-wrapper.ag-checked {
  border-radius: 5px;
  background-color: var(--blue-50);
}

.ag-theme-visma .ag-checkbox-input-wrapper.ag-checkbox-input-wrapper::after {
  width: 16px;
  height: 16px;
  content: "";
  border-radius: 5px;
}

.ag-theme-visma .ag-checkbox-input-wrapper:not(.ag-checked)::after {
  border-width: 1px;
  border-color: var(--neutral-70);
}

.ag-theme-visma .ag-checkbox-input-wrapper.ag-checked::after {
  background-color: var(--neutral-05);
  mask: url("./assets/checkboxTick.svg");
}

.ag-theme-visma .ag-cell.visma-invalid-cell::before,
/* Show star on required cells when row is expanded / in focus */
.ag-theme-visma .ag-row-focus .ag-cell.visma-mandatory-cell::before,
.ag-theme-visma .ag-row-group-expanded .ag-cell.visma-mandatory-cell::before {
  color: var(--red-70);
  font-size: 20px;
  line-height: 28px;
  position: absolute;
  top: 0;
  left: 4px;
  content: "*";
}

/* Not relevant cell */
.ag-theme-visma
  .ag-row:not(.ag-row-footer)
  .ag-cell.ag-cell-focus.visma-not-relevant-cell {
  background-color: var(--neutral-10);
}
.ag-theme-visma
  .ag-row:not(.ag-row-footer)
  .ag-cell.ag-cell-focus.visma-not-relevant-cell {
  border-color: var(--neutral-60);
}

/* Set border and border color on pending pill since row selected have same color */
.ag-theme-visma .ag-row-focus.ag-row-selected .border-on-pending-pill {
  border-width: 1px;
  border-color: var(--blue-20);
  border-opacity: 0.7;
}

/* Mark focused/hovered row with blue/green/red border on the left */
.ag-theme-visma .ag-row:has(.ag-cell-focus)::before,
.ag-theme-visma .ag-row.ag-row-hover::before,
.ag-theme-visma .ag-row:has(.ag-cell.visma-warning-cell)::before,
.ag-theme-visma .ag-row:has(.ag-cell.visma-invalid-cell)::before,
.ag-theme-visma .ag-row.visma-warning-row::before,
.ag-theme-visma .ag-row.visma-invalid-row::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border-left-width: 8px;
  border-left-style: solid;
}
.ag-theme-visma .ag-row.ag-row-hover::before {
  border-left-color: var(--blue-50);
}
.ag-theme-visma .ag-row:has(.ag-cell-focus)::before {
  border-left-color: var(--green-50);
}
.ag-theme-visma .ag-row:has(.ag-cell.visma-warning-cell)::before,
.ag-theme-visma .ag-row.visma-warning-row::before {
  border-left-color: var(--orange-70);
}
.ag-theme-visma .ag-row:has(.ag-cell.visma-invalid-cell)::before,
.ag-theme-visma .ag-row.visma-invalid-row::before {
  border-left-color: var(--red-60);
}

/* Remove cell border and shadow when editing */
.ag-theme-visma .ag-row .ag-cell-popup-editing,
.ag-theme-visma .ag-row .ag-cell-inline-editing:focus-within {
  border-style: none;
  box-shadow: none;
}

.ag-theme-visma .ag-row.ag-row-focus {
  --ag-background-color: var(--blue-05);
  --ag-odd-row-background-color: var(--ag-background-color);
}

.ag-theme-visma .ag-row.visma-warning-row:not(.visma-invalid-row) {
  background-color: var(--orange-05);
}

.ag-theme-visma .ag-row.ag-row-focus.visma-warning-row .visma-warning-cell {
  background-color: #fff;
  color: var(--orange-70);
  border-color: var(--orange-70) !important;
}

.ag-theme-visma .ag-row.visma-invalid-row {
  background-color: var(--red-05);
}

.ag-theme-visma .ag-row.ag-row-focus.visma-invalid-row .visma-invalid-cell {
  background-color: #fff;
  color: var(--red-60);
  border-color: var(--red-60) !important;
}

/* Style the footer row */
.ag-theme-visma .ag-row-footer {
  font-weight: 700;
  border-top-width: 1px;
  border-top-color: var(--neutral-60);
}
.ag-theme-visma .ag-row-footer::before {
  background-color: transparent;
}
/* Style pinned row */
.ag-theme-visma .ag-row-pinned {
  font-weight: 700;
}
.ag-theme-visma .ag-row-pinned::before {
  background-color: transparent;
}

/* Override icons */
.ag-theme-alpine .ag-icon-columns {
  --ag-icon-font-code-columns: "";
  background: transparent url("./assets/table.svg") center/contain no-repeat;
  color: transparent;
}
.ag-theme-alpine .ag-icon-filter {
  --ag-icon-font-code-filter: "";
  background: transparent url("./assets/filter.svg") center/contain no-repeat;
  color: transparent;
}
.ag-theme-visma.ag-theme-alpine .ag-icon-tree-open,
.ag-theme-visma.ag-theme-alpine .ag-icon-tree-open:hover {
  background: transparent url("./assets/chevron-up.svg") center/contain
    no-repeat;
  color: transparent;
}
.ag-theme-visma.ag-theme-alpine .ag-icon-tree-closed,
.ag-theme-visma.ag-theme-alpine .ag-icon-tree-closed:hover {
  background: transparent url("./assets/chevron-down.svg") center/contain
    no-repeat;
  color: transparent;
}

/* Show orange dot on icon */
.ag-theme-visma .ag-header-row .visma-active-icon {
  margin-right: 8px;
}
.ag-theme-visma .visma-active-icon::before {
  position: absolute;
  background-color: var(--orange-50);
  top: -6px;
  right: -6px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

/* Disable filter icon next to column name when filtering if floating filters are hidden  */
.ag-theme-visma:not(.visma-floating-filter-row-hidden)
  .ag-header-icon.ag-filter-icon {
  display: none;
}

/* Details row padding */
.ag-theme-visma .ag-details-row {
  padding: 24px;
}

/* Remove bottom border on expanded warning and invalid row */
.ag-theme-visma .visma-warning-row.ag-row-group-expanded,
.ag-theme-visma .visma-invalid-row.ag-row-group-expanded {
  border-bottom: 0;
}

/* Remove left colored border on detail rows if class 'visma-no-detail-border-left' is set */
.ag-theme-visma.visma-no-detail-border-left
  .ag-row:not(.ag-row-level-0)::before {
  border-left-width: 0;
}
