
// Drawers
//*** scss local variable ***
$drawer-border-radius:         var(--#{$vudTokenPrefix}radius-xl);
$drawer-border-color:          var(--#{$vudTokenPrefix}modal-border-color);

$drawer-bg:                    var(--#{$vudTokenPrefix}modal-bg);

$drawer-shadow:                var(--#{$vudTokenPrefix}modal-shadow);


//*** element/component code ***
.#{$vudClassPrefix}drawer {
    position: fixed;
    height: 100%;
    padding-top: $spacing-32;
    right: -100%;
    bottom: 0;

    border-radius: $drawer-border-radius 0 0 $drawer-border-radius;
    border: 0.1rem solid $drawer-border-color;
    background-color: $drawer-bg;
    box-shadow: $drawer-shadow;

    z-index: $zindex-fixed;
    transition: right 800ms ease-in-out;

    &, &.#{$vudClassPrefix}drawer-md {
        width: 50%;
        max-width: rem(960px);
    }

    &.#{$vudClassPrefix}drawer-lg {
        width: 75%;
        max-width: rem(1440px);
    }

    &.#{$vudClassPrefix}drawer-sm {
        max-width: 25%;
        width: 25%;
        min-width: rem(480px);
    }

    @media (max-width: $screen-md-min) {
        &, &.#{$vudClassPrefix}drawer-sm, &.#{$vudClassPrefix}drawer-md, &.#{$vudClassPrefix}drawer-lg {
            width: 90%;
            min-width: 1%;
            max-width: 100%;
        }
    }

    a, button, .#{$vudClassPrefix}btn {
        visibility: hidden;
    }

    &.show {
        right: 0;

        a, button, .#{$vudClassPrefix}btn {
            visibility: visible;
        }
    }

    .#{$vudClassPrefix}drawer-header {
        padding: $spacing-48 $spacing-48 $spacing-16;

        h1, h2, h3, h4 {
            font: $font-md;
            font-weight: $semibold;
        }

        > *:only-child { margin-bottom: 0; }
    }

    .#{$vudClassPrefix}drawer-body {
        padding: 0 $spacing-48 0;
        margin: $spacing-16 0 $spacing-48;
        height: calc(#{'100% - ' rem(265px)});
        overflow-y: auto;
    }

    .#{$vudClassPrefix}drawer-footer {
        padding: $spacing-32 $spacing-48;
        border-top: rem(1px) solid $drawer-border-color;
    }

    .#{$vudClassPrefix}close {
        position: absolute;
        top: rem(36px);
        right: rem(52px);
    }
}
