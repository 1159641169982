//=== Welcome card

//*** scss local variable ***
$hero-card-font-family:        $font-family-primary;
$hero-card-text-size-sm:       var(--#{$vudTokenPrefix}font-size-sm);
$hero-card-text-size-lg:       var(--#{$vudTokenPrefix}font-size-xxl);
$hero-card-background-color:   var(--#{$vudTokenPrefix}hero-card-bg);
$hero-card-border-radius:      var(--#{$vudTokenPrefix}radius-sm);
$hero-card-border-color:       var(--#{$vudTokenPrefix}hero-card-border-color);
$hero-card-inner-border-color: var(--#{$vudTokenPrefix}hero-card-inner-border-color);
$hero-card-inner-shadow:       var(--#{$vudTokenPrefix}hero-card-shadow);

//*** element/component code ***
.#{$vudClassPrefix}hero-card {
    display: flex;
    flex-wrap: wrap-reverse;
    width: #{'calc(100% - ' rem(32px) ')' };
    margin:  rem(16px) rem(16px) rem(48px);

    border-radius: $hero-card-border-radius;
    background-color: $hero-card-background-color;
    box-shadow: 0 0 0 rem(1px) $hero-card-inner-border-color, 0 0 0 rem(12px) $hero-card-border-color, $hero-card-inner-shadow;

    [class^="col-"] {
        display: flex;
    }

    p {
        margin-bottom: rem(16px);

        font: {
            size: $hero-card-text-size-sm;
        }
        line-height: 1.35714em;
    }

    .#{$vudClassPrefix}hero-card-content {
        display: flex;
        flex-direction: column;
        max-width: rem(564px);
        padding: rem(48px) rem(32px);

        @media (max-width: $screen-sm-min) { padding: rem(32px) rem(16px); }

        .#{$vudClassPrefix}btn-block {
            margin-top: rem(32px);
            margin-bottom: rem(32px);

            &:last-child {
                margin-bottom: 0;
            }
        }

        p + .#{$vudClassPrefix}btn-block {
            margin-top: rem(16px);
        }

        .#{$vudClassPrefix}h1 {
            font: {
                size: $hero-card-text-size-lg;
            }
            line-height: 1.15625em;
        }
    }

    .#{$vudClassPrefix}hero-card-illustration {
        display: flex;
        position: relative;
        align-self: flex-start;
        max-width: rem(327px);
        top: rem(-32px);

        &.#{$vudClassPrefix}hero-card-illustration-lg {
            max-width: rem(465px);
        }

        img, svg, .#{$vudClassPrefix}hero-card-image {
            max-width: 100%;
        }
    }

    .#{$vudClassPrefix}card-title {
        margin-bottom: rem(16px);

        font: {
            family: $hero-card-font-family;
            size: $hero-card-text-size-lg;
            weight: $normal;
        }
        line-height: 1.15625em;
    }
}





