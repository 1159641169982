
// Horizontal loading bar

@import 'progress-bar';

.horizontal-loading {
  .loading-bar {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    width: 0;
    animation: horizontal-loading-bar 2s linear infinite;
  }

  .loading-bar-label { top: rem(28px); }

  &.horizontal-loading-sm .loading-bar-label { top: rem(17px); }

  &.horizontal-loading-xs .loading-bar-label { top: rem(12px); }
}

@keyframes horizontal-loading-bar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

