
// Split buttons

//*** scss local variable ***
$btn-split-border-radius:                    var(--#{$vudTokenPrefix}radius-sm);
$btn-split-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$btn-split-primary-shadow:                   var(--#{$vudTokenPrefix}button-primary-shadows);

$btn-split-primary-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$btn-split-primary-focus-inner-border-color: var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);
$btn-split-primary-selected-border-color:    var(--#{$vudTokenPrefix}button-primary-selected-border-color);
$btn-split-primary-disabled-border-color:    var(--#{$vudTokenPrefix}button-disabled-border-color);

//*** element/component code ***
.#{$vudClassPrefix}btn-group {
    &.#{$vudClassPrefix}btn-split {
        position: relative;
        margin: 0 $spacing-8 $spacing-8 0;

        z-index: 2;

        > .#{$vudClassPrefix}btn {
            margin: 0;

            text-decoration: none;
            text-align: left;
            justify-content: flex-start;

            z-index: 1;
        }

        .#{$vudClassPrefix}btn-primary {
            &:not(:last-child) {
                border-right-color: rgba(0, 0, 0, 0.16);
            }
        }

        //# Dropdown trigger style.
        .#{$vudClassPrefix}dropdown-toggle {
            min-width: $spacing-32;
            padding: $spacing-4;
            border-top-right-radius: $btn-split-border-radius;
            border-bottom-right-radius: $btn-split-border-radius;

            > .#{$vudClassPrefix}caret { display: none; }

            //## drodown arrow style
            &::before {
                right: rem(12px);
            }

            &.#{$vudClassPrefix}btn-primary {
                box-shadow: rem(-1px) 0 0 0 $btn-split-primary-selected-border-color, $btn-split-primary-shadow;

                &:focus:not(:active) {
                    box-shadow: rem(-1px) 0 0 0 $btn-split-primary-focus-border-color, inset 0 0 0 rem(1px) $btn-split-primary-focus-border-color, inset 0 0 0 rem(2px) $btn-split-primary-focus-inner-border-color;
                }

                &.disabled, &:disabled {
                    &, &:hover, &:focus {
                        border-color: $btn-split-disabled-border-color;
                        box-shadow: none;
                    }
                }
            }
        }

        &.#{$vudClassPrefix}btn-group-lg {
            .#{$vudClassPrefix}dropdown-toggle {
                min-width: $spacing-48;

                &::before {
                    top: rem(22px);
                    right: rem(20px);
                }
            }
        }
        .disabled &, :disabled &,
        &.disabled, &:disabled {
            .#{$vudClassPrefix}dropdown-toggle {
                &, &.#{$vudClassPrefix}btn-primary {
                    &, &:hover, &:focus {
                        border-color: $btn-split-primary-disabled-border-color;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
