
// Button Group

//*** scss local variable ***
// default button group
$button-group-border-color:                     var(--#{$vudTokenPrefix}button-border-color);
$button-group-hover-border-color:               var(--#{$vudTokenPrefix}button-hover-border-color);
$button-group-focus-border-color:               var(--#{$vudTokenPrefix}button-focus-border-color);
$button-group-pressed-border-color:             var(--#{$vudTokenPrefix}button-pressed-border-color);
$button-group-selected-border-accent-color:     var(--#{$vudTokenPrefix}button-group-selected-border-accent-color);
$button-group-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-group-hover-bg:                         var(--#{$vudTokenPrefix}button-hover-bg);
$button-group-pressed-bg:                       var(--#{$vudTokenPrefix}button-pressed-bg);
$button-group-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);

$button-group-shadow:                           var(--#{$vudTokenPrefix}button-shadow);

$button-group-text-color:                       var(--#{$vudTokenPrefix}button-text-color);
$button-group-disabled-text-color:              var(--#{$vudTokenPrefix}button-disabled-text-color);

$button-group-default-icon-color:               var(--#{$vudTokenPrefix}button-icon-color);
$button-group-default-disabled-icon-color:      var(--#{$vudTokenPrefix}button-disabled-icon-color);

// primary button group
$button-group-primary-focus-border-color:          var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$button-group-primary-focus-inner-shadow-color:    var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);
$button-group-primary-pressed-border-color:        $button-group-pressed-border-color;
$button-group-primary-selected-border-color:       var(--#{$vudTokenPrefix}button-primary-border-color);
$button-group-primary-selected-hover-border-color: var(--#{$vudTokenPrefix}button-primary-hover-border-color);
$button-group-primary-disabled-border-color:       $button-group-disabled-border-color;

$button-group-primary-pressed-bg:                  $button-group-pressed-bg;
$button-group-primary-selected-bg:                 var(--#{$vudTokenPrefix}button-primary-bg);
$button-group-primary-selected-hover-bg:           var(--#{$vudTokenPrefix}button-primary-hover-bg);
$button-group-primary-disabled-bg:                 $button-group-disabled-bg;

$button-group-primary-hover-shadow:               var(--#{$vudTokenPrefix}button-primary-shadow);

$button-group-primary-pressed-text-color:          $button-group-text-color;
$button-group-primary-selected-text-color:         var(--#{$vudTokenPrefix}button-primary-text-color);
$button-group-primary-disabled-text-color:         $button-group-disabled-text-color;

$button-group-primary-icon-color:                  var(--#{$vudTokenPrefix}button-primary-icon);

//*** element/component code ***
// Group a series of buttons together on a single line with the button group
.#{$vudClassPrefix}btn-group {
    display: inline-flex;
    vertical-align: middle;

    .#{$vudClassPrefix}btn-group-lg &, &.#{$vudClassPrefix}btn-group-lg {
        .#{$vudClassPrefix}btn {
            &.active {
                --vud-border-accent-color-size: #{rem(-3px)};
            }
        }
    }

    .#{$vudClassPrefix}btn.#{$vudClassPrefix}btn-lg {
        &.active {
            --vud-border-accent-color-size: #{rem(-3px)};
        }
    }

    //# Defining/overwiting the button defaults inside button groups
    .#{$vudClassPrefix}btn {
        //## Removing left border-radius and margins, exept if it's the only button or is the last button
        &:not(:last-child) {
            margin-right: 0;

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        //### button hover state
        &:hover {
            &:not(:first-child) {
                box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, $button-group-shadow;
            }
        } // END '.btn-group .btn:hover'

        //### button focus state
        &:focus {
            &:not(:first-child) {
                box-shadow: rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 0 0 rem(1px) $button-group-focus-border-color, $button-group-shadow;
            }
        } // END '.btn-group .btn:focus'

        //### button active/pressed state
        &:active {
            &:focus, &:hover {
                box-shadow: none;
            }
        } // END '.btn-group .btn:active'

        //### button active/selected state in group buttons
        &.active {
            --vud-border-accent-color-size: #{rem(-1px)};

            border-bottom-color: $button-group-selected-border-accent-color;
            box-shadow: inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color;

            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $button-group-default-icon-color;
                }
            }

            // selected button on hoverd
            &:hover {
                border-color: $button-group-hover-border-color;
                background-color: $button-group-hover-bg;
                box-shadow: inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color, rem(-1px) 0 0 0 $button-group-hover-border-color;

                &:first-child {
                    box-shadow: inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color;
                }
            } // END '.btn-group .btn.active:hover'

            // selected button on focused
            &:focus {
                border-color: $button-group-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $button-group-focus-border-color, rem(-1px) 0 0 0 $button-group-focus-border-color, inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color;

                &:first-child {
                    box-shadow: inset 0 0 0 rem(1px) $button-group-focus-border-color, inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color;
                }
            } // END '.btn-group .btn:focus'

            // pressed selected button
            &:active {
                border-color: $button-group-pressed-border-color;
                background-color: $button-group-pressed-bg;

                &, &:focus {
                    box-shadow: none;
                }

            } // END '.btn-group .btn:active'

            // disabled selected button
            &:disabled, &.disabled {
                &, &:focus, &:hover {
                    border-color: $button-group-disabled-border-color;
                    background-color: $button-group-disabled-bg;
                    box-shadow: none;
                }

                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-group-default-disabled-icon-color;
                    }
                }
            } // END '.btn-group .btn.active.disabled', '.btn-group .btn.active:disabled'
        } // END '.btn-group .btn.active'

        //### button disabled state
        &:disabled, &.disabled {
            &, &:active, &:focus, &:hover {
                border-color: $button-group-disabled-border-color;
                background-color: $button-group-disabled-bg;
                box-shadow: none;
            }

            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $button-group-default-disabled-icon-color;
                }
            }

            // Button style for one that precede a 'disabled' button
            + .#{$vudClassPrefix}btn {
                box-shadow: rem(-1px) 0 0 0 $button-group-border-color, $button-group-shadow;

                &.active {
                    box-shadow: inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color, rem(-1px) 0 0 0 $button-group-border-color;

                    &:hover:not(:active) {
                        box-shadow: inset 0 var(--vud-border-accent-color-size) 0 0 $button-group-selected-border-accent-color, rem(-1px) 0 0 0 $button-group-hover-border-color;
                    }

                    &:disabled, &.disabled { box-shadow: none; }
                } // END '.btn-group .btn:disabled + .btn.active'

                &:hover {
                    box-shadow: rem(-1px) 0 0 0 $button-group-hover-border-color, $button-group-shadow;
                } // END '.btn-group .btn:disabled + .btn:hover'

                &:focus {
                    box-shadow: inset 0 0 0 rem(1px) $button-group-focus-border-color, rem(-1px) 0 0 0 $button-group-focus-border-color;
                } // END '.btn-group .btn:disabled + .btn:focus'

                &:active {
                    box-shadow: rem(-1px) 0 0 0 $button-group-pressed-border-color;
                } // END '.btn-group .btn:disabled + .btn:active'

                // disabled state
                &:disabled, &.disabled {
                    box-shadow: none;
                } // END '.btn-group .btn:disabled + .btn.disabled'
            } // END '.btn-group .btn:disabled + .btn'
        } // END '.btn-group .btn:disabled'

        //## Restyling the second and up button in the group
        + .#{$vudClassPrefix}btn {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        } // END '.btn-group .btn + .btn'
    } // END '.btn-group .btn'

    > .#{$vudClassPrefix}btn-split, > .#{$vudClassPrefix}dropdown, > .#{$vudClassPrefix}dropup {
        //### 'Button group' inside a button group that is not the first child
        &:not(:first-child) {
            .#{$vudClassPrefix}btn {
                &:first-child {
                    border-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &:last-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &:last-child {
            .#{$vudClassPrefix}btn.#{$vudClassPrefix}dropdown-toggle {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }

        //### 'Button group' inside a button group that is not the last child
        &:not(:last-child) {
            margin-right: 0;
        }

        //### Button style for one that precede a 'button group', 'split button group' or 'dropdown button'
        + .#{$vudClassPrefix}btn {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        } // END '.btn-group > .btn-group + .btn' or '.btn-group > .dropdown + .btn'
    } // END '.btn-group > .btn-group' or '.btn-group > .dropdown'

    > .#{$vudClassPrefix}btn + .#{$vudClassPrefix}btn-group:not(.#{$vudClassPrefix}btn-split) {
        margin-left: rem(6px);
    }

    > .#{$vudClassPrefix}btn-group:not(.#{$vudClassPrefix}btn-split) {
        margin-right: rem(6px);
    }

    // group of buttons stretch at equal sizes to span the entire width of its parent
    &.#{$vudClassPrefix}btn-group-justified {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

        > .#{$vudClassPrefix}btn, > .#{$vudClassPrefix}btn-group, > div {
            float: none;
            width: 100%;
            min-width: unset;
            margin-bottom: 0;
        } // END '.btn-group.btn-group-justified > .btn'

        .#{$vudClassPrefix}btn.#{$vudClassPrefix}dropdown-toggle {
            min-width: 100%;
        }
    } // END '.btn-group.btn-group-justified'

    //# Alternative styling for group buttons
    &.#{$vudClassPrefix}btn-group-primary, .#{$vudClassPrefix}btn-toolbar-primary & {
        //## Defind the states for selected or pressed button in the alternative styling
        .#{$vudClassPrefix}btn {
            //### Selected state
            &.active {
                color: $button-group-primary-selected-text-color;

                border-color: $button-group-primary-selected-border-color;
                background-color: $button-group-primary-selected-bg;

                box-shadow: none;

                &:not(:first-child) {
                    box-shadow: rem(-1px) 0 0 0 $button-group-primary-selected-border-color;
                }

                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-group-primary-icon-color;
                    }
                }

                // selected and hovered state
                &:hover {
                    border-color: $button-group-primary-selected-hover-border-color;
                    background-color: $button-group-primary-selected-hover-bg;
                    box-shadow: $button-group-primary-hover-shadow;

                    &:not(:first-child) {
                        box-shadow: rem(-1px) 0 0 0 $button-group-primary-selected-hover-border-color, $button-group-primary-hover-shadow;
                    }
                } // END '.btn-group.btn-group-primary .btn.active:hover'

                // selected and focused state
                &:focus {
                    border-color: $button-group-primary-focus-border-color;
                    box-shadow: inset 0 0 0 rem(1px) $button-primary-focus-border-color, inset 0 0 0 rem(2px) $button-group-primary-focus-inner-shadow-color;

                    &:not(:first-child) {
                        box-shadow: rem(-1px) 0 0 0 $button-primary-focus-border-color, inset 0 0 0 rem(1px) $button-primary-focus-border-color, inset 0 0 0 rem(2px) $button-group-primary-focus-inner-shadow-color;
                    }
                } // END '.btn-group.btn-group-primary .btn.active:focus'

                // selected and pressed state
                &:active {
                    color: $button-group-primary-pressed-text-color;

                    border-color: $button-group-primary-pressed-border-color;
                    background-color: $button-group-primary-pressed-bg;

                    &, &:not(:first-child) {
                        box-shadow: none;
                    }

                    .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                        &::before {
                            background-color: $button-group-default-icon-color;
                        }
                    }
                } // END '.btn-group.btn-group-primary .btn.active:active'

                // selected and disabled state
                &:disabled,  &.disabled {
                    color: $button-group-primary-disabled-text-color;

                    border-color: $button-group-primary-disabled-border-color;
                    background-color: $button-group-primary-disabled-bg;

                    &, &:not(:first-child) {
                        box-shadow: none;
                    }

                    .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                        &::before {
                            background-color: $button-group-default-disabled-icon-color;
                        }
                    }
                } // END '.btn-group.btn-group-primary .btn.active.disabled'

            } // END '.btn-group.btn-group-primary .btn.active'
        } // END '.btn-group.btn-group-primary .btn'
    } // END '.btn-group.btn-group-primary'

    //# Style for buttons in 'disabled group'
    :disabled &, .disabled &,
    &:disabled,  &.disabled {
        &, &.#{$vudClassPrefix}btn-group-primary {
            .#{$vudClassPrefix}btn{
                &, &.active {
                    color: $button-group-disabled-text-color;

                    border-color: $button-group-disabled-border-color;
                    background-color: $button-group-disabled-bg;
                    box-shadow: none;
                }
            } // END '.disabled .btn-group .btn' or '.btn-group.btn-group-primary.disabled .btn'

            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $button-group-default-disabled-icon-color;
                }
            }
        }
    } // END '.disabled .btn-group' or '.btn-group.disabled'
} // END '.btn-group'
