
// Toggle/Switch button
//*** scss local variable ***

$switches-border-color:                     var(--#{$vudTokenPrefix}switches-border-color);
$switches-hover-border-color:               var(--#{$vudTokenPrefix}switches-hover-border-color);
$switches-focus-border-color:               var(--#{$vudTokenPrefix}switches-focus-border-color);
$switches-error-border-color:               var(--#{$vudTokenPrefix}switches-error-border-color);
$switches-disabled-border-color:            var(--#{$vudTokenPrefix}switches-disabled-border-color);

$switches-checked-border-color:             var(--#{$vudTokenPrefix}switches-checked-border-color);
$switches-checked-hover-border-color:       var(--#{$vudTokenPrefix}switches-checked-hover-border-color);
// $switches-checked-focus-inner-border-color: var(--#{$vudTokenPrefix}switches-focus-inner-border-color);
$switches-checked-focus-inner-border-color: var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);

$switches-checked-togglemark-border-color: var(--#{$vudTokenPrefix}switches-checked-togglemark-border-color);


$switches-togglemark-border-color:          var(--#{$vudTokenPrefix}switches-togglemark-border-color);
$switches-disabled-togglemark-border-color: var(--#{$vudTokenPrefix}switches-disabled-togglemark-border-color);


$switches-bg:                               var(--#{$vudTokenPrefix}switches-bg);
$switches-hover-bg:                         var(--#{$vudTokenPrefix}switches-hover-bg);
$switches-disabled-bg:                      var(--#{$vudTokenPrefix}switches-disabled-bg);

$switches-checked-bg:                       var(--#{$vudTokenPrefix}switches-checked-bg);
$switches-checked-hover-bg:                 var(--#{$vudTokenPrefix}switches-checked-hover-bg);
$switches-checked-disabled-bg:              color-mix(in oklch, black 12%, var(--#{$vudTokenPrefix}switches-checked-disabled-bg));

$switches-togglemark-bg:                    var(--#{$vudTokenPrefix}switches-togglemark-bg);
$switches-disabled-togglemark-bg:           var(--#{$vudTokenPrefix}switches-disabled-togglemark-bg);

$switches-togglemark-shadow:                var(--#{$vudTokenPrefix}input-focus-shadow);

$switches-text-size:                        var(--#{$vudTokenPrefix}font-size-sm);
$switches-text-sm-size:                     var(--#{$vudTokenPrefix}font-size-xs);

$switches-text-color:                       var(--#{$vudTokenPrefix}switches-text-color);
$switches-disabled-text-color:              var(--#{$vudTokenPrefix}switches-disabled-text-color);
$switches-disabled-label-text-color:        var(--#{$vudTokenPrefix}switches-disabled-text-color);

$switches-checked-text-color:               var(--#{$vudTokenPrefix}switches-checked-text-color);
$switches-checked-disabled-label-color:     var(--#{$vudTokenPrefix}switches-checked-disabled-text-color);


$switches-focus-togglemark-border-color:    var(--#{$vudTokenPrefix}input-focus-border-color); // light/dark switch


//*** element/component code ***
.switch {
    display: inline-block;
    position: relative;
    content: "";
    min-height: $spacing-16;
    margin: 0 $spacing-8 $spacing-8 0;
    padding: 0;

    color: $switches-text-color;
    font-size: $switches-text-size;
    line-height: 1;

    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // hide the input, keep for functionality
    input {
        &[type="checkbox"],
        &[type="radio"] {
            position: absolute;
            opacity: 0;
            cursor: default;
            width: 0;
            height: 0;
        }

        &:focus {
            ~ .togglemark {
                &::before {
                    box-shadow: inset 0 0 0 rem(1px) $switches-focus-border-color, 0 0 0 rem(1px) $switches-focus-border-color, $switches-togglemark-shadow;
                }
            }
        }

        &:checked, &.checked {
            ~ .togglemark {
                &::before {
                    color: $switches-checked-text-color;
                    text-align: left;

                    background-color: $switches-checked-bg;
                    box-shadow: inset 0 0 0 rem(1px) $switches-checked-border-color;
                }

                &::after {
                    left: rem(20px);

                    box-shadow: inset 0 0 0 rem(1px) $switches-checked-togglemark-border-color;
                }

                &:hover {
                    &::before {
                        background: $switches-checked-hover-bg;
                        box-shadow: inset 0 0 0 rem(1px) $switches-checked-hover-border-color;
                    }
                }
            }

            &:focus {
                ~ .togglemark {
                    &::before {
                        box-shadow: inset 0 0 0 rem(1px) $switches-focus-border-color, inset 0 0 0 rem(2px) $switches-checked-focus-inner-border-color, 0 0 0 rem(1px) $switches-focus-border-color, $switches-togglemark-shadow;
                    }
                }
            }
        }
    }

    // define styles for the visual que of the switch/toggle button
    .togglemark {
        position: relative;
        cursor: pointer;
        padding-left: $spacing-40;

        &::before, &::after {
            position: absolute;
            display: block;
            content: '';
        }

        &::before {
            width: $spacing-32;
            height: $spacing-16;
            top: $spacing-2;
            left: $spacing-2;

            color: $switches-text-color;
            font: {
                size: $switches-text-sm-size;
                weight: $bold;
            }
            text-align: right;

            border-radius: $spacing-16;
            background-color: $switches-bg;
            box-shadow: inset 0 0 0 rem(1px) $switches-border-color;

            transition: background-color 0.12s ease-in-out;
        }

        &::after {
            width: $spacing-12;
            height: $spacing-12;
            top: $spacing-4;
            left: $spacing-4;

            border-radius: $spacing-12;
            background-color: $switches-togglemark-bg;
            box-shadow: inset 0 0 0 rem(1px) $switches-togglemark-border-color;

            transition: left 0.09s ease-in-out;
        }

        &:hover {
        //     box-shadow: none;

            &::before {
                background-color: $switches-hover-bg;
                box-shadow: inset 0 0 0 rem(1px) $switches-hover-border-color;
            }
        }
    }

    // define the styles for the large/biger version of the switch/toggle button
    &.switch-lg {
        line-height: 1.714;

        .togglemark {
            padding-left: rem(56px);

            &::before {
                width: $spacing-48;
                height: $spacing-24;
                top: rem(-3px);

                border-radius: $spacing-24;
            }

            &::after {
                width: $spacing-16;
                height: $spacing-16;
                top: rem(1px);
                left: rem(7px);

                border-radius: $spacing-16;
            }
        }

        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::after {
                        left: rem(29px);
                    }
                }
            }
        }
    }

    // define the styles for the version with "ON"/"OFF" text
    &.switch-label {
        line-height: 1.714;

        .togglemark {
            padding-left: rem(72px);

            &::before {
                box-sizing: border-box;
                content: 'OFF';
                width: rem(60px);
                height: $spacing-24;
                top: rem(-3px);
                padding: 0 rem(10px);

                font-weight: $font-weight-base;
                line-height: 1.8;
                text-indent: 0;

                border-radius: $spacing-24;
            }

            &::after {
                width: $spacing-16;
                height: $spacing-16;
                top: rem(1px);
                left: rem(7px);

                border-radius: $spacing-16;
            }
        }

        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::before {
                        content: 'ON';
                    }

                    &::after {
                        left: rem(41px);
                    }
                }
            }
        }
    }
    
    // lable on the right - .switch-rtl
    &.switch-rtl {
        input {
            &:checked, &.checked {
                ~ .togglemark {
                    &::after {
                        right: rem(20px);
                        left: auto;
                    }
                }
            }
        }

        .togglemark {
            padding-left: 0;
            padding-right: $spacing-40;

            &:before {
                left: auto;
                right: $spacing-2;
            }

            &:after {
                left: auto;
                right: $spacing-4;
            }
        }

        &.switch-lg {
            input {
                &:checked, &.checked {
                    ~ .togglemark {
                        &::after {
                            right: rem(29px);
                            left: auto;
                        }
                    }
                }
            }

            .togglemark {
                padding-left: 0;
                padding-right: rem(56px);

                &:after {
                    left: auto;
                    right: rem(7px);
                }
            }
        }

        &.switch-label {
            input {
                &:checked, &.checked {
                    ~ .togglemark {
                        &::before {
                            text-align: right;
                        }

                        &::after {
                            right: rem(41px);
                            left: auto;
                        }
                    }
                }
            }

            .togglemark {
                padding-left: 0;
                padding-right: rem(72px);

                &::before {
                    text-align: left;
                }
            }
        }
    }
}


.has-error .switch input,
.switch.has-error input,
.switch input.has-error {
    &[type="checkbox"],
    &[type="radio"] {
        ~ .togglemark {
            &::before {
                box-shadow: inset 0 0 0 rem(1px) $switches-error-border-color;
            }
        }

        &:focus {
            ~ .togglemark {
                &::before {
                    box-shadow: inset 0 0 0 rem(1px) $switches-error-border-color, 0 0 0 rem(1px) $switches-error-border-color, $switches-togglemark-shadow;
                }
            }
        }

        &:checked, &.checked {
            ~ .togglemark {
                &::before {
                    box-shadow: inset 0 0 0 rem(1px) $switches-error-border-color, inset 0 0 0 rem(2px) $switches-checked-focus-inner-border-color;
                }
            }
    
            &:focus {
                ~ .togglemark {
                    &::before {
                        box-shadow: inset 0 0 0 rem(1px) $switches-error-border-color, inset 0 0 0 rem(2px) $switches-checked-focus-inner-border-color, 0 0 0 rem(1px) $switches-error-border-color, $switches-togglemark-shadow;
                    }
                }
            }
        }
    }
}


// Disabled style of the switch/toggle button
.disabled .switch input, :disabled .switch input,
.switch.disabled input, .switch:disabled input,
.switch input.disabled,
.switch input:disabled {
    &[type="checkbox"],
    &[type="radio"] {
        ~ .togglemark {
            cursor: default;
            pointer-events: none;
            color: $switches-disabled-text-color;

            &::before {
                color: $switches-disabled-label-text-color;

                background-color: $switches-disabled-bg;
                box-shadow: inset 0 0 0 rem(1px) $switches-disabled-border-color;
            }

            &::after {
                background-color: $switches-disabled-togglemark-bg;
                box-shadow: inset 0 0 0 rem(1px) $switches-disabled-togglemark-border-color;
            }
        }

        &:checked, &.checked {
            ~ .togglemark {
                &::before {
                    color: $switches-checked-disabled-label-color;
                    background-color: $switches-checked-disabled-bg;
                }
            }
        }
    }
}


// Switcher from light to dark mode
.light-dark-toggle {
    &.switch { 
        &.switch-label {
            display: inline-flex;
            width: 100%;
            padding: rem(15px) rem(5px) rem(15px) rem(25px);
            margin: 0;

            align-items: center;

            b { 
                font: $font-sm;
            }

            .togglemark {
                display: inline-flex;
                overflow: hidden;
                width: $spacing-48;
                min-width: $spacing-48;
                height: $spacing-24;
                padding: 0;
                margin: 0 $spacing-12;

                border-radius: $spacing-12;

                &, &:hover {
                    background-image: linear-gradient(360deg, #52B9FF 0%, #1C98EB 100%);
                    transition: background-image 0.3s ease-in-out;

                    &::before,
                    &::after {
                        content: '';
                        width: $spacing-16 !important;
                        height: $spacing-16 !important;
                        top: $spacing-4;
                    }

                    &::before {
                        width: rem(50px);
                        padding: 0 $spacing-6 0 $spacing-6;
                        left: $spacing-4;

                        background: radial-gradient(62.5% 62.5% at 37.5% 37.5%, #FED177 0%, #FEC044 100%);
                        box-shadow: none;
                        transition: left 0.3s ease-in-out;
                    }

                    &::after {
                        opacity: 0;
                        top: $spacing-2;
                        left: $spacing-2;

                        background: linear-gradient(to bottom, #1B3D54 0%, #224157 100%);
                        box-shadow: none;
                        transition: left 0.3s ease-in-out, opacity 0.02s ease-in-out;
                    }

                    .details {
                        &:before,
                        &:after {
                            content:'';
                            display: block;
                            position: absolute;
                            top: rem(3px);

                            z-index: 1;
                        }

                        &:before { // Clouds
                            opacity: 1;
                            width: $spacing-48;
                            height: $spacing-24;
                            left: $spacing-4;
                            top: 0;

                            background-image: url(#{$image-path}/switcher-clouds.svg);
                            transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
                        }

                        &:after { // Stars
                            opacity: 0;
                            width: rem(22px);
                            height: $spacing-8;
                            left: $spacing-8;

                            background-image: url(#{$image-path}/switcher-stars.svg);
                            transition: opacity 0.3s ease-in-out;
                        }
                    }
                }
            }

            input {
                &:checked, &.checked {
                    ~ .togglemark {
                        background-image: linear-gradient(to bottom, #193C54 0%, #244358 100%);
                        transition: background-image 0.3s ease-in-out;

                        &::before,
                        &::after { content:''; }

                        &::before {
                            left: rem(26px);
                            transition: left 0.3s ease-in-out;
                        }

                        &::after {
                            opacity: 1;
                            left: rem(22px);

                            transition: left 0.3s ease-in-out, opacity 0.02s ease-in-out;
                        }

                        .details {
                            &:before {
                                opacity: 0.4;
                                left: rem(-23px);

                                transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
                            }

                            &:after {
                                opacity: 1;
                                transition: opacity 0.3s ease-in-out;
                            }
                        }
                    }
                }

                &:focus {
                    ~ .togglemark {
                        outline: rem(2px) solid $switches-focus-togglemark-border-color;
                        outline-offset: rem(2px);

                        &::before {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    b.light {
        @media (prefers-color-scheme: dark) { font-weight: $normal !important; }
        @media (prefers-color-scheme: light) { font-weight: $bold !important; }
        .dark-theme & { font-weight: $normal !important; }
        .light-theme & { font-weight: $bold !important; }
    }

    b.dark {
        @media (prefers-color-scheme: light) { font-weight: $normal !important; }
        @media (prefers-color-scheme: dark) { font-weight: $bold !important; }
        .light-theme & { font-weight: $normal !important; }
        .dark-theme & { font-weight: $bold !important; }
    }
} // Switch from dark-light


