// CSS Custom Properties / CSS Variables
//
// Light & Dark Mode Variables
//
//=== A. Ligth Mode
//=== B. Dark Mode


/* Setup a few global scope properties */
//=== Theme Variables

/* A. Ligth Mode */
:root, :host,
html[data-theme='light'],
html.light-theme {
  @include light-mode();
  }


/* B. Dark Mode */
html[data-theme='dark']:not(.light-theme),
html.dark-theme:not([data-theme=light]):not(.light-theme) {
  @include dark-mode();
  }
