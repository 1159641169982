// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row            { flex-direction: row !important; }
    .flex#{$infix}-column         { flex-direction: column !important; }
    .flex#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
    .flex#{$infix}-column-reverse { flex-direction: column-reverse !important; }

    .flex#{$infix}-wrap         { flex-wrap: wrap !important; }
    .flex#{$infix}-nowrap       { flex-wrap: nowrap !important; }
    .flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }

    .justify-content#{$infix}-start   { justify-content: flex-start !important; }
    .justify-content#{$infix}-end     { justify-content: flex-end !important; }
    .justify-content#{$infix}-center  { justify-content: center !important; }
    .justify-content#{$infix}-between { justify-content: space-between !important; }
    .justify-content#{$infix}-around  { justify-content: space-around !important; }

    .align-items#{$infix}-start    { align-items: flex-start !important; }
    .align-items#{$infix}-end      { align-items: flex-end !important; }
    .align-items#{$infix}-center   { align-items: center !important; }
    .align-items#{$infix}-baseline { align-items: baseline !important; }
    .align-items#{$infix}-stretch  { align-items: stretch !important; }

    .align-content#{$infix}-start   { align-content: flex-start !important; }
    .align-content#{$infix}-end     { align-content: flex-end !important; }
    .align-content#{$infix}-center  { align-content: center !important; }
    .align-content#{$infix}-between { align-content: space-between !important; }
    .align-content#{$infix}-around  { align-content: space-around !important; }
    .align-content#{$infix}-stretch { align-content: stretch !important; }

    .align-self#{$infix}-auto     { align-self: auto !important; }
    .align-self#{$infix}-start    { align-self: flex-start !important; }
    .align-self#{$infix}-end      { align-self: flex-end !important; }
    .align-self#{$infix}-center   { align-self: center !important; }
    .align-self#{$infix}-baseline { align-self: baseline !important; }
    .align-self#{$infix}-stretch  { align-self: stretch !important; }

    .order#{$infix}-1	{order: 1;}
    .order#{$infix}-2	{order: 2;}
    .order#{$infix}-3	{order: 3;}
    .order#{$infix}-4	{order: 4;}
    .order#{$infix}-5	{order: 5;}
    .order#{$infix}-6	{order: 6;}
    .order#{$infix}-7	{order: 7;}
    .order#{$infix}-8	{order: 8;}
    .order#{$infix}-9	{order: 9;}
    .order#{$infix}-10 {order: 10;}
    .order#{$infix}-11 {order: 11;}
    .order#{$infix}-12 {order: 12;}
    .order#{$infix}-first	{order: -9999;}
    .order#{$infix}-last {order: 9999;}
    .order#{$infix}-none {order: 0;}

    .flex#{$infix}-fill,  
    .flex#{$infix}-auto { flex: 1 1 auto !important; }
    .flex#{$infix}-1 { flex: 1 1 0% !important; }
    .flex#{$infix}-initial { flex: 0 1 auto !important; }
    .flex#{$infix}-none { flex: none !important; }


    .flex-shrink#{$infix}-0  { flex-shrink: 0 !important; }
    .flex-shrink#{$infix}-1  { flex-shrink: 1 !important; }

    .flex-grow#{$infix}-0  { flex-grow: 0 !important; }
    .flex-grow#{$infix}-1  { flex-grow: 1 !important; }

    .me#{$infix}-auto { margin-right: auto !important; }
    .ms#{$infix}-auto { margin-left: auto !important; }

  }
}
