
//# Interface controls icons

//*** scss local variable ***

$vismaicon-datepicker-mask-img:                url('#{$image-path}/interface-controls/datepicker.svg');
$vismaicon-timepicker-mask-img:                url('#{$image-path}/interface-controls/timepicker.svg');
$vismaicon-autocomplete-mask-img:              url('#{$image-path}/interface-controls/autocomplete.svg');
$vismaicon-multiselection-mask-img:            url('#{$image-path}/interface-controls/multiselection.svg');
$vismaicon-close-mask-img:                     url('#{$image-path}/interface-controls-dynamic/close.svg');


$vismaicon-dynamic-calendar-mask-img:          url('#{$image-path}/interface-controls-dynamic/24_datepicker.svg');
$vismaicon-dynamic-sm-calendar-mask-img:       url('#{$image-path}/interface-controls-dynamic/16_datepicker.svg');

$vismaicon-dynamic-autocomplete-mask-img:      url('#{$image-path}/interface-controls-dynamic/24_autocomplete.svg');
$vismaicon-dynamic-sm-autocomplete-mask-img:   url('#{$image-path}/interface-controls-dynamic/16_autocomplete.svg');

$vismaicon-dynamic-multiselection-mask-img:    url('#{$image-path}/interface-controls-dynamic/24_multiselection.svg');
$vismaicon-dynamic-sm-multiselection-mask-img: url('#{$image-path}/interface-controls-dynamic/16_multiselection.svg');

$vismaicon-dynamic-close-mask-img:             url('#{$image-path}/interface-controls-dynamic/24_close.svg');
$vismaicon-dynamic-sm-close-mask-img:          url('#{$image-path}/interface-controls-dynamic/16_close.svg');

//*** mixins for icons group of dynamyc and standard interface controls icons ***

// standard/clasic set
@mixin standard-interface-controls-icons {
    &.vismaicon-calendar,
    &.vismaicon-datepicker {
        &:before { 
            -webkit-mask-image: $vismaicon-datepicker-mask-img;
            mask-image: $vismaicon-datepicker-mask-img;
        }
    }

    &.vismaicon-timepicker {
        &:before { 
            -webkit-mask-image: $vismaicon-timepicker-mask-img;
            mask-image: $vismaicon-timepicker-mask-img;
        }
    }

    &.vismaicon-autocomplete {
        &:before {
            -webkit-mask-image: $vismaicon-autocomplete-mask-img;
            mask-image: $vismaicon-autocomplete-mask-img;
        }
    }

    .vismaicon-multiselection {
        &:before {
            -webkit-mask-image: $vismaicon-multiselection-mask-img;
            mask-image: $vismaicon-multiselection-mask-img;
        }
    }

    .vismaicon-close {
        &:before {
            -webkit-mask-image: $vismaicon-close-mask-img;
            mask-image: $vismaicon-close-mask-img;
        }
    }
}

// dynamic set
@mixin dynamic-interface-controls-icons {
    &.vismaicon-calendar,
    &.vismaicon-datepicker {
        &:before {
            -webkit-mask-image: $vismaicon-dynamic-calendar-mask-img;
            mask-image: $vismaicon-dynamic-calendar-mask-img;
        }
        &.vismaicon-sm:before {
            -webkit-mask-image: $vismaicon-dynamic-sm-calendar-mask-img;
            mask-image: $vismaicon-dynamic-sm-calendar-mask-img;
        }
    }

    &.vismaicon-timepicker {
        &:before { 
            -webkit-mask-image: $vismaicon-timepicker-mask-img;
            mask-image: $vismaicon-timepicker-mask-img;
        }
    }

    &.vismaicon-autocomplete {
        &:before {
            -webkit-mask-image: $vismaicon-dynamic-autocomplete-mask-img;
            mask-image: $vismaicon-dynamic-autocomplete-mask-img;
        }
        &.vismaicon-sm:before { -webkit-mask-image: $vismaicon-dynamic-sm-autocomplete-mask-img;
            mask-image: $vismaicon-dynamic-sm-autocomplete-mask-img;
        }
    }

    &.vismaicon-multiselection {
        &:before {
            -webkit-mask-image: $vismaicon-dynamic-multiselection-mask-img;
            mask-image: $vismaicon-dynamic-multiselection-mask-img;
        }
        &.vismaicon-sm:before {
            -webkit-mask-image: $vismaicon-dynamic-sm-multiselection-mask-img;
            mask-image: $vismaicon-dynamic-sm-multiselection-mask-img;
        }
    }

    &.vismaicon-close {
        &:before {
            -webkit-mask-image: $vismaicon-dynamic-close-mask-img;
            mask-image: $vismaicon-dynamic-close-mask-img;
        }
        &.vismaicon-sm:before {
            -webkit-mask-image: $vismaicon-dynamic-sm-close-mask-img;
            mask-image: $vismaicon-dynamic-sm-close-mask-img;
        }
    }
}

//*** element/component code ***
.vismaicon {
    @if $vismaicon-style != 'dynamic' {

        @include standard-interface-controls-icons;

        &.vismaicon-dynamic {
            @include dynamic-interface-controls-icons;
        }

    } @else {

        @include dynamic-interface-controls-icons;

        &.vismaicon-standard,
        &.vismaicon-classic {
            @include standard-interface-controls-icons;
        }
    }
}
