﻿.h-table-wrapper {
  height: calc(100vh - #{$h-content} - #{$h-buttons});
}

.h-max-table-wrapper {
  max-height: calc(100vh - #{$h-content} - #{$h-buttons});
}

.h-max-table-vb {
  max-height: calc(
    100vh - #{$h-header-footer} - #{$h-vb-title-stepper} - #{$h-vb-buttons} - #{$h-vb-year}
  );
}

@mixin col-widths($isIVB: false) {
  //checkbox
  > :first-child {
    width: 2%;
    min-width: $checkboxColMinWidth;
  }

  //id
  > :nth-child(2) {
    width: 4%;
    min-width: 120px;
  }

  @if ($isIVB) {
    &.no-checkbox {
      > :first-child {
        width: 4%;
        min-width: 120px;
      }

      > :nth-child(2) {
        width: 10%;
        min-width: 300px;
      }
    }
    > :nth-child(n + 3) {
      flex: 1;
    }
  } @else {
    //name
    > :nth-child(3) {
      width: 16%;
      min-width: 200px;
    }

    > :nth-child(n + 4) {
      width: 19%;
      min-width: 140px;
    }

    > .tags-width {
      width: 25%;
      min-width: 200px;
    }
  }
}

.min-60 {
  min-width: 60px;
}

.table {
  min-width: fit-content;
  border-right: none;

  .table-header {
    .table-row-wrapper {
      > div {
        padding: 3px 10px;
        display: flex;
        align-items: center;
        width: 100%;
        border-right: 1px solid var(--table-border-color);
        min-height: 48px;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .table-body {
    .table-row-wrapper {
      > div,
      .rows-wrapper {
        min-height: 38px;

        .table-col {
          min-height: inherit;
        }
      }

      > div:only-child {
        flex: 1;
      }
    }
  }

  .table-row-wrapper {
    align-items: center;
    display: flex;

    .table-col {
      padding: 3px 10px;
      display: flex;
      align-items: center;
    }

    &:nth-child(2n) {
      background-color: var(--table-alt-bg);
    }

    &.error-bg,
    .error-bg {
      background-color: var(--alert-danger-bg);
    }

    &.warning-bg,
    .warning-bg {
      background-color: var(--alert-warning-bg);
    }

    &.success-bg,
    .success-bg {
      background-color: var(--alert-success-bg);
    }

    .truncated-text {
      overflow-wrap: break-word;
    }
  }

  .table-header {
    border-bottom: 1px solid var(--table-border-color);

    .table-row-wrapper {
      min-height: 36px;
    }
  }

  // .equal - when all columns in the table have to be equal width. F.e. in history
  &.equal {
    .table-row-wrapper {
      .table-col {
        &:nth-last-child(2) {
          background: none;
          border-right: none;
          border-right: none;
        }

        &:not(:last-child) {
          flex: 1;
        }

        &:last-child {
          flex: 0;
        }
      }
    }
  }

  &.two-first-columns {
    .table-header {
      .table-row-wrapper {
        @include col-widths(true);
      }
    }

    .table-body {
      .table-row-wrapper {
        //employees, time agreements screen
        > div:only-child,
        .rows-wrapper {
          @include col-widths(true);
        }

        //with errors
        &[class*='-invalid'],
        &[class*='error-bg'],
        &[class*='warning'] {
          > div:first-child {
            @include col-widths(true);
          }
        }
      }
    }

    .table-footer {
      .table-row-wrapper {
        @include col-widths(true);
      }
    }
  }

  &:not(.equal):not(.two-first-columns) {
    .table-header {
      .table-row-wrapper {
        @include col-widths;
      }
    }

    .table-body {
      .table-row-wrapper {
        //employees screen
        > div:only-child {
          @include col-widths;
        }

        //with errors
        &[class*='-invalid'],
        &[class*='error-bg'],
        &[class*='warning'] {
          > div:first-child {
            @include col-widths;
          }
        }
      }
    }

    .table-footer {
      .table-row-wrapper {
        @include col-widths;
      }
    }
  }

  .table-header,
  .table-footer {
    font-weight: 600;

    .table-col {
      min-height: 48px;

      &:last-child {
        background: none;
      }

      &.checkbox-column-width {
        padding: 0 10px;
      }
    }
  }

  .table-body {
    overflow-y: scroll;
    --scrollbar-track-bg: var(--table-bg);
    width: 100%;
    overflow-x: hidden;

    .table-row-wrapper {
      width: 100%;

      //with errors
      &[class*='-invalid'],
      &[class*='error-bg'],
      &[class*='warning'] {
        flex-direction: column;

        > div:first-child {
          width: 100%;
        }

        > div:nth-child(2) {
          width: 100%;

          > :first-child {
            width: 2%;
            min-width: $checkboxColMinWidth;
            background: none;
          }

          > :nth-child(2) {
            width: 98%;
          }
        }
      }
    }
  }

  .table-col {
    input.form-control[readonly] {
      padding-right: 0;
    }
  }

  .table-header,
  .table-body,
  .table-footer {
    background: var(--table-bg);
    display: block;
  }

  .table-body,
  .table-footer {
    .table-col {
      background: url('/assets/icons/dotted-on-white-vertical.svg') repeat-y transparent 100% 0;

      &:last-child {
        background: none;
      }
    }
  }

  .table-header,
  .table-footer {
    .table-row-wrapper {
      width: calc(100% - 8px);
    }
  }

  .form-width {
    min-width: fit-content;
    width: -webkit-fill-available;
  }
}

.table-wrapper {
  //--scrollbar-track-bg: var(--table-bg);

  &::-webkit-scrollbar {
    height: 7px;
  }
}

di-update-salaries-table,
di-update-salaries-review-table,
di-update-salaries-results-table {
  .table-header,
  .table-footer {
    .table-row-wrapper {
      > .table-col {
        &:not(:first-child) {
          flex: 1;
          min-width: 120px !important;
        }
      }
    }
  }

  .table-body {
    .table-row-wrapper {
      div > .table-col {
        &:not(:first-child) {
          flex: 1;
          min-width: 120px !important;
        }
      }
    }
  }
}

.light-theme {
  .bg-aggrid {
    background-color: #f8f8f8;
  }
}

.bg-aggrid {
  border-color: var(--neutral-40);
  border-style: solid;
  border-width: 1px 1px 0 1px;
}
