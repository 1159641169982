
// Stepper - vertical

//*** scss local variable ***

$stepper-vertical-text-color:   var(--#{$vudTokenPrefix}stepper-text-color);

$stepper-vertical-border-color: var(--#{$vudTokenPrefix}stepper-active-border-color);

$stepper-vertical-wrapper-bg:   var(--#{$vudTokenPrefix}stepper-wrapper-bg);
$stepper-vertical-bg:           var(--#{$vudTokenPrefix}stepper-bg);
$stepper-vertical-visited-bg:   var(--#{$vudTokenPrefix}stepper-visited-bg);

//*** element/component code ***
.#{$vudClassPrefix}stepper.#{$vudClassPrefix}stepper-vertical {
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    padding: 0;

    > ul {
        flex-direction: column;
        padding-left: 0;

        > li,
        > li:first-child {
            position: relative;
            max-width: 100%;
            width: auto;
        }

        > li {
            padding-left: rem(4rem);
            top: rem(5px);

            &.visited > *,
            > * {
                width: rem(300px);
                min-height: rem(120px);
                padding-left: rem(65px);
                padding-top: rem(4px);

                @media (max-width: $screen-md-min) {
                    padding-left: rem(48px);
                    padding-top: 0;
                    min-height: rem(45px);
                }

                > span {
                    width: 100%;
                    max-width: 100%;
                }

                &:after {
                    width: rem(3px);
                    height: 100%;
                    top: calc(#{'-100% + ' rem(8px)});
                    left: rem(18px);
                    bottom: auto;

                    @media (max-width: $screen-md-min) {
                        width: rem(2px);
                        left: rem(9px);
                    }
                }

                &:before {
                    bottom: auto;
                    right: auto;
                    top: 0;
                    left: rem(4px);

                    @media (max-width: $screen-md-min) {
                        width: rem(16px);
                        height: rem(16px);
                        left: rem(2px);
                    }
                }

                > span:not(.step) {
                    text-align: left;

                    @media (max-width: $screen-md-min) {
                        top: rem(-5px);
                        position: relative;
                    }
                }
            }

            &:last-child {
                max-height: rem(40px);
            }

            &.active {
                top: 0;

                @media (max-width: $screen-md-min) { margin-top: rem(-3px); }

                > * {
                    padding-top: rem(12px);

                    &:before {
                        left: rem(-4px);

                        @media (max-width: $screen-md-min) {
                            width: rem(20px);
                            height: rem(20px);
                            left: 0;

                            border-width: rem(8px);
                        }
                    }

                    > span:not(.step) {
                        top: rem(8px);

                        @media (max-width: $screen-md-min) {
                            top: rem(-9px);
                        }
                    }
                }

                &:not(:last-child):after {
                    display: block;
                    content: '';
                    position: absolute;
                    width: rem(3px);
                    height: 100%;
                    top: rem(25px);
                    left: rem(22px);

                    background-color: $stepper-vertical-bg;

                    @media (max-width: $screen-md-min) {
                        width: rem(2px);
                        left: rem(13px);
                        height: 90%;
                        top: rem(20px);
                    }
                }

                &:not(:last-child):not(:first-child):after {
                    height: calc(#{'100% - ' rem(20px)});

                    @media (max-width: $screen-md-min) {
                        height: max(90%, calc(#{'100% - ' rem(64px)}));
                    }
                }
            }

            &.passed {
                > * {
                    > span:not(.step) {
                        @media (max-width: $screen-md-min) { top: rem(-4px); }
                    }

                    &:before {
                        @media (max-width: $screen-md-min) { background-image: none; }
                    }
                }
                
                + .active {
                    > * {
                        &:after {
                            background: linear-gradient(180deg, $stepper-vertical-visited-bg 0%, $stepper-vertical-border-color 50%, $stepper-vertical-bg 50%, $stepper-vertical-bg 100%);
                            background-size: auto 200%;
                            background-position-y: 0%;
                        }
                    }
                }
            }

        }
    }

    &.no-ellipsis {
        > ul > li > * > span:not(.step) {
            @media (min-width: $screen-md-max) {
                display: flex;
                justify-content: flex-start;
                position: relative;
                top: rem(-11px);
            }
        }

        > ul > li > :before {
            @media (max-width: $screen-md-min) {
                top: rem(-2px);
            }
        }

        > ul > li > :after {
            @media (max-width: $screen-md-min) {
                top: calc(#{'-100% + ' rem(6px)});
            }
        }
    }
}


// Stepper vertical inside a container
.#{$vudClassPrefix}stepper.#{$vudClassPrefix}stepper-vertical.#{$vudClassPrefix}stepper-content {
    > ul {
        > li {
            margin-bottom: rem(20px);
            padding: rem(28px) rem(25px) rem(14px);
            top: 0;

            border-radius: $border-radius-10;
            background: $stepper-vertical-wrapper-bg;

            @media (max-width: $screen-md-min) {
                padding-top: rem(30px);
            }

            > * {
                min-height: rem(30px);

                &:after {
                    height: calc(#{'100% + ' rem(55px)});
                    top: calc(#{'-100% + ' rem(-55px)});
                }
            }

            > *:not(.#{$vudClassPrefix}panel) {
                > span { display: none; }
            }

            > .#{$vudClassPrefix}panel {
                cursor: default;
                width: 100%;
                margin: 0;
                margin-top: rem(-59px);
                padding: 0 0 0 rem(40px);

                font: $font-sm;
                text-align: left;
                white-space: normal;
                font-weight: normal;

                background: none;
                box-shadow: none;

                @media (max-width: $screen-md-min) {
                    padding: 0 0 0 rem(15px);
                    margin-top: rem(-64px);
                }

                &:before,
                &:after { display: none; }

                .#{$vudClassPrefix}panel-heading { cursor: pointer; }

                .#{$vudClassPrefix}panel-body {
                    display: none;
                    overflow: hidden;
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &:not(.active) {
                > .#{$vudClassPrefix}panel {
                    .#{$vudClassPrefix}panel-body {
                        padding: 0;
                    }

                    .#{$vudClassPrefix}panel-title {
                        overflow: hidden;

                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            &.active {
                @media (max-width: $screen-md-min) {
                    margin-top: 0;
                }

                > * {
                    &:before {
                        top: rem(-6px);
                    }
                }

                &:not(:last-child):after {
                    top: rem(35px);
                    left: rem(43px);

                    @media (max-width: $screen-md-min) {
                        left: rem(34px);
                    }
                }

                > .#{$vudClassPrefix}panel {
                    .#{$vudClassPrefix}panel-body {
                        display: block;
                        opacity: 1;
                        visibility: visible;
                        color: $stepper-vertical-text-color;
                    }
                }
            }

            &:last-child {
                max-height: 100%;
            }
        }
    }

    &.#{$vudClassPrefix}stepper-content-outside {
        > ul {
            padding-left: rem(72px);

            @media (max-width: $screen-md-min) {
                padding-left: rem(48px);
            }

            > li {
                padding-left: 0;
                padding-right: 0;

                > *:before {
                    left: rem(-63px);

                    @media (max-width: $screen-md-min) {
                        left: rem(-45px);
                    }
                }

                > *:after {
                    left: rem(-49px);

                    @media (max-width: $screen-md-min) {
                        left: rem(-38px);
                    }
                }

                &.active {
                    > *:before {
                        left: rem(-72px);

                        @media (max-width: $screen-md-min) {
                            left: rem(-47px);
                        }
                    }

                    &:not(:last-child):after {
                        left: rem(-49px);

                        @media (max-width: $screen-md-min) {
                            left: rem(-38px);
                        }
                    }
                }

                > .#{$vudClassPrefix}panel {
                    padding: 0;
                }
            }
        }
    }
}
