//# Table

//*** scss local variable ***
$table-condensed-size:        rem(32px);
$table-default-size:          rem(40px);
$table-regular-size:          rem(48px);
$table-relaxed-size:          rem(56px);
$table-row-line-height:       rem(20px);
$table-default-height:        rem(32px);
$table-border-width:          rem(1px);

$table-default-padding-x:     $spacing-16;
$table-condensed-padding-y:   calc(($table-condensed-size - $table-default-height) / 2);
$table-default-padding-y:     calc(($table-default-size - $table-default-height) / 2);
$table-regular-padding-y:     calc(($table-regular-size - $table-default-height) / 2);
$table-relaxed-padding-y:     calc(($table-relaxed-size - $table-default-height) / 2);

$table-border-color:          var(--#{$vudTokenPrefix}table-border-color);
$table-hover-border-color:    var(--#{$vudTokenPrefix}table-hover-border-color);
$table-focus-border-color:    var(--#{$vudTokenPrefix}tabs-focus-border-color);
$table-pressed-border-color:  var(--#{$vudTokenPrefix}table-active-border-color);
$table-selected-border-color: var(--#{$vudTokenPrefix}table-selected-border-color);

$table-inner-divider:         linear-gradient(to bottom, var(--#{$vudTokenPrefix}table-dotted-divider-color) 10%, rgba(255, 255, 255, 0) 0%);

$table-bg:                    var(--#{$vudTokenPrefix}table-bg);
$table-alt-bg:                var(--#{$vudTokenPrefix}table-alt-bg);
$table-hover-bg:              var(--#{$vudTokenPrefix}table-hover-bg);
$table-pressed-bg:            var(--#{$vudTokenPrefix}table-active-bg);
$table-selected-bg:           var(--#{$vudTokenPrefix}table-selected-bg);
$table-error-bg:              var(--#{$vudTokenPrefix}table-error-bg);
$table-error-text-color:      var(--#{$vudTokenPrefix}table-error-text-color);

$table-shadow:                var(--#{$vudTokenPrefix}table-shadow);

//*** element/component code ***
.#{$vudClassPrefix}table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacing-16;

    border-spacing: 0;
    border-collapse: separate;
    border: rem(1px) solid $table-border-color;

    background: $table-bg;
    box-shadow: $table-shadow;

    // caption basic style
    caption {
        padding: $spacing-8 $spacing-16;
        text-align: left;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // cell main lateral padding
    > thead, > tbody, > tfoot {
        > tr {
            > th, > td {
                padding-right: $table-default-padding-x;
                padding-left: $table-default-padding-x;

                vertical-align: middle;
                background-color: $table-bg;

                .checkbox { top: rem(2px); }
            }
        }
    }

    // thead cell - the main style
    > thead {
        > tr {
            > th, > td {
                height: $table-regular-size;
                padding-top: $table-regular-padding-y;
                padding-bottom: $table-regular-padding-y;

                font-weight: $semibold;

                border: $table-border-width solid transparent;
                border-bottom-color: $table-border-color;
            }
        }
    }

    // tbody cell - the main style
    > tbody {
        > tr {
            > th, > td {
                position: relative;
                height: $table-default-size;
                padding-top: $table-default-padding-y;
                padding-bottom: $table-default-padding-y;

                font-weight: $normal;

                border-top: $table-border-width solid $table-border-color;
                border-bottom: $table-border-width solid transparent;

                &:first-child {
                    border-left: $table-border-width solid transparent;

                    .indent-error {
                        position: absolute;
                        width: rem(32px);
                        height: rem(32px);
                        left: rem(-32px);

                        justify-content: center;

                        .vismaicon {
                            top: rem(3px);
                        }
                    }
                }

                &:last-child { border-right: $table-border-width solid transparent; }

                &.no-border {
                    border-color: transparent;
                }

                .indent-error {
                    display: inline-flex;
                }
            }


            &:first-child {
                > th, > td { border-top-color: transparent; }
            }

            &.focus {
                > th, > td {
                    border-color: $table-focus-border-color;
                    box-shadow: inset 0 rem(1px) 0 $table-focus-border-color, inset 0 rem(-1px) 0 $table-focus-border-color;

                    &:first-child {
                        &:not(:last-child) {
                            box-shadow: inset rem(1px) rem(1px) 0 $table-focus-border-color, inset 0 rem(-1px) 0 $table-focus-border-color;
                        }

                        &:last-child {
                            box-shadow: inset rem(1px) rem(1px) 0 $table-focus-border-color, inset rem(-1px) rem(-1px) 0 $table-focus-border-color;
                        }
                    }

                    &:last-child:not(:first-child) {
                        box-shadow: inset 0 rem(1px) 0 $table-focus-border-color, inset rem(-1px) rem(-1px) 0 $table-focus-border-color;
                    }
                }
            }

            &.has-error {
                &:hover {
                    > th, > td {
                        color: $table-error-text-color;
                        background-color: $table-error-bg;
                    }
                }
                > th, > td {
                    .vismaicon {
                        position: inherit;
                        top: rem(3px);
                        right: unset;
                        transform: none;
                    }
                }

                .checkbox:not(.has-error):not(:focus-within) {
                    outline: 0;
                }
            }
        }
    }

    // tfoot cell - the main style
    > tfoot {
        > tr {
            > th, > td {
                padding-top: $spacing-16;
                padding-bottom: $spacing-16;

                font-weight: normal;

                border-top: $table-border-width solid $table-selected-bg;

                p, .btn { margin-bottom: 0; }
            }
        }
    }

    &.#{$vudClassPrefix}table-header-sticky {
        > thead {
            > tr {
                > th, > td {
                    position: sticky;
                    top: 0;
                    z-index: 2;
                }
            }
        }
    }

    &.#{$vudClassPrefix}table-first-column-sticky {
        > thead {
            > tr {
                > th, > td {
                    &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 3;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > th, > td {
                    &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 1;
                    }
                }
            }
        }
    }

    &.#{$vudClassPrefix}table-last-column-sticky {
        > thead {
            > tr {
                > th, > td {
                    &:last-child {
                        position: sticky;
                        right: 0;
                        z-index: 3;
                    }
                }
            }
        }

        > tbody {
            > tr {
                > th, > td {
                    &:last-child {
                        position: sticky;
                        right: 0;
                        z-index: 1;
                    }
                }
            }
        }
    }


    //## '.table-bordered' - add border on the tables in case the tables will be added on a white background
    &.#{$vudClassPrefix}table-bordered {
        border: $table-border-width solid $table-border-color;
        border-bottom: 0;
    }


    //## '.table-stripe' - variant table design - zebra design
    &.#{$vudClassPrefix}table-stripe {
        // thead and tbody - vertical dotted divider
        > thead, > tbody {
            > tr {
                > th, > td {
                    background: {
                        image: $table-inner-divider;
                        position: right;
                        size: rem(1px) rem(6px);
                        repeat: repeat-y;
                    }
    
                    &:last-child { background-image: none; }
                }
            }
        }

        // zebra background style declaration
        > tbody {
            > tr {
                &:nth-child(odd) > th,
                &:nth-child(odd) > td { background-color: $table-alt-bg; }

                > th, > td { border-color: transparent; }
            }
        }
    }


    //## '.table-zebra' - variant table design - zebra design
    &.#{$vudClassPrefix}table-plain {
        > tbody {
            > tr {
                > th, > td {
                    border-color: transparent;
                }
            }
        }
    }


    //## '.table-hover' - variant table design - hover state for table fields (this applied to .table-active too)
    &.#{$vudClassPrefix}table-active,
    &.#{$vudClassPrefix}table-hover {
        > thead, > tbody {
            > tr {
                > th, > td { cursor: pointer; }
            }
        }

        > thead {
            > tr {
                > th, > td {
                    &:hover:not(.no-hover) {
                        border-color: $table-hover-border-color;
                        background-color: $table-hover-bg;
                    }
                }
            }
        }

        > tbody {
            > tr {
                &:hover {
                    > th, > td {
                        border-color: $table-hover-border-color;
                        background-color: $table-hover-bg;
                    }

                    + tr:not(.focus) {
                        > th, > td {
                            border-top-color: transparent;
                        }
                    }
                }

                &.has-error {
                    &, &.active {
                        &:hover,
                        &:hover:not(:active) {
                            > th, > td {
                                color: $table-error-text-color;
                                border-color: $table-border-color transparent $table-border-color transparent;
                                background-color: $table-error-bg;
                            }
                        }
                    }
                }
            }
        }
    }


    //## '.table-active' - variant table design - active state for table fields
    &.#{$vudClassPrefix}table-active {
        > thead, > tbody {
            > tr {
                > th, > td { cursor: pointer; }
            }
        }

        > thead {
            > tr {
                > th, > td {
                    // pressed state for thead cells
                    &:active:not(.no-hover) {
                        border-color: $table-pressed-border-color;
                        background-color: $table-pressed-bg;
                    }
                }
            }
        }

        > tbody {
            > tr {
                &:active, &.active {
                    > th, > td {
                        border-color: $table-pressed-border-color;
                        background-color: $table-selected-bg;
                    }

                    + tr:not(.focus) {
                        > th, > td {
                            border-top-color: transparent;
                        }
                    }
                }

                &:active {
                    > th, > td {
                        background-color: $table-pressed-bg;
                    }
                }

                &.active {
                    &:hover {
                        > th, > td {
                            border-color: $table-hover-border-color;
                            background-color: $table-hover-bg;
                        }
                    }
                }
            }
        }
    }


    //## '.table-condensed' - variant table design - make tables more compact
    &.#{$vudClassPrefix}table-condensed {
        > tbody {
            > tr {
                > th, > td {
                    height: $table-condensed-size;
                    padding-top: $table-condensed-padding-y;
                    padding-bottom: $table-condensed-padding-y;
                }
            }
        }
    }


    //## '.table-regular' - variant table design
    &.#{$vudClassPrefix}table-regular {
        > tbody {
            > tr {
                > th, > td {
                    height: $table-regular-size;
                    padding-top: $table-regular-padding-y;
                    padding-bottom: $table-regular-padding-y;
                }
            }
        }
    }


    //## '.table-relaxed' - variant table design - make tables more compact
    &.#{$vudClassPrefix}table-relaxed {
        > tbody {
            > tr {
                > th, > td {
                    height: $table-relaxed-size;
                    padding-top: $table-relaxed-padding-y;
                    padding-bottom: $table-relaxed-padding-y;
                }
            }
        }
    }
}



//# Responsive tables
.#{$vudClassPrefix}table-responsive {
    min-height: .01%;
    overflow: auto;

    @media(max-width: $screen-md-min) {
        width: 100%;
        margin-bottom: rem(18px);
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        box-shadow: $table-shadow;

        > .#{$vudClassPrefix}table { 
            margin: 0;
            box-shadow: none;
        }
    }
}
