// Datepicker inline

//*** scss local variable ***
$datepicker-inline-dropdown-bg: var(--#{$vudTokenPrefix}datepicker-inline-bg);

//*** element/component code ***
.#{$vudClassPrefix}datepicker {
    .is-inline {
        .#{$vudClassPrefix}dropdown-menu {
            position: static;
            display: inline-block;
            background: $datepicker-inline-dropdown-bg;
            z-index: 1;
        }
    }
}
