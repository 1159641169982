
// Button pills/facets

//*** scss local variable ***
// pills/facets default theme
$facet-text-size:                        var(--#{$vudTokenPrefix}font-size-sm);
$facet-text-color:                       var(--#{$vudTokenPrefix}button-text-color);
$facet-disabled-text-color:              var(--#{$vudTokenPrefix}button-disabled-text-color);

$facet-border-color:                     var(--#{$vudTokenPrefix}button-border-color);
$facet-hover-border-color:               var(--#{$vudTokenPrefix}button-hover-border-color);
$facet-focus-border-color:               var(--#{$vudTokenPrefix}button-focus-border-color);
$facet-pressed-border-color:             var(--#{$vudTokenPrefix}button-pressed-border-color);
$facet-active-border-color:              var(--#{$vudTokenPrefix}button-active-border-color);
$facet-disabled-border-color:            var(--#{$vudTokenPrefix}button-disabled-border-color);

$facet-bg:                               var(--#{$vudTokenPrefix}button-bg);
$facet-hover-bg:                         var(--#{$vudTokenPrefix}button-hover-bg);
$facet-pressed-bg:                       var(--#{$vudTokenPrefix}button-pressed-bg);
$facet-active-bg:                        var(--#{$vudTokenPrefix}button-selected-bg);
$facet-disabled-bg:                      var(--#{$vudTokenPrefix}button-disabled-bg);

$facet-shadow:                           var(--#{$vudTokenPrefix}button-shadow);
$facet-primary-shadow:                   var(--#{$vudTokenPrefix}button-primary-shadows);

$facet-icon-color:                       var(--#{$vudTokenPrefix}button-icon-color);
$facet-disabled-icon-color:              var(--#{$vudTokenPrefix}button-disabled-icon-color);

// pills/facets primary/alternative theme
$facet-primary-text-color:               var(--#{$vudTokenPrefix}button-primary-text-color);

$facet-primary-border-color:             var(--#{$vudTokenPrefix}button-primary-selected-border-color);
$facet-primary-hover-border-color:       color-mix(in oklch, black 12%, var(--#{$vudTokenPrefix}button-primary-border-color));
$facet-primary-focus-border-color:       var(--#{$vudTokenPrefix}button-primary-focus-border-color);
$facet-primary-focus-inner-border-color: var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);

$facet-primary-bg:                       var(--#{$vudTokenPrefix}button-primary-selected-bg);
$facet-primary-hover-bg:                 color-mix(in oklch, black 12%, var(--#{$vudTokenPrefix}button-primary-bg));

$facet-primary-icon-color:               var(--#{$vudTokenPrefix}button-primary-icon);


//*** element/component code ***
.#{$vudClassPrefix}nav {
    //
    &.#{$vudClassPrefix}nav-pills {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $spacing-16;
        margin: 0;
        padding: 0;

        list-style: none;

        li {
            display: flex;
        }

        //## Base style for a button pills
        a, button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: $spacing-32;
            padding: $spacing-4 $spacing-16;
            margin: 0;
            min-width: $spacing-128;
            text-align: center;
            color: $facet-text-color;
            font: {
                size: $facet-text-size;
                weight: $normal;
            }
            text-decoration: none;

            outline: 0;
            border: {
                radius: rem(48px);
                width: rem(1px);
                style: solid;
                color: $facet-border-color;
            }
            background-color: $facet-bg;
            box-shadow: $facet-shadow;

            //### pills hover state
            &:hover {
                border-color: $facet-hover-border-color;
                background-color: $facet-hover-bg;
            }

            //### pills focus state
            &:focus {
                border-color: $facet-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $facet-focus-border-color, $facet-shadow;
            }

            //### pills pressed state
            &:active, &.active:active, &.pressed, &.pressed:active {
                border-color: $facet-pressed-border-color;
                background-color: $facet-pressed-bg;
                box-shadow: none;
            }

            //### icons inside pills
            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $facet-icon-color;
                }
            }
        }

        //### pills active/selected state
        .active a, a.active,
        .active button,
        button.active {
            border-color: $facet-active-border-color;
            background-color: $facet-active-bg;
            box-shadow: none;

            //### pills active/selected and hover state
            &:hover {
                border-color: $facet-hover-border-color;
                background-color: $facet-hover-bg;
            }

            //### pills active/selected focus and not pressed state
            &:focus:not(:active) {
                border-color: $facet-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $facet-focus-border-color;
            }

            //### pills active/selected and pressed state
            &:active {
                border-color: $facet-pressed-border-color;
                background-color: $facet-pressed-bg;
                box-shadow: none;
            }
        }

        //### pills disabled state
        .disabled a,
        a.disabled,
        .disabled button,
        button.disabled,
        button:disabled {
            cursor: default;
            pointer-events: none;

            color: $facet-disabled-text-color;

            border-color: $facet-disabled-border-color;
            background-color: $facet-disabled-bg;
            box-shadow: none;

            //### icons inside pills disabled state
            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                &::before {
                    background-color: $facet-primary-icon-color;
                }
            }
        }


        //# Alternative styling for pill/facet buttons
        &.#{$vudClassPrefix}nav-pills-primary {
            .active a,
            a.active,
            .active button,
            button.active {
                color: $facet-primary-text-color;

                border-color: $facet-primary-border-color;
                background-color: $facet-primary-bg;
                box-shadow: $facet-primary-shadow;

                //### icons inside nav-pills-primary
                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $facet-primary-icon-color;
                    }
                }

                //### pills active/selected and hover state
                &:hover {
                    border-color: $facet-primary-hover-border-color;
                    background-color: $facet-primary-hover-bg;
                }

                //### pills active/selected focus and not pressed state
                &:focus:not(:active) {
                    border-color: $facet-primary-focus-border-color;
                    box-shadow: inset 0 0 0 rem(1px) $facet-primary-focus-border-color, inset 0 0 0 rem(2px) $facet-primary-focus-inner-border-color, $facet-primary-shadow;
                }

                //### pills active/selected and pressed state
                &:active {
                    color: $facet-text-color;

                    border-color: $facet-pressed-border-color;
                    background-color: $facet-pressed-bg;
                    box-shadow: none;

                    //### icons inside pills
                    .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                        &::before {
                            background-color: $facet-icon-color;
                        }
                    }
                }

                &:disabled, &.disabled {
                    color: $facet-disabled-text-color;

                    border-color: $facet-disabled-border-color;
                    background-color: $facet-disabled-bg;

                    //### icons inside nav-pills-primary on disabled state
                    .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                        &::before {
                            background-color: $facet-disabled-icon-color;
                        }
                    }
                }
            }
        }


        //# Larger styling for pill/facet buttons
        &.#{$vudClassPrefix}nav-pills-lg {
            gap: $spacing-16;

            a, button {
                padding: rem(11px) $spacing-24;
                line-height: $spacing-24;
                height: rem(48px);
            }
        }


        //# Disabled state for all pills in wrapper ('.nav.nav-pills')
        :disabled &, .disabled &,
        &:disabled, &.disabled {
            a, button {
                cursor: default;
                pointer-events: none;

                color: $facet-disabled-text-color;

                border-color: $facet-disabled-border-color;
                background-color: $facet-disabled-bg;
                box-shadow: none;

                //### icons inside pills disabled state
                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $facet-disabled-icon-color;
                    }
                }
            }

            //# Alternative styling for pill/facet buttons
            &.#{$vudClassPrefix}nav-pills-primary {
                .active a,
                a.active,
                .active button,
                button.active {
                    color: $facet-disabled-text-color;

                    border-color: $facet-disabled-border-color;
                    background-color: $facet-disabled-bg;

                    //### icons inside pills disabled state
                    .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                        &::before {
                            background-color: $facet-disabled-icon-color;
                        }
                    }
                }
            }
        }
    }
}
