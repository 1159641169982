@use './base' as *;
@use './inputs' as *;
@use './rows' as *;
@use './status-bar' as *;
@use './tool-panel' as *;
@use './header' as *;
@use './dark_theme_base' as *;

//.ag-theme-hrm {
//  @include base();
//  @include inputs();
//  @include rows();
//  @include status-bar();
//  @include tool-panel();
//  @include header();
//}

.ag-theme-visma {
  @include base();
  //@include inputs();
  @include rows();
  @include status-bar();
  @include tool-panel();
  @include header();
}
