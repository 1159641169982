
//# Custom checkbox

//*** scss local variable ***
// radio-checkbox
$radio-checkbox-text-size:                             var(--#{$vudTokenPrefix}font-size-sm);
$radio-checkbox-text-color:                            var(--#{$vudTokenPrefix}radio-checkbox-text-color);
$radio-checkbox-disabled-text-color:                   var(--#{$vudTokenPrefix}radio-checkbox-disabled-text-color);

$radio-checkbox-border-radius:                         var(--#{$vudTokenPrefix}radius-xs);
$radio-checkbox-dot-border-radius:                     var(--#{$vudTokenPrefix}radius-xl);

$radio-checkbox-border-color:                          var(--#{$vudTokenPrefix}radio-checkbox-border-color);
$radio-checkbox-focus-border-color:                    var(--#{$vudTokenPrefix}radio-checkbox-focus-border-color);
$radio-checkbox-error-focus-border-color:              var(--#{$vudTokenPrefix}radio-checkbox-error-focus-border-color);
$radio-checkbox-disabled-border-color:                 var(--#{$vudTokenPrefix}radio-checkbox-disabled-border-color);

$radio-checkbox-checked-border-color:                  var(--#{$vudTokenPrefix}radio-checkbox-checked-border-color);
$radio-checkbox-checked-disabled-dot-border-color:     var(--#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg);
$radio-checkbox-checked-disabled-border-color:         var(--#{$vudTokenPrefix}radio-checkbox-checked-disabled-border-color);

$radio-checkbox-bg:                                    var(--#{$vudTokenPrefix}radio-checkbox-bg);
$radio-checkbox-checkmark-bg:                          var(--#{$vudTokenPrefix}radio-checkbox-checkmark-bg);
$radio-checkbox-dot-bg:                                var(--#{$vudTokenPrefix}radio-checkbox-dot-bg);
$radio-checkbox-disabled-bg:                           var(--#{$vudTokenPrefix}radio-checkbox-disabled-bg);

$radio-checkbox-checked-bg:                            var(--#{$vudTokenPrefix}radio-checkbox-checked-bg);
$radio-checkbox-checked-disabled-checkmark-bg:         var(--#{$vudTokenPrefix}radio-checkbox-checked-disabled-checkmark-bg);
$radio-checkbox-checked-disabled-dot-bg:               var(--#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg);
$radio-checkbox-checked-disabled-bg:                   var(--#{$vudTokenPrefix}radio-checkbox-checked-disabled-bg);


// radio-checkbox - outline style
$radio-checkbox-outline-border-color:                  var(--#{$vudTokenPrefix}radio-checkbox-outline-border-color); //
$radio-checkbox-outline-disabled-border-color:         var(--#{$vudTokenPrefix}radio-checkbox-outline-disabled-border-color);
$radio-checkbox-outline-checked-disabled-border-color: var(--#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-border-color);

$radio-checkbox-outline-bg:                            var(--#{$vudTokenPrefix}radio-checkbox-outline-bg); //
$radio-checkbox-outline-checkmark-bg:                  var(--#{$vudTokenPrefix}radio-checkbox-outline-checkmark-bg); //
$radio-checkbox-outline-dot-bg:                        var(--#{$vudTokenPrefix}radio-checkbox-outline-dot-bg); //

$radio-checkbox-outline-disabled-bg:                   var(--#{$vudTokenPrefix}radio-checkbox-outline-disabled-bg);

$radio-checkbox-outline-checked-disabled-bg:           var(--#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-bg);
$radio-checkbox-outline-disabled-checkmark-bg:         var(--#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-checkmark-bg);
$radio-checkbox-outline-disabled-dot-bg:               var(--#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-dot-bg);

// Note: update in figma
$radio-checkbox-checked-disabled-bg: $radio-checkbox-outline-disabled-dot-bg;

//*** element/component code ***
// Customize the label / the container
.checkbox, .radio {
    display: inline-block;
    content: "";
    // cursor: default;
    position: relative;
    min-height: rem(20px);
    margin: $spacing-6 $spacing-6 $spacing-16 0;
    padding: rem(1px) 0 rem(1px) $spacing-24;

    color: $radio-checkbox-text-color;
    font: $font-sm;
    text-indent: 0;
    line-height: 1.2;

    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Create a custom checkbox/radio button
    input {
        &[type="checkbox"],
        &[type="radio"] {
            &, &.#{$vudClassPrefix}form-control {
                position: absolute;
                opacity: 0;
                cursor: default;
                width: 0;
                height: 0;
            }

            + .checkmark,
            + label {
                padding: 0;
                position: initial;

                &::after,
                &::before {
                    display: block;
                    position: absolute;
                    content: '';
                    cursor: pointer;
                }

                &::before {
                    width: $spacing-16;
                    height: $spacing-16;
                    top: rem(2px);
                    left: rem(1px);

                    border-radius: $radio-checkbox-border-radius;
                    background-color: $radio-checkbox-bg;
                    box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-border-color;
                }

                &::after { display: none; }
            }

            &:checked {
                // When the checkbox is checked, add a blue background
                + .checkmark,
                + label {
                    &:before {
                        background-color: $radio-checkbox-checked-bg;
                        box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-checked-border-color;
                    }

                    // Show the checkmark/indicator (dot/circle) when checked
                    &::after { display: block; }
                }
            }
        }

        &[type="radio"] {
            + .checkmark,
            + label {
                &:before { border-radius: $radio-checkbox-dot-border-radius; }
            }
        }

    }

    // On focus
    &:focus-within, &.focus {
        outline: rem(2px) solid $radio-checkbox-focus-border-color;
        outline-offset: rem(6px);
    }

    // On error
    .has-error &, &.has-error {
        outline: rem(1px) solid $radio-checkbox-error-focus-border-color;
        outline-offset: rem(7px);

        // On Error and focused
        &:focus-within, &.focus {
            outline: rem(2px) solid $radio-checkbox-error-focus-border-color;
            outline-offset: rem(6px);
        }
    }

    // Disabled state
    .disabled &, :disabled &,
    &.disabled, &:disabled,
    &:disabled + label,
    :disabled + label,
    .disabled + label {
        color: $radio-checkbox-disabled-text-color;
        pointer-events: none;
    }

    .disabled &, :disabled &,
    &.disabled, &:disabled,
    input.disabled, input:disabled {
        &[type="checkbox"],
        &[type="radio"] {
            + .checkmark,
            + label {
                &:before {
                    cursor: default;
                    pointer-events: none;

                    background-color: $radio-checkbox-disabled-bg;
                    box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-disabled-border-color;
                }

                &::after { cursor: default; }
            }

            // checked style for disabled state
            &:checked {
                + .checkmark,
                + label {
                    &:before {
                        background-color: $radio-checkbox-checked-disabled-bg;
                        box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-checked-disabled-border-color;
                    }

                    &::after { border-color: $radio-checkbox-checked-disabled-checkmark-bg; }
                }
            }
        }

        // checked state for input type radio
        &[type="radio"] {
            &:checked {
                + .checkmark,
                + label {
                    &::after { background-color: $radio-checkbox-checked-disabled-dot-bg; }
                }
            }
        }
    }

    &.radio-inline,
    &.checkbox-inline {
        display: inline;
        padding-left: $spacing-24;
        margin-right: $spacing-24;
    }
}

// When the checkbox is unchecked or checked (indeterminate state)
.checkbox input[type="checkbox"]:indeterminate,
.checkbox.mixed input[type="checkbox"] {
    + .checkmark,
    + label {
        &:before {
            background-color: $radio-checkbox-checked-bg;
            box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-checked-border-color;
        }

        &::after {
            display: block;
            width: rem(10px);
            top: rem(9px);
            left: rem(4px);
        }
    }
}

// Secondary/outline version of the check-box and radio-box
.checkbox.checkbox-outline input[type="checkbox"],
.radio.radio-outline input[type="radio"] {
    &, &:checked {
        + .checkmark,
        + label {
            &::before {
                background-color: $radio-checkbox-outline-bg;
                box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-outline-border-color;
            }
        }
    }

    &:checked {
        + .checkmark,
        + label {
            &::after { border-color: $radio-checkbox-outline-checkmark-bg; }
        }
    }
}

// Secondary/outline version of the checked radio-box
.radio.radio-outline input[type="radio"]:checked {
    + .checkmark,
    + label {
        &::after { background-color: $radio-checkbox-outline-dot-bg; }
    }
}

// Indeterminate/mixed state for secondary/outline version of the check-box
.checkbox.checkbox-outline.mixed input[type="checkbox"],
.checkbox.checkbox-outline input[type="checkbox"]:indeterminate {
    + .checkmark,
    + label {
        &::before {
            background-color: $radio-checkbox-outline-bg;
            box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-outline-border-color;
        }

        &::after { border-color: $radio-checkbox-outline-checkmark-bg; }
    }
}

// Disabled state for secondary/outline version
:disabled .checkbox.checkbox-outline input[type="checkbox"],
.disabled .checkbox.checkbox-outline input[type="checkbox"],
.checkbox.checkbox-outline:disabled input[type="checkbox"],
.checkbox.checkbox-outline.disabled input[type="checkbox"],
.checkbox.checkbox-outline input[type="checkbox"]:disabled,
.checkbox.checkbox-outline input.disabled[type="checkbox"],

:disabled .radio.radio-outline input[type="radio"],
.disabled .radio.radio-outline input[type="radio"],
.radio.radio-outline:disabled input[type="radio"],
.radio.radio-outline.disabled input[type="radio"],
.radio.radio-outline input[type="radio"]:disabled,
.radio.radio-outline input.disabled[type="radio"] {
    + .checkmark,
    + label {
        &:before {
            background-color: $radio-checkbox-outline-disabled-bg;
            box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-outline-disabled-border-color;
        }

        &::after { border-color: $radio-checkbox-outline-disabled-checkmark-bg; }
    }
}

:disabled .radio.radio-outline input[type="radio"],
.disabled .radio.radio-outline input[type="radio"],
.radio.radio-outline:disabled input[type="radio"],
.radio.radio-outline.disabled input[type="radio"],
.radio.radio-outline input[type="radio"]:disabled,
.radio.radio-outline input.disabled[type="radio"] {
    &:checked {
        + .checkmark,
        + label {
            &::after { background-color: $radio-checkbox-outline-disabled-dot-bg; }
        }
    }
}

// Indeterminate state on disabled
.disabled .checkbox.mixed input[type="checkbox"],
:disabled .checkbox.mixed input[type="checkbox"],
.disabled .checkbox input[type="checkbox"]:indeterminate,
:disabled .checkbox input[type="checkbox"]:indeterminate,

.checkbox.mixed.disabled input[type="checkbox"],
.checkbox.mixed:disabled input[type="checkbox"],
.checkbox.disabled input[type="checkbox"]:indeterminate,
.checkbox:disabled input[type="checkbox"]:indeterminate,

.checkbox.mixed input[type="checkbox"].disabled,
.checkbox.mixed input[type="checkbox"]:disabled,
.checkbox input[type="checkbox"].disabled:indeterminate,
.checkbox input[type="checkbox"]:disabled:indeterminate {
    + .checkmark,
    + label {
        &:before {
            background-color: $radio-checkbox-checked-disabled-bg;
            box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-checked-disabled-border-color;
        }

        &::after { border-color: $radio-checkbox-checked-disabled-checkmark-bg; }
    }
}

// Indeterminate state for secondary/outline version on disabled
.disabled .checkbox.checkbox-outline.mixed input[type="checkbox"],
:disabled .checkbox.checkbox-outline.mixed input[type="checkbox"],
.disabled .checkbox.checkbox-outline input[type="checkbox"]:indeterminate,
:disabled .checkbox.checkbox-outline input[type="checkbox"]:indeterminate,

.checkbox.checkbox-outline.mixed.disabled input[type="checkbox"],
.checkbox.checkbox-outline.mixed:disabled input[type="checkbox"],
.checkbox.checkbox-outline.disabled input[type="checkbox"]:indeterminate,
.checkbox.checkbox-outline:disabled input[type="checkbox"]:indeterminate,

.checkbox.checkbox-outline.mixed input[type="checkbox"].disabled,
.checkbox.checkbox-outline.mixed input[type="checkbox"]:disabled,
.checkbox.checkbox-outline input[type="checkbox"].disabled:indeterminate,
.checkbox.checkbox-outline input[type="checkbox"]:disabled:indeterminate {
    + .checkmark,
    + label {
        &:before {
            background-color: $radio-checkbox-outline-checked-disabled-bg;
            box-shadow: inset 0 0 0 rem(1px) $radio-checkbox-outline-checked-disabled-border-color;
        }

        &::after { border-color: $radio-checkbox-outline-disabled-dot-bg; }
    }
}

.checkbox label,
.radio label {
    text-indent: 0;
    line-height: 1.3;
}

//  Style the checkmark/indicator
.checkbox {
    // When the checkbox is checked
    label,
    .checkmark {
    //     border-radius: rem(3px);

        &::after {
            width: rem(5px);
            height: rem(10px);
            top: $spacing-4;
            left: $spacing-6;

            border: 0 solid $radio-checkbox-checkmark-bg;
            border-width: 0 rem(2px) rem(2px) 0;

            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    // When the checkbox is not checked or not unchecked (indeterminate state)
    &.mixed input[type="checkbox"],
    input[type="checkbox"]:indeterminate {
        + label,
        + .checkmark {
            &::after {
                border-width: rem(2px) 0 0;

                -webkit-transform: rotate(0);
                -ms-transform: rotate(0);
                transform: rotate(0);
            }
        }
    }
}

// Style the indicator (dot/circle)
.radio {
    label,
    .checkmark {
        border-radius: 50%;

        &::after {
            width: rem(6px);
            height: rem(6px);
            top: rem(7px);
            left: rem(6px);

            border-radius: 50%;
            background-color: $radio-checkbox-dot-bg;
        }
    }
}
