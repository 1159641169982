
// Button base styles

//*** scss local variable ***
$button-text-color:      var(--#{$vudTokenPrefix}button-text-color);
$button-text-size:       var(--#{$vudTokenPrefix}font-size-sm);
$button-login-text-size: var(--#{$vudTokenPrefix}font-size-md);

$button-border-radius:   var(--#{$vudTokenPrefix}radius-sm);


//*** element/component code ***
.#{$vudClassPrefix}btn {
    // display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    cursor: pointer;

    height: $spacing-32;
    padding: rem(3px) $spacing-16 $spacing-4;
    margin: 0 $spacing-8 $spacing-8 0;
    max-width: 100%;
    min-width: rem(128px);
    @media (max-width: $screen-md-min) { min-width: rem(120px); }
    @media (max-width: $screen-sm-min) { min-width: rem(100px); }

    color: $button-text-color;

    font: {
        size: $button-text-size;
        weight: $normal;
    }

    line-height: 1.2;
    text: {
        align: center;
        overflow: ellipsis;
        decoration: none;
        shadow: none;
    }
    vertical-align: middle;
    white-space: nowrap;

    border: {
        width: rem(1px);
        style: solid;
        color: transparent;
        radius: $button-border-radius;
    }

    user-select: none;
    touch-action: manipulation;
    box-sizing: border-box;

    //### Button base, focus state
    &:focus { outline: none; }

    //### Button base, disabled state
    &:disabled, &.disabled,
    :disabled &, .disabled & {
        pointer-events: none;
        cursor: default;
    }

    //### Buttons and buttons group size
    // //=== Small buttons
    // &.btn-sm,
    // .btn-group-sm & { }

    //=== Large buttons
    &.#{$vudClassPrefix}btn-lg,
    .#{$vudClassPrefix}btn-group-lg & {
        height: $spacing-48;
        padding: $spacing-8 $spacing-16;

        // line-height: rem(36);
    }

    // block level buttons - those that span the full width of a parent
    &.#{$vudClassPrefix}btn-block {
        display: block;
        width: 100%;
        margin-right: 0;
    }

    &:only-child,
    &:last-child { margin-right: 0; }

    &.#{$vudClassPrefix}btn-login {
        height: $input-btn-height-lg;
        font-size: $button-login-text-size;
        font-weight: $semibold;
        width: 100%;
    }

    &.#{$vudClassPrefix}btn-google, // Deprecated - will be removed
    &.#{$vudClassPrefix}btn-login-secondary {
        width: 100%;
    }
}
