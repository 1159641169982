// Timepicker style

//*** scss local variable ***
$timepicker-focus-border-color:         var(--#{$vudTokenPrefix}input-focus-border-color);

$timepicker-icon-bg:                    var(--#{$vudTokenPrefix}icon-cta-bg);
$timepicker-icon-disabled-bg:           var(--#{$vudTokenPrefix}icon-disabled-bg);

$timepicker-border-radius:              var(--#{$vudTokenPrefix}radius-xs);

$timepicker-text-color:                 var(--#{$vudTokenPrefix}dropdown-menu-text-color);

$timepicker-item-border-radius:         var(--#{$vudTokenPrefix}radius-sm);
$timepicker-item-hover-border-color:    var(--#{$vudTokenPrefix}dropdown-menu-hover-border-color);
$timepicker-item-focus-border-color:    var(--#{$vudTokenPrefix}button-focus-border-color);
$timepicker-item-pressed-border-color:  var(--#{$vudTokenPrefix}button-pressed-border-color);
$timepicker-item-selected-border-color: var(--#{$vudTokenPrefix}dropdown-menu-selected-border-color);

$timepicker-item-bg:                    transparent;
$timepicker-item-hover-bg:              var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
$timepicker-item-pressed-bg:            var(--#{$vudTokenPrefix}button-pressed-bg);
$timepicker-item-selected-bg:           var(--#{$vudTokenPrefix}dropdown-menu-selected-bg);


//*** element/component code ***
.#{$vudClassPrefix}timepicker-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;

    .#{$vudClassPrefix}timepicker-btn {
        cursor: pointer;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: rem(30px);
        height: rem(30px);
        margin: 0;
        padding: 0;
        left: rem(-31px);

        border: 0;
        outline: none;
        border-radius: $timepicker-border-radius;
        border: rem(1px) solid transparent;
        background: transparent;
        box-shadow: none;

        z-index: 3;

        &:focus {
            outline: none;
            border-color: $timepicker-focus-border-color;
            box-shadow: 0 0 0 rem(1px) $timepicker-focus-border-color;
        }

        .vismaicon:before {
            width: rem(16px);
            height: rem(16px);

            background-color: $timepicker-icon-bg;
        }
    }

    .disabled &, :disabled &,
    &.disabled, &:disabled,
    &:has(.timepicker.disabled),
    &:has(.timepicker:disabled) {
        .#{$vudClassPrefix}timepicker-btn {
            .vismaicon:before {
                background-color: $timepicker-icon-disabled-bg;
            }
        }
    }

    .#{$vudClassPrefix}dropdown-menu {
        max-height: rem(216px);
        overflow: auto;
        max-width: rem(128px);

        ul {
            padding: 0;
            margin: 0;

            .#{$vudClassPrefix}dropdown-item {
                overflow: hidden;
                cursor: pointer;
                display: flex;
                flex: none;
                width: 100%;
                padding: rem(6px) rem(16px);

                color: $timepicker-text-color;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1.2;

                border-radius: $timepicker-item-border-radius;
                background-color: $timepicker-item-bg;

                &:hover {
                    background-color: $timepicker-item-hover-bg;
                    box-shadow: inset 0 0 0 rem(1px) $timepicker-item-hover-border-color;
                } 

                &.focus:not(:active),
                &:focus:not(:active) {
                    outline: 0;
                    box-shadow: inset 0 0 0 rem(2px) $timepicker-item-focus-border-color;
                }

                &:active {
                    background-color: $timepicker-item-pressed-bg;
                    box-shadow: inset 0 0 0 rem(1px) $timepicker-item-pressed-border-color;
                }

                &.selected {
                    background-color: $timepicker-item-selected-bg;
                    box-shadow: inset 0 0 0 rem(1px) $timepicker-item-selected-border-color;
                }

                + .#{$vudClassPrefix}dropdown-item {
                    margin-top: rem(2px);
                }
            }
        }
    }
}

input.#{$vudClassPrefix}timepicker {
    max-width: rem(128px);

    &,
    &.#{$vudClassPrefix}form-control { padding-right: $spacing-24; }
}
