
// Input in Groups

//*** scss local variable ***
$input-labe-text-size-sm:          var(--#{$vudTokenPrefix}font-size-xs);
$input-label-text-color:           var(--#{$vudTokenPrefix}input-label-text-color);
$input-label-disabled-text-color:  var(--#{$vudTokenPrefix}input-label-text-color);
$input-label-error-text-color:     var(--#{$vudTokenPrefix}input-error-text-color);

//*** element/component code ***
.#{$vudClassPrefix}input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 $spacing-8 0;

    align-items: center;

    // wrapper for the cta in the group
    .#{$vudClassPrefix}input-group-btn {
        display: flex;

        &, &.dropdown {
            margin: 0;
            z-index: 1;

            .#{$vudClassPrefix}btn {  margin: 0; }
        }
    }

    .#{$vudClassPrefix}btn-group, .#{$vudClassPrefix}dropdown {
        margin: 0;

        .#{$vudClassPrefix}btn {
            &:hover { z-index: 2; }
            &:focus { z-index: 3; }
        }

        &:not(:last-child) {
            .#{$vudClassPrefix}dropdown-toggle {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            .#{$vudClassPrefix}btn:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .#{$vudClassPrefix}dropdown.open,
    .#{$vudClassPrefix}btn-split.open {
        z-index: 4;
    }

    > .#{$vudClassPrefix}btn {
        margin: 0;

        &:hover { z-index: 2; }
        &:focus { z-index: 3; }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // styling the input
    > .#{$vudClassPrefix}form-control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin: 0;
        z-index: 1;

        &:not(:first-child) {
            margin-left: rem(-1px);

            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &:not(:last-child) {
            margin-right: rem(-1px);

            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:hover { z-index: 2; }
        &:focus { z-index: 3; }
    }
}

// Styling wrapper for grouping form elements
.#{$vudClassPrefix}form-group {
    clear: both;
    margin-bottom: $spacing-24;
    position: relative;

    .#{$vudClassPrefix}btn { margin-bottom: 0; }

    label {
        padding: $label-padding-y $label-padding-x rem(5px) 0;
        display: inline-block;
        position: relative;
        line-height: 1.6;

        color: $input-label-text-color;

        &.checkbox, &.radio {
            padding: rem(1px) 0 rem(1px) rem(19px);
        }
    }

    .disabled &, :disabled &,
    &.disabled, &:disabled {
        label, .#{$vudClassPrefix}label, .holder {
            color: $input-label-disabled-text-color;
        }
    }

    // Required fields
    &.required  {
        > label,
        > a {
            //padding-left: $spacing-8;

            &:before {
                position: absolute;
                content: "*";
                margin-left: rem(-7px);
                top: rem(5px);
    
                color: $input-label-error-text-color;
                font-size: $input-labe-text-size-sm;
            }
        }
    }

    .#{$vudClassPrefix}form-horizontal & {
        display: flex;
        flex-wrap: wrap;

        label + [class*="col-"] { padding: 0; }

        &.required  {
            > label,
            > a {
                &:before {
                    top: rem(3px);
                }
            }
        }
    }

    // form elements inline
    .#{$vudClassPrefix}form-inline & {
        display: inline-block;
        padding-right: $spacing-16;
        margin-bottom: $spacing-16;

        .#{$vudClassPrefix}form-control {
            width: auto;

            @media (min-width: $screen-sm-min) {
                display: inline-block;
                margin-bottom: 0;

                &.select-wrapper select { margin-top: rem(-1px); }
            }
        }
    }

    .#{$vudClassPrefix}input-group {
        margin: 0;
        flex-wrap: nowrap;
    }
}

//
.#{$vudClassPrefix}form-group-lg {
    input{
        &, &.#{$vudClassPrefix}form-control {
            height: $input-btn-height-lg;
        }
    }

    label, .control-label {
        height: $input-btn-height-lg;
        padding-top: $input-btn-padding-y-t;
        line-height: $input-btn-line-height-lg;
    }
}

.#{$vudClassPrefix}form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .#{$vudClassPrefix}btn { margin-bottom: $spacing-16; }

    .checkbox,
    .radio {
        padding-right: $spacing-16;
        margin-bottom: $spacing-16;
    }
} 

.#{$vudClassPrefix}form-horizontal {
    .#{$vudClassPrefix}form-group > [class*="offset-"] { padding: 0; }
}

// wrapper for text to be align with inputs
.#{$vudClassPrefix}form-control-static {
    min-height: $input-btn-height;
    margin-bottom: 0;
    padding-top: $input-btn-padding-y-t;
    padding-bottom: $input-btn-padding-y-b;
    line-height: 1.6;
    margin-bottom: $spacing-24;
}

// Help block
.#{$vudClassPrefix}help-block {
    display: block;
    margin-bottom: 0;
    color: $neutral-80
    }