
// // Top navigation - primary/'inverted' variant styles - GRAPHITE VERSION

//*** scss local variable ***
$navigation-inverted-bg:                            var(--#{$vudTokenPrefix}navigation-primary-bg);
$navigation-inverted-hamburger-bg:                  var(--#{$vudTokenPrefix}navigation-default-hamburger-bg);

$navigation-inverted-fl-item-hover-border-color:    var(--#{$vudTokenPrefix}navigation-primary-item-hover-border-color);
$navigation-inverted-fl-item-focus-border-color:    var(--#{$vudTokenPrefix}navigation-default-item-focus-border-color);

$navigation-inverted-fl-item-hover-bg:              var(--#{$vudTokenPrefix}navigation-primary-item-hover-bg);
$navigation-inverted-fl-item-pressed-bg:            var(--#{$vudTokenPrefix}navigation-primary-item-active-bg);
$navigation-inverted-fl-item-selected-bg:           var(--#{$vudTokenPrefix}navigation-primary-item-active-bg);

$navigation-inverted-userdropdown-small-text-color: var(--#{$vudTokenPrefix}navigation-default-item-dropdown-small-text-color);
$navigation-inverted-fl-item-text-color:            var(--#{$vudTokenPrefix}navigation-primary-item-text-color);

$navigation-inverted-fl-item-icon-bg:               var(--#{$vudTokenPrefix}navigation-primary-icon-bg);

$navigation-inverted-search-bg:                     var(--#{$vudTokenPrefix}navigation-inverted-search-bg);
$navigation-inverted-search-hover-bg:               var(--#{$vudTokenPrefix}navigation-inverted-search-hover-bg);

$navigation-inverted-search-border-color:           var(--#{$vudTokenPrefix}navigation-primary-search-border-color);
$navigation-inverted-search-hover-border-color:     var(--#{$vudTokenPrefix}navigation-primary-search-hover-border-color);
$navigation-inverted-search-focus-border-color:     var(--#{$vudTokenPrefix}navigation-primary-search-focus-border-color);

//*** element/component code ***
.#{$vudClassPrefix}navbar {
    &.#{$vudClassPrefix}navbar-primary,
    &.#{$vudClassPrefix}navbar-inverted {
        background: $navigation-inverted-bg;

        .#{$vudClassPrefix}navbar-brand {
            > a, > button {
                &, &.#{$vudClassPrefix}dropdown-toggle {
                    color: $navigation-inverted-fl-item-text-color;

                    .#{$vudClassPrefix}caret { background-color: $navigation-inverted-fl-item-icon-bg; }

                    &:hover {
                        background-color: $navigation-inverted-fl-item-hover-bg;
                        box-shadow: inset 0 0 0 rem(1px) $navigation-inverted-fl-item-hover-border-color;
                    }

                    &:focus:not(:active) {
                        box-shadow: inset 0 0 0 rem(2px) $navigation-inverted-fl-item-focus-border-color;
                    }

                    &:active {
                        background-color: $navigation-inverted-fl-item-pressed-bg;
                        box-shadow: none;
                    }
                }
            }
        }

        .#{$vudClassPrefix}navbar-collapse {
            .first-level {
                > li {
                    > a, > button {
                        color: $navigation-inverted-fl-item-text-color;

                        &:hover {
                            background-color: $navigation-inverted-fl-item-hover-bg;
                            box-shadow: inset 0 0 0 rem(1px) $navigation-inverted-fl-item-hover-border-color;
                        }

                        &:active {
                            background-color: $navigation-inverted-fl-item-pressed-bg;
                            box-shadow: none;
                        }

                        &:focus:not(:active) {
                            outline: 0;
                            box-shadow: inset 0 0 0 rem(2px) $navigation-inverted-fl-item-focus-border-color;
                        }

                        &.#{$vudClassPrefix}dropdown-toggle {
                            .#{$vudClassPrefix}caret { background-color: $navigation-inverted-fl-item-icon-bg; }
                        }
                    }

                    > * {
                        .vismaicon::before { background-color: $navigation-inverted-fl-item-text-color; }
                    }

                    &.active {
                        > a, > button {
                            background-color: $navigation-inverted-fl-item-selected-bg;

                            &:hover {
                                background-color: $navigation-inverted-fl-item-hover-bg;
                            }

                            &:active {
                                background-color: $navigation-inverted-fl-item-pressed-bg;
                                box-shadow: none;
                            }
                        }
                    }

                    &.icon {
                        >a , > button {
                            &:hover {
                                background-color: transparent;
                                box-shadow: none;

                                &:after { background-color: $navigation-inverted-fl-item-hover-bg; }

                                &:before { background-color: $navigation-inverted-fl-item-hover-border-color; }
                            }

                            &:focus:not(:active) {
                                box-shadow: none;

                                &:before { background-color: $navigation-inverted-fl-item-focus-border-color; }
                            }

                            &:active {
                                background-color: transparent;

                                &:after { background-color: $navigation-inverted-fl-item-pressed-bg; }
                            }

                        }

                        &.active{
                            > a, > button {
                                background-color: transparent;

                                &:after { background-color: $navigation-inverted-fl-item-pressed-bg; }

                                &:before { background-color: $navigation-inverted-fl-item-hover-border-color; }

                                &:hover {
                                    &:after { background-color: $navigation-inverted-fl-item-hover-bg; }
                                    &:before { background-color: $navigation-inverted-fl-item-focus-border-color; }
                                }
                            }
                        }
                    }

                    &.disabled {
                        > a, > button { opacity: 0.55; }

                        &.icon {
                            > a, > button {
                                background-color: transparent;
                                box-shadow: none;

                                &::before, &::after { display: none; }

                                .vismaicon::before { background-color: $navigation-inverted-fl-item-text-color !important; }
                            }
                        }
                    }

                    &.menudrop:not(.open) {
                        .#{$vudClassPrefix}dropdown-toggle {
                            .icon-align-justify {
                                background: $navigation-inverted-hamburger-bg;

                                &::before, &::after { background: $navigation-inverted-hamburger-bg; }
                            }
                        }
                    }
                }

                .search-group {
                    &, &.search-group-primary {
                        input {
                            &.#{$vudClassPrefix}form-control {
                                &[type=text], &[type=search] {
                                    border-color: $navigation-inverted-search-border-color;
                                    background-color: $navigation-inverted-search-bg;
                                    box-shadow: none;

                                    &:hover {
                                        border-color: $navigation-inverted-search-hover-border-color;
                                        background-color: transparent;
                                    }

                                    &:hover, &:focus {
                                        border-color: $navigation-inverted-search-focus-border-color;
                                        background-color: transparent;
                                    }

                                    &:focus { box-shadow: inset 0 0 0 rem(1px) $navigation-inverted-search-focus-border-color; }

                                    &, &::placeholder { color: $navigation-inverted-fl-item-text-color; }

                                    &:-webkit-autofill  {
                                        &, &:hover, &:focus {
                                            -webkit-text-fill-color: $navigation-inverted-fl-item-text-color;
                                            -webkit-box-shadow: 0 0 0 rem(1000px) #25262600 inset !important;
                                            box-shadow: inset 0 0 0 rem(1px) $navigation-inverted-search-focus-border-color, 0 0 0 rem(1000px) #25262600 inset !important;
                                        }
                                    }
                                }
                            }
                        }

                        .search-icon, .clear-search {
                            &::before { background-color: $navigation-inverted-fl-item-icon-bg; }
                        }
                    }
                }
            }
        }
    }
}
