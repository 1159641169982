﻿@mixin header {
  .ag-header {
    font-weight: 600;
  }

  .ag-icon-filter {
    color: var(--blue-60);
  }

  .ag-pinned-right-header {
    border-left: 0;
  }

  .hide-icon {
    .vismaicon-info {
      display: none;
    }
  }
}
