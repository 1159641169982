
// Top navigation base styles

//*** scss local variable ***
$navigation-shadow:                              var(--#{$vudTokenPrefix}navigation-default-shadow);

$navigation-dot-badge-color:                     var(--#{$vudTokenPrefix}navigation-default-attention-bg);

$navigation-icon-bg:                             var(--#{$vudTokenPrefix}icon-bg);

$navigation-hamburger-bg:                        var(--#{$vudTokenPrefix}navigation-default-hamburger-bg);
$navigation-hamburger-open-bg:                   var(--#{$vudTokenPrefix}navigation-default-hamburger-open-bg);

$navigation-badge-font-family:                   $font-family-base;
$navigation-anchor-text-color:                   var(--#{$vudTokenPrefix}anchor-color);
$navigation-anchor-hover-text-color:             var(--#{$vudTokenPrefix}anchor-hover-color);

$navigation-userdropdown-small-text-color:       var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color);

$navigation-search-disabled-bg:                  var(--#{$vudTokenPrefix}navigation-brand-search-disabled-bg);
$navigation-search-disabled-border-color:        var(--#{$vudTokenPrefix}navigation-brand-search-disabled-border-color);
$navigation-search-disabled-text-color:          var(--#{$vudTokenPrefix}navigation-default-disabled-text-color);
$navigation-search-disabled-icon-bg:             var(--#{$vudTokenPrefix}navigation-default-disabled-icon-bg);

$navigation-dropdown-divider:                    linear-gradient(to right, var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-dotted) 10%, rgba(0,0,0,0) 0%);
$navigation-dropdown-divider-strong:             var(--#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong);

$navigation-fl-dropdown-open-bg:                 var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg);
$navigation-fl-dropdown-open-hover-bg:           var(--#{$vudTokenPrefix}navigation-secondary-item-hover-bg);
$navigation-fl-dropdown-open-pressed-bg:         var(--#{$vudTokenPrefix}button-pressed-bg);

$navigation-fl-dropdown-open-hover-border-color: var(--#{$vudTokenPrefix}dropdown-menu-hover-border-color);
$navigation-fl-dropdown-open-focus-border-color: var(--#{$vudTokenPrefix}navbar-brand-item-focus-border-color);

$navigation-fl-text-font-family:                 $font-family-primary;
$navigation-fl-text-size:                        var(--#{$vudTokenPrefix}font-size-md);
$navigation-fl-dropdown-item-open-text-color:    var(--#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color);
$navigation-fl-dropdown-item-open-icon-bg:       var(--#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg);

$navigation-fl-border-thin-mask:                 url(#{$image-path}/squerkle-border-thin.svg);
$navigation-fl-border-thick-mask:                url(#{$image-path}/squerkle-border-thick.svg);
$navigation-fl-bg-mask:                          url(#{$image-path}/squerkle-mask.svg);

$navigation-sl-bg:                               var(--#{$vudTokenPrefix}navigation-default-second-level-bg);
$navigation-sl-shadow:                           var(--#{$vudTokenPrefix}navigation-default-second-level-shadow);
$navigation-sl-item-text-color:                  var(--#{$vudTokenPrefix}navigation-default-dropdown-text-color);
$navigation-sl-item-disabled-text-color:         var(--#{$vudTokenPrefix}anchor-disabled-color);

$navigation-sl-item-focus-border-color:          var(--#{$vudTokenPrefix}anchor-focus-outline);

$navigation-mobile-item-hover-border-color:      var(--#{$vudTokenPrefix}dropdown-menu-hover-border-color);
$navigation-mobile-item-focus-border-color:      var(--#{$vudTokenPrefix}button-focus-border-color);
$navigation-mobile-item-pressed-border-color:    var(--#{$vudTokenPrefix}button-pressed-border-color);
$navigation-mobile-item-selected-border-color:   var(--#{$vudTokenPrefix}dropdown-menu-selected-border-color);

$navigation-mobile-item-bg:                      var(--#{$vudTokenPrefix}dropdown-menu-bg);
$navigation-mobile-item-hover-bg:                var(--#{$vudTokenPrefix}dropdown-menu-hover-bg);
$navigation-mobile-item-pressed-bg:              var(--#{$vudTokenPrefix}button-pressed-bg);
$navigation-mobile-item-selected-bg:             var(--#{$vudTokenPrefix}dropdown-menu-selected-bg);

//*** element/component code ***
body {
    padding-top: rem(105px); /* When top navigation/main nav has only one level */

    /* When top navigation/main nav has 2 levels */
    &.nav-space {
      padding-top: rem(165px);

      @media (max-width: $screen-sm-min) { padding-top: rem(105px); }
    }
}

.#{$vudClassPrefix}navbar {
    position: fixed;
    height: auto;
    min-height: rem(64px);
    min-width: rem(320px);
    margin-bottom: rem(15px);
    padding: 0;
    left: 0;
    right: 0;
    top: 0;

    box-shadow: $navigation-shadow;
    z-index: $zindex-navbar;

    .#{$vudClassPrefix}dropdown {
        .#{$vudClassPrefix}dropdown-toggle {
            .#{$vudClassPrefix}caret {
                position: absolute;
                @include caret('lg', 'default');
            }

            &::before { display: none; }
        }

        &.open {
            > .#{$vudClassPrefix}dropdown-toggle {
                background-color: $navigation-fl-dropdown-open-bg;

                .#{$vudClassPrefix}caret {
                    background-color: $navigation-fl-dropdown-item-open-icon-bg;
                    transform: rotate(180deg);
                }

                .vismaicon::before { background-color: $navigation-fl-dropdown-item-open-icon-bg; }
            }
        }

        &.user-dropdown {
            &.open, &.show {
                .#{$vudClassPrefix}dropdown-toggle {
                    small { color: $navigation-userdropdown-small-text-color; }
                }
            }

            .#{$vudClassPrefix}dropdown-toggle {
                overflow: hidden;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: unset;
                min-width: rem(300px);
                padding: rem(5px) rem(50px) rem(7px) rem(30px);

                line-height: 2.2;
                white-space: nowrap;
                text-overflow: ellipsis;

                > small {
                    display: block;
                    overflow: hidden;
                    max-width: rem(220px);
                    height: $spacing-16;
                    margin-top: rem(-7px);
                    margin-bottom: $spacing-8;

                    font: $font-sm;
                    line-height: 1;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .#{$vudClassPrefix}caret {
                    position: absolute;
                    top: rem(30px);
                    right: rem(20px);
                }

                .vismaicon { display: none; }
            }

            &.icon {
                .#{$vudClassPrefix}dropdown-toggle {
                    width: $spacing-48;
                    min-width: unset;
                    padding: $spacing-12;

                    .vismaicon { display: inline-block; }
                }

                &.open, &.show {
                    .#{$vudClassPrefix}dropdown-toggle {
                        margin: 0;
                        padding: rem(20px) rem(40px) rem(20px) rem(16px);
                    }
                }
            }

            .#{$vudClassPrefix}dropdown-menu {
                top: 100%;
                margin-top: rem(1px);
                padding: $spacing-16 0;
                z-index: 1;

                > li {
                    padding: 0 $spacing-16;
                    margin-bottom: $spacing-2;

                    > * {
                        padding: 0 $spacing-16;
                        white-space: normal;
                    }

                    > p {
                        > a {
                            color: $navigation-anchor-text-color;
                            padding: 0;
                            display: initial;
                            border-radius: 0;
                            border: 0;

                            &:hover {
                                background-color:  transparent;
                                text-decoration: underline;
                            }
                        }
                    }

                    > a {
                        padding: $spacing-8 $spacing-16;
                        font: $font-sm;
                        line-height: 1.4285714;

                        &.log-out-link {
                            padding-left: rem(58px);
                            position: relative;
                            top: 0;

                            &::before {
                                content: "";
                                width: $spacing-16;
                                height: $spacing-16;
                                display: block;
                                position: absolute;
                                left: $spacing-32;
                                top: rem(10px);
                            }
                        }
                    }
                }

                .#{$vudClassPrefix}btn {
                    display: flex;
                    width: calc(100% - $spacing-32);
                    margin: 0 $spacing-16;
                }

                .divider {
                    padding: $spacing-16 $spacing-32;
                    cursor: default;
                    pointer-events: none;

                    &::after {
                        content: "";
                        display: block;
                        height: rem(1px);
                        width: 100%;
                        margin: 0;
                        background-position: bottom left;
                        background-repeat: repeat-x;
                        background-size: rem(6px) rem(1px);
                        background-color: transparent;
                        background-image: $navigation-dropdown-divider;
                    }

                    &.divider-strong {
                        &::after {
                            background: $navigation-dropdown-divider-strong;
                        }
                    }
                }
            }

            .company-selection-area {
                &.one-context {
                    display: none;

                    +.divider { display: none; }
                }
            }

            .user-details-area {
                cursor: default;

                > div {
                    display: flex;
                    align-items: center;
                    padding: 0 $spacing-16;
                    @include text-overflow;

                    .user-img {
                        width: $spacing-48;
                        height: $spacing-48;
                        flex: 0 0 $spacing-48;
                    }

                    .user-text {
                        padding-left: $spacing-12;

                        span {
                            display: block;
                            width: 92%;
                            @include text-overflow;
                        }
                    }
                }
            }

        }

        &.shopping-cart {
            &:not(.icon) {
                .#{$vudClassPrefix}badge {
                    position: absolute;
                    top: rem(4px);
                    left: rem(18px);
                }
            }

            &.icon {
                .vismaicon { margin-right: 0; }
            }
        }

        .#{$vudClassPrefix}dropdown-menu {
            position: absolute;
            overflow-y: auto;
            max-height: calc( 100vh - rem(72px) );
            min-width: rem(300px);
            right: 0;
            left: auto;
        }

        .icon {
            &:last-child:not(.user-dropdown) { margin-right: rem(20px); }

            &.open, &.show {
                > .#{$vudClassPrefix}dropdown-toggle {
                    height: rem(64px);
                    margin: 0;
                    padding: rem(20px) rem(40px) rem(20px) rem(16px);

                    color: $navigation-fl-dropdown-item-open-text-color;
                    background-color: $navigation-fl-dropdown-open-bg;

                    .#{$vudClassPrefix}badge {
                        top: rem(4px);
                        left: rem(24px);
                    }

                    .vismaicon::before { background-color: $navigation-fl-dropdown-item-open-icon-bg; }
                }
            }

            &.active:not(.open):not(.show) {
                > a, > button {
                    &::after { display: block; }
                }
            }

        }
    }

    .#{$vudClassPrefix}dropdown-menu {
        top: 100%;
        z-index: 1;
    }

    .has-children { position: relative; }

    .#{$vudClassPrefix}navbar-collapse {
        .first-level {
            .disabled {
                &, &.#{$vudClassPrefix}navbar-form {
                    .search-group {
                        &, &.search-group-primary {
                            input {
                                &.#{$vudClassPrefix}form-control {
                                    &[type=text], &[type=search] {
                                        border-color: $navigation-search-disabled-border-color;
                                        background-color: $navigation-search-disabled-bg;

                                        &, &::placeholder {
                                            color: $navigation-search-disabled-text-color;
                                        }

                                    }
                                }
                            }

                            .search-icon, .clear-search {
                                &::before { background-color: $navigation-search-disabled-icon-bg; }
                            }
                        }
                    }
                }
            }

            > .user-dropdown {
                .#{$vudClassPrefix}dropdown-menu {
                    .vismaicon {
                        &::before {
                            background-color: $navigation-icon-bg;
                        }
                    }
                }
            }
        }
    }

    &.is-mobile {

    }
}


.#{$vudClassPrefix}navbar-collapse {
    position: relative;
    width: 100%;

    .nav {
        margin: 0;
        padding: 0;

        list-style: none;

        li {
            float: left;

            > a, > button {
                display: block;

                font: $font-md;
                line-height: 1.5;
                text-decoration: none;
            }
        }
    }


    .first-level {
        position: static;
        float: left;
        max-height: rem(64px);

        &:not(.#{$vudClassPrefix}navbar-right) {
            .fl-menu-item.has-children {
                .#{$vudClassPrefix}caret {
                    display: none;
                }
            }
        }

        &.#{$vudClassPrefix}navbar-right {
            position: relative;
            float: right;

            .#{$vudClassPrefix}navbar-form {
                &.#{$vudClassPrefix}navbar-form-shortened {
                    input.#{$vudClassPrefix}form-control {
                        max-width: rem(150px);
                    }
                }

                input.#{$vudClassPrefix}form-control {
                    padding-right: rem(40px);
                }
            }

            .#{$vudClassPrefix}dropdown {
                margin: 0;

                &.open {
                    position: relative;

                    .#{$vudClassPrefix}dropdown-toggle {
                        &::after, &::before { display: none; }

                        .#{$vudClassPrefix}caret {
                            background-color: $navigation-fl-dropdown-item-open-icon-bg;
                        }
                    }
                }

                &.icon-dropdown {
                    &.open {
                        .#{$vudClassPrefix}dropdown-menu {
                            display: flex;
                        }
                    }

                    .#{$vudClassPrefix}dropdown-menu {
                        flex-direction: row-reverse;
                        flex-wrap: nowrap;
                        align-items: center;
                        gap: $spacing-8;
                        padding: 0 $spacing-8;

                        > li.icon {
                            overflow: visible;
                            float: none;
                            width: auto;
                            width: unset;
                            margin: 0;

                            > a, > button {
                                position: relative;
                                cursor: pointer;
                                overflow: visible;
                                width: $spacing-48;
                                height: $spacing-48;
                                margin: $spacing-8 $spacing-4;
                                padding: $spacing-12;
            
                                border: 0;
                                background-color: transparent;
                                box-shadow: none;
                                z-index: 1;

                                &::before, &::after {
                                    display: none;
                                    position: absolute;
                                    content:'';
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    right: 0;

                                    -webkit-mask-size: cover;
                                    mask-size: cover;
                                    -webkit-mask-repeat: no-repeat;
                                    mask-repeat: no-repeat;
                                    z-index: -1;
                                }

                                &:before {
                                    -webkit-mask-image: $navigation-fl-border-thin-mask;
                                    mask-image: $navigation-fl-border-thin-mask;
                                    z-index: 0;
                                }

                                &:after {
                                    -webkit-mask-image: $navigation-fl-bg-mask;
                                    mask-image: $navigation-fl-bg-mask;
                                }

                                &:focus:not(:active) {
                                    outline: none;

                                    &::before {
                                        display: block;

                                        background-color: $navigation-fl-dropdown-open-focus-border-color;
                                        -webkit-mask-image: $navigation-fl-border-thick-mask;
                                        mask-image: $navigation-fl-border-thick-mask;
                                    }
                                }

                                &:hover {
                                    &::after {
                                        display: block;
                                        background-color: $navigation-fl-dropdown-open-hover-bg;
                                    }

                                    &::before {
                                        display: block;
                                        background-color: $navigation-fl-dropdown-open-hover-border-color;
                                    }
                                }

                                .vismaicon {
                                    display: inline-block;
                                    margin: 0;
                                    top: 0;
                                }
                            }


                            .vismaicon::before {
                                background-color: $navigation-fl-dropdown-item-open-icon-bg;
                            }

                            .badge {
                                position: absolute;
                                margin: 0;
                                right: rem(-8px);
                            }
                        }
                    }
                }
            }
        }

        > li {
            > a, > button {
                height: rem(64px); /*(IE Edge bug)*/
                padding: rem(20px) $spacing-16;

                font-family: $navigation-fl-text-font-family;
                font-weight: $normal;
                outline: 0;
            }

            &.active {
                ul.second-level {
                    display: block;
                    margin: 0;
                    padding: 0;
                }
            }

            &.disabled {
                > a, > button {
                    pointer-events: none;
                }
            }

            &.icon {
                &.active {
                    > a, > button {
                        &::before, &::after { display: block; }
                    }
                }

                > a, > button {
                    position: relative;
                    cursor: pointer;
                    width: $spacing-48;
                    height: $spacing-48;
                    margin: $spacing-8 $spacing-4;
                    padding: $spacing-12;

                    border: 0;
                    background-color: transparent;
                    box-shadow: none;

                    &::before, &::after {
                        display: none;
                        position: absolute;
                        content:'';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        right: 0;

                        -webkit-mask-size: cover;
                        mask-size: cover;
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        z-index: -1;
                    }

                    &:before {
                        -webkit-mask-image: $navigation-fl-border-thin-mask;
                        mask-image: $navigation-fl-border-thin-mask;
                        z-index: 0;
                    }

                    &:after {
                        -webkit-mask-image: $navigation-fl-bg-mask;
                        mask-image: $navigation-fl-bg-mask;
                    }

                    &:focus:not(:active) {
                        outline: none;

                        &::before {
                            -webkit-mask-image: $navigation-fl-border-thick-mask;
                            mask-image: $navigation-fl-border-thick-mask;
                            display: block;
                        }
                    }

                    &:hover {
                        &::after { display: block; }

                        &::before {display: block;}
                    }

                    &.#{$vudClassPrefix}dropdown-toggle {
                        position: relative;
                        width: $spacing-48;
                        min-width: 1%;
                        height: $spacing-48;
                        margin: $spacing-8 $spacing-4;
                        padding: rem(12px);

                        font-size: rem(0px);
                        text-indent: rem(-9999px);

                        > .#{$vudClassPrefix}caret { display: none; }

                        &:focus {
                            outline: none;
                        }
                    }

                    .#{$vudClassPrefix}badge {
                        position: absolute;
                        overflow: hidden;
                        top: -$spacing-4;
                        left: rem(20px);
                        margin: 0;

                        font-family: $navigation-badge-font-family;
                        text-indent: 0;
                        z-index: 1;
                    }

                    .vismaicon {
                        display: inline-block;
                        margin-right: 0;
                        top: 0;
                    }

                }

                &.attention {
                    position: relative;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: $spacing-16;
                        right: $spacing-12;
                        width: $spacing-8;
                        height: $spacing-8;

                        border-radius: rem(4px);
                        background-color: $navigation-dot-badge-color;

                        z-index: 3;
                    }
                }
            }

            &.#{$vudClassPrefix}dropdown {
                &.open {
                    &, &.icon {
                        .#{$vudClassPrefix}dropdown-toggle {
                            color: $navigation-fl-dropdown-item-open-text-color;

                            &:hover {
                                background-color: $navigation-fl-dropdown-open-hover-bg;
                                box-shadow: inset 0 0 0 rem(1px) $navigation-fl-dropdown-open-hover-border-color;
                            }

                            &:focus:not(:active) {
                                box-shadow: inset 0 0 0 rem(2px) $navigation-fl-dropdown-open-focus-border-color;
                            }

                            &:active {
                                background-color: $navigation-fl-dropdown-open-pressed-bg;
                                box-shadow: none;
                            }

                            .vismaicon::before { background-color: $navigation-fl-dropdown-item-open-icon-bg; }
                        }
                    }

                    &.icon {
                        .#{$vudClassPrefix}dropdown-toggle {
                            gap: 0;
                            width: auto;
                            height: $spacing-64;
                            padding: rem(20px) rem(16px);
                            margin: 0;

                            .#{$vudClassPrefix}badge {
                                top: $spacing-4;
                                left: $spacing-24;
                            }
                        }
                    }
                }
            }

            &.menudrop {
                position: relative;
                height: $spacing-64;

                > .#{$vudClassPrefix}dropdown-toggle {
                    display: inline-block;
                    width: $spacing-64;
                    height: $spacing-64;

                    &::before{ display: none; }

                    .icon-align-justify {
                        position: absolute;
                        width: rem(5px);
                        height: rem(5px);
                        top: rem(30px);
                        left: rem(30px);

                        border-radius: rem(5px);

                        &::before, &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            width: rem(5px);
                            height: rem(5px);
                            top: rem(-8px);

                            border-radius: rem(5px);
                        }

                        &::after{ top: rem(8px); }
                    }
                }

                &.open {
                    .#{$vudClassPrefix}dropdown-toggle {
                        .icon-align-justify {
                            &,
                            &::before,
                            &::after { background-color: $navigation-hamburger-open-bg; }
                        }
                    }
                }

            }

            .#{$vudClassPrefix}dropdown-toggle {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: $spacing-16;

                .#{$vudClassPrefix}caret {
                    position: static;
                    margin-inline-start: auto;
                }
            }
        }

        .#{$vudClassPrefix}navbar-form {
            padding: rem(16px) rem(12px);

            .search-group { margin-bottom: 0; }
        }
    }

    .second-level {
        display: none;
        position: absolute;
        width: 100%;
        right: auto;
        bottom: auto;
        left: 0;

        background: $navigation-sl-bg;
        box-shadow: $navigation-sl-shadow;

        > li {
            display: inline-block;
            vertical-align: middle;
            padding: $spacing-12 0;
            margin: 0;

            > a, > button {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: $spacing-8;
                height: $spacing-24;
                padding: 0 $spacing-16;

                color: $navigation-sl-item-text-color;
                font: $font-sm;

                background-color: transparent;

                &.#{$vudClassPrefix}dropdown-toggle {
                    .#{$vudClassPrefix}caret {
                        display: inline-block;
                        position: initial;

                        @include caret('sm', 'default');
                    }
                }

                &:hover {
                    text-decoration: underline;
                    color: $navigation-anchor-hover-text-color;

                    background-color: transparent;

                    &.#{$vudClassPrefix}dropdown-toggle {
                        > .#{$vudClassPrefix}caret {
                            background-color: $navigation-anchor-hover-text-color;
                        }
                    }
                }

                &:focus {
                    outline: rem(2px) solid $navigation-sl-item-focus-border-color;
                    outline-offset: rem(0px);
                }
            }

            &.active {
                > a, > button { font-weight: $bold; }
            }

            &.disabled {
                > a, > button {
                    pointer-events: none;
                    color: $navigation-sl-item-disabled-text-color;
                }
            }

            &.open {
                .third-level {
                    overflow-y: auto;
                    width: auto;
                    min-width: rem(150px);
                    min-height: rem(92px);
                    max-height: calc(#{'100vh - ' rem(180px)});
                    padding: $spacing-12 0;

                    @media (min-height: 980px) { max-height: 800px; }

                    > li {
                        padding: $spacing-2 $spacing-12;

                        > a, > button {
                            padding: rem(10px) rem(22px);
                        }
                    }
                }

                &:first-child {
                    .#{$vudClassPrefix}dropdown-menu {
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
}


.#{$vudClassPrefix}navbar.is-mobile {
    .#{$vudClassPrefix}navbar-collapse {
        .first-level {
            &.#{$vudClassPrefix}navbar-right {
                position: static;

                .user-dropdown .company-selection {
                    max-width: none;
                    width: 100%;
                    top: rem(64px);
                    padding: $spacing-12;
                }
            }

            .menudrop, .dropdown {
                position: static;

                .icon-align-justify {
                    &,
                    &::before,
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: rem(24px);
                        height: rem(2px);
                        left: rem(24px);
                        top: rem(32px);
                        border-radius: $border-radius;
                        background: $navigation-hamburger-bg;
                        transition: transform 0.3s ease-in-out;
                    }

                    &::before {
                        top: rem(-8px);
                        left: 0;
                    }

                    &::after {
                        bottom: rem(-8px);
                        top: auto;
                        left: 0;
                    }
                }

                &.open {
                    .icon-align-justify {
                        background-color: transparent;

                        &::before,
                        &::after { background: $navigation-hamburger-open-bg; }

                        &::before {
                            transform: rotate(45deg);
                            top: -1px;
                            left: 0px;
                        }

                        &::after { 
                            transform: rotate(-45deg);
                            bottom: 0;
                            left: 0px;
                        }
                    }

                    .#{$vudClassPrefix}navbar-brand {
                        > a:not(.#{$vudClassPrefix}dropdown-toggle) {
                            .#{$vudClassPrefix}caret { display: none; }
                        }
                    }
                }
            }

            .#{$vudClassPrefix}dropdown-menu {
                width: 100%;
                padding: 0;
                top: $spacing-64;

                li {
                    padding: 0;

                    a, button {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: $spacing-8;
                        padding: $spacing-12 $spacing-16;

                        line-height: $spacing-24;

                        border-radius: 0;

                        .#{$vudClassPrefix}caret { margin-inline-start: auto; }

                        &.#{$vudClassPrefix}dropdown-toggle {
                            box-shadow: none;
                        }

                        &:hover {
                            text-decoration: none;

                            background-color: $navigation-fl-dropdown-open-hover-bg;
                            box-shadow: inset 0 0 0 rem(1px) $navigation-fl-dropdown-open-hover-border-color;
                        }

                        &:focus:not(:active) {
                            box-shadow: inset 0 0 0 rem(2px) $navigation-fl-dropdown-open-focus-border-color;
                        }

                        &:active {
                            background-color: $navigation-fl-dropdown-open-pressed-bg;
                            box-shadow: none;
                        }
                    }

                    &.active {
                        &.is-open, &.open {
                            .fl-menu-item.has-children {
                                &:not(:focus):not(:hover):not(:active) {
                                    box-shadow: none;
                                }

                                &:not(:hover):not(:active) {
                                    background-color: $navigation-mobile-item-bg;
                                }
                            }
                        }
                    }

                }
            }

            .second-level {
                > li {
                    a, button {
                        padding: $spacing-12 $spacing-16 $spacing-12 $spacing-32;
                    }

                    &:hover {
                        background-color: $navigation-mobile-item-hover-bg;
                        box-shadow: inset 0 0 0 rem(1px) $navigation-mobile-item-hover-border-color;
                    }

                    &:focus:not(:active) {
                        box-shadow: inset 0 0 0 rem(2px) $navigation-mobile-item-focus-border-color;
                    }

                    &:active {
                        background-color: $navigation-mobile-item-pressed-bg;
                        box-shadow: inset 0 0 0 rem(1px) $navigation-mobile-item-pressed-border-color;
                    }
                }

                &.active {
                    a, button {
                        background-color: $navigation-mobile-item-selected-bg;

                        &:not(:hover) {
                            box-shadow: inset 0 0 0 rem(1px) $navigation-mobile-item-selected-border-color;
                        }
                    }
                }
            }

            .third-level {
                > li {
                    a, button {
                        padding: $spacing-12 $spacing-16 $spacing-12 $spacing-48;

                        &:hover {
                            box-shadow: inset 0 0 0 rem(1px) $navigation-mobile-item-hover-border-color;
                        }

                        &:focus:not(:active) {
                            box-shadow: inset 0 0 0 rem(2px) $navigation-mobile-item-focus-border-color;
                        }

                        &:active {
                            box-shadow: inset 0 0 0 rem(1px) $navigation-mobile-item-pressed-border-color;
                        }
                    }

                    &.active {
                        a, button {
                            &:not(:hover) {
                                box-shadow: inset 0 0 0 rem(1px) $navigation-mobile-item-selected-border-color;
                            }
                        }
                    }
                }
            }

            .menudrop {
                .second-level-children {
                    &.is-open , &.open {
                        > a {
                            .#{$vudClassPrefix}caret { transform: rotate(180deg); }
                        }

                        .second-level {
                            display: block;
                        }
                    }
                }

                > .#{$vudClassPrefix}dropdown-toggle {
                    width: auto;
                    padding-left: rem(60px);
                    font: $font-lg;
                    line-height: 1.2;

                    &::before { display: none; }
                }

                .fl-menu-item.has-children {
                    .#{$vudClassPrefix}caret {
                        display: inline-block;
                    }
                }
            }

            .#{$vudClassPrefix}navbar-brand {
                .#{$vudClassPrefix}dropdown-toggle {
                    display: flex;
                    align-items: center;
                    padding-left: rem(32px) !important;

                    .#{$vudClassPrefix}caret {
                        position: static;
                        margin-inline-start: auto;

                        background-color: $navigation-sl-item-text-color;
                        transform: rotate(90deg);
                    }
                }

                &.is-open {
                    .#{$vudClassPrefix}dropdown-toggle {
                        .#{$vudClassPrefix}caret {
                            transform: rotate(-90deg);
                        }
                    }

                    .#{$vudClassPrefix}dropdown-menu {
                        display: block;
                        top: 0;
                        padding: 0;
                        position: relative;

                        .app-item {
                            display: flex;
                            align-items: center;
                            height: $spacing-64;
                            padding: $spacing-8 $spacing-24 $spacing-8 $spacing-16;

                            img { margin-right: $spacing-16; }
                        }
                    }
                }
            }
        }

        .second-level {
            position: static;
            margin: 0;
            padding: 0;

            border: 0;
            box-shadow: none;

            li {
                display: block;
                float: none;
                float: unset;
                padding: 0;

                a, button {
                    outline: 0;
                    height: $spacing-48;

                    color: $navigation-fl-dropdown-item-open-text-color;
                    font: $font-md;
                    line-height: $spacing-24;
                }

                .dropdown-menu {
                    position: static;
                    border: 0;
                    box-shadow: none;
                }
            }

            .active {
                > a { box-shadow: none; }
            }
        }
    }
}
