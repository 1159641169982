@use '@vismaux/vud/src/scss/settings/config';

@mixin tool-panel {
  .ag-side-buttons {
    width: 30px;
    font-size: larger;
  }

  .ag-filter-toolpanel-group-item {
    margin-left: calc(var(--ag-grid-size) * 2);
  }
}
