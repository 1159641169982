
// Imports

//*** scss local variable ***
$image-no-alt-border-color: var(--#{$vudTokenPrefix}image-no-alt-border-color);
$thumbnail-border-color: var(--#{$vudTokenPrefix}thumbnail-border-color);

//*** element/component code ***
img, svg {
  vertical-align: middle;
}

img:not([alt]) {
  border: rem(1px) solid $image-no-alt-border-color;
}

.img-fluid, 
.img-responsive {
  @include img-fluid();
}

.img-rounded {
  border-radius: 50%;
}

.img-squerkle {
  @include img-squerkle();
}

.img-thumbnail {
  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);

  // Keep them at most 100% wide
  @include img-fluid();
}
