
// Buton as link

//*** scss local variable ***

//*** Button icon - confirmatory
$button-icon-color:                                    var(--#{$vudTokenPrefix}button-icon-color);
$button-icon-disabled-color:                           var(--#{$vudTokenPrefix}button-disabled-icon-color);

$button-link-text-color:                               var(--#{$vudTokenPrefix}anchor-color);
$button-link-selected-text-color:                      var(--#{$vudTokenPrefix}breadcrumb-selected-color);
$button-link-disabled-text-color:                      var(--#{$vudTokenPrefix}anchor-disabled-color);

$button-link-focus-border-color:                       var(--#{$vudTokenPrefix}anchor-focus-outline);

$button-icon-border-color:                             var(--#{$vudTokenPrefix}button-border-color);
$button-icon-hover-border-color:                       var(--#{$vudTokenPrefix}button-hover-border-color);
$button-icon-focus-border-color:                       var(--#{$vudTokenPrefix}button-focus-border-color);
$button-icon-pressed-border-color:                     var(--#{$vudTokenPrefix}button-pressed-border-color);
$button-icon-selected-border-color:                    var(--#{$vudTokenPrefix}button-active-border-color);
$button-icon-disabled-border-color:                    var(--#{$vudTokenPrefix}button-disabled-border-color);

$button-icon-bg:                                       var(--#{$vudTokenPrefix}button-bg);
$button-icon-hover-bg:                                 var(--#{$vudTokenPrefix}button-hover-bg);
$button-icon-pressed-bg:                               var(--#{$vudTokenPrefix}button-pressed-bg);
$button-icon-selected-bg:                              var(--#{$vudTokenPrefix}button-selected-bg);
$button-icon-disabled-bg:                              var(--#{$vudTokenPrefix}button-disabled-bg);

$button-icon-shadow:                                   var(--#{$vudTokenPrefix}button-shadow);


//*** Button transparent - confirmatory
$button-transparent-pressed-border-color:              $button-icon-pressed-border-color;
$button-transparent-disabled-border-color:             $button-icon-disabled-border-color;

$button-transparent-text-color:                        var(--#{$vudTokenPrefix}button-text-color);
$button-transparent-disabled-text-color:               $button-link-disabled-text-color;


//*** Button transparent - destructive
$button-transparent-destructive-border-color:          var(--#{$vudTokenPrefix}button-destructive-border-color);
$button-transparent-destructive-disabled-border-color: $button-transparent-disabled-border-color;

$button-transparent-destructive-disabled-bg:           var(--#{$vudTokenPrefix}button-disabled-bg);

$button-transparent-destructive-text-color:            var(--#{$vudTokenPrefix}button-destructive-text-color);
$button-transparent-destructive-hover-text-color:      var(--#{$vudTokenPrefix}button-destructive-hover-text-color);
$button-transparent-destructive-disabled-text-color:   $button-link-disabled-text-color;

// squircle svg map
$squircle-shape:                  $squerkle-patern;
$squircle-border-shape:           $squerkle-sm-patern-border-thin;
$squircle-focus-border-shape:     $squerkle-sm-patern-border-thick;
$squircles-lg-border-shape:       $squerkle-patern-border-thin;
$squircle-lg-focus-border-shape:  $squerkle-patern-border-thick;



//*** element/component code ***
.#{$vudClassPrefix}btn {
    //### '.btn-link' normal/default state
    &.#{$vudClassPrefix}btn-link,
    &.#{$vudClassPrefix}btn-icon {
        &:not(.#{$vudClassPrefix}btn-default):not(.#{$vudClassPrefix}btn-destructive) {
            min-width: auto;
            max-width: unset;
            height: auto;

            text-decoration: underline;
        }

        color: $button-link-text-color;

        border-color: transparent;
        background: transparent;
        box-shadow: none;

        //### '.btn-link' hover state
        &:hover {
            text-decoration: none;
        }

        //### '.btn-link' focus state
        &:focus {
            text-decoration: none;

            border-color: $button-link-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $button-link-focus-border-color;
        }

        //### '.btn-link' active/pressed/selected state
        &.active, &:active {
            color: $button-link-selected-text-color;
            text-decoration: none;
        }

        //### '.btn-link' disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            color: $button-link-disabled-text-color;

            &:focus {
                box-shadow: none;
            }
        }
    }

    &.#{$vudClassPrefix}btn-link {
        padding-left: $spacing-4;
        padding-right: $spacing-4;

        border-radius: 0;

        &.#{$vudClassPrefix}btn-default, &.#{$vudClassPrefix}btn-destructive {
            padding: rem(3px) $spacing-16 $spacing-4;
            border-radius: $border-radius;

            &.#{$vudClassPrefix}btn-lg {
                padding: $spacing-8 $spacing-16;
            }
        }

        //### '.btn-link.btn-default' normal state
        &.#{$vudClassPrefix}btn-default {
            color: $button-transparent-text-color;

            //### '.btn-primary' pressed/active/selected state
            &:active,&.active, &.active:active, &.pressed, &.pressed:active {
                border-color: $button-transparent-pressed-border-color;
                box-shadow: none;
            }

            //### '.btn-link.btn-default' normal and hover state for icon
            &, &:hover, &.hover, &.hover:hover {
                > .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-icon-color;
                    }
                }
            }

            //### '.btn-link.btn-default' disabled state
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                color: $button-transparent-disabled-text-color;
                border-color: $button-transparent-disabled-border-color;
            }
        }

        //### '.btn-link.btn-destructive' normal state
        &.#{$vudClassPrefix}btn-destructive {
            color: $button-transparent-destructive-text-color;

            //### '.btn-link.btn-destructive' hover state for icon
            &:hover, &.hover, &.hover:hover {
                color: $button-transparent-destructive-hover-text-color;

                border-color: $button-transparent-destructive-border-color;

                // '.btn-link.btn-destructive' hover state for icon
                > .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-transparent-destructive-hover-text-color;
                        box-shadow: none;
                    }
                }
            }

            //### '.btn-link.btn-destructive' pressed/active/selected state
            &:active,&.active, &.active:active, &.pressed, &.pressed:active {
                color: $button-transparent-destructive-hover-text-color;

                box-shadow: none;

                // '.btn-link.btn-destructive' pressed/active/selected state for icon
                > .vismaicon:not(.vismaicon-flags):not(.vismaicon-media) {
                    &::before {
                        background-color: $button-transparent-destructive-hover-text-color;
                        box-shadow: none;
                    }
                }
            }

            //### '.btn-link.btn-destructive' disabled state
            &:disabled, &.disabled,
            :disabled &, .disabled & {
                color: $button-transparent-destructive-disabled-text-color;

                border-color: $button-transparent-destructive-disabled-border-color;
                background-color: $button-transparent-destructive-disabled-bg;
            }
        }
    }

    //### '.btn.btn-icon' normal state
    &.#{$vudClassPrefix}btn-icon {
        padding: rem(3px) $spacing-4;

        border-radius: 0;

        &.#{$vudClassPrefix}btn-solid, &.#{$vudClassPrefix}btn-transparent {
            padding: $spacing-8;

            border: 0;

            &.#{$vudClassPrefix}btn-lg {
                padding: $spacing-12;
            }

            &, &.#{$vudClassPrefix}btn-square { border-radius: rem(5px); }

            &:hover, &.hover {
                &, &.active {
                    background-color: $button-icon-hover-bg;
                    box-shadow: inset 0 0 0 rem(1px) $button-icon-hover-border-color, $button-icon-shadow;
                }

                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media)::before {
                    background-color: $button-icon-color;
                }
            }

            &:focus, &.focus {
                &:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) $button-icon-focus-border-color;
                }
            }

            &:active, &.active:active, &.pressed, &.pressed:active {
                background-color: $button-icon-pressed-bg;
                box-shadow: inset 0 0 0 rem(1px) $button-icon-pressed-border-color;

                .vismaicon:not(.vismaicon-flags):not(.vismaicon-media)::before {
                    background-color: $button-icon-color;
                }
            }

            &.active {
                background-color: $button-icon-selected-bg;
                box-shadow: inset 0 0 0 rem(1px) $button-icon-selected-border-color;
            }

            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &, &:active, &:focus {
                    background-color: transparent;
                    box-shadow: none;
                }
            }

            .vismaicon:not(.vismaicon-flags):not(.vismaicon-media)::before {
                background-color: $button-icon-color;
            }
        }

        &.#{$vudClassPrefix}btn-solid {
            background-color: $button-icon-bg;
            box-shadow: inset 0 0 0 rem(1px) $button-icon-border-color, $button-icon-shadow;

            &:focus, &.focus {
                &:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) $button-icon-focus-border-color, $button-icon-shadow;
                }
            }

            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &, &:active, &:focus {
                    background-color: $button-icon-disabled-bg;
                    box-shadow: inset 0 0 0 rem(1px) $button-icon-disabled-border-color;
                }
            }
        }

        &.#{$vudClassPrefix}btn-round { border-radius: 50% }

        &.#{$vudClassPrefix}btn-squircle {
            position: relative;
            border: 0;
            border-radius: rem(12px);
            box-shadow: none;

            &, &::before, &::after {
                width: $spacing-32;
                height: $spacing-32;

                background-color: transparent;
            }

            &::before, &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;

                -webkit-mask-size: $spacing-32 $spacing-32;
                mask-size: $spacing-32 $spacing-32;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
            }

            &::before {
                -webkit-mask: $squircle-shape;
                mask: $squircle-shape;
                -webkit-mask-size: $spacing-32 $spacing-32;
                mask-size: $spacing-32 $spacing-32;
            }

            &::after {
                -webkit-mask: $squircle-border-shape;
                mask: $squircle-border-shape;
                -webkit-mask-size: $spacing-32 $spacing-32;
                mask-size: $spacing-32 $spacing-32;
            }

            &.#{$vudClassPrefix}btn-lg {
                border-radius: rem(18px);

                &, &::before, &::after {
                    width: $spacing-48;
                    height: $spacing-48;
                }

                &::before, &::after {
                    -webkit-mask-size: $spacing-48 $spacing-48;
                    mask-size: $spacing-48 $spacing-48;
                }

                &::after {
                    -webkit-mask: $squircles-lg-border-shape;
                    mask: $squircles-lg-border-shape;
                }
            }

            &.#{$vudClassPrefix}btn-solid {
                box-shadow: $button-icon-shadow;

                &::before { background-color: $button-icon-bg; }

                &::after { background-color: $button-icon-border-color; }
            }

            &:hover, &.hover {
                &, &.active {
                    background-color: transparent;
                    box-shadow: $button-icon-shadow;

                    &::before{ background-color: $button-icon-hover-bg; }

                    &::after { background-color: $button-icon-hover-border-color; }
                }
            }

            &:focus, &.focus {
                &:not(:active) {
                    background-color: transparent;
                    box-shadow: none;

                    &::after {
                        background-color: $button-icon-focus-border-color;

                        -webkit-mask: $squircle-focus-border-shape;
                        mask: $squircle-focus-border-shape;
                    }

                    &.#{$vudClassPrefix}btn-solid {
                        box-shadow: $button-icon-shadow;
                    }

                    &.#{$vudClassPrefix}btn-lg {
                        &::after {
                            -webkit-mask: $squircle-lg-focus-border-shape;
                            mask: $squircle-lg-focus-border-shape;
                        }
                    }
                }
            }

            &:active, &.active:active, &.pressed, &.pressed:active {
                background-color: transparent;
                box-shadow: none;

                &::before { background-color: $button-icon-pressed-bg; }

                &::after { background-color: $button-icon-pressed-border-color; }
            }

            &.active {
                background-color: transparent;
                box-shadow: none;

                &::before { background-color: $button-icon-selected-bg; }

                &::after { background-color: $button-icon-selected-border-color; }
            }

            &:disabled, &.disabled,
            :disabled &, .disabled & {
                &, &:active, &:focus {
                    background-color: transparent;
                    box-shadow: none;

                    &::after {
                        background-color: transparent;
                        -webkit-mask: $squircle-border-shape;
                        mask: $squircle-border-shape;
                    }

                    &.#{$vudClassPrefix}btn-solid {
                        box-shadow: none;

                        &::before { background-color: $button-icon-disabled-bg; }

                        &::after { background-color: $button-icon-disabled-border-color; }
                    }

                    &.#{$vudClassPrefix}btn-lg {
                        &::after {
                            -webkit-mask: $squircles-lg-border-shape;
                            mask: $squircles-lg-border-shape;
                        }
                    }
                }
            }
        }

        > .vismaicon {
            margin: 0;
            top: 0;
        }
    }
}
