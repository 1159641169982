@mixin rows() {
  .rotate-90 {
    transform: rotate(90deg);
  }

  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border: 0;
    overflow: visible;
  }

  .visma-warning-row.ag-row-group-expanded:not(:has(.ag-cell.cell-renderer-error)):before {
    background-color: var(--orange-05);
  }

  .visma-invalid-row.ag-row-group-expanded:before {
    background-color: var(--red-05);
  }

  .visma-warning-row.ag-row-group-expanded,
  .visma-invalid-row.ag-row-group-expanded {
    border-bottom: 0;
    .label-danger {
      border-color: var(--red-20);
    }
  }

  .ag-row-group-expanded {
    .ag-row-cell-warning {
      color: var(--text-warning);
    }
  }

  .ag-cell-inline-editing.warning-input {
    border-color: var(--orange-60) !important;
    box-shadow: 0 2px 4px 0 rgba(17, 111, 174, 0.2); // value from payroll guidelines
  }

  .ag-row-hover:not(.visma-warning-row) {
    .ag-cell-inline-editing {
      background-color: var(--ag-row-hover-color);
    }
  }

  .ag-cell-inline-editing {
    .ag-cell-wrapper {
      display: block;
    }
    .ag-input-field-input {
      height: 32px;
    }
  }

  .ag-row-focus {
    .ag-cell-inline-editing {
      border: 0;
      box-shadow: none;
      background-color: inherit;
      padding: 0 2px;
      input {
        background-color: var(--neutral-05);
      }
    }
  }

  //.ag-row {
  //  $statuses: (
  //    warning: var(--orange-60),
  //    success: var(--green-60),
  //    error: var(--red-70),
  //  );
  //
  //  @each $status, $color in $statuses {
  //    &.ag-row-#{$status}:before {
  //      border-left: 8px solid $color;
  //      bottom: 0;
  //      content: '';
  //      left: 0;
  //      position: absolute;
  //      right: 0;
  //      top: 0;
  //    }
  //  }
  //}

  .ag-row-status-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-row-level-1.visma-invalid-row {
    background-color: var(--red-05);
  }
  .ag-row-hover {
    .tag-item {
      border: 1px solid var(--blue-20);
    }
  }
}

// cell validation errors as in the Payroll guidelines
.ag-row {
  &:has(.ag-cell.cell-renderer-error):before {
    background-color: var(--red-05);
    border-left: 4px solid var(--red-60);
    inset: 0;
    content: '';
    position: absolute;
  }

  &.ag-row-not-inline-editing {
    .cell-renderer-error {
      color: var(--red-70);
      border: 1px solid var(--input-error-border-color);
    }
  }

  &.ag-row-focus:has(.ag-cell.cell-renderer-error) {
    .ag-cell-inline-editing {
      background-color: var(--red-05);

      &.cell-renderer-error {
        input {
          color: var(--red-70);
        }

        &:not(.ag-cell-focus) {
          input,
          select {
            border: 1px solid var(--input-error-border-color) !important;
          }
        }
      }
    }
  }
}
