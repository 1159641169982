
//# Alert

//*** scss local variable ***
$alert-border-radius:        var(--#{$vudTokenPrefix}radius-sm);
$alert-icon-mask:            url('#{$image-path}/informative-icons/16_informative_icons_dynamic.svg');
$alert-ancor-text-color:     var(--#{$vudTokenPrefix}anchor-color);

$alert-success-bg:           var(--#{$vudTokenPrefix}alert-success-bg);
$alert-success-icon-bg:      var(--#{$vudTokenPrefix}icon-informative-success-bg);
$alert-success-text:         var(--#{$vudTokenPrefix}alert-success-text);
$alert-success-border-color: var(--#{$vudTokenPrefix}alert-success-border);
$alert-success-shadow:       var(--#{$vudTokenPrefix}alert-success-shadow);

$alert-info-bg:              var(--#{$vudTokenPrefix}alert-info-bg);
$alert-info-icon-bg:         var(--#{$vudTokenPrefix}icon-informative-info-bg);
$alert-info-text:            var(--#{$vudTokenPrefix}alert-info-text);
$alert-info-border-color:    var(--#{$vudTokenPrefix}alert-info-border);
$alert-info-shadow:          var(--#{$vudTokenPrefix}alert-info-shadow);

$alert-warning-bg:           var(--#{$vudTokenPrefix}alert-warning-bg);
$alert-warning-icon-bg:      var(--#{$vudTokenPrefix}icon-informative-warning-bg);
$alert-warning-text:         var(--#{$vudTokenPrefix}alert-warning-text);
$alert-warning-border-color: var(--#{$vudTokenPrefix}alert-warning-border);
$alert-warning-shadow:       var(--#{$vudTokenPrefix}alert-warning-shadow);

$alert-danger-bg:            var(--#{$vudTokenPrefix}alert-danger-bg);
$alert-danger-icon-bg:       var(--#{$vudTokenPrefix}icon-informative-error-bg);
$alert-danger-text:          var(--#{$vudTokenPrefix}alert-danger-text);
$alert-danger-border-color:  var(--#{$vudTokenPrefix}alert-danger-border);
$alert-danger-shadow:        var(--#{$vudTokenPrefix}alert-danger-shadow);

//*** element/component code ***
.#{$vudClassPrefix}alert {
    position: relative;
    min-height: rem(67px);
    padding: $spacing-24 rem(72px) $spacing-24 rem(52px);
    margin-bottom: $spacing-16;

    line-height: $line-height-sm;
    color: $alert-info-text;

    border-radius: $alert-border-radius;
    border-width: rem(1px);
    border-style: solid;
    border-color: $alert-info-border-color;
    background: $alert-info-bg;
    box-shadow: $alert-info-shadow;


    @media(max-width: $screen-md-min) {
        padding-right: $spacing-48;
    }

    &:before {
        content:'';
        width: rem(16px);
        height: rem(16px);
        display: block;
        position: absolute;
        top: rem(26px);
        left: $spacing-24;

        background-color: $alert-info-icon-bg;

        mask: $alert-icon-mask;
        -webkit-mask: $alert-icon-mask;
        mask-position: rem(-63px) 0;
        -webkit-mask-position: rem(-63px) 0;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: auto rem(16px);
        -webkit-mask-size: auto rem(16px);
        }

    .vismaicon-filled { display: none; }

    .#{$vudClassPrefix}btn,
    > p:last-child { margin-bottom: 0; }

    a {
        color: $alert-ancor-text-color;
    }

    h1, h2, h3, h4, h5, h6 {
        font: $font-sm;
        font-weight: $bold;
        line-height: 1.6;

        &:last-child{
            margin-bottom: 0;
        }
    }

    &.#{$vudClassPrefix}alert-success {
        color: $alert-success-text;

        border-color: $alert-success-border-color;
        background: $alert-success-bg;
        box-shadow: $alert-success-shadow;

        &:before {
            mask-position: 0 0;
            -webkit-mask-position: 0 0;
            background-color: $alert-success-icon-bg;
        }
    }

    &.#{$vudClassPrefix}alert-warning {
        color: $alert-warning-text;

        border-color: $alert-warning-border-color;
        background: $alert-warning-bg;
        box-shadow: $alert-warning-shadow;

        &:before { 
            mask-position: rem(-42px) 0;
            -webkit-mask-position: rem(-42px) 0;
            background-color: $alert-warning-icon-bg;
        }
    }

    &.#{$vudClassPrefix}alert-error,
    &.#{$vudClassPrefix}alert-danger {
        color: $alert-danger-text;

        border-color: $alert-danger-border-color;
        background: $alert-danger-bg;
        box-shadow: $alert-danger-shadow;

        &::before {
            mask-position: rem(-21px) 0;
            -webkit-mask-position: rem(-21px) 0;
            background-color: $alert-danger-icon-bg;
        }
    }


    //Close button
    .#{$vudClassPrefix}close {
        position: absolute;
        top: rem(26px);
        right: $spacing-24;
    }


    // Small
    &.#{$vudClassPrefix}alert-sm {
        min-height: rem(52px);
        padding-top: $spacing-16;
        padding-bottom: $spacing-16;

        &::before,
        .#{$vudClassPrefix}close { top: rem(17px); }
    }

    // Medium size icon
    &.#{$vudClassPrefix}alert-icon-md {
        min-height: rem(84px);
        padding: $spacing-24 rem(88px) $spacing-24 rem(72px);

        &::before {
            width: rem(32px);
            height: rem(32px);
            left: $spacing-24;

            mask-size: auto rem(32px);
            -webkit-mask-size: auto rem(32px);
        }

        &.#{$vudClassPrefix}alert-info::before {
            mask-position: rem(-126px) 0;
            -webkit-mask-position: rem(-126px) 0;
        }

        &.#{$vudClassPrefix}alert-warning::before {
            mask-position: rem(-84px) 0;
            -webkit-mask-position: rem(-84px) 0;
        }

        &.#{$vudClassPrefix}alert-danger::before,
        &.#{$vudClassPrefix}alert-error::before {
            mask-position: rem(-42px) 0;
            -webkit-mask-position: rem(-42px) 0;
        }
    }

    // Large size icon
    &.#{$vudClassPrefix}alert-icon-lg {
        min-height: rem(100px);
        padding: $spacing-24 rem(88px) $spacing-24 rem(96px);

        &::before {
            width: rem(48px);
            height: rem(48px);
            left: $spacing-24;

            mask-size: auto rem(48px);
            -webkit-mask-size: auto rem(48px);
        }

        &.#{$vudClassPrefix}alert-info::before {
            mask-position: rem(-189px) 0;
            -webkit-mask-position: rem(-189px) 0;
        }

        &.#{$vudClassPrefix}alert-warning::before {
            mask-position: rem(-126px) 0;
            -webkit-mask-position: rem(-126px) 0;
        }

        &.#{$vudClassPrefix}alert-danger::before,
        &.#{$vudClassPrefix}alert-error::before {
            mask-position: rem(-63px) 0;
            -webkit-mask-position: rem(-63px) 0;
        }
    }
}
