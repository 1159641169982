
// VUD variables
//
//=== Table of Contents:
//
//  1. Core CSS Variables
//    # a. Color palette
//    # b. Icons
//    # c. Typography
//
//  2. Light mode CSS Variables (mixin)
//    # a. Color palette - Neutral palette
//    # b. Base body properties
//    # c. Typography
//    # d. Buttons
//    # e. Icons
//    # f. Badges
//    # g. List groups
//    # h. Navigation
//    # i. Vertical navigation
//    # j. Modals
//    # k. Forms
//         - inputs
//         - switches
//         - radio-checkbox
//    # l. Images
//    # m. Table
//    # n. Stepper
//    # o.
//
//  3. Dark mode CSS Variables (mixin)
//    # a. Color palette - Neutral palette
//    # b. Base body properties
//    # c. Typography
//    # d. Buttons
//    # e. Icons
//    # f. Badges
//    # g. List groups
//    # h. Navigation
//    # i. Vertical navigation
//    # j. Modals
//    # k. Forms
//         - inputs
//         - switches
//         - radio-checkbox
//    # l. Images
//    # m. Table
//    # n. Stepper
//    # o. Progress Bar


//=== Aditional SCSS Variables
@import '../variables';
//== Your custom scss overwrite variables
@import '../custom-variables';

@import "../../../tokens/scss/variables-light";
@import "../../../tokens/scss/variables-dark";


// #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', 'var(--'+$vudTokenPrefix+'blue-70)')}
$useBrandTokens: false !default;
@function brandColorToken($brandValue, $hardValue) {
  @if $useBrandTokens {
    @return $brandValue;
  } @else {
    @return $hardValue;
  }
}



/* Setup a few global scope properties */
:root, :host {
  //=== 0. Deprecated CSS Variables
  //# Old Stepper icons tokens - unused anymore - deprecated tokens
  --stepper-visited-icon-light: #{$check-mark-light}; // <- deprecated tokens
  --stepper-visited-icon-dark: #{$check-mark-dark}; // <- deprecated tokens




  //=== 1. Core CSS Variables
  //# a. Color palette
  --#{$vudTokenPrefix}white: #{$white};
  --#{$vudTokenPrefix}black: #{$black};


  //### Purple palette
  --#{$vudTokenPrefix}purple-90: #{$light-vud-purple-90};
  --#{$vudTokenPrefix}purple-80: #{$light-vud-purple-80};
  --#{$vudTokenPrefix}purple-70: #{$light-vud-purple-70};
  --#{$vudTokenPrefix}purple-60: #{$light-vud-purple-60};
  --#{$vudTokenPrefix}purple-50: #{$light-vud-purple-50};
  --#{$vudTokenPrefix}purple-40: #{$light-vud-purple-40};
  --#{$vudTokenPrefix}purple-30: #{$light-vud-purple-30};
  --#{$vudTokenPrefix}purple-20: #{$light-vud-purple-20};
  --#{$vudTokenPrefix}purple-10: #{$light-vud-purple-10};
  --#{$vudTokenPrefix}purple-05: #{$light-vud-purple-05};

  --#{$vudTokenPrefix}primary-purple: var(--#{$vudTokenPrefix}purple-80);


  //### Blue palette
  --#{$vudTokenPrefix}blue-90: #{$light-vud-blue-90};
  --#{$vudTokenPrefix}blue-80: #{$light-vud-blue-80};
  --#{$vudTokenPrefix}blue-70: #{$light-vud-blue-70};
  --#{$vudTokenPrefix}blue-60: #{$light-vud-blue-60};
  --#{$vudTokenPrefix}blue-50: #{$light-vud-blue-50};
  --#{$vudTokenPrefix}blue-40: #{$light-vud-blue-40};
  --#{$vudTokenPrefix}blue-30: #{$light-vud-blue-30};
  --#{$vudTokenPrefix}blue-20: #{$light-vud-blue-20};
  --#{$vudTokenPrefix}blue-10: #{$light-vud-blue-10};
  --#{$vudTokenPrefix}blue-05: #{$light-vud-blue-05};

  --#{$vudTokenPrefix}primary-blue: var(--#{$vudTokenPrefix}blue-60);


  //### Green palette
  --#{$vudTokenPrefix}green-90: #{$light-vud-green-90};
  --#{$vudTokenPrefix}green-80: #{$light-vud-green-80};
  --#{$vudTokenPrefix}green-70: #{$light-vud-green-70};
  --#{$vudTokenPrefix}green-60: #{$light-vud-green-60};
  --#{$vudTokenPrefix}green-50: #{$light-vud-green-50};
  --#{$vudTokenPrefix}green-40: #{$light-vud-green-40};
  --#{$vudTokenPrefix}green-30: #{$light-vud-green-30};
  --#{$vudTokenPrefix}green-20: #{$light-vud-green-20};
  --#{$vudTokenPrefix}green-10: #{$light-vud-green-10};
  --#{$vudTokenPrefix}green-05: #{$light-vud-green-05};

  --#{$vudTokenPrefix}primary-green: var(--#{$vudTokenPrefix}green-70);


  //### Red palette
  --#{$vudTokenPrefix}red-90: #{$light-vud-red-90};
  --#{$vudTokenPrefix}red-80: #{$light-vud-red-80};
  --#{$vudTokenPrefix}red-70: #{$light-vud-red-70};
  --#{$vudTokenPrefix}red-60: #{$light-vud-red-60};
  --#{$vudTokenPrefix}red-50: #{$light-vud-red-50};
  --#{$vudTokenPrefix}red-40: #{$light-vud-red-40};
  --#{$vudTokenPrefix}red-30: #{$light-vud-red-30};
  --#{$vudTokenPrefix}red-20: #{$light-vud-red-20};
  --#{$vudTokenPrefix}red-10: #{$light-vud-red-10};
  --#{$vudTokenPrefix}red-05: #{$light-vud-red-05};

  --#{$vudTokenPrefix}primary-red: var(--#{$vudTokenPrefix}red-70);


  //### Orange palette
  --#{$vudTokenPrefix}orange-90: #{$light-vud-orange-90};
  --#{$vudTokenPrefix}orange-80: #{$light-vud-orange-80};
  --#{$vudTokenPrefix}orange-70: #{$light-vud-orange-70};
  --#{$vudTokenPrefix}orange-60: #{$light-vud-orange-60};
  --#{$vudTokenPrefix}orange-50: #{$light-vud-orange-50};
  --#{$vudTokenPrefix}orange-40: #{$light-vud-orange-40};
  --#{$vudTokenPrefix}orange-30: #{$light-vud-orange-30};
  --#{$vudTokenPrefix}orange-20: #{$light-vud-orange-20};
  --#{$vudTokenPrefix}orange-10: #{$light-vud-orange-10};
  --#{$vudTokenPrefix}orange-05: #{$light-vud-orange-05};

  --#{$vudTokenPrefix}primary-orange: var(--#{$vudTokenPrefix}orange-90);


  //### Neutral light palette
  --#{$vudTokenPrefix}neutral-light-90: #{$light-vud-neutral-light-90};
  --#{$vudTokenPrefix}neutral-light-80: #{$light-vud-neutral-light-80};
  --#{$vudTokenPrefix}neutral-light-70: #{$light-vud-neutral-light-70};
  --#{$vudTokenPrefix}neutral-light-60: #{$light-vud-neutral-light-60};
  --#{$vudTokenPrefix}neutral-light-50: #{$light-vud-neutral-light-50};
  --#{$vudTokenPrefix}neutral-light-40: #{$light-vud-neutral-light-40};
  --#{$vudTokenPrefix}neutral-light-30: #{$light-vud-neutral-light-30};
  --#{$vudTokenPrefix}neutral-light-20: #{$light-vud-neutral-light-20};
  --#{$vudTokenPrefix}neutral-light-10: #{$light-vud-neutral-light-10};
  --#{$vudTokenPrefix}neutral-light-05: #{$light-vud-neutral-light-05};


  //### Neutral dark palette
  --#{$vudTokenPrefix}neutral-dark-90: #{$dark-vud-neutral-dark-90};
  --#{$vudTokenPrefix}neutral-dark-80: #{$dark-vud-neutral-dark-80};
  --#{$vudTokenPrefix}neutral-dark-70: #{$dark-vud-neutral-dark-70};
  --#{$vudTokenPrefix}neutral-dark-60: #{$dark-vud-neutral-dark-60};
  --#{$vudTokenPrefix}neutral-dark-50: #{$dark-vud-neutral-dark-50};
  --#{$vudTokenPrefix}neutral-dark-40: #{$dark-vud-neutral-dark-40};
  --#{$vudTokenPrefix}neutral-dark-30: #{$dark-vud-neutral-dark-30};
  --#{$vudTokenPrefix}neutral-dark-20: #{$dark-vud-neutral-dark-20};
  --#{$vudTokenPrefix}neutral-dark-10: #{$dark-vud-neutral-dark-10};
  --#{$vudTokenPrefix}neutral-dark-05: #{$dark-vud-neutral-dark-05};


  //### Brand primary palette
  --#{$vudTokenPrefix}brand-palette-primary-90: var(--#{$backupVudTokenPrefix}brand-primary-90, #{$light-brand-primary-90});
  --#{$vudTokenPrefix}brand-palette-primary-80: var(--#{$backupVudTokenPrefix}brand-primary-80, #{$light-brand-primary-80});
  --#{$vudTokenPrefix}brand-palette-primary-70: var(--#{$backupVudTokenPrefix}brand-primary-70, #{$light-brand-primary-70});
  --#{$vudTokenPrefix}brand-palette-primary-60: var(--#{$backupVudTokenPrefix}brand-primary-60, #{$light-brand-primary-60});
  --#{$vudTokenPrefix}brand-palette-primary-50: var(--#{$backupVudTokenPrefix}brand-primary-50, #{$light-brand-primary-50});
  --#{$vudTokenPrefix}brand-palette-primary-40: var(--#{$backupVudTokenPrefix}brand-primary-40, #{$light-brand-primary-40});
  --#{$vudTokenPrefix}brand-palette-primary-30: var(--#{$backupVudTokenPrefix}brand-primary-30, #{$light-brand-primary-30});
  --#{$vudTokenPrefix}brand-palette-primary-20: var(--#{$backupVudTokenPrefix}brand-primary-20, #{$light-brand-primary-20});
  --#{$vudTokenPrefix}brand-palette-primary-10: var(--#{$backupVudTokenPrefix}brand-primary-10, #{$light-brand-primary-10});
  --#{$vudTokenPrefix}brand-palette-primary-05: var(--#{$backupVudTokenPrefix}brand-primary-05, #{$light-brand-primary-05});


  //### Brand secondary palette
  --#{$vudTokenPrefix}brand-palette-secondary-90: var(--#{$backupVudTokenPrefix}brand-secondary-90, #{$light-brand-secondary-90});
  --#{$vudTokenPrefix}brand-palette-secondary-80: var(--#{$backupVudTokenPrefix}brand-secondary-80, #{$light-brand-secondary-80});
  --#{$vudTokenPrefix}brand-palette-secondary-70: var(--#{$backupVudTokenPrefix}brand-secondary-70, #{$light-brand-secondary-70});
  --#{$vudTokenPrefix}brand-palette-secondary-60: var(--#{$backupVudTokenPrefix}brand-secondary-60, #{$light-brand-secondary-60});
  --#{$vudTokenPrefix}brand-palette-secondary-50: var(--#{$backupVudTokenPrefix}brand-secondary-50, #{$light-brand-secondary-50});
  --#{$vudTokenPrefix}brand-palette-secondary-40: var(--#{$backupVudTokenPrefix}brand-secondary-40, #{$light-brand-secondary-40});
  --#{$vudTokenPrefix}brand-palette-secondary-30: var(--#{$backupVudTokenPrefix}brand-secondary-30, #{$light-brand-secondary-30});
  --#{$vudTokenPrefix}brand-palette-secondary-20: var(--#{$backupVudTokenPrefix}brand-secondary-20, #{$light-brand-secondary-20});
  --#{$vudTokenPrefix}brand-palette-secondary-10: var(--#{$backupVudTokenPrefix}brand-secondary-10, #{$light-brand-secondary-10});
  --#{$vudTokenPrefix}brand-palette-secondary-05: var(--#{$backupVudTokenPrefix}brand-secondary-05, #{$light-brand-secondary-05});


  //### Brand tertiary palette
  --#{$vudTokenPrefix}brand-palette-tertiary-90: var(--#{$backupVudTokenPrefix}brand-tertiary-90, #{$light-brand-tertiary-90});
  --#{$vudTokenPrefix}brand-palette-tertiary-80: var(--#{$backupVudTokenPrefix}brand-tertiary-80, #{$light-brand-tertiary-80});
  --#{$vudTokenPrefix}brand-palette-tertiary-70: var(--#{$backupVudTokenPrefix}brand-tertiary-70, #{$light-brand-tertiary-70});
  --#{$vudTokenPrefix}brand-palette-tertiary-60: var(--#{$backupVudTokenPrefix}brand-tertiary-60, #{$light-brand-tertiary-60});
  --#{$vudTokenPrefix}brand-palette-tertiary-50: var(--#{$backupVudTokenPrefix}brand-tertiary-50, #{$light-brand-tertiary-50});
  --#{$vudTokenPrefix}brand-palette-tertiary-40: var(--#{$backupVudTokenPrefix}brand-tertiary-40, #{$light-brand-tertiary-40});
  --#{$vudTokenPrefix}brand-palette-tertiary-30: var(--#{$backupVudTokenPrefix}brand-tertiary-30, #{$light-brand-tertiary-30});
  --#{$vudTokenPrefix}brand-palette-tertiary-20: var(--#{$backupVudTokenPrefix}brand-tertiary-20, #{$light-brand-tertiary-20});
  --#{$vudTokenPrefix}brand-palette-tertiary-10: var(--#{$backupVudTokenPrefix}brand-tertiary-10, #{$light-brand-tertiary-10});
  --#{$vudTokenPrefix}brand-palette-tertiary-05: var(--#{$backupVudTokenPrefix}brand-tertiary-05, #{$light-brand-tertiary-05});


  //### Brand neutral-light palette
  --#{$vudTokenPrefix}brand-palette-neutral-light-90: var(--#{$backupVudTokenPrefix}brand-neutral-light-90, #{$light-brand-neutral-light-90});
  --#{$vudTokenPrefix}brand-palette-neutral-light-80: var(--#{$backupVudTokenPrefix}brand-neutral-light-80, #{$light-brand-neutral-light-80});
  --#{$vudTokenPrefix}brand-palette-neutral-light-70: var(--#{$backupVudTokenPrefix}brand-neutral-light-70, #{$light-brand-neutral-light-70});
  --#{$vudTokenPrefix}brand-palette-neutral-light-60: var(--#{$backupVudTokenPrefix}brand-neutral-light-60, #{$light-brand-neutral-light-60});
  --#{$vudTokenPrefix}brand-palette-neutral-light-50: var(--#{$backupVudTokenPrefix}brand-neutral-light-50, #{$light-brand-neutral-light-50});
  --#{$vudTokenPrefix}brand-palette-neutral-light-40: var(--#{$backupVudTokenPrefix}brand-neutral-light-40, #{$light-brand-neutral-light-40});
  --#{$vudTokenPrefix}brand-palette-neutral-light-30: var(--#{$backupVudTokenPrefix}brand-neutral-light-30, #{$light-brand-neutral-light-30});
  --#{$vudTokenPrefix}brand-palette-neutral-light-20: var(--#{$backupVudTokenPrefix}brand-neutral-light-20, #{$light-brand-neutral-light-20});
  --#{$vudTokenPrefix}brand-palette-neutral-light-10: var(--#{$backupVudTokenPrefix}brand-neutral-light-10, #{$light-brand-neutral-light-10});
  --#{$vudTokenPrefix}brand-palette-neutral-light-05: var(--#{$backupVudTokenPrefix}brand-neutral-light-05, #{$light-brand-neutral-light-05});


  //### Brand neutral-dark palette
  --#{$vudTokenPrefix}brand-palette-neutral-dark-90: var(--#{$backupVudTokenPrefix}brand-neutral-dark-90, #{$dark-brand-neutral-dark-90});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-80: var(--#{$backupVudTokenPrefix}brand-neutral-dark-80, #{$dark-brand-neutral-dark-80});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-70: var(--#{$backupVudTokenPrefix}brand-neutral-dark-70, #{$dark-brand-neutral-dark-70});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-60: var(--#{$backupVudTokenPrefix}brand-neutral-dark-60, #{$dark-brand-neutral-dark-60});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-50: var(--#{$backupVudTokenPrefix}brand-neutral-dark-50, #{$dark-brand-neutral-dark-50});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-40: var(--#{$backupVudTokenPrefix}brand-neutral-dark-40, #{$dark-brand-neutral-dark-40});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-30: var(--#{$backupVudTokenPrefix}brand-neutral-dark-30, #{$dark-brand-neutral-dark-30});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-20: var(--#{$backupVudTokenPrefix}brand-neutral-dark-20, #{$dark-brand-neutral-dark-20});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-10: var(--#{$backupVudTokenPrefix}brand-neutral-dark-10, #{$dark-brand-neutral-dark-10});
  --#{$vudTokenPrefix}brand-palette-neutral-dark-05: var(--#{$backupVudTokenPrefix}brand-neutral-dark-05, #{$dark-brand-neutral-dark-05});


  //# c. Typography
  // base/html font size
  --#{$vudTokenPrefix}html-font-size: #{$html-font-size};
  --#{$vudTokenPrefix}body-font-size: var(--#{$backupVudTokenPrefix}body-base-font-size, #{$body-font-size});


  // font-family
  --#{$vudTokenPrefix}font-family-primary: var(--#{$backupVudTokenPrefix}font-familiy-primary, #{$light-font-familiy-primary});
  --#{$vudTokenPrefix}font-family-secondary: var(--#{$backupVudTokenPrefix}font-familiy-secondary, #{$light-font-familiy-secondary});
  --#{$vudTokenPrefix}font-family-tertiary: var(--#{$backupVudTokenPrefix}font-familiy-tertiary, #{$light-font-familiy-tertiary});

  --#{$vudTokenPrefix}font-family-base: var(--#{$backupVudTokenPrefix}font-family-body-base, var(--#{$vudTokenPrefix}font-family-secondary));


  // font-size
  //# fixed size
  --#{$vudTokenPrefix}font-size-xs: var(--#{$backupVudTokenPrefix}font-size-fixed-xs, #{$light-font-size-fixed-xs});
  --#{$vudTokenPrefix}font-size-sm: var(--#{$backupVudTokenPrefix}font-size-fixed-sm, #{$light-font-size-fixed-sm});
  --#{$vudTokenPrefix}font-size-md: var(--#{$backupVudTokenPrefix}font-size-fixed-md, #{$light-font-size-fixed-md});
  --#{$vudTokenPrefix}font-size-lg: var(--#{$backupVudTokenPrefix}font-size-fixed-lg, #{$light-font-size-fixed-lg});
  --#{$vudTokenPrefix}font-size-xl: var(--#{$backupVudTokenPrefix}font-size-fixed-xl, #{$light-font-size-fixed-xl});
  --#{$vudTokenPrefix}font-size-xxl: var(--#{$backupVudTokenPrefix}font-size-fixed-xxl, #{$light-font-size-fixed-xxl});

  //# fluid size
  --#{$vudTokenPrefix}font-size-fluid-xs: var(--#{$backupVudTokenPrefix}font-size-clamp-xs, #{$light-scale-xs-clamp-css});
  --#{$vudTokenPrefix}font-size-fluid-sm: var(--#{$backupVudTokenPrefix}font-size-clamp-sm, #{$light-scale-s-clamp-css});
  --#{$vudTokenPrefix}font-size-fluid-md: var(--#{$backupVudTokenPrefix}font-size-clamp-md, #{$light-scale-md-clamp-css});
  --#{$vudTokenPrefix}font-size-fluid-lg: var(--#{$backupVudTokenPrefix}font-size-clamp-lg, #{$light-scale-lg-clamp-css});
  --#{$vudTokenPrefix}font-size-fluid-xl: var(--#{$backupVudTokenPrefix}font-size-clamp-xl, #{$light-scale-xl-clamp-css});
  --#{$vudTokenPrefix}font-size-fluid-xxl: var(--#{$backupVudTokenPrefix}font-size-clamp-xxl, #{$light-scale-xxl-clamp-css});


  // full font declaration
  //# headings/titles
  --#{$vudTokenPrefix}font-heading-sm-regular: var(--#{$backupVudTokenPrefix}font-regular-heading-sm, var(--#{$backupVudTokenPrefix}contextual-regular-heading-sm, #{$light-contextual-regular-heading-sm}));
  --#{$vudTokenPrefix}font-heading-sm-medium: var(--#{$backupVudTokenPrefix}font-medium-heading-sm, var(--#{$backupVudTokenPrefix}contextual-medium-heading-sm, #{$light-contextual-medium-heading-sm}));

  --#{$vudTokenPrefix}font-heading-md-regular: var(--#{$backupVudTokenPrefix}font-regular-heading-md, var(--#{$backupVudTokenPrefix}contextual-regular-heading-md, #{$light-contextual-regular-heading-md}));
  --#{$vudTokenPrefix}font-heading-md-medium: var(--#{$backupVudTokenPrefix}font-medium-heading-md, var(--#{$backupVudTokenPrefix}contextual-medium-heading-md, #{$light-contextual-medium-heading-md}));

  --#{$vudTokenPrefix}font-heading-xl-regular: var(--#{$backupVudTokenPrefix}font-regular-heading-xl, var(--#{$backupVudTokenPrefix}contextual-regular-heading-xl, 400 2.9rem/1 Ubuntu));
  --#{$vudTokenPrefix}font-heading-xl-medium: var(--#{$backupVudTokenPrefix}font-medium-heading-xl, var(--#{$backupVudTokenPrefix}contextual-medium-heading-xl, #{$light-contextual-medium-heading-xl}));

  //# body text
  --#{$vudTokenPrefix}font-body-xs-regular: var(--#{$backupVudTokenPrefix}font-regular-body-xs, var(--#{$backupVudTokenPrefix}contextual-regular-body-xs, #{$light-contextual-regular-body-xs}));
  --#{$vudTokenPrefix}font-body-xs-medium: var(--#{$backupVudTokenPrefix}font-medium-body-xs, var(--#{$backupVudTokenPrefix}contextual-medium-body-xs, #{$light-contextual-medium-body-xs}));

  --#{$vudTokenPrefix}font-body-sm-regular: var(--#{$backupVudTokenPrefix}font-regular-body-sm, var(--#{$backupVudTokenPrefix}contextual-regular-body-sm, #{$light-contextual-regular-body-sm}));
  --#{$vudTokenPrefix}font-body-sm-medium: var(--#{$backupVudTokenPrefix}font-medium-body-sm, var(--#{$backupVudTokenPrefix}contextual-medium-body-sm, #{$light-contextual-medium-body-sm}));
  --#{$vudTokenPrefix}font-body-sm-bold: var(--#{$backupVudTokenPrefix}font-bold-body-sm, var(--#{$backupVudTokenPrefix}contextual-bold-body-sm, #{$light-contextual-bold-body-sm}));

  --#{$vudTokenPrefix}font-body-sm-link: var(--#{$backupVudTokenPrefix}font-regular-body-sm-link, var(--#{$backupVudTokenPrefix}contextual-regular-body-sm-link, #{$light-contextual-regular-body-sm-link}));


  // Unique base for sizing, spacing, radius, border
  // Radii scale list
  --#{$vudTokenPrefix}radius-xxs: var(--#{$backupVudTokenPrefix}radii-xxs, #{$light-radii-xxs});
  --#{$vudTokenPrefix}radius-xs: var(--#{$backupVudTokenPrefix}radii-xs, #{$light-radii-xs});
  --#{$vudTokenPrefix}radius-sm: var(--#{$backupVudTokenPrefix}radii-sm, #{$light-radii-sm});
  --#{$vudTokenPrefix}radius-md: var(--#{$backupVudTokenPrefix}radii-md, #{$light-radii-md});
  --#{$vudTokenPrefix}radius-lg: var(--#{$backupVudTokenPrefix}radii-lg, #{$light-radii-lg});
  --#{$vudTokenPrefix}radius-xl: var(--#{$backupVudTokenPrefix}radii-xl, #{$light-radii-xl});
  --#{$vudTokenPrefix}radius-xxl: var(--#{$backupVudTokenPrefix}radii-xxl, #{$light-radii-xxl});
}



// Light and Dark CSS Variables
//
// 2. Light mode CSS Variables
@mixin light-mode {
  //=== 0'. Deprecated CSS Variables
  //# Old Stepper icons tokens - unused anymore - deprecated tokens
  // anchor/breadcrumbs properties
  --#{$vudTokenPrefix}anchor-focus-outline-alt: var(--custom-anchor-focus-outline-alt, var(--#{$vudTokenPrefix}blue-10)); // <- deprecated (not defined in figma)

  //=== Buttons
  //## Default button
  --#{$vudTokenPrefix}button-icon: var(--custom-button-icon, var(--#{$vudTokenPrefix}button-icon-color)); // <- deprecated/duplicate (use: "--#{$vudTokenPrefix}button-icon-color")
  --#{$vudTokenPrefix}button-inner-border-color: var(--custom-button-inner-border-color, var(--#{$vudTokenPrefix}neutral-40)); // <- deprecated (no longer borders that ware shered in group buttons have diffrent color for that state)
  --#{$vudTokenPrefix}button-shadow-color: var(--custom-button-shadow-color, rgba(27, 56, 76, 0.12)); // <- deprecated (shadows no longer have color tint for states)

  // Default button - hover state
  --#{$vudTokenPrefix}button-hover-shadow-color: var(--custom-button-hover-shadow-color, rgba(20, 130, 204, 0.15)); // <- deprecated (shadows no longer have color tint for states)

  // Default button - focus state
  --#{$vudTokenPrefix}button-focus-shadow-color: var(--custom-button-focus-shadow-color, rgba(20, 130, 204, 0.15)); // <- deprecated (shadows no longer have color tint for states)

  // Default button - disabled state
  --#{$vudTokenPrefix}button-disabled-inner-border-color: var(--custom-button-disabled-inner-border-color, var(--#{$vudTokenPrefix}neutral-50)); // <- deprecated (no longer borders that ware shered in group buttons have diffrent color for that state)


  //## Primary button
  --#{$vudTokenPrefix}button-primary-inner-border-color: var(--custom-button-primary-inner-border-color, var(--#{$vudTokenPrefix}green-80)); // <- deprecated (no longer borders that ware shered in group buttons have diffrent color for that state)
  --#{$vudTokenPrefix}button-primary-shadow-color: var(--custom-button-primary-shadow-color, rgba(49, 98, 69, 0.2)); // <- deprecated (shadows no longer have color tint for states)

  // Primary button - focus state
  --#{$vudTokenPrefix}button-primary-focus-shadow-color: var(--custom-button-primary-focus-shadow-color, rgba(20, 130, 204, 0.15)); // <- deprecated (shadows no longer have color tint for states)


  //# dropdown button
  --#{$vudTokenPrefix}dropdown-menu-shadow-color: var(--custom-dropdown-menu-shadow-color, rgba(27, 56, 76, 0.15)); // <- deprecated (shadows no longer have color tint for states)


  //# wizard
  --#{$vudTokenPrefix}wizard-inner-border-color: var(--custom-wizard-inner-border-color, var(--#{$vudTokenPrefix}wizard-border-color)); // <- deprecated (no longer borders that ware shered in wizard have diffrent color for that state)


  //=== e. Icons
  // worksourface, action and social media icons
  --#{$vudTokenPrefix}icon-hover-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-icon-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-ref-icon-emphasis)})); // <- deprecated (on their own they do not have an added hover color)

  // pictograms
  --#{$vudTokenPrefix}pictogram-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-icon-disabled)}); //<- deprec (do not use)

  // Carets and close icons
  --#{$vudTokenPrefix}caret-navigation-bg: var(--custom-caret-navigation-bg, var(--#{$vudTokenPrefix}neutral-05)); // <-- depre
  --#{$vudTokenPrefix}close-focus-outline: var(--custom-close-focus-outline, var(--#{$vudTokenPrefix}blue-50)); // <- depr [var(--#{$backupVudTokenPrefix}ref-border-default-focused, var(--custom-anchor-focus-outline, #{$light-ref-border-default-focused}))]

  //=== f. Badges
  --#{$vudTokenPrefix}badge-shadow: var(--custom-badge-shadow, 0 0 0 rem(1px) #8A8A8A); // <- deprecated (not defined in figma, badges no longer have a shadow)
  --#{$vudTokenPrefix}badge-success-shadow: var(--custom-badge-success-shadow, 0 0 0 rem(1px) #2D7048); // <- deprecated (not defined in figma, badges no longer have a shadow)
  --#{$vudTokenPrefix}badge-danger-shadow: var(--custom-badge-danger-shadow, 0 0 0 rem(1px) #CC334C); // <- deprecated (not defined in figma, badges no longer have a shadow)

  --#{$vudTokenPrefix}badge-info-alt-text: var(--custom-badge-info-alt-text, var(--#{$vudTokenPrefix}blue-50)); // <- deprecated (not defined in figma)
  --#{$vudTokenPrefix}badge-info-alt-bg: var(--custom-badge-info-alt-bg, var(--#{$vudTokenPrefix}neutral-05)); // <- deprecated (not defined in figma)


  //=== g. List groups
  --#{$vudTokenPrefix}list-group-primary-divider: var(--custom-list-group-primary-divider, #{inset 0 1px 0 0} var(--#{$vudTokenPrefix}blue-50)); // deprecated
  --#{$vudTokenPrefix}list-group-primary-active-bg: var(--custom-list-group-primary-active-bg, rgba(#{hex-to-rgb($neutral-light-90)}, 0.15)); // deprecated
  --#{$vudTokenPrefix}list-group-primary-hover-bg: var(--custom-list-group-primary-hover-bg, var(--#{$vudTokenPrefix}blue-60)); // deprecated


    //=== h. Top Navigation (rename/add 'brand' variant)
  --#{$vudTokenPrefix}navigation-default-badge-shadow: var(--custom-navigation-default-badge-shadow, var(--#{$vudTokenPrefix}badge-shadow)); // <- deprecated (not defined in figma, badges no longer have a shadow)


  // Primary navigation (rename/add 'inverted' variant)
  --#{$vudTokenPrefix}navigation-primary-item-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-enabled, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-hor-brand-border-enabled)})); // <- deprecated

  // Secondary navigation (rename/add after depracated is in fact 'default' variant)
  --#{$vudTokenPrefix}navigation-secondary-shadow: var(--custom-navigation-secondary-shadow, var(--#{$vudTokenPrefix}navigation-default-shadow)); // the same with others
  --#{$vudTokenPrefix}navigation-secondary-item-active-font-weight: var(--custom-navigation-secondary-item-active-font-weight, #{$normal}); // <- deprecated

  --#{$vudTokenPrefix}navigation-secondary-badge-shadow: var(--custom-navigation-secondary-badge-shadow, var(--#{$vudTokenPrefix}badge-success-shadow)); // <- deprecated (not defined in figma, badges no longer have a shadow)


  //=== i. Vertical navigation
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-shadow: var(--custom-vertical-nav-default-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-50)}, 0.3)); // <- deprecated

  // ('brand' variant)
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-bg: var(--custom-vertical-nav-primary-collapse-bg, rgba(#{hex-to-rgb($blue-90)}, 0.5)); // not connected
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-shadow: var(--custom-vertical-nav-primary-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.15)); // <- deprecated


  //=== k. Forms
  // inputs/textarea
  --#{$vudTokenPrefix}input-shadow-color: var(--custom-input-shadow-color); // <- deprecated (shadows no longer have color tint for states)
  --#{$vudTokenPrefix}input-hover-shadow-color: var(--custom-input-hover-shadow-color); // <- deprecated (shadows no longer have color tint for states)
  --#{$vudTokenPrefix}input-focus-shadow-color: var(--custom-input-focus-shadow-color); // <- deprecated (shadows no longer have color tint for states)
  --#{$vudTokenPrefix}input-primary-shadow-color: var(--custom-input-primary-shadow-color); // <- deprecated (shadows no longer have color tint for states)

  // switches
  --#{$vudTokenPrefix}switches-togglemark-shadow-color: var(--custom-switches-togglemark-shadow-color, rgba(#{hex-to-rgb($blue-90)}, 0)); // <- deprecated

  // radio-checkbox
  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-radio-checkmark-bg, #{$light-toggle-icon-emphasis}))); // <- deprecated (unchecked checkbox - the checkmark isn't visible)
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--comp-toggle-icon-inverse, var(--custom-radio-checkbox-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-inverse)}))); // <- deprecated (unchecked checkbox - the checkmark isn't visible)

  // radio-checkbox - disabled - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-radio-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-checked-disabled-radio-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-disabled)}))); // <- deprecated/duplicate (use: '--#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg')

  // radio-checkbox - read only - checked
  --#{$vudTokenPrefix}radio-checkbox-read-only-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-radio-checkbox-read-only-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-toggle-surface-disabled-moderate)}))); // <- deprecated (read-only not applying to radio or check boxes)
  --#{$vudTokenPrefix}radio-checkbox-read-only-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-read-only-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-disabled)}))); // <- deprecated (read-only not applying to radio or check boxes)


  //=== l. Images
  --#{$vudTokenPrefix}thumbnail-border-color: var(--custom-thumbnail-border-color, var(--#{$vudTokenPrefix}neutral-50)); // <- deprecated (not in used)

  //=== m. Table
  --#{$vudTokenPrefix}table-shadow-color: var(--custom-table-shadow-color); // <- deprecated (shadows no longer have color tint for states)

  //=== n. Stepper
  --#{$vudTokenPrefix}stepper-wrapper-text-color: var(--custom-stepper-wrapper-text-color, var(--#{$vudTokenPrefix}primary-neutral)); // <- deprecated
  --#{$vudTokenPrefix}stepper-wrapper-bg: var(--custom-stepper-wrapper-bg, var(--#{$vudTokenPrefix}neutral-05)); // <- deprecated

  // stepper - active/selected state
  --#{$vudTokenPrefix}stepper-active-shadow-color: var(--custom-stepper-active-shadow-color, rgba(#{hex-to-rgb($blue-50)}, 0.25)); // <- deprecated

  //=== o. Progress Bar
  --#{$vudTokenPrefix}progress-bar-shadow-color: var(--custom-progress-bar-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.15)); // <- deprecated (shadows no longer have color tint for states)

  //=== o. Datepicker
  --#{$vudTokenPrefix}datepicker-popup-bg: var(--custom-datepicker-popup-bg, var(--#{$vudTokenPrefix}module-container-bg-color)); // <- deprecated (use: '--#{$vudTokenPrefix}datepicker-inline-bg')

  --#{$vudTokenPrefix}datepicker-item-range-focus-shadow: var(--custom-datepicker-item-range-focus-shadow, var(--#{$vudTokenPrefix}blue-60)); // <- deprecated
  --#{$vudTokenPrefix}datepicker-item-range-focus-border: var(--custom-datepicker-item-range-focus-border, var(--#{$vudTokenPrefix}neutral-05)); // <- deprecated

  --#{$vudTokenPrefix}datepicker-clear-btn-text: var(--custom-datepicker-clear-btn-text, var(--#{$vudTokenPrefix}red-70)); // <- deprecated (use: '--#{$vudTokenPrefix}datepicker-clear-btn-text-color')

  //=== p. Tabs
  --#{$vudTokenPrefix}tabs-normal-Ypoition:              #{rem(1px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-disabled-Ypoition:            #{rem(-49px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-hover-Ypoition:               #{rem(-99px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-selected-Ypoition:            #{rem(-148px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-focus-Ypoition:               #{rem(-199px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-focus-hover-Ypoition:         #{rem(-249px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-selected-focused-Ypoition:    #{rem(-299px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-pressed-Ypoition:             var(--#{$vudTokenPrefix}tabs-selected-Ypoition); // <- deprecated (no longer in used)

  --#{$vudTokenPrefix}tabs-dropdown-Ypoition:               #{rem(8px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-normal-Xpoition:        #{rem(0px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-hover-Xpoition:       #{rem(-50px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-focus-Xpoition:       #{rem(-100px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-pressed-Xpoition:    #{rem(-150px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-disabled-Xpoition:   #{rem(-200px)}; // <- deprecated (no longer in used)





  //=== a. Color palette
  //# Neutral palette
  --#{$vudTokenPrefix}neutral-90: var(--#{$vudTokenPrefix}neutral-light-90);
  --#{$vudTokenPrefix}neutral-80: var(--#{$vudTokenPrefix}neutral-light-80);
  --#{$vudTokenPrefix}neutral-70: var(--#{$vudTokenPrefix}neutral-light-70);
  --#{$vudTokenPrefix}neutral-60: var(--#{$vudTokenPrefix}neutral-light-60);
  --#{$vudTokenPrefix}neutral-50: var(--#{$vudTokenPrefix}neutral-light-50);
  --#{$vudTokenPrefix}neutral-40: var(--#{$vudTokenPrefix}neutral-light-40);
  --#{$vudTokenPrefix}neutral-30: var(--#{$vudTokenPrefix}neutral-light-30);
  --#{$vudTokenPrefix}neutral-20: var(--#{$vudTokenPrefix}neutral-light-20);
  --#{$vudTokenPrefix}neutral-10: var(--#{$vudTokenPrefix}neutral-light-10);
  --#{$vudTokenPrefix}neutral-05: var(--#{$vudTokenPrefix}neutral-light-05);

  --#{$vudTokenPrefix}primary-neutral: var(--#{$vudTokenPrefix}neutral-light-90);


  //### Brand neutral palette
  --#{$vudTokenPrefix}brand-palette-neutral-90: var(--#{$vudTokenPrefix}brand-palette-neutral-light-90, #{$light-brand-neutral-light-90});
  --#{$vudTokenPrefix}brand-palette-neutral-80: var(--#{$vudTokenPrefix}brand-palette-neutral-light-80, #{$light-brand-neutral-light-80});
  --#{$vudTokenPrefix}brand-palette-neutral-70: var(--#{$vudTokenPrefix}brand-palette-neutral-light-70, #{$light-brand-neutral-light-70});
  --#{$vudTokenPrefix}brand-palette-neutral-60: var(--#{$vudTokenPrefix}brand-palette-neutral-light-60, #{$light-brand-neutral-light-60});
  --#{$vudTokenPrefix}brand-palette-neutral-50: var(--#{$vudTokenPrefix}brand-palette-neutral-light-50, #{$light-brand-neutral-light-50});
  --#{$vudTokenPrefix}brand-palette-neutral-40: var(--#{$vudTokenPrefix}brand-palette-neutral-light-40, #{$light-brand-neutral-light-40});
  --#{$vudTokenPrefix}brand-palette-neutral-30: var(--#{$vudTokenPrefix}brand-palette-neutral-light-30, #{$light-brand-neutral-light-30});
  --#{$vudTokenPrefix}brand-palette-neutral-20: var(--#{$vudTokenPrefix}brand-palette-neutral-light-20, #{$light-brand-neutral-light-20});
  --#{$vudTokenPrefix}brand-palette-neutral-10: var(--#{$vudTokenPrefix}brand-palette-neutral-light-10, #{$light-brand-neutral-light-10});
  --#{$vudTokenPrefix}brand-palette-neutral-05: var(--#{$vudTokenPrefix}brand-palette-neutral-light-05, #{$light-brand-neutral-light-05});


  //=== b. Base body properties
  // Set site background color
  --#{$vudTokenPrefix}root-background-color: var(--#{$backupVudTokenPrefix}app-background-color, var(--#{$backupVudTokenPrefix}ref-fill-screen-default, var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-root-background-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-ref-fill-screen-default)}))));

  --#{$vudTokenPrefix}scrollbar-track-bg: var(--#{$backupVudTokenPrefix}scrollbar-track-background-color, var(--#{$backupVudTokenPrefix}scrollbar-surface-muted, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-scrollbar-surface-muted)}));
  --#{$vudTokenPrefix}scrollbar-thumb-bg: var(--#{$backupVudTokenPrefix}scrollbar-thumb-background-color, var(--#{$backupVudTokenPrefix}scrollbar-surface-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-scrollbar-surface-default)}));
  --#{$vudTokenPrefix}scrollbar-thumb-drag-bg: var(--#{$backupVudTokenPrefix}scrollbar-thumb-drag-background-color, var(--#{$backupVudTokenPrefix}scrollbar-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-scrollbar-surface-enabled)}));


  // body properties
  --#{$vudTokenPrefix}body-font-color: var(--#{$backupVudTokenPrefix}body-text-color, var(--#{$backupVudTokenPrefix}content-text-default, var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-body-font-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-content-text-default)}))));
  --#{$vudTokenPrefix}titles-font-color: var(--#{$backupVudTokenPrefix}titles-text-color, var(--#{$backupVudTokenPrefix}content-text-default, var(--custom-titles-font-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-content-text-default)})));


  //=== c. Typography
  // body text
  --#{$vudTokenPrefix}text-default: var(--#{$backupVudTokenPrefix}ref-text-default, var(--custom-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-ref-text-default)}));
  --#{$vudTokenPrefix}text-alt: var(--#{$backupVudTokenPrefix}ref-text-inverse, var(--custom-text-alt, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-ref-text-inverse)}));
  --#{$vudTokenPrefix}text-primary: var(--#{$backupVudTokenPrefix}ref-text-emphasis, var(--custom-text-primary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-ref-text-emphasis)}));
  --#{$vudTokenPrefix}text-secondary: var(--#{$backupVudTokenPrefix}ref-text-secondary, var(--custom-text-secondary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-text-secondary)}));
  --#{$vudTokenPrefix}text-disabled: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-text-disabled)}));
  --#{$vudTokenPrefix}text-error: var(--#{$backupVudTokenPrefix}ref-text-error-moderate, var(--custom-text-error, #{$light-ref-text-error-moderate}));
  --#{$vudTokenPrefix}text-warning: var(--#{$backupVudTokenPrefix}ref-text-warning-moderate, var(--custom-text-warning, #{$light-ref-text-warning-moderate}));
  --#{$vudTokenPrefix}text-success: var(--#{$backupVudTokenPrefix}ref-text-success-moderate, var(--custom-text-success, #{$light-ref-text-success-moderate}));


  // backgrounds
  --#{$vudTokenPrefix}bg-default: var(--#{$backupVudTokenPrefix}ref-fill-surface-default, var(--#{$backupVudTokenPrefix}ref-fill-on-surface-default, var(--custom-bg-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-ref-fill-surface-default)})));
  --#{$vudTokenPrefix}bg-primary: var(--#{$backupVudTokenPrefix}ref-fill-screen-default, var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-bg-primary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-ref-fill-screen-default)})));
  --#{$vudTokenPrefix}bg-secondary: var(--#{$backupVudTokenPrefix}ref-fill-secondary-enabled, var(--#{$backupVudTokenPrefix}ref-fill-on-secondary-enabled, var(--custom-bg-secondary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-ref-fill-secondary-enabled)})));


  // shadows
  --#{$vudTokenPrefix}shadow-40: var(--#{$backupVudTokenPrefix}shadow-default-elevation-4, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-4, #{$light-shadow-default-elevation-4}));
  --#{$vudTokenPrefix}shadow-30: var(--#{$backupVudTokenPrefix}shadow-default-elevation-3, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-3, #{$light-shadow-default-elevation-3}));
  --#{$vudTokenPrefix}shadow-20: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-2, #{$light-shadow-default-elevation-2}));
  --#{$vudTokenPrefix}shadow-10: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, #{$light-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}shadow-brand: var(--#{$backupVudTokenPrefix}shadow-brand-elevation-1, #{$light-shadow-brand-elevation-1});
  --#{$vudTokenPrefix}shadow-none: none;

  --#{$vudTokenPrefix}shadow-50: var(--#{$vudTokenPrefix}shadow-40);


  // Components background color
  --#{$vudTokenPrefix}module-container-bg-color: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-module-container-bg-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)})));
  --#{$vudTokenPrefix}module-container-shadow: var(--custom-module-container-shadow, #{$light-shadow-default-elevation-1});
  --#{$vudTokenPrefix}module-container-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});
  --#{$vudTokenPrefix}module-container-divider: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-module-container-divider, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}));
  --#{$vudTokenPrefix}module-container-active-bg-color: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, var(--custom-module-container-active-bg-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-list-surface-selected-moderate)}));
  --#{$vudTokenPrefix}module-container-active-before-color: var(--#{$backupVudTokenPrefix}separator-emphasis, var(--custom-module-container-active-before-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)}));
  --#{$vudTokenPrefix}module-container-hover-bg-color: var(--#{$backupVudTokenPrefix}list-surface-hover, var(--custom-module-container-hover-bg-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-list-surface-hover)}));


  // anchor
  --#{$vudTokenPrefix}anchor-color: var(--#{$backupVudTokenPrefix}ref-text-links, var(--custom-anchor-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-ref-text-links)}));
  --#{$vudTokenPrefix}anchor-hover-color: var(--custom-anchor-hover-color, var(--#{$vudTokenPrefix}blue-60));
  --#{$vudTokenPrefix}anchor-visited-color: var(--#{$backupVudTokenPrefix}ref-text-visited, #{$light-ref-text-visited});
  --#{$vudTokenPrefix}anchor-focus-outline: var(--#{$backupVudTokenPrefix}ref-border-default-focused, var(--custom-anchor-focus-outline, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-ref-border-default-focused)}));
  --#{$vudTokenPrefix}anchor-selected-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-anchor-selected-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-default-text-default)})));
  --#{$vudTokenPrefix}anchor-disabled-color: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-anchor-disabled-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-text-disabled)}));

  // breadcrumbs
  --#{$vudTokenPrefix}breadcrumb-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-hor-default-text-emphasis)});
  --#{$vudTokenPrefix}breadcrumb-selected-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-default-text-default)});
  --#{$vudTokenPrefix}breadcrumb-disabled-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-default-text-disabled)});


  // 'mark' tag/element (highlighted text)
  --#{$vudTokenPrefix}highlighted-text-bg-color: var(--#{$backupVudTokenPrefix}ref-fill-mark-default, var(--#{$backupVudTokenPrefix}ref-fill-on-mark-default, var(--custom-highlighted-text-bg-color, #{$light-ref-fill-mark-default})));
  --#{$vudTokenPrefix}highlighted-text-color: var(--#{$backupVudTokenPrefix}mark-default-text-color, var(--#{$backupVudTokenPrefix}list-text-inverse, var(--#{$backupVudTokenPrefix}comp-list-text-inverse, var(--custom-highlighted-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-list-text-inverse)}))));


  // 'hr' tag/element
  --#{$vudTokenPrefix}divider-color: var(--#{$backupVudTokenPrefix}separator-strong, var(--custom-divider-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-separator-strong)}));
  --#{$vudTokenPrefix}divider-disabled-color: var(--#{$backupVudTokenPrefix}separator-weak, var(--custom-divider-disabled-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-separator-weak)}));
  --#{$vudTokenPrefix}fancy-divider-color: var(--#{$vudTokenPrefix}divider-color);

  --#{$vudTokenPrefix}divider-default-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-divider-default-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)})); // <- for solid divider
  --#{$vudTokenPrefix}divider-primary-color: var(--#{$backupVudTokenPrefix}separator-emphasis, var(--custom-divider-primary-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)})); // <- not present/define in figma
  --#{$vudTokenPrefix}divider-error-color: var(--#{$backupVudTokenPrefix}ref-border-funct-warning-weak, var(--#{$backupVudTokenPrefix}ref-border-on-funct-warning-weak, var(--custom-divider-error-color, #{$light-ref-border-funct-warning-weak}))); // <- not present/define in figma


  //=== d. Buttons
  //## Default button
  --#{$vudTokenPrefix}button-text-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-text-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-default, var(--custom-button-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-button-confirmatory-default-text-default)})));

  --#{$vudTokenPrefix}button-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-enabled, var(--custom-button-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-default-surface-enabled)})));
  --#{$vudTokenPrefix}button-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-enabled, var(--custom-button-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-border-enabled)})));
  --#{$vudTokenPrefix}button-shadow: var(--#{$backupVudTokenPrefix}button-default-shadow, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}button-icon-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-button-icon-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-button-confirmatory-default-icon-default)})));

  // Default button - hover state
  --#{$vudTokenPrefix}button-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-hover, var(--custom-button-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-button-confirmatory-default-surface-hover)})));
  --#{$vudTokenPrefix}button-hover-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-hover, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-hover, var(--custom-button-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-button-confirmatory-default-border-hover)})));


  // Default button - focus state
  --#{$vudTokenPrefix}button-focus-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-focused, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-focused, var(--custom-button-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-button-confirmatory-default-border-focused)})));


  // Default button - pressed state
  --#{$vudTokenPrefix}button-pressed-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-pressed, var(--custom-button-pressed-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-button-confirmatory-default-surface-pressed)})));
  --#{$vudTokenPrefix}button-pressed-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-pressed, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-pressed, var(--custom-button-pressed-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-border-pressed)})));


  // Default button - active state
  --#{$vudTokenPrefix}button-active-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-moderate, var(--custom-button-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-button-confirmatory-default-surface-selected-moderate)})));
  --#{$vudTokenPrefix}button-active-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-selected-moderate, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-button-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-border-selected-moderate)})));


  // Default button - active/selected state
  --#{$vudTokenPrefix}button-selected-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-button-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-button-confirmatory-default-surface-selected-moderate)})));


  // Default button - disabled state
  --#{$vudTokenPrefix}button-disabled-text-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-text-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-disabled, var(--custom-button-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-text-disabled)})));

  --#{$vudTokenPrefix}button-disabled-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-disabled, var(--custom-button-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-default-surface-disabled)})));
  --#{$vudTokenPrefix}button-disabled-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-disabled, var(--custom-button-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-button-confirmatory-default-border-disabled)})));

  --#{$vudTokenPrefix}button-disabled-icon-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-disabled, var(--custom-dropdown-arrow-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-icon-disabled)}));

  // Default Destructive button
  --#{$vudTokenPrefix}button-destructive-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-default, var(--custom-button-destructive-text-color, #{$light-button-destructive-default-text-default})));
  --#{$vudTokenPrefix}button-destructive-hover-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-inverse, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-destructive-default-text-inverse)}));

  --#{$vudTokenPrefix}button-destructive-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-enabled, var(--custom-button-destructive-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-destructive-default-surface-enabled)})));
  --#{$vudTokenPrefix}button-destructive-hover-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-hover, var(--custom-button-destructive-hover-bg, #{$light-button-destructive-default-surface-hover})));
  --#{$vudTokenPrefix}button-destructive-pressed-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-pressed, var(--custom-button-destructive-pressed-bg, #{$light-button-destructive-default-surface-pressed})));

  --#{$vudTokenPrefix}button-destructive-border-color: var(--#{$backupVudTokenPrefix}button-destructive-default-border-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-border-enabled, var(--custom-button-destructive-border-color, #{$light-button-destructive-default-border-enabled})));
  --#{$vudTokenPrefix}button-destructive-hover-border-color: var(--#{$backupVudTokenPrefix}button-destructive-default-border-hover, #{$light-button-destructive-default-border-hover});


  //## Primary button
  --#{$vudTokenPrefix}button-primary-text-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-text-default, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-text-default, var(--custom-button-primary-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-primary-text-default)})));

  --#{$vudTokenPrefix}button-primary-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-enabled, var(--custom-button-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-button-confirmatory-primary-surface-enabled)})));
  --#{$vudTokenPrefix}button-primary-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-enabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-enabled, var(--custom-button-primary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-button-confirmatory-primary-border-enabled)})));
  --#{$vudTokenPrefix}button-primary-shadow: var(--#{$backupVudTokenPrefix}shadow-brand-elevation-1, #{$light-shadow-brand-elevation-1});

  --#{$vudTokenPrefix}button-primary-icon: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-button-primary-icon, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-primary-icon-default)})));

  --#{$vudTokenPrefix}facet-primary-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-facet-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-default-surface-enabled)})));

  // Primary button - hover state
  --#{$vudTokenPrefix}button-primary-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-hover, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-hover, var(--custom-button-primary-hover-bg, color-mix(in oklch, black 12%, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-button-confirmatory-primary-surface-hover)}))));
  --#{$vudTokenPrefix}button-primary-hover-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-hover, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-hover, var(--custom-button-primary-hover-border-color, color-mix(in oklch, black 12%, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-button-confirmatory-primary-border-hover)}))));

  // Primary button - focus state
  --#{$vudTokenPrefix}button-primary-focus-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-focused, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-focused, var(--custom-button-primary-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-button-confirmatory-primary-border-focused)})));
  --#{$vudTokenPrefix}button-primary-focus-inner-shadow-color: var(--custom-button-primary-focus-inner-shadow-color, var(--#{$vudTokenPrefix}neutral-05));

  // Primary button - active/pressed state
  --#{$vudTokenPrefix}button-primary-active-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-pressed, var(--custom-button-primary-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-90)', $light-button-confirmatory-primary-surface-pressed)})));
  --#{$vudTokenPrefix}button-primary-active-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-pressed, var(--custom-button-primary-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-90)', $light-button-confirmatory-primary-border-pressed)})));

  // Primary button - active/selected state
  --#{$vudTokenPrefix}button-primary-selected-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-button-primary-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-80)', $light-button-confirmatory-default-surface-selected-strong)})));
  --#{$vudTokenPrefix}button-primary-selected-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-selected-strong, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-strong, var(--custom-button-primary-selected-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-80)', $light-button-confirmatory-default-border-selected-strong)})));


  // Primary Destructive button
  --#{$vudTokenPrefix}button-primary-destructive-bg: var(--#{$backupVudTokenPrefix}button-destructive-primary-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-enabled, var(--custom-button-primary-destructive-bg, #{$light-button-destructive-primary-surface-enabled})));
  --#{$vudTokenPrefix}button-primary-destructive-hover-bg: var(--#{$backupVudTokenPrefix}button-destructive-primary-surface-hover, color-mix(in oklch, black 12%, #{$light-button-destructive-primary-surface-hover}));
  --#{$vudTokenPrefix}button-primary-destructive-active-bg: var(--#{$backupVudTokenPrefix}button-destructive-primary-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-pressed, var(--custom-button-primary-destructive-active-bg, #{$light-button-destructive-primary-surface-pressed})));

  --#{$vudTokenPrefix}button-primary-destructive-hover-border-color: var(--#{$backupVudTokenPrefix}button-destructive-primary-border-hover, color-mix(in oklch, black 12%, #{$light-button-destructive-primary-border-hover}));
  --#{$vudTokenPrefix}button-primary-destructive-active-border-color: var(--#{$backupVudTokenPrefix}button-destructive-primary-border-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-border-pressed, var(--custom-button-primary-destructive-active-border-color, #{$light-button-destructive-primary-border-pressed})));


  // Button-groups
  --#{$vudTokenPrefix}button-group-selected-border-accent-color: var(--#{$backupVudTokenPrefix}separator-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)});


  //# dropdown button menu list
  --#{$vudTokenPrefix}dropdown-menu-text-color: var(--#{$backupVudTokenPrefix}list-text-default, var(--#{$backupVudTokenPrefix}comp-list-text-default, var(--custom-dropdown-menu-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-list-text-default)})));

  --#{$vudTokenPrefix}dropdown-menu-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dropdown-menu-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)})));
  --#{$vudTokenPrefix}dropdown-menu-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // dropdown button menu list - hover state
  --#{$vudTokenPrefix}dropdown-menu-hover-bg: var(--#{$backupVudTokenPrefix}list-surface-hover, var(--#{$backupVudTokenPrefix}comp-list-surface-hover, var(--custom-dropdown-menu-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-list-surface-hover)})));
  --#{$vudTokenPrefix}dropdown-menu-hover-border-color: var(--#{$backupVudTokenPrefix}list-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-list-border-hover)});

  // dropdown button menu list - active/selected state
  --#{$vudTokenPrefix}dropdown-menu-selected-text-color: var(--#{$backupVudTokenPrefix}list-text-selected, var(--#{$backupVudTokenPrefix}list-text-default, var(--#{$backupVudTokenPrefix}comp-list-text-default, var(--custom-dropdown-menu-selected-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-list-text-default)}))));

  --#{$vudTokenPrefix}dropdown-menu-selected-bg: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-list-surface-selected-moderate)});
  --#{$vudTokenPrefix}dropdown-menu-selected-border-color: var(--#{$backupVudTokenPrefix}list-border-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-list-border-selected-moderate)});

  // dropdown arrow
  --#{$vudTokenPrefix}dropdown-arrow-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-dropdown-arrow-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-button-confirmatory-default-icon-default)})));
  --#{$vudTokenPrefix}dropdown-arrow-disabled-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-disabled, var(--custom-dropdown-arrow-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-icon-disabled)})));

  --#{$vudTokenPrefix}dropdown-primary-arrow-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-dropdown-primary-arrow-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-primary-icon-default)})));
  --#{$vudTokenPrefix}dropdown-primary-arrow-disabled-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-icon-disabled, var(--custom-dropdown-arrow-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-button-confirmatory-default-icon-disabled)}));


  //# segmented control
  --#{$vudTokenPrefix}segmented-control-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-muted, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-nav-stepper-surface-muted)});
  --#{$vudTokenPrefix}segmented-control-divider-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});

  --#{$vudTokenPrefix}segmented-control-button-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-stepper-text-default)});

  --#{$vudTokenPrefix}segmented-control-button-hover-border-color: var(--#{$vudTokenPrefix}button-hover-border-color);
  --#{$vudTokenPrefix}segmented-control-button-focus-border-color: var(--#{$vudTokenPrefix}button-focus-border-color);
  --#{$vudTokenPrefix}segmented-control-button-pressed-border-color: transparent;
  --#{$vudTokenPrefix}segmented-control-button-selected-border-color: var(--#{$vudTokenPrefix}segmented-control-divider-color);

  --#{$vudTokenPrefix}segmented-control-button-bg: transparent;
  --#{$vudTokenPrefix}segmented-control-button-hover-bg: var(--#{$vudTokenPrefix}button-hover-bg);
  --#{$vudTokenPrefix}segmented-control-button-pressed-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-stepper-surface-enabled)});
  --#{$vudTokenPrefix}segmented-control-button-selected-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-stepper-surface-enabled)});


  //# wizard
  --#{$vudTokenPrefix}wizard-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-stepper-surface-enabled)});
  --#{$vudTokenPrefix}wizard-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-enabled, var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-enabled, var(--custom-wizard-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-stepper-border-enabled)})));

  --#{$vudTokenPrefix}wizard-icon-color: var(--#{$backupVudTokenPrefix}nav-stepper-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-stepper-icon-default)});

  // wizard hover
  --#{$vudTokenPrefix}wizard-hover-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-stepper-surface-hover)});
  --#{$vudTokenPrefix}wizard-hover-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-hover, var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-hover, var(--custom-wizard-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-stepper-border-hover)})));

  // wizard focus
  --#{$vudTokenPrefix}wizard-focus-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-stepper-border-focused)});

  // wizard selected
  --#{$vudTokenPrefix}wizard-selected-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-nav-stepper-surface-selected-moderate)});
  --#{$vudTokenPrefix}wizard-selected-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-selected-strong, var(--custom-wizard-selected-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-80)', $light-nav-stepper-border-selected-strong)})));
  --#{$vudTokenPrefix}wizard-checkmark-icon: var(--custom-wizard-checkmark-icon, url(#{$image-path}/wizard-checkmark.svg)); // let come back to it

  // wizard tabdrop
  --#{$vudTokenPrefix}wizard-tabdrop-color: var(--#{$backupVudTokenPrefix}nav-stepper-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-default, var(--custom-wizard-tabdrop-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-stepper-icon-default)})));


  //=== e. Icons
  // worksourface, action and social media icons
  --#{$vudTokenPrefix}icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-icon-default)}));
  --#{$vudTokenPrefix}icon-cta-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-ref-icon-emphasis)});
  --#{$vudTokenPrefix}icon-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-icon-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-icon-disabled)}));

  --visma-icon-color: var(--#{$vudTokenPrefix}icon-bg);
  --visma-icon-hover-color: var(--#{$vudTokenPrefix}icon-hover-bg);
  --visma-icon-disabled-color: var(--#{$vudTokenPrefix}icon-disabled-bg);


  // pictograms
  --#{$vudTokenPrefix}pictogram-primary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-pictogram-primary-stroke-default)}));
  --#{$vudTokenPrefix}pictogram-secondary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-secondary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-secondary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-pictogram-secondary-stroke-default)}));
  --#{$vudTokenPrefix}pictogram-tertiary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-tertiary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-tertiary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-pictogram-tertiary-stroke-default)}));

  --#{$vudTokenPrefix}pictogram-fill: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-fill-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-fill-default, #{$light-ref-pictogram-primary-fill-default}));
  --#{$vudTokenPrefix}pictogram-stroke: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-pictogram-primary-stroke-default)}));

  --#{$vudTokenPrefix}pictogram-accent-fill: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-fill-emphasis, #{$light-ref-pictogram-primary-fill-emphasis});
  --#{$vudTokenPrefix}pictogram-accent-stroke: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-ref-pictogram-primary-stroke-emphasis)});

  --#{$vudTokenPrefix}pictogram-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-pictogram-primary-stroke-default)});

  --visma-pictogram-color: var(--#{$vudTokenPrefix}pictogram-bg);
  --visma-pictogram-fill: var(--#{$vudTokenPrefix}pictogram-fill);
  --visma-pictogram-stroke: var(--#{$vudTokenPrefix}pictogram-stroke);


  // Informative icons
  --#{$vudTokenPrefix}icon-informative-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-success, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-icon-informative-success-bg, #{$light-situation-funct-icon-success})));
  --#{$vudTokenPrefix}icon-informative-error-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-error, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-icon-informative-error-bg, #{$light-situation-funct-icon-error})));
  --#{$vudTokenPrefix}icon-informative-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-warning, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-icon-informative-warning-bg, #{$light-situation-funct-icon-warning})));
  --#{$vudTokenPrefix}icon-informative-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-icon-informative-info-bg, #{$light-situation-funct-icon-emphasis})));
  --#{$vudTokenPrefix}icon-informative-help-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-icon-informative-help-bg, #{$light-situation-funct-icon-emphasis})));

  --visma-informative-success-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
  --visma-informative-error-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
  --visma-informative-warning-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
  --visma-informative-info-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);
  --visma-informative-help-color: var(--#{$vudTokenPrefix}icon-informative-help-bg);


  // Carets and close icons
  --#{$vudTokenPrefix}caret-default-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-caret-default-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-icon-default)}));
  --#{$vudTokenPrefix}caret-primary-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-caret-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-ref-icon-emphasis)}));
  --#{$vudTokenPrefix}caret-secondary-bg: var(--#{$backupVudTokenPrefix}ref-icon-inverse, var(--custom-caret-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-ref-icon-inverse)}));
  --#{$vudTokenPrefix}caret-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-caret-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-icon-disabled)}));

  --#{$vudTokenPrefix}close-icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-close-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-ref-icon-default)}));


  //=== f. Badges
  --#{$vudTokenPrefix}badge-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-situation-funct-text-default)})));
  --#{$vudTokenPrefix}badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-surface-default)})));
  --#{$vudTokenPrefix}badge-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)});

  --#{$vudTokenPrefix}badge-disabled-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-situation-funct-text-disabled)});
  --#{$vudTokenPrefix}badge-disabled-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-surface-disabled)});
  --#{$vudTokenPrefix}badge-disabled-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-situation-funct-border-disabled)});

  --#{$vudTokenPrefix}badge-success-text: var(--#{$backupVudTokenPrefix}situation-funct-text-success-weak, var(--custom-badge-success-text, #{$light-situation-funct-text-success-weak}));
  --#{$vudTokenPrefix}badge-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-success-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-moderate, var(--custom-badge-success-bg, #{$light-situation-funct-surface-success-moderate})));
  --#{$vudTokenPrefix}badge-success-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-success, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-badge-success-sm-bg, #{$light-situation-funct-icon-success})));
  --#{$vudTokenPrefix}badge-success-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-success-moderate, #{$light-situation-funct-border-success-moderate});

  --#{$vudTokenPrefix}badge-warning-text: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-weak, #{$light-situation-funct-text-warning-weak});
  --#{$vudTokenPrefix}badge-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-moderate, var(--custom-badge-warning-bg, #{$light-situation-funct-surface-warning-moderate})));
  --#{$vudTokenPrefix}badge-warning-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-warning, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-badge-warning-sm-bg, #{$light-situation-funct-icon-warning})));
  --#{$vudTokenPrefix}badge-warning-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-moderate, #{$light-situation-funct-border-warning-moderate});

  --#{$vudTokenPrefix}badge-danger-text: var(--#{$backupVudTokenPrefix}situation-funct-text-error-weak, #{$light-situation-funct-text-error-weak});
  --#{$vudTokenPrefix}badge-danger-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-moderate, var(--custom-badge-danger-bg, #{$light-situation-funct-surface-error-moderate})));
  --#{$vudTokenPrefix}badge-danger-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-error, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-badge-danger-sm-bg, #{$light-situation-funct-icon-error})));
  --#{$vudTokenPrefix}badge-danger-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-error-moderate, #{$light-situation-funct-border-error-moderate});

  --#{$vudTokenPrefix}badge-info-text: var(--#{$backupVudTokenPrefix}situation-funct-text-info-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-weak, var(--custom-badge-info-text, #{$light-situation-funct-text-info-weak})));
  --#{$vudTokenPrefix}badge-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-info-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-moderate, var(--custom-badge-info-bg, #{$light-situation-funct-surface-info-moderate})));
  --#{$vudTokenPrefix}badge-info-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-badge-info-sm-bg, #{$light-situation-funct-icon-emphasis})));
  --#{$vudTokenPrefix}badge-info-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-info-moderate, #{$light-situation-funct-border-info-moderate});


  //=== g. Labels
  --#{$vudTokenPrefix}label-text-size: var(--#{$backupVudTokenPrefix}font-size-fixed-xs, #{$light-font-size-fixed-xs});
  --#{$vudTokenPrefix}label-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-situation-funct-text-default)});

  --#{$vudTokenPrefix}label-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)});
  --#{$vudTokenPrefix}label-border-radius: var(--#{$backupVudTokenPrefix}radii-xs, #{$light-radii-xs});

  --#{$vudTokenPrefix}label-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$light-situation-funct-surface-overlay-inverse});

  --#{$vudTokenPrefix}label-disabled-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-situation-funct-text-disabled)});
  --#{$vudTokenPrefix}label-disabled-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-situation-funct-border-disabled)});
  --#{$vudTokenPrefix}label-disabled-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-default, #{$light-situation-funct-surface-overlay-default});

  --#{$vudTokenPrefix}label-info-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-info-moderate, #{$light-situation-funct-text-info-moderate});
  --#{$vudTokenPrefix}label-info-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-info-weak, #{$light-situation-funct-border-info-weak});
  --#{$vudTokenPrefix}label-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-info-weak, #{$light-situation-funct-surface-info-weak});

  --#{$vudTokenPrefix}label-success-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-success-moderate, #{$light-situation-funct-text-success-moderate});
  --#{$vudTokenPrefix}label-success-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-success-weak, #{$light-situation-funct-border-success-weak});
  --#{$vudTokenPrefix}label-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-success-weak, #{$light-situation-funct-surface-success-weak});

  --#{$vudTokenPrefix}label-warning-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-moderate, #{$light-situation-funct-text-warning-moderate});
  --#{$vudTokenPrefix}label-warning-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-weak, #{$light-situation-funct-border-warning-weak});
  --#{$vudTokenPrefix}label-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-weak, #{$light-situation-funct-surface-warning-weak});

  --#{$vudTokenPrefix}label-danger-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-error-moderate, #{$light-situation-funct-text-error-moderate});
  --#{$vudTokenPrefix}label-danger-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-error-weak, #{$light-situation-funct-border-error-weak});
  --#{$vudTokenPrefix}label-danger-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-weak, #{$light-situation-funct-surface-error-weak});


  //=== g. List groups
  --#{$vudTokenPrefix}list-group-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)});
  --#{$vudTokenPrefix}list-group-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});
  --#{$vudTokenPrefix}list-group-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}list-group-divider: var(--custom-list-group-divider, inset 0 2px 0 -1px #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});

  --#{$vudTokenPrefix}list-group-badge-text: var(--#{$backupVudTokenPrefix}list-text-default, var(--#{$backupVudTokenPrefix}comp-list-text-secondary, var(--custom-list-group-badge-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-list-text-default)})));
  --#{$vudTokenPrefix}list-group-header-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-list-group-header-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-nav-hor-brand-surface-default)})));
  --#{$vudTokenPrefix}list-group-badge-border: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-default, var(--custom-list-group-badge-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)})));
  --#{$vudTokenPrefix}list-group-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-list-group-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-surface-default)})));

  --#{$vudTokenPrefix}list-group-primary-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-list-group-primary-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-brand-text-default)})));
  --#{$vudTokenPrefix}list-group-primary-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-list-group-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-nav-hor-brand-surface-default)})));



  //=== h. Top Navigation (rename/add 'brand' variant)
  --#{$vudTokenPrefix}navigation-default-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-navigation-default-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-nav-hor-brand-surface-default)})));
  --#{$vudTokenPrefix}navigation-default-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}navbar-brand-item-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-focused, var(--custom-navbar-brand-item-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-hor-default-border-focused)}));

  --#{$vudTokenPrefix}navigation-default-second-level-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-navigation-default-second-level-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-default-surface-default)})));
  --#{$vudTokenPrefix}navigation-default-second-level-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-navigation-default-second-level-shadow, #{$light-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}navigation-default-second-level-divider: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-navigation-default-second-level-divider, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}));

  --#{$vudTokenPrefix}navigation-default-item-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-navigation-default-item-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-brand-text-default)})));
  --#{$vudTokenPrefix}navigation-default-disabled-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-brand-text-disabled)});

  --#{$vudTokenPrefix}navigation-default-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-hor-brand-border-hover)});
  --#{$vudTokenPrefix}navigation-default-item-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-focused, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-focused, var(--custom-navigation-default-item-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-nav-hor-brand-border-focused)})));

  --#{$vudTokenPrefix}navigation-default-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-hover, var(--custom-navigation-default-item-hover-bg, #{$light-nav-hor-brand-surface-hover})));
  --#{$vudTokenPrefix}navigation-default-item-active-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-selected-moderate, var(--custom-navigation-default-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-80)', $light-nav-hor-brand-surface-selected-moderate)})));

  --#{$vudTokenPrefix}navigation-default-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-navigation-default-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-brand-icon-default)})));
  --#{$vudTokenPrefix}navigation-default-disabled-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-brand-icon-disabled)});
  --#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-inverse, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-navigation-default-dropdown-open-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-hor-brand-icon-inverse)})));

  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-inverse, var(--custom-navigation-default-item-dropdown-open-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-default-text-default)})));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-navigation-default-item-dropdown-small-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-brand-text-default)})));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-content-text-secondary, var(--custom-navigation-default-item-dropdown-open-small-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-content-text-secondary)})));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-navigation-default-item-dropdown-open-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-default-surface-default)})));


  --#{$vudTokenPrefix}navigation-default-dropdown-text-color: var(--#{$backupVudTokenPrefix}content-text-default, var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-navigation-default-dropdown-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-content-text-default)})));
  --#{$vudTokenPrefix}navigation-default-dropdown-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-navigation-default-dropdown-shadow, #{$light-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-dotted: var(--#{$backupVudTokenPrefix}separator-strong, var(--custom-navigation-default-dropdown-divider-color-dotted, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-separator-strong)}));
  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-navigation-default-dropdown-divider-color-strong, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}));

  --#{$vudTokenPrefix}navigation-default-attention-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-warning, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-navigation-default-attention-bg, #{$light-situation-funct-icon-warning})));

  --#{$vudTokenPrefix}navigation-default-badge-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-navigation-default-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-situation-funct-text-default)})));
  --#{$vudTokenPrefix}navigation-default-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-navigation-default-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-surface-default)})));
  --#{$vudTokenPrefix}navigation-default-badge-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--custom-navigation-default-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)}));

  --#{$vudTokenPrefix}navigation-default-menudrop-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-navigation-default-menudrop-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)}));
  --#{$vudTokenPrefix}navigation-default-menudrop-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});
  --#{$vudTokenPrefix}navigation-default-menudrop-shadow: var(--custom-navigation-default-menudrop-shadow, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}navigation-default-hamburger-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-inverse, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-navigation-default-hamburger-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-brand-icon-default)})));
  --#{$vudTokenPrefix}navigation-default-hamburger-open-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-navigation-default-hamburger-open-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-hor-brand-icon-inverse)})));

  --#{$vudTokenPrefix}navigation-brand-search-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-nav-hor-brand-surface-enabled)});
  --#{$vudTokenPrefix}navigation-brand-search-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-hover, #{$light-nav-hor-brand-surface-hover});
  --#{$vudTokenPrefix}navigation-brand-search-disabled-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-brand-surface-disabled)});
  --#{$vudTokenPrefix}navigation-brand-search-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-hor-brand-border-enabled)});
  --#{$vudTokenPrefix}navigation-brand-search-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-hor-brand-border-hover)});
  --#{$vudTokenPrefix}navigation-brand-search-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-nav-hor-brand-border-focused)});
  --#{$vudTokenPrefix}navigation-brand-search-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-disabled-low, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-nav-hor-brand-border-disabled-low)});


  // Primary navigation (rename/add 'inverted' variant)
  --#{$vudTokenPrefix}navigation-primary-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-default,var(--custom-navigation-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-inverted-surface-default)})));

  --#{$vudTokenPrefix}navigation-primary-item-text-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-inverted-text-default)}));

  --#{$vudTokenPrefix}navigation-primary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-hover, var(--custom-navigation-primary-item-hover-bg, #{$light-nav-hor-inverted-surface-hover})));
  --#{$vudTokenPrefix}navigation-primary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-selected-moderate, var(--custom-navigation-primary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-inverted-surface-selected-moderate)})));
  --#{$vudTokenPrefix}navigation-primary-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-inverted-border-hover)});
  --#{$vudTokenPrefix}navigation-primary-item-active-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-selected, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-inverted-border-selected)});

  --#{$vudTokenPrefix}navigation-inverted-search-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-inverted-surface-enabled)});
  --#{$vudTokenPrefix}navigation-inverted-search-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-hover, #{$light-nav-hor-inverted-surface-hover});
  --#{$vudTokenPrefix}navigation-inverted-search-disabled-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-inverted-surface-disabled)});
  --#{$vudTokenPrefix}navigation-primary-search-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-inverted-border-enabled)});
  --#{$vudTokenPrefix}navigation-primary-search-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-inverted-border-hover)});
  --#{$vudTokenPrefix}navigation-primary-search-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-nav-hor-inverted-border-focused)});
  --#{$vudTokenPrefix}navigation-primary-search-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-disabled-low, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-nav-hor-inverted-border-disabled-low)});

  --#{$vudTokenPrefix}navigation-primary-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-inverted-icon-default)}));
  --#{$vudTokenPrefix}navigation-primary-dropdown-open-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-hor-inverted-icon-inverse)});


  // Secondary navigation (rename/add after depracated is in fact 'default' variant)
  --#{$vudTokenPrefix}navigation-secondary-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-navigation-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-default-surface-default)})));

  --#{$vudTokenPrefix}navigation-secondary-second-level-shadow: var(--custom-navigation-secondary-second-level-shadow, var(--#{$vudTokenPrefix}navigation-default-second-level-shadow));
  --#{$vudTokenPrefix}navigation-secondary-second-level-divider: var(--custom-navigation-secondary-second-level-divider, var(--#{$vudTokenPrefix}navigation-default-second-level-divider, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}));

  --#{$vudTokenPrefix}navigation-secondary-item-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-navigation-secondary-item-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-default-text-default)})));

  --#{$vudTokenPrefix}navigation-secondary-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-emphasis, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-emphasis, var(--custom-navigation-secondary-item-active-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-hor-default-text-emphasis)})));

  --#{$vudTokenPrefix}navigation-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-selected-moderate, var(--custom-navigation-secondary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-default-surface-selected-moderate)})));
  --#{$vudTokenPrefix}navigation-secondary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-hover, var(--custom-navigation-secondary-item-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-hor-default-surface-hover)})));
  --#{$vudTokenPrefix}navigation-secondary-item-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-focused, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-border-focused, var(--custom-navigation-secondary-item-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-hor-default-border-focused)})));
  --#{$vudTokenPrefix}navigation-secondary-item-selected-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-default-border-selected-moderate)});
  --#{$vudTokenPrefix}navigation-secondary-item-active-shadow: var(--custom-navigation-secondary-item-active-shadow, #{inset 0 #{$light-border-xl} 0px} #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-hor-default-border-selected-strong)});

  --#{$vudTokenPrefix}navigation-secondary-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-navigation-secondary-item-dropdown-small-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-hor-default-text-default)})));

  --#{$vudTokenPrefix}navigation-secondary-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-icon-default, var(--custom-navigation-secondary-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-hor-default-icon-default)}));

  --#{$vudTokenPrefix}navigation-secondary-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--custom-navigation-secondary-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-surface-default)}));
  --#{$vudTokenPrefix}navigation-secondary-badge-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)});
  --#{$vudTokenPrefix}navigation-secondary-badge-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--custom-navigation-secondary-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-situation-funct-text-default)}));

  --#{$vudTokenPrefix}navigation-secondary-menudrop-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-icon-default, var(--custom-navigation-secondary-menudrop-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-hor-default-icon-default)})));

  --#{$vudTokenPrefix}navigation-secondary-hamburger-bg: var(--custom-navigation-secondary-hamburger-bg, var(--#{$vudTokenPrefix}neutral-80));
  --#{$vudTokenPrefix}navigation-secondary-hamburger-open-bg: var(--custom-navigation-secondary-hamburger-open-bg, var(--#{$vudTokenPrefix}neutral-80));

  --#{$vudTokenPrefix}navigation-secondary-search-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-hor-default-surface-enabled)});
  --#{$vudTokenPrefix}navigation-secondary-search-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-transparent, #{$light-nav-hor-default-surface-transparent}); // need a overwite token
  --#{$vudTokenPrefix}navigation-secondary-search-disabled-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-disabled, #{$light-nav-hor-default-surface-disabled});
  --#{$vudTokenPrefix}navigation-secondary-search-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-hor-default-border-enabled)});
  --#{$vudTokenPrefix}navigation-secondary-search-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-hor-default-border-hover)});
  --#{$vudTokenPrefix}navigation-secondary-search-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-hor-default-border-focused)});
  --#{$vudTokenPrefix}navigation-secondary-search-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-disabled-low, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-nav-hor-default-border-disabled-low)});



  //=== i. Vertical navigation
  --#{$vudTokenPrefix}vertical-nav-default-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-default, var(--custom-vertical-nav-default-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-default-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-default-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--custom-vertical-nav-default-shadow, #{$light-shadow-default-elevation-2}));

  --#{$vudTokenPrefix}vertical-nav-default-item-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-vert-default-text-default)});
  --#{$vudTokenPrefix}vertical-nav-default-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-default-text-inverse)});
  --#{$vudTokenPrefix}vertical-nav-default-item-disabled-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-vert-default-text-disabled)});

  --#{$vudTokenPrefix}vertical-nav-default-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-hover, var(--custom-vertical-nav-default-item-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-vert-default-surface-hover)})));
  --#{$vudTokenPrefix}vertical-nav-default-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-vert-default-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-vert-default-border-hover)});

  --#{$vudTokenPrefix}vertical-nav-default-item-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-vertical-nav-default-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-vert-default-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-default-item-open-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-weak, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-weak, var(--custom-vertical-nav-default-item-open-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-10)', $light-nav-vert-default-surface-selected-weak)})));

  --#{$vudTokenPrefix}vertical-nav-default-sublvl-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-vertical-nav-default-sublvl-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-vert-default-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-vertical-nav-default-collapse-btn-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-vert-brand-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-surface-hover, var(--custom-vertical-nav-default-collapse-btn-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-button-confirmatory-secondary-surface-hover)}));

  --#{$vudTokenPrefix}vertical-nav-default-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-default-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-default, var(--custom-vertical-nav-default-icon-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-vert-default-icon-default)})));
  --#{$vudTokenPrefix}vertical-nav-default-active-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-default-icon-inverse, #{$light-nav-vert-default-icon-inverse});
  --#{$vudTokenPrefix}vertical-nav-default-icon-disabled-color: var(--#{$backupVudTokenPrefix}nav-vert-default-icon-disabled, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-disabled, var(--custom-vertical-nav-default-icon-disabled-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-vert-default-icon-disabled)})));

  --#{$vudTokenPrefix}vertical-nav-default-divider-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-separator, var(--custom-vertical-nav-default-divider-bg, #{$light-nav-vert-default-separator}));
  --#{$vudTokenPrefix}vertical-nav-default-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-overlay-default, var(--custom-vertical-nav-default-badge-bg, #{$light-situation-funct-surface-overlay-default})));

  --#{$vudTokenPrefix}vertical-nav-default-item-active-badge-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-weak, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-10)', $light-nav-vert-default-surface-selected-weak)});

  --#{$vudTokenPrefix}vertical-nav-default-title-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-secondary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-vert-default-text-secondary)});


  // (rename/add 'brand' variant)
  --#{$vudTokenPrefix}vertical-nav-primary-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-vertical-nav-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-vert-brand-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-primary-shadow: var(--custom-vertical-nav-primary-shadow, --#{$vudTokenPrefix}vertical-nav-default-shadow);

  --#{$vudTokenPrefix}vertical-nav-primary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-hover, var(--custom-vertical-nav-primary-item-hover-bg, #{$light-nav-vert-brand-surface-hover}));
  --#{$vudTokenPrefix}vertical-nav-brand-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-vert-brand-border-hover)});

  --#{$vudTokenPrefix}vertical-nav-primary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-selected-strong, var(--custom-vertical-nav-primary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-brand-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-primary-item-open-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-selected-weak, var(--custom-vertical-nav-primary-item-open-bg, #{$light-nav-vert-brand-surface-selected-weak}));

  --#{$vudTokenPrefix}vertical-nav-primary-item-text: var(--#{$backupVudTokenPrefix}nav-vert-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-text-default, var(--custom-vertical-nav-primary-item-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-brand-text-default)})));
  --#{$vudTokenPrefix}vertical-nav-brand-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-vert-brand-text-inverse)});

  --#{$vudTokenPrefix}vertical-nav-primary-item-disabled: var(--#{$backupVudTokenPrefix}nav-vert-brand-text-disabled, var(--custom-vertical-nav-primary-item-disabled, #{$light-nav-vert-brand-text-disabled}));

  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-icon-default, var(--custom-vertical-nav-primary-collapse-btn-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-brand-icon-default)})));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-surface-hover, var(--custom-vertical-nav-primary-collapse-btn-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $light-button-confirmatory-secondary-surface-hover)})); //
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-arrow: var(--custom-vertical-nav-primary-collapse-arrow, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-ref-icon-emphasis)}));

  --#{$vudTokenPrefix}vertical-nav-brand-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-brand-icon-default)});
  --#{$vudTokenPrefix}vertical-nav-brand-active-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-vert-brand-icon-inverse)});
  --#{$vudTokenPrefix}vertical-nav-brand-icon-disabled-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-disabled, #{$light-nav-vert-brand-icon-disabled});
 
  --#{$vudTokenPrefix}vertical-nav-brand-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$light-situation-funct-surface-overlay-inverse});
  --#{$vudTokenPrefix}vertical-nav-brand-item-active-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$light-situation-funct-surface-overlay-inverse});

  --#{$vudTokenPrefix}vertical-nav-primary-divider-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-separator, var(--custom-vertical-nav-primary-divider-bg, #{$light-nav-vert-brand-separator}));


  // (rename/add 'inverted' variant)
  --#{$vudTokenPrefix}vertical-nav-secondary-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-default, var(--custom-vertical-nav-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-vert-inverted-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-secondary-border-color: var(--custom-vertical-nav-secondary-border-color, var(--#{$vudTokenPrefix}vertical-nav-secondary-bg)); /// bg

  --#{$vudTokenPrefix}vertical-nav-secondary-item-text: var(--#{$backupVudTokenPrefix}nav-vert-inverted-text-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-inverted-text-default)}));
  --#{$vudTokenPrefix}vertical-nav-inverted-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-vert-inverted-text-inverse)});

  --#{$vudTokenPrefix}vertical-nav-inverted-item-disabled: var(--#{$backupVudTokenPrefix}nav-vert-inverted-text-disabled, #{$light-nav-vert-inverted-text-disabled});
  --#{$vudTokenPrefix}vertical-nav-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-selected-strong, var(--custom-vertical-nav-secondary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-inverted-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-open-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-selected-weak, var(--custom-vertical-nav-secondary-item-open-bg, #{$light-nav-vert-inverted-surface-selected-weak}));

  --#{$vudTokenPrefix}vertical-nav-secondary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-hover, var(--custom-vertical-nav-secondary-item-hover-bg, #{$light-nav-vert-inverted-surface-hover}));
  --#{$vudTokenPrefix}vertical-nav-inverted-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-vert-inverted-border-hover)});

  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-border-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-default, var(--custom-vertical-nav-secondary-collapsed-border-color, #{$light-nav-vert-inverted-text-disabled})); // < colapsed btn
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default,var(--custom-vertical-nav-secondary-collapse-btn-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-inverted-icon-default)})));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-hover-bg: var(--custom-vertical-nav-secondary-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}neutral-30));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-btn-hover-bg: var(--custom-vertical-nav-secondary-collapsed-btn-hover-bg, var(--#{$vudTokenPrefix}neutral-70));

  --#{$vudTokenPrefix}vertical-nav-inverted-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-vert-inverted-icon-default)});
  --#{$vudTokenPrefix}vertical-nav-inverted-active-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-vert-inverted-icon-inverse)});
  --#{$vudTokenPrefix}vertical-nav-inverted-icon-disabled-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-disabled, #{$light-nav-vert-inverted-icon-disabled});

  --#{$vudTokenPrefix}vertical-nav-inverted-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$light-situation-funct-surface-overlay-inverse});
  --#{$vudTokenPrefix}vertical-nav-inverted-item-active-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$light-situation-funct-surface-overlay-inverse});

  --#{$vudTokenPrefix}vertical-nav-inverted-divider-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-separator, var(--custom-vertical-nav-primary-divider-bg, #{$light-nav-vert-brand-separator}));


  //=== j. Modals
  --#{$vudTokenPrefix}modal-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-modal-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)})));
  --#{$vudTokenPrefix}modal-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--custom-modal-shadow, #{$light-shadow-default-elevation-2}));

  --#{$vudTokenPrefix}modal-overlay-bg: var(--custom-modal-overlay-bg, rgba(#{hex-to-rgb($primary-neutral-light)}, 0.5));
  --#{$vudTokenPrefix}modal-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});

  --#{$vudTokenPrefix}modal-text-color: var(--#{$backupVudTokenPrefix}content-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-content-text-default)});


  //=== k. Forms
  // inputs/textarea
  --#{$vudTokenPrefix}input-text-color: var(--#{$backupVudTokenPrefix}inputs-text-default, var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-input-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-inputs-text-default)})));
  --#{$vudTokenPrefix}input-placeholder-color: var(--#{$backupVudTokenPrefix}inputs-text-secondary, var(--#{$backupVudTokenPrefix}comp-inputs-text-secondary, var(--custom-input-placeholder-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-inputs-text-secondary)})));

  --#{$vudTokenPrefix}input-bg: var(--#{$backupVudTokenPrefix}inputs-surface-enabled, var(--#{$backupVudTokenPrefix}comp-inputs-surface-enabled, var(--custom-input-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-inputs-surface-enabled)})));
  --#{$vudTokenPrefix}input-border-color: var(--#{$backupVudTokenPrefix}inputs-border-enabled, var(--#{$backupVudTokenPrefix}comp-inputs-border-enabled, var(--custom-input-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-inputs-border-enabled)})));
  --#{$vudTokenPrefix}input-shadow: none;

  // inputs - hover state
  --#{$vudTokenPrefix}input-hover-bg: var(--#{$backupVudTokenPrefix}inputs-surface-hover, var(--#{$backupVudTokenPrefix}comp-inputs-surface-hover, var(--custom-input-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-inputs-surface-hover)})));
  --#{$vudTokenPrefix}input-hover-border-color: var(--#{$backupVudTokenPrefix}inputs-border-hover, var(--#{$backupVudTokenPrefix}comp-inputs-border-hover, var(--custom-input-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-inputs-border-hover)})));
  --#{$vudTokenPrefix}input-hover-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // inputs - focus state
  --#{$vudTokenPrefix}input-focus-border-color: var(--#{$backupVudTokenPrefix}inputs-border-focused, var(--#{$backupVudTokenPrefix}comp-inputs-border-focused, var(--custom-input-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-inputs-border-focused)})));
  --#{$vudTokenPrefix}input-focus-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // inputs - disabled state
  --#{$vudTokenPrefix}input-disabled-text-color: var(--#{$backupVudTokenPrefix}inputs-text-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-input-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-inputs-text-disabled)})));

  --#{$vudTokenPrefix}input-disabled-bg: var(--#{$backupVudTokenPrefix}inputs-surface-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-surface-disabled, var(--custom-input-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-inputs-surface-disabled)})));
  --#{$vudTokenPrefix}input-disabled-border-color: var(--#{$backupVudTokenPrefix}inputs-border-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-border-disabled, var(--custom-input-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-inputs-border-disabled)})));

  // error inputs
  --#{$vudTokenPrefix}input-error-text-color: var(--#{$backupVudTokenPrefix}inputs-text-error, var(--#{$backupVudTokenPrefix}comp-inputs-text-error, var(--custom-input-error-text-color, #{$light-inputs-text-error})));

  --#{$vudTokenPrefix}input-error-bg: var(--#{$backupVudTokenPrefix}inputs-surface-error, #{$light-inputs-surface-error});
  --#{$vudTokenPrefix}input-error-border-color: var(--#{$backupVudTokenPrefix}inputs-border-error, var(--#{$backupVudTokenPrefix}comp-inputs-border-error, var(--custom-input-error-border-color, #{$light-inputs-border-error})));

  // read-only inputs
  --#{$vudTokenPrefix}input-read-only-bg: var(--#{$backupVudTokenPrefix}input-read-only-bg, transparent);
  --#{$vudTokenPrefix}input-read-only-inverted-bg: var(--#{$backupVudTokenPrefix}input-read-only-inverted-bg, var(--#{$backupVudTokenPrefix}inputs-surface-muted, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-inputs-surface-muted)}));

  // labels for inputs
  --#{$vudTokenPrefix}input-label-text-color: var(--#{$backupVudTokenPrefix}inputs-text-default, var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-input-label-text-color,#{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-inputs-text-default)})));
  --#{$vudTokenPrefix}input-label-disabled-text-color: var(--#{$backupVudTokenPrefix}inputs-text-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-input-label-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-inputs-text-disabled)})));

  // inputs - primary
  --#{$vudTokenPrefix}input-primary-bg: var(--#{$backupVudTokenPrefix}search-brand-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-search-brand-surface-enabled)});
  --#{$vudTokenPrefix}input-primary-border-color: var(--#{$backupVudTokenPrefix}search-brand-border-enabled, var(--#{$backupVudTokenPrefix}comp-search-brand-border-enabled, var(--custom-input-primary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-search-brand-border-enabled)})));
  --#{$vudTokenPrefix}input-primary-shadow: var(--#{$backupVudTokenPrefix}shadow-brand-elevation-1, #{$light-shadow-brand-elevation-1});

  // inputs - primary - hover
  --#{$vudTokenPrefix}input-primary-hover-border-color: var(--#{$backupVudTokenPrefix}search-brand-border-hover, var(--#{$backupVudTokenPrefix}comp-search-brand-border-hover, var(--custom-input-primary-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-search-brand-border-hover)})));

  // inputs - primary - disabled
  --#{$vudTokenPrefix}search-field-primary-disabled-button: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-disabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-disabled, var(--custom-search-field-primary-disabled-button, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-primary-surface-disabled)})));
  --#{$vudTokenPrefix}search-field-primary-disabled-button-border: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-disabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-disabled, var(--custom-search-field-primary-disabled-button-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-button-confirmatory-primary-border-disabled)})));


  // switches
  --#{$vudTokenPrefix}switches-text-color: var(--#{$backupVudTokenPrefix}switch-text-default, var(--#{$backupVudTokenPrefix}comp-switch-text-default, var(--custom-switches-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-switch-text-default)})));

  --#{$vudTokenPrefix}switches-bg: var(--#{$backupVudTokenPrefix}switch-surface-enabled, var(--#{$backupVudTokenPrefix}comp-switch-surface-enabled, var(--custom-switches-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-switch-surface-enabled)})));
  --#{$vudTokenPrefix}switches-border-color: var(--#{$backupVudTokenPrefix}switch-border-enabled, var(--#{$backupVudTokenPrefix}comp-switch-border-enabled, var(--custom-switches-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-switch-border-enabled)})));

  --#{$vudTokenPrefix}switches-togglemark-bg: var(--#{$backupVudTokenPrefix}switch-surface-strong, var(--#{$backupVudTokenPrefix}comp-switch-surface-strong, var(--custom-switches-togglemark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-switch-surface-strong)})));
  --#{$vudTokenPrefix}switches-togglemark-border-color: var(--#{$backupVudTokenPrefix}switch-border-strong, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-switch-border-strong)});

  // switches - hover
  --#{$vudTokenPrefix}switches-hover-bg: var(--#{$backupVudTokenPrefix}switch-surface-hover, var(--#{$backupVudTokenPrefix}comp-switch-surface-hover, var(--custom-switches-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-switch-surface-hover)})));
  --#{$vudTokenPrefix}switches-hover-border-color: var(--#{$backupVudTokenPrefix}switch-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-switch-border-hover)});

  // switches - focus
  --#{$vudTokenPrefix}switches-focus-border-color: var(--#{$backupVudTokenPrefix}switch-border-focused, var(--#{$backupVudTokenPrefix}comp-switch-border-focused, var(--custom-switches-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-switch-border-focused)})));
  --#{$vudTokenPrefix}switches-focus-inner-border-color: var(--#{$backupVudTokenPrefix}border-inner-shadow, var(--custom-switches-focus-inner-border-color, #{$light-border-inner-shadow}));

  // switches - disabled
  --#{$vudTokenPrefix}switches-disabled-text-color: var(--#{$backupVudTokenPrefix}switch-text-disabled, var(--#{$backupVudTokenPrefix}comp-switch-text-disabled, var(--custom-switches-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-switch-text-disabled)})));

  --#{$vudTokenPrefix}switches-disabled-bg: var(--#{$backupVudTokenPrefix}switch-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-switches-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-switch-surface-disabled-moderate)})));
  --#{$vudTokenPrefix}switches-disabled-border-color: var(--#{$backupVudTokenPrefix}switch-border-disabled, var(--#{$backupVudTokenPrefix}comp-switch-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-switch-border-disabled)}));

  --#{$vudTokenPrefix}switches-disabled-togglemark-bg: var(--#{$backupVudTokenPrefix}switch-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-switches-disabled-togglemark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-switch-surface-disabled-moderate)})));
  --#{$vudTokenPrefix}switches-disabled-togglemark-border-color: var(--#{$backupVudTokenPrefix}switch-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-switch-border-disabled)});

  // switches - error
  --#{$vudTokenPrefix}switches-error-border-color: var(--#{$backupVudTokenPrefix}switch-border-error, #{$light-switch-border-error});

  // switches - checked
  --#{$vudTokenPrefix}switches-checked-text-color: var(--#{$backupVudTokenPrefix}switch-text-inverse, var(--#{$backupVudTokenPrefix}comp-switch-text-inverse, var(--custom-switches-checked-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-switch-text-inverse)})));

  --#{$vudTokenPrefix}switches-checked-border-color: var(--#{$backupVudTokenPrefix}switch-border-selected-strong, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-switch-border-selected-strong)});
  --#{$vudTokenPrefix}switches-checked-bg: var(--#{$backupVudTokenPrefix}switch-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-switch-surface-selected-strong, var(--custom-switches-checked-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-80)', $light-switch-surface-selected-strong)})));

  --#{$vudTokenPrefix}switches-checked-togglemark-border-color: var(--#{$backupVudTokenPrefix}switch-checked-togglemark-border-color, #{$light-switch-border-transparent}); // <- add overwite token

  // switches - hover - checked
  --#{$vudTokenPrefix}switches-checked-hover-bg: var(--#{$backupVudTokenPrefix}switch-surface-selected-strong-hover, var(--#{$backupVudTokenPrefix}-comp-switch-surface-selected-strong-hover, var(--custom-switches-checked-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-80)', $light-switch-surface-selected-strong)})));
  --#{$vudTokenPrefix}switches-checked-hover-border-color: var(--#{$backupVudTokenPrefix}switch-border-selected-strong-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $light-switch-border-selected-strong)});

  // switches - disabled - checked
  --#{$vudTokenPrefix}switches-checked-disabled-text-color: var(--#{$backupVudTokenPrefix}switch-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-switch-text-disabled)});

  --#{$vudTokenPrefix}switches-checked-disabled-bg: var(--#{$backupVudTokenPrefix}switch-surface-disabled-strong, var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-switches-checked-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-switch-surface-disabled-strong)})));


  // radio-checkbox
  --#{$vudTokenPrefix}radio-checkbox-text-color: var(--#{$backupVudTokenPrefix}toggle-text-default, var(--#{$backupVudTokenPrefix}comp-toggle-text-default, var(--custom-radio-checkbox-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-toggle-text-default)})));

  --#{$vudTokenPrefix}radio-checkbox-bg: var(--#{$backupVudTokenPrefix}toggle-surface-enabled, var(--#{$backupVudTokenPrefix}comp-toggle-surface-enabled, var(--custom-radio-checkbox-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-surface-enabled)})));
  --#{$vudTokenPrefix}radio-checkbox-border-color: var(--#{$backupVudTokenPrefix}toggle-border-enabled, var(--#{$backupVudTokenPrefix}comp-toggle-border-enabled, var(--custom-radio-checkbox-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-toggle-border-enabled)})));

  // radio-checkbox - focus
  --#{$vudTokenPrefix}radio-checkbox-focus-border-color: var(--#{$backupVudTokenPrefix}toggle-border-focused, var(--#{$backupVudTokenPrefix}comp-toggle-border-focused, var(--custom-radio-checkbox-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-toggle-border-focused)})));
  --#{$vudTokenPrefix}radio-checkbox-error-focus-border-color: var(--#{$backupVudTokenPrefix}toggle-border-error, var(--#{$backupVudTokenPrefix}comp-toggle-border-error, var(--custom-radio-checkbox-error-focus-border-color, #{$light-toggle-border-error})));

  // radio-checkbox - disabled
  --#{$vudTokenPrefix}radio-checkbox-disabled-text-color: var(--#{$backupVudTokenPrefix}toggle-text-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-text-disabled, var(--custom-radio-checkbox-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-toggle-text-disabled)})));

  --#{$vudTokenPrefix}radio-checkbox-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-toggle-surface-disabled-moderate)})));
  --#{$vudTokenPrefix}radio-checkbox-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-radio-checkbox-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-toggle-border-disabled-moderate)})));

  // radio-checkbox - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-bg: var(--#{$backupVudTokenPrefix}toggle-surface-emphasis, var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-radio-checkbox-checked-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-toggle-surface-emphasis)})));
  --#{$vudTokenPrefix}radio-checkbox-checked-border-color: var(--#{$backupVudTokenPrefix}toggle-border-emphasis, var(--#{$backupVudTokenPrefix}comp-toggle-border-emphasis, var(--custom-radio-checkbox-checked-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-toggle-border-emphasis)})));

  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--custom-radio-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-inverse)}));
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--custom-radio-checkbox-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-inverse)}));

  // radio-checkbox - disabled - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-strong, var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-radio-checkbox-checked-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-toggle-surface-disabled-strong)})));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-strong, var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-radio-checkbox-checked-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-toggle-border-disabled-strong)})));

  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-checked-disabled-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-disabled)})));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-radio-checkbox-checked-disabled-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-icon-disabled)})));


  // radio-checkbox - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-bg: var(--#{$backupVudTokenPrefix}toggle-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-toggle-surface-enabled)});
  --#{$vudTokenPrefix}radio-checkbox-outline-border-color: var(--#{$backupVudTokenPrefix}toggle-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-toggle-border-enabled)});

  // radio-checkbox disabled - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-toggle-surface-disabled-moderate)});
  --#{$vudTokenPrefix}radio-checkbox-outline-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-toggle-border-disabled-moderate)});

  // radio-checkbox - checked - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-toggle-icon-emphasis)});
  --#{$vudTokenPrefix}radio-checkbox-outline-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-toggle-icon-emphasis)});

  // radio-checkbox - disabled - checked - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-toggle-surface-disabled-moderate)});
  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-toggle-border-disabled-moderate)});

  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-checkmark-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-icon-disabled)});
  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-dot-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-ref-icon-disabled)});


  // selects/dropdowns
  --#{$vudTokenPrefix}select-arrow: var(--custom-select-arrow, url(#{$image-path}/arrow-large.svg));
  --#{$vudTokenPrefix}select-arrow-disabled: var(--custom-select-arrow-disabled, url(#{$image-path}/arrow-large-disabled.svg));

  --#{$vudTokenPrefix}select-text-color: var(--#{$backupVudTokenPrefix}inputs-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-inputs-text-default)});

  --#{$vudTokenPrefix}select-bg: var(--#{$backupVudTokenPrefix}inputs-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-inputs-surface-enabled)});
  --#{$vudTokenPrefix}select-border-color: var(--#{$backupVudTokenPrefix}inputs-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-inputs-border-enabled)});
  --#{$vudTokenPrefix}select-border-radius: var(--#{$backupVudTokenPrefix}radii-xs, #{$light-radii-xs});

  --#{$vudTokenPrefix}select-menu-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}select-item-menu-text-color: var(--#{$backupVudTokenPrefix}list-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-list-text-default)});

  --#{$vudTokenPrefix}select-item-menu-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)});
  --#{$vudTokenPrefix}select-item-menu-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});
  --#{$vudTokenPrefix}select-item-menu-border-radius: var(--#{$backupVudTokenPrefix}radii-sm, #{$light-radii-sm});

  // hover
  --#{$vudTokenPrefix}select-hover-bg: var(--#{$backupVudTokenPrefix}inputs-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-inputs-surface-hover)});
  --#{$vudTokenPrefix}select-hover-border-color: var(--#{$backupVudTokenPrefix}inputs-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-inputs-border-hover)});

  --#{$vudTokenPrefix}select-item-menu-hover-bg: var(--#{$backupVudTokenPrefix}list-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-list-surface-hover)});

  // focus
  --#{$vudTokenPrefix}select-focus-border-color: var(--#{$backupVudTokenPrefix}inputs-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-inputs-border-focused)});
  --#{$vudTokenPrefix}select-item-menu-focus-border-color: var(--#{$backupVudTokenPrefix}list-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-list-border-focused)});

  // pressed
  --#{$vudTokenPrefix}select-pressed-bg: var(--#{$backupVudTokenPrefix}inputs-surface-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-inputs-surface-pressed)});
  --#{$vudTokenPrefix}select-pressed-border-color: var(--#{$backupVudTokenPrefix}inputs-border-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-inputs-border-pressed)});

  // selected
  --#{$vudTokenPrefix}select-item-menu-selected-bg: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-list-surface-selected-moderate)});

  // disabled
  --#{$vudTokenPrefix}select-disabled-text-color: var(--#{$backupVudTokenPrefix}inputs-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-inputs-text-disabled)});

  --#{$vudTokenPrefix}select-disabled-bg: var(--#{$backupVudTokenPrefix}inputs-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-inputs-surface-disabled)});
  --#{$vudTokenPrefix}select-disabled-border-color: var(--#{$backupVudTokenPrefix}inputs-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-inputs-border-disabled)});

  --#{$vudTokenPrefix}select-item-menu-disabled-text-color: var(--#{$backupVudTokenPrefix}list-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-list-text-disabled)});


  // drop area
  --#{$vudTokenPrefix}drop-area-bg: var(--custom-drop-area-bg, var(--#{$vudTokenPrefix}neutral-10));
  --#{$vudTokenPrefix}drop-area-border-color: var(--custom-drop-area-border-color, var(--#{$vudTokenPrefix}neutral-70));
  --#{$vudTokenPrefix}drop-area-highlight-border-color: var(--custom-drop-area-highlight-border-color, var(--#{$vudTokenPrefix}blue-50));
  --#{$vudTokenPrefix}drop-area-highlight-bg: var(--custom-drop-area-highlight-bg, var(--#{$vudTokenPrefix}button-hover-bg));
  --#{$vudTokenPrefix}drop-area-focus-border-color: var(--custom-drop-area-focus-border-color, var(--#{$vudTokenPrefix}blue-50));
  --#{$vudTokenPrefix}drop-area-preview-item-bg: var(--custom-drop-area-preview-item-bg, var(--#{$vudTokenPrefix}neutral-20));
  --#{$vudTokenPrefix}drop-area-preview-item-op: var(--custom-drop-area-preview-item-op, 1);
  --#{$vudTokenPrefix}drop-area-preview-item-border-color: var(--custom-drop-area-preview-item-border-color, var(--#{$vudTokenPrefix}neutral-60));


  //=== l. Images
  --#{$vudTokenPrefix}image-no-alt-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-moderate, var(--custom-image-no-alt-border-color, #{$light-situation-funct-border-warning-moderate}));


  //=== m. Table
  --#{$vudTokenPrefix}table-bg: var(--#{$backupVudTokenPrefix}table-surface-enabled, var(--#{$backupVudTokenPrefix}comp-table-surface-enabled, var(--custom-table-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-table-surface-enabled)})));
  --#{$vudTokenPrefix}table-alt-bg: var(--#{$backupVudTokenPrefix}table-surface-muted, var(--#{$backupVudTokenPrefix}comp-table-surface-muted, var(--custom-table-alt-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-table-surface-muted)})));
  --#{$vudTokenPrefix}table-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-table-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}))); // add to hole table 1px solid 
  --#{$vudTokenPrefix}table-dotted-divider-color: var(--#{$backupVudTokenPrefix}separator-strong, var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-table-dotted-divider-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-separator-strong)})));
  --#{$vudTokenPrefix}table-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // table - cell hover
  --#{$vudTokenPrefix}table-hover-bg: var(--#{$backupVudTokenPrefix}table-surface-hover, var(--#{$backupVudTokenPrefix}comp-table-surface-hover, var(--custom-table-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-table-surface-hover)})));
  --#{$vudTokenPrefix}table-hover-border-color: var(--#{$backupVudTokenPrefix}table-border-hover, var(--#{$backupVudTokenPrefix}comp-table-border-hover, var(--custom-table-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-table-border-hover)})));

  // table - cell active/pressed state
  --#{$vudTokenPrefix}table-active-bg: var(--#{$backupVudTokenPrefix}table-surface-selected, var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-table-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-table-surface-selected)})));
  --#{$vudTokenPrefix}table-active-border-color: var(--#{$backupVudTokenPrefix}table-border-selected, var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-table-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-table-border-selected)})));

  // table - cell active/selected state
  --#{$vudTokenPrefix}table-selected-bg: var(--#{$backupVudTokenPrefix}table-surface-selected, var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-table-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-table-surface-selected)})));
  --#{$vudTokenPrefix}table-selected-border-color: var(--#{$backupVudTokenPrefix}table-border-selected, var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-table-selected-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-table-border-selected)})));

  // table - error row state
  --#{$vudTokenPrefix}table-error-text-color: var(--#{$backupVudTokenPrefix}table-text-error-moderate, var(--#{$backupVudTokenPrefix}comp-table-text-error-moderate, #{$light-table-text-error-moderate}));

  --#{$vudTokenPrefix}table-error-bg: var(--#{$backupVudTokenPrefix}table-surface-error-hover, var(--#{$backupVudTokenPrefix}comp-table-surface-error-hover, var(--custom-table-error-bg, #{$light-table-surface-error-hover})));


  //=== n. Stepper
  --#{$vudTokenPrefix}stepper-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-disabled, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-stepper-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-stepper-text-disabled)})));
  --#{$vudTokenPrefix}stepper-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-weak, var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-weak, var(--custom-stepper-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-nav-stepper-surface-weak)})));

  // stepper - active/selected state
  --#{$vudTokenPrefix}stepper-active-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-emphasis, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-emphasis, var(--custom-stepper-active-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-stepper-text-emphasis)})));
  --#{$vudTokenPrefix}stepper-active-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-surface-strong, var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-strong, var(--custom-stepper-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-stepper-surface-strong)})));
  --#{$vudTokenPrefix}stepper-active-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-inverse, var(--custom-stepper-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-stepper-icon-inverse)}));

  // stepper - visited state
  --#{$vudTokenPrefix}stepper-visited-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-success, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-success, var(--custom-stepper-visited-text-color, #{$light-nav-stepper-text-success})));
  --#{$vudTokenPrefix}stepper-visited-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-success, var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-success, var(--custom-stepper-visited-bg, #{$light-nav-stepper-icon-success})));


  //=== o. Progress Bar
  --#{$vudTokenPrefix}progress-bar-label: var(--#{$backupVudTokenPrefix}progress-text-secondary, var(--#{$backupVudTokenPrefix}comp-progress-text-secondary, var(--custom-progress-bar-label, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-progress-text-secondary)})));

  --#{$vudTokenPrefix}progress-bar-bg: var(--#{$backupVudTokenPrefix}progress-secondary-border-strong, var(--#{$backupVudTokenPrefix}comp-progress-secondary-border-strong, var(--custom-progress-bar-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-progress-secondary-border-strong)})));
  --#{$vudTokenPrefix}progress-bar-wrapper-bg: var(--#{$backupVudTokenPrefix}progress-default-border-weak, var(--#{$backupVudTokenPrefix}comp-progress-default-border-weak, var(--custom-progress-bar-wrapper-bg, #{$light-progress-default-border-weak})));


  //=== m. Alerts and toasts
  --#{$vudTokenPrefix}alert-success-text: var(--#{$backupVudTokenPrefix}situation-funct-text-success-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-success-moderate, var(--custom-alert-success-text, #{$light-situation-funct-text-success-moderate})));
  --#{$vudTokenPrefix}alert-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-success-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-weak, var(--custom-alert-success-bg, #{$light-situation-funct-surface-success-weak})));
  --#{$vudTokenPrefix}alert-success-border: var(--#{$backupVudTokenPrefix}situation-funct-border-success-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-success-weak, var(--custom-alert-success-border, #{$light-situation-funct-border-success-weak})));
  --#{$vudTokenPrefix}alert-success-shadow: var(--custom-alert-success-shadow, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-info-text: var(--#{$backupVudTokenPrefix}situation-funct-text-info-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-moderate, var(--custom-alert-info-text, #{$light-situation-funct-text-info-moderate})));
  --#{$vudTokenPrefix}alert-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-info-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-weak, var(--custom-alert-info-bg, #{$light-situation-funct-surface-info-weak})));
  --#{$vudTokenPrefix}alert-info-border: var(--#{$backupVudTokenPrefix}situation-funct-border-info-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-info-weak, var(--custom-alert-info-border, #{$light-situation-funct-border-info-weak})));
  --#{$vudTokenPrefix}alert-info-shadow: var(--custom-alert-info-shadow, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-warning-text: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-warning-moderate, var(--custom-alert-warning-text, #{$light-situation-funct-text-warning-moderate})));
  --#{$vudTokenPrefix}alert-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-weak, var(--custom-alert-warning-bg, #{$light-situation-funct-surface-warning-weak})));
  --#{$vudTokenPrefix}alert-warning-border: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-warning-weak, var(--custom-alert-warning-border, #{$light-situation-funct-border-warning-weak})));
  --#{$vudTokenPrefix}alert-warning-shadow: var(--custom-alert-warning-shadow, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-danger-text: var(--#{$backupVudTokenPrefix}situation-funct-text-error-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-error-moderate, var(--custom-alert-danger-text, #{$light-situation-funct-text-error-moderate})));
  --#{$vudTokenPrefix}alert-danger-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-weak, var(--custom-alert-danger-bg, #{$light-situation-funct-surface-error-weak})));
  --#{$vudTokenPrefix}alert-danger-border: var(--#{$backupVudTokenPrefix}situation-funct-border-error-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-error-weak, var(--custom-alert-danger-border, #{$light-situation-funct-border-error-weak})));
  --#{$vudTokenPrefix}alert-danger-shadow: var(--custom-alert-danger-shadow, #{$light-shadow-default-elevation-1});


  //=== n. Popovers and tooltips
  --#{$vudTokenPrefix}popover-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-popover-shadow, #{$light-shadow-default-elevation-1}));
  --#{$vudTokenPrefix}popover-arrow-tip: var(--custom-popover-arrow-tip, url(#{$image-path}/popover-tip.svg));


  //Tooltips
  // default variant
  --#{$vudTokenPrefix}tooltip-text: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-moderate, var(--custom-tooltip-primary-text, #{$light-situation-funct-text-warning-moderate}));

  --#{$vudTokenPrefix}tooltip-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-weak, var(--custom-tooltip-bg, #{$light-situation-funct-surface-warning-weak}));
  --#{$vudTokenPrefix}tooltip-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-weak, var(--custom-tooltip-border-color, #{$light-situation-funct-border-warning-weak}));
  --#{$vudTokenPrefix}tooltip-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--custom-tooltip-shadow, #{$light-shadow-default-elevation-2}));

  --#{$vudTokenPrefix}tooltip-arrow-tip: var(--custom-tooltip-arrow-tip, url(#{$image-path}/tooltip-tip.svg));

  // primary variant
  --#{$vudTokenPrefix}tooltip-primary-text: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--custom-tooltip-primary-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-situation-funct-text-default)}));

  --#{$vudTokenPrefix}tooltip-primary-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--custom-tooltip-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-surface-default)}));
  --#{$vudTokenPrefix}tooltip-primary-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--custom-tooltip-primary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)}));
  --#{$vudTokenPrefix}tooltip-primary-shadow: var(--custom-tooltip-primary-shadow, var(--#{$vudTokenPrefix}tooltip-shadow));

  --#{$vudTokenPrefix}tooltip-primary-arrow-tip: var(--custom-tooltip-primary-arrow-tip, url(#{$image-path}/tooltip-tip-primary.svg));

  // secondary variant
  --#{$vudTokenPrefix}tooltip-secondary-text: var(--#{$backupVudTokenPrefix}situation-funct-text-inverse, var(--custom-tooltip-secondary-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-situation-funct-text-inverse)}));

  --#{$vudTokenPrefix}tooltip-secondary-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-inverse, var(--custom-tooltip-secondary-bg, #{$light-situation-funct-surface-inverse}));
  --#{$vudTokenPrefix}tooltip-secondary-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--custom-tooltip-secondary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-situation-funct-border-default)}));
  --#{$vudTokenPrefix}tooltip-secondary-shadow: var(--custom-tooltip-secondary-shadow, var(--#{$vudTokenPrefix}tooltip-shadow));

  --#{$vudTokenPrefix}tooltip-secondary-arrow-tip: var(--custom-tooltip-secondary-arrow-tip, url(#{$image-path}/tooltip-tip-secondary.svg));

  // error variant
  --#{$vudTokenPrefix}tooltip-error-text: var(--#{$backupVudTokenPrefix}situation-funct-text-error-moderate, var(--custom-tooltip-error-text, #{$light-situation-funct-text-error-moderate}));

  --#{$vudTokenPrefix}tooltip-error-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-weak, var(--custom-tooltip-error-bg, #{$light-situation-funct-surface-error-weak}));
  --#{$vudTokenPrefix}tooltip-error-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-error-weak, var(--custom-tooltip-error-border-color, #{$light-situation-funct-border-error-weak}));
  --#{$vudTokenPrefix}tooltip-error-shadow: var(--custom-tooltip-error-shadow, var(--#{$vudTokenPrefix}tooltip-shadow));

  --#{$vudTokenPrefix}tooltip-error-arrow-tip: var(--custom-tooltip-error-arrow-tip, url(#{$image-path}/tooltip-tip-error.svg));


  //=== o. Datepicker
  --#{$vudTokenPrefix}datepicker-inline-bg: var(--#{$backupVudTokenPrefix}calendar-surface-enabled, var(--#{$backupVudTokenPrefix}comp-calendar-surface-enabled, var(--custom-datepicker-inline-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-calendar-surface-enabled)})));

  --#{$vudTokenPrefix}datepicker-arrow: var(--custom-datepicker-arrow, url(#{$image-path}/arrow-small-blue.svg)); // use poligons with proper color token

  --#{$vudTokenPrefix}datepicker-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-datepicker-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}))); // vertical divider
  --#{$vudTokenPrefix}datepicker-nonclickable-text-color: var(--#{$backupVudTokenPrefix}calendar-text-disabled, var(--custom-datepicker-nonclickable-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-calendar-text-disabled)}));

  --#{$vudTokenPrefix}datepicker-item-text: var(--#{$backupVudTokenPrefix}calendar-text-default, var(--custom-datepicker-item-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-calendar-text-default)}));

  --#{$vudTokenPrefix}datepicker-item-today-border: var(--#{$backupVudTokenPrefix}calendar-border-focused, var(--custom-datepicker-item-today-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-calendar-border-focused)}));


  --#{$vudTokenPrefix}datepicker-item-hover-bg: var(--#{$backupVudTokenPrefix}calendar-surface-hover, var(--custom-datepicker-item-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-calendar-surface-hover)}));
  --#{$vudTokenPrefix}datepicker-item-hover-range-bg: var(--#{$backupVudTokenPrefix}calendar-surface-hover, var(--custom-datepicker-item-hover-range-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-calendar-surface-hover)}));


  --#{$vudTokenPrefix}datepicker-item-selected-focus-border: var(--#{$backupVudTokenPrefix}calendar-border-focused, var(--custom-datepicker-item-selected-focus-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-calendar-border-focused)}));


  --#{$vudTokenPrefix}datepicker-item-selected-bg: var(--#{$backupVudTokenPrefix}calendar-surface-selected-strong, var(--custom-datepicker-item-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-80)', $light-calendar-surface-selected-strong)}));
  --#{$vudTokenPrefix}datepicker-item-selected-text: var(--#{$backupVudTokenPrefix}calendar-text-inverse, var(--custom-datepicker-item-selected-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-calendar-text-inverse)}));
  --#{$vudTokenPrefix}datepicker-item-selected-range-bg: var(--#{$backupVudTokenPrefix}calendar-surface-muted, var(--custom-datepicker-item-selected-range-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-calendar-surface-muted)}));


  --#{$vudTokenPrefix}datepicker-today-btn-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-confirmatory-default-surface-enabled)});
  --#{$vudTokenPrefix}datepicker-today-btn-border: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-border-enabled, var(--custom-datepicker-today-btn-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-button-confirmatory-secondary-tint-border-enabled)}));
  --#{$vudTokenPrefix}datepicker-today-btn-text: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-text-default, var(--custom-datepicker-today-btn-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-90)', $light-button-confirmatory-secondary-tint-text-default)}));

  // hover
  --#{$vudTokenPrefix}datepicker-today-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-20)', $light-button-confirmatory-secondary-tint-surface-hover)});

  // focused
  --#{$vudTokenPrefix}datepicker-today-btn-focus-btn-border: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-button-confirmatory-secondary-tint-border-focused)});


  --#{$vudTokenPrefix}datepicker-clear-btn-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-button-destructive-default-surface-enabled)});
  --#{$vudTokenPrefix}datepicker-clear-btn-border: var(--#{$backupVudTokenPrefix}button-destructive-default-border-enabled, var(--custom-datepicker-today-btn-border, #{$light-button-destructive-default-border-enabled}));
  --#{$vudTokenPrefix}datepicker-clear-btn-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-default, var(--custom-datepicker-today-btn-text, #{$light-button-destructive-default-text-default}));

  // hover
  --#{$vudTokenPrefix}datepicker-clear-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-hover, #{$light-button-destructive-default-surface-hover});
  --#{$vudTokenPrefix}datepicker-clear-btn-hover-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', light-button-destructive-default-text-inverse)});

  // focused
  --#{$vudTokenPrefix}datepicker-clear-btn-focus-btn-border: var(--#{$backupVudTokenPrefix}button-destructive-default-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-button-destructive-default-border-focused)});


  //=== p. Tabs
  --#{$vudTokenPrefix}tabs-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-default, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-tabs-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-stepper-text-default)})));
  --#{$vudTokenPrefix}tabs-hover-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $light-nav-stepper-text-emphasis)});
  --#{$vudTokenPrefix}tabs-focused-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-default, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-tabs-focused-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-nav-stepper-text-default)})));
  --#{$vudTokenPrefix}tabs-disabled-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-disabled, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-tabs-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-stepper-text-disabled)})));

  --#{$vudTokenPrefix}tabs-content-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-tabs-content-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)}));
  --#{$vudTokenPrefix}tabs-content-border-color: var(--#{$backupVudTokenPrefix}separator-emphasis, var(--#{$backupVudTokenPrefix}comp-separator-extreme, var(--custom-tabs-content-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)})));
  --#{$vudTokenPrefix}tabs-content-secondary-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-pressed, var(--custom-tabs-content-secondary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)})));
  --#{$vudTokenPrefix}tabs-content-shadow-color: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-tabs-content-shadow-color, #{$light-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}tabs-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-stepper-border-enabled)});
  --#{$vudTokenPrefix}tabs-hover-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-stepper-border-hover)});
  --#{$vudTokenPrefix}tabs-focus-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-nav-stepper-border-focused)});
  --#{$vudTokenPrefix}tabs-pressed-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-stepper-border-pressed)});
   --#{$vudTokenPrefix}tabs-selected-border-color: var(--#{$backupVudTokenPrefix}separator-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)});
  --#{$vudTokenPrefix}tabs-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-40)', $light-nav-stepper-border-disabled)});

  --#{$vudTokenPrefix}tabs-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-stepper-surface-enabled)});
  --#{$vudTokenPrefix}tabs-hover-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-nav-stepper-surface-hover)});
  --#{$vudTokenPrefix}tabs-pressed-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-nav-stepper-surface-pressed)});
  --#{$vudTokenPrefix}tabs-disabled-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-nav-stepper-surface-disabled)});

  --#{$vudTokenPrefix}tabs-dots-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-80)', $light-nav-stepper-icon-default)});
  --#{$vudTokenPrefix}tabs-dots-disabled-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-nav-stepper-icon-disabled)});


  //=== q. Helper classes
  //Borders
  --#{$vudTokenPrefix}border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)}));



  //=== r. Login page
  --#{$vudTokenPrefix}mask-bg: var(--custom-mask-bg, rgba(#{hex-to-rgb($blue-90)}, 0));

  --#{$vudTokenPrefix}cookies-alert-text: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--custom-cookies-alert-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-situation-funct-text-default)}));
  --#{$vudTokenPrefix}cookies-alert-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-cookies-alert-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)}));
  --#{$vudTokenPrefix}cookies-alert-secondary-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-muted, var(--custom-cookies-alert-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-situation-funct-surface-muted)}));

  --#{$vudTokenPrefix}password-field-icon: var(--custom-password-field-icon, url(#{$image-path}/login/password-icon.svg));

  --#{$vudTokenPrefix}visma-logo: var(--custom-visma-logo, url(#{$image-path}/visma-logo.svg));


  //=== s. Error pages
  --#{$vudTokenPrefix}error-img-opacity: var(--custom-error-img-opacity, 1);


  //=== t. Spinners
  --#{$vudTokenPrefix}spinner-default-blue: var(--custom-spinner-default-blue, url(#{$image-path}/spinners/spinner_doughnut_blue_light.svg));
  --#{$vudTokenPrefix}spinner-default-grey: var(--custom-spinner-default-grey, url(#{$image-path}/spinners/spinner_doughnut_grey_light.svg));
  --#{$vudTokenPrefix}spinner-default-green: var(--custom-spinner-default-green, url(#{$image-path}/spinners/spinner_doughnut_green_light.svg));

  --#{$vudTokenPrefix}spinner-primary-blue: var(--custom-spinner-primary-blue, url(#{$image-path}/spinners/spinner_dotted_blue_light.svg));
  --#{$vudTokenPrefix}spinner-primary-grey: var(--custom-spinner-primary-grey, url(#{$image-path}/spinners/spinner_dotted_grey_light.svg));
  --#{$vudTokenPrefix}spinner-primary-green: var(--custom-spinner-primary-green, url(#{$image-path}/spinners/spinner_dotted_green_light.svg));

  --#{$vudTokenPrefix}spinner-secondary-blue: var(--custom-spinner-secondary-blue, url(#{$image-path}/spinners/spinner_snake_blue_light.svg));
  --#{$vudTokenPrefix}spinner-secondary-grey: var(--custom-spinner-secondary-grey, url(#{$image-path}/spinners/spinner_snake_grey_light.svg));
  --#{$vudTokenPrefix}spinner-secondary-green: var(--custom-spinner-secondary-green, url(#{$image-path}/spinners/spinner_snake_green_light.svg));



  //=== u. Cards list
  --#{$vudTokenPrefix}card-list-text-color: var(--#{$backupVudTokenPrefix}list-text-default, var(--custom-card-list-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-90)', $light-list-text-default)}));

  --#{$vudTokenPrefix}card-list-bg: var(--#{$backupVudTokenPrefix}list-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-list-surface-enabled)});
  --#{$vudTokenPrefix}card-list-border-color: var(--#{$backupVudTokenPrefix}list-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-list-border-enabled)});
  --#{$vudTokenPrefix}card-list-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  --#{$vudTokenPrefix}card-list-secondary-bg: var(--custom-card-list-secondary-bg, var(--#{$vudTokenPrefix}neutral-20));

  // hover
  --#{$vudTokenPrefix}card-list-hover-bg: var(--#{$backupVudTokenPrefix}list-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $light-list-surface-hover)});
  --#{$vudTokenPrefix}card-list-hover-border-color: var(--#{$backupVudTokenPrefix}list-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $light-list-border-hover)});
  --#{$vudTokenPrefix}card-list-hover-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // selected
  --#{$vudTokenPrefix}card-list-selected-bg: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-20)', $light-list-surface-selected-moderate)});
  --#{$vudTokenPrefix}card-list-selected-border-color: var(--#{$backupVudTokenPrefix}list-border-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-60)', $light-list-border-selected-moderate)});
  --#{$vudTokenPrefix}card-list-selected-border-accent: var(--#{$backupVudTokenPrefix}separator-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)});
  --#{$vudTokenPrefix}card-list-selected-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // hero card
  --#{$vudTokenPrefix}hero-card-bg: var(--#{$backupVudTokenPrefix}content-surface-muted, var(--#{$backupVudTokenPrefix}comp-content-surface-muted, var(--custom-hero-card-bg, #{$light-content-surface-muted})));
  --#{$vudTokenPrefix}hero-card-border-color: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-hero-card-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-05)', $light-content-surface-enabled)}));
  --#{$vudTokenPrefix}hero-card-inner-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-light-30)', $light-separator-moderate)});
  --#{$vudTokenPrefix}hero-card-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-3, #{$light-shadow-default-elevation-3});

  }

// END Light mode.






//
// 3. Dark mode CSS Variables
@mixin dark-mode {
  //=== 0'. Deprecated CSS Variables
  //# Old Stepper icons tokens - unused anymore - deprecated tokens
  // anchor/breadcrumbs properties
  --#{$vudTokenPrefix}anchor-focus-outline-alt: var(--custom-dark-anchor-focus-outline-alt, var(--#{$vudTokenPrefix}neutral-05)); // deprecated (not defined in figma)


  //=== Buttons
  //## Default button
  --#{$vudTokenPrefix}button-icon: var(--custom-dark-button-icon, var(--#{$vudTokenPrefix}button-icon-color)); // <- deprecated/duplicate (use: "--#{$vudTokenPrefix}button-icon-color")
  --#{$vudTokenPrefix}button-inner-border-color: var(--custom-dark-button-inner-border-color, var(--#{$vudTokenPrefix}neutral-40)); // <- deprecated (no longer borders that ware shered in group buttons have diffrent color for that state)
  --#{$vudTokenPrefix}button-shadow-color: var(--custom-dark-button-shadow-color, rgba(0, 0, 0, 0.12)); // <- deprecated (shadows no longer have color tint for states)

  // Default button - hover state
  --#{$vudTokenPrefix}button-hover-shadow-color: var(--custom-dark-button-hover-shadow-color, rgba(0, 0, 0, 0.15)); // <- deprecated (shadows no longer have color tint for states)

  // Default button - focus state
  --#{$vudTokenPrefix}button-focus-shadow-color: var(--custom-dark-button-focus-shadow-color, rgba(0, 0, 0, 0.15)); // <- deprecated (shadows no longer have color tint for states)

  // Default button - disabled state
  --#{$vudTokenPrefix}button-disabled-inner-border-color: var(--custom-dark-button-disabled-inner-border-color, var(--#{$vudTokenPrefix}neutral-60)); // <- deprecated (no longer borders that ware shered in group buttons have diffrent color for that state)


  // //## Primary button
  --#{$vudTokenPrefix}button-primary-inner-border-color: var(--custom-dark-button-primary-inner-border-color, var(--#{$vudTokenPrefix}blue-80)); // <- deprecated (no longer borders that ware shered in group buttons have diffrent color for that state)
  --#{$vudTokenPrefix}button-primary-shadow-color: var(--custom-dark-button-primary-shadow-color, rgba(0, 0, 0, 0.15)); // <- deprecated (shadows no longer have color tint for states)


  // primary button - focus state
  --#{$vudTokenPrefix}button-primary-focus-shadow-color: var(--custom-dark-button-primary-focus-shadow-color, rgba(0, 0, 0, 0.15)); // <- deprecated (shadows no longer have color tint for states)


  //# dropdown button
  --#{$vudTokenPrefix}dropdown-menu-shadow-color: var(--custom-dark-dropdown-menu-shadow-color, rgba(0, 0, 0, 0.25)); // <- deprecated (shadows no longer have color tint for states)


  //# wizard
  --#{$vudTokenPrefix}wizard-inner-border-color: var(--custom-dark-wizard-inner-border-color, var(--#{$vudTokenPrefix}wizard-border-color)); // <- deprecated (no longer borders that ware shered in wizard have diffrent color for that state)


  //=== e. Icons
  // worksourface, action and social media icons
  --#{$vudTokenPrefix}icon-hover-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-dark-icon-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-ref-icon-emphasis)})); // <- deprecated (on their own they do not have an added hover color)


  // Carets and close icons
  --#{$vudTokenPrefix}caret-navigation-bg: var(--custom-dark-caret-navigation-bg, var(--#{$vudTokenPrefix}neutral-20)); // <-- depre
  --#{$vudTokenPrefix}close-focus-outline: var(--custom-dark-close-focus-outline, var(--#{$vudTokenPrefix}blue-50)); // <- depr [var(--#{$backupVudTokenPrefix}ref-border-default-focused, var(--custom-anchor-focus-outline, #{$light-ref-border-default-focused}))]

  //=== f. Badges
  --#{$vudTokenPrefix}badge-shadow: var(--custom-dark-badge-shadow, 0 0 0 rem(1px) #C3D0D9); // <- deprecated (not defined in figma, badges no longer have a shadow)
  --#{$vudTokenPrefix}badge-success-shadow: var(--custom-dark-badge-success-shadow, 0 0 0 rem(1px) #67A870); // <- deprecated (not defined in figma, badges no longer have a shadow)
  --#{$vudTokenPrefix}badge-danger-shadow: var(--custom-dark-badge-danger-shadow, 0 0 0 rem(1px) #DB6B7D); // <- deprecated (not defined in figma, badges no longer have a shadow)

  --#{$vudTokenPrefix}badge-info-alt-text: var(--custom-dark-badge-info-alt-text, var(--#{$vudTokenPrefix}neutral-05)); // <- deprecated (not defined in figma)
  --#{$vudTokenPrefix}badge-info-alt-bg: var(--custom-dark-badge-info-alt-bg, var(--#{$vudTokenPrefix}blue-70)); // <- deprecated (not defined in figma)


  //=== g. List groups
  --#{$vudTokenPrefix}list-group-primary-divider: var(--custom-dark-list-group-primary-divider, #{inset 0 1px 0 0} rgba(#{hex-to-rgb($neutral-dark-10)}, 0.15)); // deprecated
  --#{$vudTokenPrefix}list-group-primary-active-bg: var(--custom-dark-list-group-primary-active-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15)); // deprecated
  --#{$vudTokenPrefix}list-group-primary-hover-bg: var(--custom-dark-list-group-primary-hover-bg, rgba(#{hex-to-rgb($neutral-dark-20)}, 0.15)); // deprecated


  //=== h. Top Navigation (rename/add 'brand' variant)
  --#{$vudTokenPrefix}navigation-default-badge-shadow: var(--custom-dark-navigation-default-badge-shadow, var(--#{$vudTokenPrefix}badge-shadow)); // <- deprecated (not defined in figma, badges no longer have a shadow)


  // Primary navigation (rename/add 'inverted' variant)
  --#{$vudTokenPrefix}navigation-primary-item-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-enabled, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-enabled, #{$dark-nav-hor-brand-border-enabled})); // <- deprecated


  // Secondary navigation (rename/add after depracated is in fact 'default' variant)
  --#{$vudTokenPrefix}navigation-secondary-shadow: var(--custom-dark-navigation-secondary-shadow, var(--#{$vudTokenPrefix}navigation-default-shadow)); // the same with others
  --#{$vudTokenPrefix}navigation-secondary-item-active-font-weight: var(--custom-dark-navigation-secondary-item-active-font-weight, #{$normal}); // <- deprecated
  --#{$vudTokenPrefix}navigation-secondary-badge-shadow: var(--custom-dark-navigation-secondary-badge-shadow, var(--#{$vudTokenPrefix}badge-success-shadow)); // <- deprecated (not defined in figma, badges no longer have a shadow)


  //=== i. Vertical navigation
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-shadow: var(--custom-dark-vertical-nav-default-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.1)); // <- deprecated

  // ('brand' variant)
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-bg: var(--custom-dark-vertical-nav-primary-collapse-bg, rgba(#{hex-to-rgb($blue-90)}, 0.5)); // not connected
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-shadow: var(--custom-dark-vertical-nav-primary-collapse-btn-shadow, #{shadow(30)} rgba(#{hex-to-rgb($blue-90)}, 0.1)); // <- deprecated


  //=== k. Forms
  // inputs/textarea
  --#{$vudTokenPrefix}input-shadow-color: var(--custom-dark-input-shadow-color); // <- deprecated (shadows no longer have color tint for states)
  --#{$vudTokenPrefix}input-hover-shadow-color: var(--custom-dark-input-hover-shadow-color); // <- deprecated (shadows no longer have color tint for states)
  --#{$vudTokenPrefix}input-focus-shadow-color: var(--custom-dark-input-focus-shadow-color); // <- deprecated (shadows no longer have color tint for states)
  --#{$vudTokenPrefix}input-primary-shadow-color: var(--custom-dark-input-primary-shadow-color); // <- deprecated (shadows no longer have color tint for states)

  // switches
  --#{$vudTokenPrefix}switches-togglemark-shadow-color: var(--custom-dark-switches-togglemark-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0)); // <- deprecated

  // radio-checkbox
  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-dark-radio-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-toggle-icon-emphasis)}))); // <- deprecated (unchecked checkbox - the checkmark isn't visible)
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--comp-toggle-icon-inverse, var(--custom-dark-radio-checkbox-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-icon-inverse)}))); // <- deprecated (unchecked checkbox - the checkmark isn't visible)

  // radio-checkbox - disabled - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-radio-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-checked-disabled-radio-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-toggle-icon-disabled)}))); // <- deprecated/duplicate (use: '--#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg')

  // radio-checkbox - read only - checked
  --#{$vudTokenPrefix}radio-checkbox-read-only-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-dark-radio-checkbox-read-only-bg, #{$dark-toggle-surface-disabled-moderate}))); // <- deprecated (read-only not applying to radio or check boxes)
  --#{$vudTokenPrefix}radio-checkbox-read-only-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-read-only-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-toggle-icon-disabled)}))); // <- deprecated (read-only not applying to radio or check boxes)

  //=== l. Images
  --#{$vudTokenPrefix}thumbnail-border-color: var(--custom-dark-thumbnail-border-color, var(--#{$vudTokenPrefix}neutral-40)); // <- deprecated (not in used)

  //=== m. Table
  --#{$vudTokenPrefix}table-shadow-color: var(--custom-dark-table-shadow-color); // <- deprecated (shadows no longer have color tint for states)

  //=== n. Stepper
  --#{$vudTokenPrefix}stepper-wrapper-text-color: var(--custom-dark-stepper-wrapper-text-color, var(--#{$vudTokenPrefix}neutral-20)); // <- deprecated
  --#{$vudTokenPrefix}stepper-wrapper-bg: var(--custom-dark-stepper-wrapper-bg, var(--#{$vudTokenPrefix}neutral-70)); // <- deprecated

  // stepper - active/selected state
  --#{$vudTokenPrefix}stepper-active-shadow-color: var(--custom-dark-stepper-active-shadow-color, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.15)); // <- deprecated

  //=== o. Progress Bar
  --#{$vudTokenPrefix}progress-bar-shadow-color: var(--custom-dark-progress-bar-shadow-color, rgba(#{hex-to-rgb($primary-blue)}, 0.25)); // <- deprecated (shadows no longer have color tint for states)

  //=== o. Datepicker
  --#{$vudTokenPrefix}datepicker-popup-bg: var(--custom-dark-datepicker-popup-bg, var(--#{$vudTokenPrefix}module-container-bg-color)); // <- deprecated (use: '--#{$vudTokenPrefix}datepicker-inline-bg')

  --#{$vudTokenPrefix}datepicker-item-range-focus-shadow: var(--custom-dark-datepicker-item-range-focus-shadow, var(--#{$vudTokenPrefix}blue-60)); // <- deprecated
  --#{$vudTokenPrefix}datepicker-item-range-focus-border: var(--custom-dark-datepicker-item-range-focus-border, var(--#{$vudTokenPrefix}neutral-05)); // <- deprecated

  --#{$vudTokenPrefix}datepicker-clear-btn-text: var(--custom-dark-datepicker-clear-btn-text, var(--#{$vudTokenPrefix}red-40)); // <- deprecated (use: '--#{$vudTokenPrefix}datepicker-clear-btn-text-color')


  //=== p. Tabs
  --#{$vudTokenPrefix}tabs-normal-Ypoition:              #{rem(-350px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-disabled-Ypoition:            #{rem(-400px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-hover-Ypoition:               #{rem(-450px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-selected-Ypoition:            #{rem(-499px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-focus-Ypoition:               #{rem(-549px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-focus-hover-Ypoition:         #{rem(-599px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-selected-focused-Ypoition:    #{rem(-650px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-pressed-Ypoition:             var(--#{$vudTokenPrefix}tabs-selected-Ypoition); // <- deprecated (no longer in used)

  --#{$vudTokenPrefix}tabs-dropdown-Ypoition:            #{rem(8px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-normal-Xpoition:     #{rem(-250px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-hover-Xpoition:      #{rem(-300px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-focus-Xpoition:      #{rem(-350px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-pressed-Xpoition:    #{rem(-400px)}; // <- deprecated (no longer in used)
  --#{$vudTokenPrefix}tabs-dropdown-disabled-Xpoition:   #{rem(-450px)}; // <- deprecated (no longer in used)




  //=== a. Color palette
  //# Neutral palette
  --#{$vudTokenPrefix}neutral-90: var(--#{$vudTokenPrefix}neutral-dark-90);
  --#{$vudTokenPrefix}neutral-80: var(--#{$vudTokenPrefix}neutral-dark-80);
  --#{$vudTokenPrefix}neutral-70: var(--#{$vudTokenPrefix}neutral-dark-70);
  --#{$vudTokenPrefix}neutral-60: var(--#{$vudTokenPrefix}neutral-dark-60);
  --#{$vudTokenPrefix}neutral-50: var(--#{$vudTokenPrefix}neutral-dark-50);
  --#{$vudTokenPrefix}neutral-40: var(--#{$vudTokenPrefix}neutral-dark-40);
  --#{$vudTokenPrefix}neutral-30: var(--#{$vudTokenPrefix}neutral-dark-30);
  --#{$vudTokenPrefix}neutral-20: var(--#{$vudTokenPrefix}neutral-dark-20);
  --#{$vudTokenPrefix}neutral-10: var(--#{$vudTokenPrefix}neutral-dark-10);
  --#{$vudTokenPrefix}neutral-05: var(--#{$vudTokenPrefix}neutral-dark-05);

  --#{$vudTokenPrefix}primary-neutral: var(--#{$vudTokenPrefix}neutral-dark-70);


  //### Brand neutral palette
  --#{$vudTokenPrefix}brand-palette-neutral-90: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-90, #{$dark-brand-neutral-dark-90});
  --#{$vudTokenPrefix}brand-palette-neutral-80: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-80, #{$dark-brand-neutral-dark-80});
  --#{$vudTokenPrefix}brand-palette-neutral-70: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-70, #{$dark-brand-neutral-dark-70});
  --#{$vudTokenPrefix}brand-palette-neutral-60: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-60, #{$dark-brand-neutral-dark-60});
  --#{$vudTokenPrefix}brand-palette-neutral-50: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-50, #{$dark-brand-neutral-dark-50});
  --#{$vudTokenPrefix}brand-palette-neutral-40: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-40, #{$dark-brand-neutral-dark-40});
  --#{$vudTokenPrefix}brand-palette-neutral-30: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-30, #{$dark-brand-neutral-dark-30});
  --#{$vudTokenPrefix}brand-palette-neutral-20: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-20, #{$dark-brand-neutral-dark-20});
  --#{$vudTokenPrefix}brand-palette-neutral-10: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-10, #{$dark-brand-neutral-dark-10});
  --#{$vudTokenPrefix}brand-palette-neutral-05: var(--#{$vudTokenPrefix}brand-palette-neutral-dark-05, #{$dark-brand-neutral-dark-05});


  //=== b. Base body properties
  // Set site background color
  --#{$vudTokenPrefix}root-background-color: var(--#{$backupVudTokenPrefix}app-background-color, var(--#{$backupVudTokenPrefix}ref-fill-screen-default, var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-dark-root-background-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-80)', $dark-ref-fill-screen-default)}))));

  --#{$vudTokenPrefix}scrollbar-track-bg: var(--#{$backupVudTokenPrefix}scrollbar-track-background-color, var(--#{$backupVudTokenPrefix}scrollbar-surface-muted, #{$dark-scrollbar-surface-muted}));
  --#{$vudTokenPrefix}scrollbar-thumb-bg: var(--#{$backupVudTokenPrefix}scrollbar-thumb-background-color, var(--#{$backupVudTokenPrefix}scrollbar-surface-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-scrollbar-surface-default)}));
  --#{$vudTokenPrefix}scrollbar-thumb-drag-bg: var(--#{$backupVudTokenPrefix}scrollbar-thumb-drag-background-color, var(--#{$backupVudTokenPrefix}scrollbar-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-scrollbar-surface-enabled)}));


  // body properties
  --#{$vudTokenPrefix}body-font-color: var(--#{$backupVudTokenPrefix}body-text-color, var(--#{$backupVudTokenPrefix}content-text-default, var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-dark-body-font-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-content-text-default)}))));
  --#{$vudTokenPrefix}titles-font-color: var(--#{$backupVudTokenPrefix}titles-text-color, var(--#{$backupVudTokenPrefix}content-text-default, var(--custom-dark-titles-font-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-content-text-default)})));


  //=== c. Typography
  // body text
  --#{$vudTokenPrefix}text-default: var(--#{$backupVudTokenPrefix}ref-text-default, var(--custom-dark-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-ref-text-default)}));
  --#{$vudTokenPrefix}text-alt: var(--#{$backupVudTokenPrefix}ref-text-inverse, var(--custom-dark-text-alt, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-ref-text-inverse)}));
  --#{$vudTokenPrefix}text-primary: var(--#{$backupVudTokenPrefix}ref-text-emphasis, var(--custom-dark-text-primary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-ref-text-emphasis)}));
  --#{$vudTokenPrefix}text-secondary: var(--#{$backupVudTokenPrefix}ref-text-secondary, var(--custom-dark-text-secondary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-ref-text-secondary)}));
  --#{$vudTokenPrefix}text-disabled: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-dark-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-ref-text-disabled)}));
  --#{$vudTokenPrefix}text-error: var(--#{$backupVudTokenPrefix}ref-text-error-moderate, var(--custom-dark-text-error, #{$dark-ref-text-error-moderate}));
  --#{$vudTokenPrefix}text-warning: var(--#{$backupVudTokenPrefix}ref-text-warning-moderate, var(--custom-dark-text-warning, #{$dark-ref-text-warning-moderate}));
  --#{$vudTokenPrefix}text-success: var(--#{$backupVudTokenPrefix}ref-text-success-moderate, var(--custom-dark-text-success, #{$dark-ref-text-success-moderate}));
 

  // backgrounds
  --#{$vudTokenPrefix}bg-default: var(--#{$backupVudTokenPrefix}ref-fill-surface-default, var(--#{$backupVudTokenPrefix}ref-fill-on-surface-default, var(--custom-dark-bg-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-ref-fill-surface-default)})));
  --#{$vudTokenPrefix}bg-primary: var(--#{$backupVudTokenPrefix}ref-fill-screen-default, var(--#{$backupVudTokenPrefix}ref-fill-on-screen-default, var(--custom-dark-bg-primary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-80)', $dark-ref-fill-screen-default)})));
  --#{$vudTokenPrefix}bg-secondary: var(--#{$backupVudTokenPrefix}ref-fill-secondary-enabled, var(--#{$backupVudTokenPrefix}ref-fill-on-secondary-enabled, var(--custom-dark-bg-secondary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-ref-fill-secondary-enabled)})));


  // shadows
  --#{$vudTokenPrefix}shadow-40: var(--#{$backupVudTokenPrefix}shadow-default-elevation-4, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-4, #{$dark-shadow-default-elevation-4}));
  --#{$vudTokenPrefix}shadow-30: var(--#{$backupVudTokenPrefix}shadow-default-elevation-3, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-3, #{$dark-shadow-default-elevation-3}));
  --#{$vudTokenPrefix}shadow-20: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-2, #{$dark-shadow-default-elevation-2}));
  --#{$vudTokenPrefix}shadow-10: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--#{$backupVudTokenPrefix}ref-shadow-default-elevation-1, #{$dark-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}shadow-brand: var(--#{$backupVudTokenPrefix}shadow-brand-elevation-1, #{$dark-shadow-brand-elevation-1});
  --#{$vudTokenPrefix}shadow-none: none;

  --#{$vudTokenPrefix}shadow-50: var(--#{$vudTokenPrefix}shadow-40);


  // Components background color
  --#{$vudTokenPrefix}module-container-bg-color: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-module-container-bg-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)})));
  --#{$vudTokenPrefix}module-container-shadow: var(--custom-dark-module-container-shadow, #{$dark-shadow-default-elevation-1});
  --#{$vudTokenPrefix}module-container-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});
  --#{$vudTokenPrefix}module-container-divider: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-dark-module-container-divider, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}));
  --#{$vudTokenPrefix}module-container-active-bg-color: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, var(--custom-dark-module-container-active-bg-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-selected-moderate)}));
  --#{$vudTokenPrefix}module-container-active-before-color: var(--#{$backupVudTokenPrefix}separator-emphasis, var(--custom-dark-module-container-active-before-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $dark-separator-emphasis)}));
  --#{$vudTokenPrefix}module-container-hover-bg-color: var(--#{$backupVudTokenPrefix}list-surface-hover, var(--custom-dark-module-container-hover-bg-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-hover)}));


  // anchor
  --#{$vudTokenPrefix}anchor-color: var(--#{$backupVudTokenPrefix}ref-text-links, var(--custom-dark-anchor-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-ref-text-links)}));
  --#{$vudTokenPrefix}anchor-hover-color: var(--custom-dark-anchor-hover-color, var(--#{$vudTokenPrefix}blue-20));
  --#{$vudTokenPrefix}anchor-visited-color: var(--#{$backupVudTokenPrefix}ref-text-visited, #{$dark-ref-text-visited});
  --#{$vudTokenPrefix}anchor-selected-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-dark-anchor-selected-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-default-text-default)})));
  --#{$vudTokenPrefix}anchor-disabled-color: var(--#{$backupVudTokenPrefix}ref-text-disabled, var(--custom-dark-anchor-disabled-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-ref-text-disabled)}));
  --#{$vudTokenPrefix}anchor-focus-outline: var(--#{$backupVudTokenPrefix}ref-border-default-focused, var(--custom-dark-anchor-focus-outline, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-ref-border-default-focused)}));


  // breadcrumbs
  --#{$vudTokenPrefix}breadcrumb-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-nav-hor-default-text-emphasis)});
  --#{$vudTokenPrefix}breadcrumb-selected-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-default-text-default)});
  --#{$vudTokenPrefix}breadcrumb-disabled-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-hor-default-text-disabled)});


  // 'mark' tag/element (highlighted text)
  --#{$vudTokenPrefix}highlighted-text-bg-color: var(--#{$backupVudTokenPrefix}ref-fill-mark-default, var(--#{$backupVudTokenPrefix}ref-fill-on-mark-default, var(--custom-dark-highlighted-text-bg-color, #{$dark-ref-fill-mark-default})));
  --#{$vudTokenPrefix}highlighted-text-color: var(--#{$backupVudTokenPrefix}mark-default-text-color, var(--#{$backupVudTokenPrefix}list-text-inverse, var(--#{$backupVudTokenPrefix}comp-list-text-inverse, var(--custom-dark-highlighted-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-list-text-inverse)}))));
 

  // 'hr' tag/element
  --#{$vudTokenPrefix}divider-color: var(--#{$backupVudTokenPrefix}separator-strong, var(--custom-dark-divider-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-separator-strong)}));
  --#{$vudTokenPrefix}divider-disabled-color: var(--#{$backupVudTokenPrefix}separator-weak, var(--custom-dark-divider-disabled-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-separator-weak)}));
  --#{$vudTokenPrefix}fancy-divider-color: var(--#{$vudTokenPrefix}divider-color);

  --#{$vudTokenPrefix}divider-default-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-dark-divider-default-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)})); // <- for solid divider
  --#{$vudTokenPrefix}divider-primary-color: var(--#{$backupVudTokenPrefix}separator-emphasis, var(--custom-dark-divider-primary-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $dark-separator-emphasis)})); // <- not present/define in figma
  --#{$vudTokenPrefix}divider-error-color: var(--#{$backupVudTokenPrefix}ref-border-funct-warning-weak, var(--#{$backupVudTokenPrefix}ref-border-on-funct-warning-weak, var(--custom-dark-divider-error-color, #{$dark-ref-border-funct-warning-weak}))); // <- not present/define in figma


  //=== d. Buttons
  //## Default button
  --#{$vudTokenPrefix}button-text-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-text-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-default, var(--custom-dark-button-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-button-confirmatory-default-text-default)})));

  --#{$vudTokenPrefix}button-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-enabled, var(--custom-dark-button-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-confirmatory-default-surface-enabled)})));
  --#{$vudTokenPrefix}button-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-enabled, var(--custom-dark-button-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-button-confirmatory-default-border-enabled)})));
  --#{$vudTokenPrefix}button-shadow: var(--#{$backupVudTokenPrefix}button-default-shadow, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}button-icon-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-dark-button-icon-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-default-icon-default)})));


  // default button - hover state
  --#{$vudTokenPrefix}button-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-hover, var(--custom-dark-button-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-button-confirmatory-default-surface-hover)})));
  --#{$vudTokenPrefix}button-hover-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-hover, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-hover, var(--custom-dark-button-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-button-confirmatory-default-border-hover)})));


  // default button - focus state
  --#{$vudTokenPrefix}button-focus-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-focused, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-focused, var(--custom-dark-button-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-default-border-focused)})));


  // default button - pressed state
  --#{$vudTokenPrefix}button-pressed-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-moderate, var(--custom-dark-button-pressed-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-button-confirmatory-default-surface-pressed)})));
  --#{$vudTokenPrefix}button-pressed-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-pressed, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-dark-button-pressed-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-button-confirmatory-default-border-pressed)})));


  // default button - active state
  --#{$vudTokenPrefix}button-active-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-selected-moderate, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-dark-button-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-button-confirmatory-default-border-selected-moderate)})));
  --#{$vudTokenPrefix}button-active-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-moderate, var(--custom-dark-button-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-button-confirmatory-default-surface-selected-moderate)})));


  // default button - active/selected state
  --#{$vudTokenPrefix}button-selected-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-moderate, var(--custom-dark-button-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-button-confirmatory-default-surface-selected-moderate)})));


  // default button - disabled state
  --#{$vudTokenPrefix}button-disabled-text-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-text-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-text-disabled, var(--custom-dark-button-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-button-confirmatory-default-text-disabled)})));

  --#{$vudTokenPrefix}button-disabled-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-disabled, var(--custom-dark-button-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-confirmatory-default-surface-disabled)})));
  --#{$vudTokenPrefix}button-disabled-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-disabled, var(--custom-dark-button-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-button-confirmatory-default-border-disabled)})));

  --#{$vudTokenPrefix}button-disabled-icon-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-disabled, var(--custom-dark-dropdown-arrow-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-button-confirmatory-default-icon-disabled)}));


  // Default Destructive button
  --#{$vudTokenPrefix}button-destructive-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-default, var(--custom-dark-button-destructive-text-color, #{$dark-button-destructive-default-text-default})));
  --#{$vudTokenPrefix}button-destructive-hover-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-inverse, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-button-destructive-default-text-inverse)}));

  --#{$vudTokenPrefix}button-destructive-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-enabled, var(--custom-dark-button-destructive-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-destructive-default-surface-enabled)})));
  --#{$vudTokenPrefix}button-destructive-hover-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-hover, var(--custom-dark-button-destructive-hover-bg, #{$dark-button-destructive-default-surface-hover})));
  --#{$vudTokenPrefix}button-destructive-pressed-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-surface-pressed, var(--custom-dark-button-destructive-pressed-bg, #{$dark-button-destructive-default-surface-pressed})));

  --#{$vudTokenPrefix}button-destructive-border-color: var(--#{$backupVudTokenPrefix}button-destructive-default-border-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-destructive-border-enabled, var(--custom-dark-button-destructive-border-color, #{$dark-button-destructive-default-border-enabled})));
  --#{$vudTokenPrefix}button-destructive-hover-border-color: var(--#{$backupVudTokenPrefix}button-destructive-default-border-hover, #{$dark-button-destructive-default-border-hover});


  //## Primary button
  --#{$vudTokenPrefix}button-primary-text-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-text-default, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-text-default, var(--custom-dark-button-primary-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-button-confirmatory-primary-text-default)})));

  --#{$vudTokenPrefix}button-primary-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-enabled, var(--custom-dark-button-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $dark-button-confirmatory-primary-surface-enabled)})));
  --#{$vudTokenPrefix}button-primary-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-enabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-enabled, var(--custom-dark-button-primary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-50)', $dark-button-confirmatory-primary-border-enabled)})));
  --#{$vudTokenPrefix}button-primary-shadow: var(--#{$backupVudTokenPrefix}shadow-brand-elevation-1, #{$dark-shadow-brand-elevation-1});

  --#{$vudTokenPrefix}button-primary-icon: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-dark-button-primary-icon, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-primary-icon-default)})));

  --#{$vudTokenPrefix}facet-primary-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-dark-facet-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-confirmatory-default-surface-enabled)})));

  // Primary button - hover state
  --#{$vudTokenPrefix}button-primary-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-hover, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-hover, var(--custom-dark-button-primary-hover-bg, color-mix(in oklch, black 12%, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $dark-button-confirmatory-primary-surface-hover)}))));
  --#{$vudTokenPrefix}button-primary-hover-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-hover, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-hover, var(--custom-dark-button-primary-hover-border-color, color-mix(in oklch, black 12%, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-40)', $dark-button-confirmatory-primary-border-hover)}))));

  // primary button - focus state
  --#{$vudTokenPrefix}button-primary-focus-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-focused, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-focused, var(--custom-dark-button-primary-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-primary-border-focused)})));
  --#{$vudTokenPrefix}button-primary-focus-inner-shadow-color: var(--custom-dark-button-primary-focus-inner-shadow-color, transparent);

  // primary button - active/pressed state
  --#{$vudTokenPrefix}button-primary-active-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-pressed, var(--custom-dark-button-primary-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-90)', $dark-button-confirmatory-primary-surface-pressed)})));
  --#{$vudTokenPrefix}button-primary-active-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-pressed, var(--custom-dark-button-primary-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-50)', $dark-button-confirmatory-primary-border-pressed)})));

  // primary button - active/selected state
  --#{$vudTokenPrefix}button-primary-selected-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-surface-selected-strong, var(--custom-dark-button-primary-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-80)', $dark-button-confirmatory-default-surface-selected-strong)})));
  --#{$vudTokenPrefix}button-primary-selected-border-color: var(--#{$backupVudTokenPrefix}button-confirmatory-default-border-selected-strong, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-border-selected-strong, var(--custom-dark-button-primary-selected-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-40)', $dark-button-confirmatory-default-border-selected-strong)})));


  // Primary Destructive button
  --#{$vudTokenPrefix}button-primary-destructive-bg: var(--#{$backupVudTokenPrefix}button-destructive-primary-surface-enabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-enabled, var(--custom-dark-button-primary-destructive-bg, #{$dark-button-destructive-primary-surface-enabled})));
  --#{$vudTokenPrefix}button-primary-destructive-hover-bg: var(--#{$backupVudTokenPrefix}button-destructive-primary-surface-hover, color-mix(in oklch, black 12%, #{$dark-button-destructive-primary-surface-hover}));
  --#{$vudTokenPrefix}button-primary-destructive-active-bg: var(--#{$backupVudTokenPrefix}button-destructive-primary-surface-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-surface-pressed, var(--custom-dark-button-primary-destructive-active-bg, #{$dark-button-destructive-primary-surface-pressed})));

  --#{$vudTokenPrefix}button-primary-destructive-hover-border-color: var(--#{$backupVudTokenPrefix}button-destructive-primary-border-hover, color-mix(in oklch, black 12%, #{$dark-button-destructive-primary-border-hover}));
  --#{$vudTokenPrefix}button-primary-destructive-active-border-color: var(--#{$backupVudTokenPrefix}button-destructive-primary-border-pressed, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-destructive-border-pressed, var(--custom-dark-button-primary-destructive-active-border-color, #{$dark-button-destructive-primary-border-pressed})));

  // Button-groups
  --#{$vudTokenPrefix}button-group-selected-border-accent-color: var(--#{$backupVudTokenPrefix}separator-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $light-separator-emphasis)});


  //# dropdown button menu list
  --#{$vudTokenPrefix}dropdown-menu-text-color: var(--#{$backupVudTokenPrefix}list-text-default, var(--#{$backupVudTokenPrefix}comp-list-text-default, var(--custom-dark-dropdown-menu-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-list-text-default)})));


  --#{$vudTokenPrefix}dropdown-menu-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-dropdown-menu-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)})));
  --#{$vudTokenPrefix}dropdown-menu-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  // dropdown button menu list - hover state
  --#{$vudTokenPrefix}dropdown-menu-hover-bg: var(--#{$backupVudTokenPrefix}list-surface-hover, var(--#{$backupVudTokenPrefix}comp-list-surface-hover, var(--custom-dark-dropdown-menu-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-hover)})));
  --#{$vudTokenPrefix}dropdown-menu-hover-border-color: var(--#{$backupVudTokenPrefix}list-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-list-border-hover)});

  // dropdown button menu list - active/selected state
  --#{$vudTokenPrefix}dropdown-menu-selected-text-color: var(--#{$backupVudTokenPrefix}list-text-selected, var(--#{$backupVudTokenPrefix}list-text-default, var(--#{$backupVudTokenPrefix}comp-list-text-default, var(--custom-dark-dropdown-menu-selected-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-list-text-default)}))));

  --#{$vudTokenPrefix}dropdown-menu-selected-bg: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-selected-moderate)});
  --#{$vudTokenPrefix}dropdown-menu-selected-border-color: var(--#{$backupVudTokenPrefix}list-border-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-list-border-selected-moderate)});

  // dropdown arrow
  --#{$vudTokenPrefix}dropdown-arrow-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-default, var(--custom-dark-dropdown-arrow-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-default-icon-default)})));
  --#{$vudTokenPrefix}dropdown-arrow-disabled-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-icon-disabled, var(--#{$backupVudTokenPrefix}comp-btn-default-on-confimatory-icon-disabled, var(--custom-dark-dropdown-arrow-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-button-confirmatory-default-icon-disabled)})));

  --#{$vudTokenPrefix}dropdown-primary-arrow-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-icon-default, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-icon-default, var(--custom-dark-dropdown-primary-arrow-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-primary-icon-default)})));
  --#{$vudTokenPrefix}dropdown-primary-arrow-disabled-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-icon-disabled, var(--custom-dark-dropdown-arrow-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-button-confirmatory-default-icon-disabled)}));


  //# segmented control
  --#{$vudTokenPrefix}segmented-control-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-muted, #{$dark-nav-stepper-surface-muted});
  --#{$vudTokenPrefix}segmented-control-divider-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});

  --#{$vudTokenPrefix}segmented-control-button-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-stepper-text-default)});

  --#{$vudTokenPrefix}segmented-control-button-hover-border-color: var(--#{$vudTokenPrefix}button-hover-border-color);
  --#{$vudTokenPrefix}segmented-control-button-focus-border-color: var(--#{$vudTokenPrefix}button-focus-border-color);
  --#{$vudTokenPrefix}segmented-control-button-pressed-border-color: transparent;
  --#{$vudTokenPrefix}segmented-control-button-selected-border-color: var(--#{$vudTokenPrefix}segmented-control-divider-color);

  --#{$vudTokenPrefix}segmented-control-button-bg: transparent;
  --#{$vudTokenPrefix}segmented-control-button-hover-bg: var(--#{$vudTokenPrefix}button-hover-bg);
  --#{$vudTokenPrefix}segmented-control-button-pressed-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-stepper-surface-enabled)});
  --#{$vudTokenPrefix}segmented-control-button-selected-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-stepper-surface-enabled)});


  //# wizard
  --#{$vudTokenPrefix}wizard-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-stepper-surface-enabled)});
  --#{$vudTokenPrefix}wizard-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-enabled, var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-enabled, var(--custom-dark-wizard-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-nav-stepper-border-enabled)})));

  --#{$vudTokenPrefix}wizard-icon-color: var(--#{$backupVudTokenPrefix}nav-stepper-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-stepper-icon-default)});

  // wizard hover
  --#{$vudTokenPrefix}wizard-hover-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-stepper-surface-hover)});
  --#{$vudTokenPrefix}wizard-hover-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-hover, var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-hover, var(--custom-dark-wizard-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-stepper-border-hover)})));


  // wizard focus
  --#{$vudTokenPrefix}wizard-focus-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-stepper-border-focused)});

  // wizard selected
  --#{$vudTokenPrefix}wizard-selected-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-stepper-surface-selected-moderate)});
  --#{$vudTokenPrefix}wizard-selected-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-stepper-border-selected-strong, var(--custom-dark-wizard-selected-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-40)', $dark-nav-stepper-border-selected-strong)})));
  --#{$vudTokenPrefix}wizard-checkmark-icon: var(--custom-dark-wizard-checkmark-icon, url(#{$image-path}/wizard-checkmark-dark.svg)); // let come back to it

  // wizard tabdrop
  --#{$vudTokenPrefix}wizard-tabdrop-color: var(--#{$backupVudTokenPrefix}nav-stepper-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-default, var(--custom-dark-wizard-tabdrop-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-stepper-icon-default)})));



  //=== e. Icons
  // worksourface, action and social media icons
  --#{$vudTokenPrefix}icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-dark-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-ref-icon-default)}));
  --#{$vudTokenPrefix}icon-cta-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-ref-icon-emphasis)});
  --#{$vudTokenPrefix}icon-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-dark-icon-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-ref-icon-disabled)}));

  --visma-icon-color: var(--#{$vudTokenPrefix}icon-bg);
  --visma-icon-hover-color: var(--#{$vudTokenPrefix}icon-hover-bg);
  --visma-icon-disabled-color: var(--#{$vudTokenPrefix}icon-disabled-bg);


  // pictograms
  --#{$vudTokenPrefix}pictogram-primary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-ref-pictogram-primary-stroke-default)}));
  --#{$vudTokenPrefix}pictogram-secondary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-secondary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-secondary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-ref-pictogram-secondary-stroke-default)}));
  --#{$vudTokenPrefix}pictogram-tertiary-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-tertiary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-tertiary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-ref-pictogram-tertiary-stroke-default)}));

  --#{$vudTokenPrefix}pictogram-fill: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-fill-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-fill-default, #{$dark-ref-pictogram-primary-fill-default}));
  --#{$vudTokenPrefix}pictogram-stroke: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-default, var(--#{$backupVudTokenPrefix}ref-pictogram-on-primary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-ref-pictogram-primary-stroke-default)}));

  --#{$vudTokenPrefix}pictogram-accent-fill: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-fill-emphasis, #{$dark-ref-pictogram-primary-fill-emphasis});
  --#{$vudTokenPrefix}pictogram-accent-stroke: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-ref-pictogram-primary-stroke-emphasis)});

  --#{$vudTokenPrefix}pictogram-bg: var(--#{$backupVudTokenPrefix}ref-pictogram-primary-stroke-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-ref-pictogram-primary-stroke-default)});

  --visma-pictogram-color: var(--#{$vudTokenPrefix}pictogram-bg);
  --visma-pictogram-fill: var(--#{$vudTokenPrefix}pictogram-fill);
  --visma-pictogram-stroke: var(--#{$vudTokenPrefix}pictogram-stroke);


  // Informative icons
  --#{$vudTokenPrefix}icon-informative-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-success, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-dark-icon-informative-success-bg, #{$dark-situation-funct-icon-success})));
  --#{$vudTokenPrefix}icon-informative-error-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-error, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-dark-icon-informative-error-bg, #{$dark-situation-funct-icon-error})));
  --#{$vudTokenPrefix}icon-informative-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-warning, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-dark-icon-informative-warning-bg, #{$dark-situation-funct-icon-warning})));
  --#{$vudTokenPrefix}icon-informative-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-dark-icon-informative-info-bg, #{$dark-situation-funct-icon-emphasis})));
  --#{$vudTokenPrefix}icon-informative-help-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-dark-icon-informative-help-bg, #{$dark-situation-funct-icon-emphasis})));

  --visma-informative-success-color: var(--#{$vudTokenPrefix}icon-informative-success-bg);
  --visma-informative-error-color: var(--#{$vudTokenPrefix}icon-informative-error-bg);
  --visma-informative-warning-color: var(--#{$vudTokenPrefix}icon-informative-warning-bg);
  --visma-informative-info-color: var(--#{$vudTokenPrefix}icon-informative-info-bg);
  --visma-informative-help-color: var(--#{$vudTokenPrefix}icon-informative-help-bg);


  // Carets and close icons
  --#{$vudTokenPrefix}caret-default-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-dark-caret-default-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-ref-icon-default)}));
  --#{$vudTokenPrefix}caret-primary-bg: var(--#{$backupVudTokenPrefix}ref-icon-emphasis, var(--custom-dark-caret-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-ref-icon-emphasis)}));
  --#{$vudTokenPrefix}caret-secondary-bg: var(--#{$backupVudTokenPrefix}ref-icon-inverse, var(--custom-dark-caret-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-ref-icon-inverse)}));
  --#{$vudTokenPrefix}caret-disabled-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, var(--custom-dark-caret-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-ref-icon-disabled)}));

  --#{$vudTokenPrefix}close-icon-bg: var(--#{$backupVudTokenPrefix}ref-icon-default, var(--custom-dark-close-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-ref-icon-default)}));


  //=== f. Badges
  --#{$vudTokenPrefix}badge-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-dark-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-default)})));
  --#{$vudTokenPrefix}badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-dark-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-situation-funct-surface-default)})));
  --#{$vudTokenPrefix}badge-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)});

  --#{$vudTokenPrefix}badge-disabled-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-disabled)});
  --#{$vudTokenPrefix}badge-disabled-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-situation-funct-surface-disabled)});
  --#{$vudTokenPrefix}badge-disabled-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-situation-funct-border-disabled)});

  --#{$vudTokenPrefix}badge-success-text: var(--#{$backupVudTokenPrefix}situation-funct-text-success-weak, var(--custom-dark-badge-success-text, #{$dark-situation-funct-text-success-weak}));
  --#{$vudTokenPrefix}badge-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-success-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-moderate, var(--custom-dark-badge-success-bg, #{$dark-situation-funct-surface-success-moderate})));
  --#{$vudTokenPrefix}badge-success-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-success, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-success, var(--custom-dark-badge-success-sm-bg, #{$dark-situation-funct-icon-success})));
  --#{$vudTokenPrefix}badge-success-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-success-moderate, #{$dark-situation-funct-border-success-moderate});

  --#{$vudTokenPrefix}badge-warning-text: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-weak, #{$dark-situation-funct-text-warning-weak});
  --#{$vudTokenPrefix}badge-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-moderate, var(--custom-dark-badge-warning-bg, #{$dark-situation-funct-surface-warning-moderate})));
  --#{$vudTokenPrefix}badge-warning-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-warning, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-dark-badge-warning-sm-bg, #{$dark-situation-funct-icon-warning})));
  --#{$vudTokenPrefix}badge-warning-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'vud-funct-warning-50)', $dark-situation-funct-border-warning-moderate)});

  --#{$vudTokenPrefix}badge-danger-text: var(--#{$backupVudTokenPrefix}situation-funct-text-error-weak, #{$dark-situation-funct-text-error-weak});
  --#{$vudTokenPrefix}badge-danger-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-moderate, var(--custom-dark-badge-danger-bg, #{$dark-situation-funct-surface-error-moderate})));
  --#{$vudTokenPrefix}badge-danger-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-error, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-error, var(--custom-dark-badge-danger-sm-bg, #{$dark-situation-funct-icon-error})));
  --#{$vudTokenPrefix}badge-danger-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-error-moderate, #{$dark-situation-funct-border-error-moderate});

  --#{$vudTokenPrefix}badge-info-text: var(--#{$backupVudTokenPrefix}situation-funct-text-info-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-weak, var(--custom-dark-badge-info-text, #{$dark-situation-funct-text-info-weak})));
  --#{$vudTokenPrefix}badge-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-info-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-moderate, var(--custom-dark-badge-info-bg, #{$dark-situation-funct-surface-info-moderate})));
  --#{$vudTokenPrefix}badge-info-sm-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-emphasis, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-emphasis, var(--custom-dark-badge-info-sm-bg, #{$dark-situation-funct-icon-emphasis})));
  --#{$vudTokenPrefix}badge-info-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-info-moderate, #{$dark-situation-funct-border-info-moderate});


  //=== g. Labels
  --#{$vudTokenPrefix}label-text-size: var(--#{$backupVudTokenPrefix}font-size-fixed-xs, #{$dark-font-size-fixed-xs});
  --#{$vudTokenPrefix}label-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-default)});

  --#{$vudTokenPrefix}label-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)});
  --#{$vudTokenPrefix}label-border-radius: var(--#{$backupVudTokenPrefix}radii-xs, #{$dark-radii-xs});

  --#{$vudTokenPrefix}label-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$dark-situation-funct-surface-overlay-inverse});

  --#{$vudTokenPrefix}label-disabled-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-disabled)});
  --#{$vudTokenPrefix}label-disabled-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-situation-funct-border-disabled)});
  --#{$vudTokenPrefix}label-disabled-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-default, #{$dark-situation-funct-surface-overlay-default});

  --#{$vudTokenPrefix}label-info-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-info-moderate, #{$dark-situation-funct-text-info-moderate});
  --#{$vudTokenPrefix}label-info-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-info-weak, #{$dark-situation-funct-border-info-weak});
  --#{$vudTokenPrefix}label-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-info-weak, #{$dark-situation-funct-surface-info-weak});

  --#{$vudTokenPrefix}label-success-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-success-moderate, #{$dark-situation-funct-text-success-moderate});
  --#{$vudTokenPrefix}label-success-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-success-weak, #{$dark-situation-funct-border-success-weak});
  --#{$vudTokenPrefix}label-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-success-weak, #{$dark-situation-funct-surface-success-weak});

  --#{$vudTokenPrefix}label-warning-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-moderate, #{$dark-situation-funct-text-warning-moderate});
  --#{$vudTokenPrefix}label-warning-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-weak, #{$dark-situation-funct-border-warning-weak});
  --#{$vudTokenPrefix}label-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-weak, #{$dark-situation-funct-surface-warning-weak});

  --#{$vudTokenPrefix}label-danger-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-error-moderate, #{$dark-situation-funct-text-error-moderate});
  --#{$vudTokenPrefix}label-danger-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-error-weak, #{$dark-situation-funct-border-error-weak});
  --#{$vudTokenPrefix}label-danger-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-weak, #{$dark-situation-funct-surface-error-weak});


  //=== g. List groups
  --#{$vudTokenPrefix}list-group-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)});
  --#{$vudTokenPrefix}list-group-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});
  --#{$vudTokenPrefix}list-group-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}list-group-divider: var(--custom-dark-list-group-divider, inset 0 2px 0 -1px #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});

  --#{$vudTokenPrefix}list-group-badge-text: var(--#{$backupVudTokenPrefix}list-text-default, var(--#{$backupVudTokenPrefix}comp-list-text-secondary, var(--custom-dark-list-group-badge-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-list-text-default)})));
  --#{$vudTokenPrefix}list-group-header-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-dark-list-group-header-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-nav-hor-brand-surface-default)})));
  --#{$vudTokenPrefix}list-group-badge-border: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-default, var(--custom-dark-list-group-badge-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)})));
  --#{$vudTokenPrefix}list-group-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-dark-list-group-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-situation-funct-surface-default)})));

  --#{$vudTokenPrefix}list-group-primary-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-dark-list-group-primary-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-brand-text-default)})));
  --#{$vudTokenPrefix}list-group-primary-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-dark-list-group-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-nav-hor-brand-surface-default)})));



  //=== h. Top Navigation (rename/add 'brand' variant)
  --#{$vudTokenPrefix}navigation-default-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-default, var(--custom-dark-navigation-default-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-nav-hor-brand-surface-default)})));
  --#{$vudTokenPrefix}navigation-default-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}navbar-brand-item-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-focused, var(--custom-dark-navbar-brand-item-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-default-border-focused)}));

  --#{$vudTokenPrefix}navigation-default-second-level-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-dark-navigation-default-second-level-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-default-surface-default)})));
  --#{$vudTokenPrefix}navigation-default-second-level-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-dark-navigation-default-second-level-shadow, #{$dark-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}navigation-default-second-level-divider: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-dark-navigation-default-second-level-divider, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}));


  --#{$vudTokenPrefix}navigation-default-item-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-dark-navigation-default-item-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-brand-text-default)})));
  --#{$vudTokenPrefix}navigation-default-disabled-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-hor-brand-text-disabled)});

  --#{$vudTokenPrefix}navigation-default-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $dark-nav-hor-brand-border-hover)});
  --#{$vudTokenPrefix}navigation-default-item-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-focused, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-border-focused, var(--custom-dark-navigation-default-item-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-brand-border-focused)})));

  --#{$vudTokenPrefix}navigation-default-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-hover, var(--custom-dark-navigation-default-item-hover-bg, #{$dark-nav-hor-brand-surface-hover})));
  --#{$vudTokenPrefix}navigation-default-item-active-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-surface-selected-moderate, var(--custom-dark-navigation-default-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-nav-hor-brand-surface-selected-moderate)})));

  --#{$vudTokenPrefix}navigation-default-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-dark-navigation-default-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-brand-icon-default)})));
  --#{$vudTokenPrefix}navigation-default-disabled-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-hor-brand-icon-disabled)});
  --#{$vudTokenPrefix}navigation-default-dropdown-open-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-inverse, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-dark-navigation-default-dropdown-open-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-brand-icon-inverse)})));

  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-inverse, var(--custom-dark-navigation-default-item-dropdown-open-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-default-text-default)})));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-text-default, var(--custom-dark-navigation-default-item-dropdown-small-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-brand-text-default)})));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-small-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-content-text-secondary, var(--custom-dark-navigation-default-item-dropdown-open-small-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-content-text-secondary)})));
  --#{$vudTokenPrefix}navigation-default-item-dropdown-open-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-dark-navigation-default-item-dropdown-open-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-default-surface-default)})));

  --#{$vudTokenPrefix}navigation-default-dropdown-text-color: var(--#{$backupVudTokenPrefix}content-text-default, var(--#{$backupVudTokenPrefix}comp-content-text-default, var(--custom-dark-navigation-default-dropdown-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-content-text-default)})));
  --#{$vudTokenPrefix}navigation-default-dropdown-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-dark-navigation-default-dropdown-shadow, #{$dark-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-dotted: var(--#{$backupVudTokenPrefix}separator-strong, var(--custom-dark-navigation-default-dropdown-divider-color-dotted, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-separator-strong)}));
  --#{$vudTokenPrefix}navigation-default-dropdown-divider-color-strong: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-dark-navigation-default-dropdown-divider-color-strong, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}));

  --#{$vudTokenPrefix}navigation-default-attention-bg: var(--#{$backupVudTokenPrefix}situation-funct-icon-warning, var(--#{$backupVudTokenPrefix}comp-situation-funct-icon-warning, var(--custom-dark-navigation-default-attention-bg, #{$dark-situation-funct-icon-warning})));

  --#{$vudTokenPrefix}navigation-default-badge-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-default, var(--custom-dark-navigation-default-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-default)})));
  --#{$vudTokenPrefix}navigation-default-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-default, var(--custom-dark-navigation-default-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-situation-funct-surface-default)})));
  --#{$vudTokenPrefix}navigation-default-badge-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--custom-dark-navigation-default-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)}));

  --#{$vudTokenPrefix}navigation-default-menudrop-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-dark-navigation-default-menudrop-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)}));
  --#{$vudTokenPrefix}navigation-default-menudrop-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});
  --#{$vudTokenPrefix}navigation-default-menudrop-shadow: var(--custom-dark-navigation-default-menudrop-shadow, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}navigation-default-hamburger-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-inverse, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-default, var(--custom-dark-navigation-default-hamburger-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-brand-icon-default)})));
  --#{$vudTokenPrefix}navigation-default-hamburger-open-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-brand-icon-inverse, var(--custom-dark-navigation-default-hamburger-open-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-brand-icon-inverse)})));

  --#{$vudTokenPrefix}navigation-brand-search-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-nav-hor-brand-surface-enabled)});
  --#{$vudTokenPrefix}navigation-brand-search-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-hover, #{$dark-nav-hor-brand-surface-hover});
  --#{$vudTokenPrefix}navigation-brand-search-disabled-bg: var(--#{$backupVudTokenPrefix}nav-hor-brand-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-brand-surface-disabled)});
  --#{$vudTokenPrefix}navigation-brand-search-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $dark-nav-hor-brand-border-enabled)});
  --#{$vudTokenPrefix}navigation-brand-search-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $dark-nav-hor-brand-border-hover)});
  --#{$vudTokenPrefix}navigation-brand-search-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-brand-border-focused)});
  --#{$vudTokenPrefix}navigation-brand-search-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-hor-brand-border-disabled-low, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-brand-border-disabled-low)});


  // Primary navigation (rename/add 'inverted' variant)
  --#{$vudTokenPrefix}navigation-primary-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-default,var(--custom-dark-navigation-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-inverted-surface-default)})));

  --#{$vudTokenPrefix}navigation-primary-item-text-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-hor-inverted-text-default)}));

  --#{$vudTokenPrefix}navigation-primary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-hover, var(--custom-dark-navigation-primary-item-hover-bg, #{$dark-nav-hor-inverted-surface-hover})));
  --#{$vudTokenPrefix}navigation-primary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-surface-selected-moderate, var(--custom-dark-navigation-primary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-inverted-surface-selected-moderate)})));
  --#{$vudTokenPrefix}navigation-primary-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-inverted-border-hover)});
  --#{$vudTokenPrefix}navigation-primary-item-active-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-selected, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-inverted-border-selected)});

  --#{$vudTokenPrefix}navigation-inverted-search-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-inverted-surface-enabled)});
  --#{$vudTokenPrefix}navigation-inverted-search-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-hover, #{$dark-nav-hor-inverted-surface-hover});
  --#{$vudTokenPrefix}navigation-inverted-search-disabled-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-inverted-surface-disabled)});
  --#{$vudTokenPrefix}navigation-primary-search-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-nav-hor-inverted-border-enabled)});
  --#{$vudTokenPrefix}navigation-primary-search-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-inverted-border-hover)});
  --#{$vudTokenPrefix}navigation-primary-search-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-inverted-border-focused)});
  --#{$vudTokenPrefix}navigation-primary-search-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-hor-inverted-border-disabled-low, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-inverted-border-disabled-low)});

  --#{$vudTokenPrefix}navigation-primary-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-inverted-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-hor-inverted-icon-default)}));
  --#{$vudTokenPrefix}navigation-primary-dropdown-open-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-inverted-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-inverted-icon-inverse)});


  // Secondary navigation (rename/add after depracated is in fact 'default' variant)
  --#{$vudTokenPrefix}navigation-secondary-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-default, var(--custom-dark-navigation-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-default-surface-default)})));

  --#{$vudTokenPrefix}navigation-secondary-second-level-shadow: var(--custom-dark-navigation-secondary-second-level-shadow, var(--#{$vudTokenPrefix}navigation-default-second-level-shadow));
  --#{$vudTokenPrefix}navigation-secondary-second-level-divider: var(--custom-dark-navigation-secondary-second-level-divider, var(--#{$vudTokenPrefix}navigation-default-second-level-divider, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}));

  --#{$vudTokenPrefix}navigation-secondary-item-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-dark-navigation-secondary-item-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-default-text-default)})));

  --#{$vudTokenPrefix}navigation-secondary-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-emphasis, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-emphasis, var(--custom-dark-navigation-secondary-item-active-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-nav-hor-default-text-emphasis)})));

  --#{$vudTokenPrefix}navigation-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-selected-moderate, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-selected-moderate, var(--custom-dark-navigation-secondary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-default-surface-selected-moderate)})));
  --#{$vudTokenPrefix}navigation-secondary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-surface-hover, var(--custom-dark-navigation-secondary-item-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-default-surface-hover)})));
  --#{$vudTokenPrefix}navigation-secondary-item-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-focused, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-border-focused, var(--custom-dark-navigation-secondary-item-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-default-border-focused)})));
  --#{$vudTokenPrefix}navigation-secondary-item-selected-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-nav-hor-default-border-selected-moderate)});
  --#{$vudTokenPrefix}navigation-secondary-item-active-shadow: var(--custom-dark-navigation-secondary-item-active-shadow, #{inset 0 #{$dark-border-xl} 0px} #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-default-border-selected-strong)});

  --#{$vudTokenPrefix}navigation-secondary-item-dropdown-small-text-color: var(--#{$backupVudTokenPrefix}nav-hor-default-text-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-text-default, var(--custom-dark-navigation-secondary-item-dropdown-small-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-hor-default-text-default)})));

  --#{$vudTokenPrefix}navigation-secondary-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-icon-default, var(--custom-dark-navigation-secondary-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-default-icon-default)}));

  --#{$vudTokenPrefix}navigation-secondary-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--custom-dark-navigation-secondary-badge-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-situation-funct-surface-default)}));
  --#{$vudTokenPrefix}navigation-secondary-badge-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)});
  --#{$vudTokenPrefix}navigation-secondary-badge-text-color: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--custom-dark-navigation-secondary-badge-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-default)}));

  --#{$vudTokenPrefix}navigation-secondary-menudrop-icon-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-hor-default-icon-default, var(--custom-dark-navigation-secondary-menudrop-icon-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-default-icon-default)})));

  --#{$vudTokenPrefix}navigation-secondary-hamburger-bg: var(--custom-dark-navigation-secondary-hamburger-bg, var(--#{$vudTokenPrefix}neutral-80));
  --#{$vudTokenPrefix}navigation-secondary-hamburger-open-bg: var(--custom-dark-navigation-secondary-hamburger-open-bg, var(--#{$vudTokenPrefix}neutral-80));

  --#{$vudTokenPrefix}navigation-secondary-search-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-hor-default-surface-enabled)});
  --#{$vudTokenPrefix}navigation-secondary-search-hover-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-transparent, #{$dark-nav-hor-default-surface-transparent}); // need a overwite token
  --#{$vudTokenPrefix}navigation-secondary-search-disabled-bg: var(--#{$backupVudTokenPrefix}nav-hor-default-surface-disabled, #{$dark-nav-hor-default-surface-disabled});
  --#{$vudTokenPrefix}navigation-secondary-search-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-nav-hor-default-border-enabled)});
  --#{$vudTokenPrefix}navigation-secondary-search-hover-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-hor-default-border-hover)});
  --#{$vudTokenPrefix}navigation-secondary-search-focus-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-hor-default-border-focused)});
  --#{$vudTokenPrefix}navigation-secondary-search-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-hor-default-border-disabled-low, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-hor-default-border-disabled-low)});



  //=== i. Vertical navigation
  --#{$vudTokenPrefix}vertical-nav-default-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-default, var(--custom-dark-vertical-nav-default-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-vert-default-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-default-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--custom-dark-vertical-nav-default-shadow, #{$dark-shadow-default-elevation-2}));

  --#{$vudTokenPrefix}vertical-nav-default-item-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-vert-default-text-default)});
  --#{$vudTokenPrefix}vertical-nav-default-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-vert-default-text-inverse)});
  --#{$vudTokenPrefix}vertical-nav-default-item-disabled-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-default-text-disabled)});

  --#{$vudTokenPrefix}vertical-nav-default-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-hover, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-hover, var(--custom-dark-vertical-nav-default-item-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-vert-default-surface-hover)})));
  --#{$vudTokenPrefix}vertical-nav-default-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-vert-default-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-vert-default-border-hover)});

  --#{$vudTokenPrefix}vertical-nav-default-item-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-dark-vertical-nav-default-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-nav-vert-default-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-default-item-open-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-weak, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-weak, var(--custom-dark-vertical-nav-default-item-open-bg, #{$dark-nav-vert-default-surface-selected-weak})));

  --#{$vudTokenPrefix}vertical-nav-default-sublvl-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-surface-selected-strong, var(--custom-dark-vertical-nav-default-sublvl-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-nav-vert-default-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-dark-vertical-nav-default-collapse-btn-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-nav-vert-brand-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-default-collapse-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-surface-hover, var(--custom-dark-vertical-nav-default-collapse-btn-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-button-confirmatory-secondary-surface-hover)}));

  --#{$vudTokenPrefix}vertical-nav-default-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-default-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-default, var(--custom-dark-vertical-nav-default-icon-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-vert-default-icon-default)})));
  --#{$vudTokenPrefix}vertical-nav-default-active-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-default-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-vert-default-icon-inverse)});
  --#{$vudTokenPrefix}vertical-nav-default-icon-disabled-color: var(--#{$backupVudTokenPrefix}nav-vert-default-icon-disabled, var(--#{$backupVudTokenPrefix}comp-nav-vert-default-icon-disabled, var(--custom-dark-vertical-nav-default-icon-disabled-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-default-icon-disabled)})));

  --#{$vudTokenPrefix}vertical-nav-default-divider-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-separator, var(--custom-dark-vertical-nav-default-divider-bg, #{$dark-nav-vert-default-separator}));
  --#{$vudTokenPrefix}vertical-nav-default-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-default, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-overlay-default, var(--custom-dark-vertical-nav-default-badge-bg, #{$dark-situation-funct-surface-overlay-default})));

  --#{$vudTokenPrefix}vertical-nav-default-item-active-badge-bg: var(--#{$backupVudTokenPrefix}nav-vert-default-surface-selected-weak, #{$dark-nav-vert-default-surface-selected-weak});

  --#{$vudTokenPrefix}vertical-nav-default-title-text-color: var(--#{$backupVudTokenPrefix}nav-vert-default-text-secondary, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-vert-default-text-secondary)});


  // (rename/add 'brand' variant)
  --#{$vudTokenPrefix}vertical-nav-primary-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, var(--custom-dark-vertical-nav-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-nav-vert-brand-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-primary-shadow: var(--custom-dark-vertical-nav-primary-shadow, --#{$vudTokenPrefix}vertical-nav-default-shadow);

  --#{$vudTokenPrefix}vertical-nav-primary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-hover, var(--custom-dark-vertical-nav-primary-item-hover-bg, #{$dark-nav-vert-brand-surface-hover}));
  --#{$vudTokenPrefix}vertical-nav-brand-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-10)', $dark-nav-vert-brand-border-hover)});

  --#{$vudTokenPrefix}vertical-nav-primary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-selected-strong, var(--custom-dark-vertical-nav-primary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-vert-brand-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-primary-item-open-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-surface-selected-weak, var(--custom-dark-vertical-nav-primary-item-open-bg, #{$dark-nav-vert-brand-surface-selected-weak}));

  --#{$vudTokenPrefix}vertical-nav-primary-item-text: var(--#{$backupVudTokenPrefix}nav-vert-brand-text-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-text-default, var(--custom-dark-vertical-nav-primary-item-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-vert-brand-text-default)})));
  --#{$vudTokenPrefix}vertical-nav-brand-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-vert-brand-text-inverse)});

  --#{$vudTokenPrefix}vertical-nav-primary-item-disabled: var(--#{$backupVudTokenPrefix}nav-vert-brand-text-disabled, var(--custom-dark-vertical-nav-primary-item-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-brand-text-disabled)}));

  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-icon-default, var(--custom-dark-vertical-nav-primary-collapse-btn-bg, #{$dark-nav-vert-brand-icon-default})));
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-surface-hover, var(--custom-dark-vertical-nav-primary-collapse-btn-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-button-confirmatory-secondary-surface-hover)})); //
  --#{$vudTokenPrefix}vertical-nav-primary-collapse-arrow: var(--custom-dark-vertical-nav-primary-collapse-arrow, var(--#{$backupVudTokenPrefix}comp-nav-vert-brand-surface-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-ref-icon-emphasis)}));

  --#{$vudTokenPrefix}vertical-nav-brand-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-vert-brand-icon-default)});
  --#{$vudTokenPrefix}vertical-nav-brand-active-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-vert-brand-icon-inverse)});
  --#{$vudTokenPrefix}vertical-nav-brand-icon-disabled-color: var(--#{$backupVudTokenPrefix}nav-vert-brand-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-brand-icon-disabled)});

  --#{$vudTokenPrefix}vertical-nav-brand-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$dark-situation-funct-surface-overlay-inverse});
  --#{$vudTokenPrefix}vertical-nav-brand-item-active-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$dark-situation-funct-surface-overlay-inverse});

  --#{$vudTokenPrefix}vertical-nav-primary-divider-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-separator, var(--custom-dark-vertical-nav-primary-divider-bg, #{$dark-nav-vert-brand-separator}));


  // (rename/add 'inverted' variant)
  --#{$vudTokenPrefix}vertical-nav-secondary-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-default, var(--custom-dark-vertical-nav-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-vert-inverted-surface-default)})));
  --#{$vudTokenPrefix}vertical-nav-secondary-border-color: var(--custom-dark-vertical-nav-secondary-border-color, var(--#{$vudTokenPrefix}vertical-nav-secondary-bg)); /// bg

  --#{$vudTokenPrefix}vertical-nav-secondary-item-text: var(--#{$backupVudTokenPrefix}nav-vert-inverted-text-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-vert-inverted-text-default)}));
  --#{$vudTokenPrefix}vertical-nav-inverted-item-active-text-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-vert-inverted-text-inverse)});

  --#{$vudTokenPrefix}vertical-nav-inverted-item-disabled: var(--#{$backupVudTokenPrefix}nav-vert-inverted-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-inverted-text-disabled)});
  --#{$vudTokenPrefix}vertical-nav-secondary-item-active-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-surface-selected-strong, var(--custom-dark-vertical-nav-secondary-item-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-vert-inverted-surface-selected-strong)})));
  --#{$vudTokenPrefix}vertical-nav-secondary-item-open-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-selected-weak, var(--custom-dark-vertical-nav-secondary-item-open-bg, #{$dark-nav-vert-inverted-surface-selected-weak}));

  --#{$vudTokenPrefix}vertical-nav-secondary-item-hover-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-hover, var(--custom-dark-vertical-nav-secondary-item-hover-bg, #{$dark-nav-vert-inverted-surface-hover}));
  --#{$vudTokenPrefix}vertical-nav-inverted-item-hover-border-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-vert-inverted-border-hover)});

  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-border-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-surface-default, var(--custom-dark-vertical-nav-secondary-collapsed-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-inverted-text-disabled)})); // < colapsed btn
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-bg: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-default, var(--#{$backupVudTokenPrefix}comp-nav-vert-inverted-icon-default,var(--custom-dark-vertical-nav-secondary-collapse-btn-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-vert-inverted-icon-default)})));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapse-btn-hover-bg: var(--custom-dark-vertical-nav-secondary-collapse-btn-hover-bg, var(--#{$vudTokenPrefix}blue-60));
  --#{$vudTokenPrefix}vertical-nav-secondary-collapsed-btn-hover-bg: var(--custom-dark-vertical-nav-secondary-collapsed-btn-hover-bg, var(--#{$vudTokenPrefix}blue-60));

  --#{$vudTokenPrefix}vertical-nav-inverted-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-vert-inverted-icon-default)});
  --#{$vudTokenPrefix}vertical-nav-inverted-active-icon-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-vert-inverted-icon-inverse)});
  --#{$vudTokenPrefix}vertical-nav-inverted-icon-disabled-color: var(--#{$backupVudTokenPrefix}nav-vert-inverted-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-vert-inverted-icon-disabled)});

  --#{$vudTokenPrefix}vertical-nav-inverted-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$dark-situation-funct-surface-overlay-inverse});
  --#{$vudTokenPrefix}vertical-nav-inverted-item-active-badge-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-overlay-inverse, #{$dark-situation-funct-surface-overlay-inverse});

  --#{$vudTokenPrefix}vertical-nav-inverted-divider-bg: var(--#{$backupVudTokenPrefix}nav-vert-brand-separator, var(--custom-dark-vertical-nav-primary-divider-bg, #{$dark-nav-vert-brand-separator}));



  //=== j. Modals
  --#{$vudTokenPrefix}modal-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--#{$backupVudTokenPrefix}comp-content-surface-enabled, var(--custom-dark-modal-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)})));
  --#{$vudTokenPrefix}modal-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--custom-dark-modal-shadow, #{$dark-shadow-default-elevation-2}));

  --#{$vudTokenPrefix}modal-overlay-bg: var(--custom-dark-modal-overlay-bg, rgba(#{hex-to-rgb($neutral-dark-90)}, 0.5));

  --#{$vudTokenPrefix}modal-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});

  --#{$vudTokenPrefix}modal-text-color: var(--#{$backupVudTokenPrefix}content-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-content-text-default)});


  //=== k. Forms
  // inputs/textarea
  --#{$vudTokenPrefix}input-text-color: var(--#{$backupVudTokenPrefix}inputs-text-default, var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-dark-input-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-inputs-text-default)})));
  --#{$vudTokenPrefix}input-placeholder-color: var(--#{$backupVudTokenPrefix}inputs-text-secondary, var(--#{$backupVudTokenPrefix}comp-inputs-text-secondary, var(--custom-dark-input-placeholder-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-inputs-text-secondary)})));

  --#{$vudTokenPrefix}input-bg: var(--#{$backupVudTokenPrefix}inputs-surface-enabled, var(--#{$backupVudTokenPrefix}comp-inputs-surface-enabled, var(--custom-dark-input-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-enabled)})));
  --#{$vudTokenPrefix}input-border-color: var(--#{$backupVudTokenPrefix}inputs-border-enabled, var(--#{$backupVudTokenPrefix}comp-inputs-border-enabled, var(--custom-dark-input-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-inputs-border-enabled)})));
  --#{$vudTokenPrefix}input-shadow: none;

  // inputs - hover state
  --#{$vudTokenPrefix}input-hover-bg: var(--#{$backupVudTokenPrefix}inputs-surface-hover, var(--#{$backupVudTokenPrefix}comp-inputs-surface-hover, var(--custom-dark-input-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-hover)})));
  --#{$vudTokenPrefix}input-hover-border-color: var(--#{$backupVudTokenPrefix}inputs-border-hover, var(--#{$backupVudTokenPrefix}comp-inputs-border-hover, var(--custom-dark-input-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-inputs-border-hover)})));
  --#{$vudTokenPrefix}input-hover-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  // inputs - focus state
  --#{$vudTokenPrefix}input-focus-border-color: var(--#{$backupVudTokenPrefix}inputs-border-focused, var(--#{$backupVudTokenPrefix}comp-inputs-border-focused, var(--custom-dark-input-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-inputs-border-focused)})));
  --#{$vudTokenPrefix}input-focus-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  // inputs - disabled state
  --#{$vudTokenPrefix}input-disabled-text-color: var(--#{$backupVudTokenPrefix}inputs-text-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-dark-input-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-inputs-text-disabled)})));

  --#{$vudTokenPrefix}input-disabled-bg: var(--#{$backupVudTokenPrefix}inputs-surface-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-surface-disabled, var(--custom-dark-input-disabled-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-disabled)})));
  --#{$vudTokenPrefix}input-disabled-border-color: var(--#{$backupVudTokenPrefix}inputs-border-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-border-disabled, var(--custom-dark-input-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-inputs-border-disabled)})));

  // error inputs
  --#{$vudTokenPrefix}input-error-text-color: var(--#{$backupVudTokenPrefix}inputs-text-error, var(--#{$backupVudTokenPrefix}comp-inputs-text-error, var(--custom-dark-input-error-text-color, #{$dark-inputs-text-error})));

  --#{$vudTokenPrefix}input-error-bg: var(--#{$backupVudTokenPrefix}inputs-surface-error, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-error)});
  --#{$vudTokenPrefix}input-error-border-color: var(--#{$backupVudTokenPrefix}inputs-border-error, var(--#{$backupVudTokenPrefix}comp-inputs-border-error, var(--custom-dark-input-error-border-color, #{$dark-inputs-border-error})));

  // read-only inputs
  --#{$vudTokenPrefix}input-read-only-bg: var(--#{$backupVudTokenPrefix}input-read-only-bg, transparent);
  --#{$vudTokenPrefix}input-read-only-inverted-bg: var(--#{$backupVudTokenPrefix}input-read-only-inverted-bg, var(--#{$backupVudTokenPrefix}inputs-surface-muted, #{$dark-inputs-surface-muted}));

  // labels for inputs
  --#{$vudTokenPrefix}input-label-text-color: var(--#{$backupVudTokenPrefix}inputs-text-default, var(--#{$backupVudTokenPrefix}comp-inputs-text-default, var(--custom-dark-input-label-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-inputs-text-default)})));
  --#{$vudTokenPrefix}input-label-disabled-text-color: var(--#{$backupVudTokenPrefix}inputs-text-disabled, var(--#{$backupVudTokenPrefix}comp-inputs-text-disabled, var(--custom-dark-input-label-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-inputs-text-disabled)})));

  // inputs - primary
  --#{$vudTokenPrefix}input-primary-bg: var(--#{$backupVudTokenPrefix}search-brand-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-search-brand-surface-enabled)});
  --#{$vudTokenPrefix}input-primary-border-color: var(--#{$backupVudTokenPrefix}search-brand-border-enabled, var(--#{$backupVudTokenPrefix}comp-search-brand-border-enabled, var(--custom-dark-input-primary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $dark-search-brand-border-enabled)})));
  --#{$vudTokenPrefix}input-primary-shadow: var(--#{$backupVudTokenPrefix}shadow-brand-elevation-1, #{$dark-shadow-brand-elevation-1});

  // inputs - primary - hover
  --#{$vudTokenPrefix}input-primary-hover-border-color: var(--#{$backupVudTokenPrefix}search-brand-border-hover, var(--#{$backupVudTokenPrefix}comp-search-brand-border-hover, var(--custom-dark-input-primary-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $dark-search-brand-border-hover)})));

  // inputs - primary - disabled
  --#{$vudTokenPrefix}search-field-primary-disabled-button: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-surface-disabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-surface-disabled, var(--custom-dark-search-field-primary-disabled-button, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-confirmatory-primary-surface-disabled)})));
  --#{$vudTokenPrefix}search-field-primary-disabled-button-border: var(--#{$backupVudTokenPrefix}button-confirmatory-primary-border-disabled, var(--#{$backupVudTokenPrefix}comp-btn-primary-on-confimatory-border-disabled, var(--custom-dark-search-field-primary-disabled-button-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-button-confirmatory-primary-border-disabled)})));


  // switches
  --#{$vudTokenPrefix}switches-text-color: var(--#{$backupVudTokenPrefix}switch-text-default, var(--#{$backupVudTokenPrefix}comp-switch-text-default, var(--custom-dark-switches-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-switch-text-default)})));

  --#{$vudTokenPrefix}switches-bg: var(--#{$backupVudTokenPrefix}switch-surface-enabled, var(--#{$backupVudTokenPrefix}comp-switch-surface-enabled, var(--custom-dark-switches-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-switch-surface-enabled)})));
  --#{$vudTokenPrefix}switches-border-color: var(--#{$backupVudTokenPrefix}switch-border-enabled, var(--#{$backupVudTokenPrefix}comp-switch-border-enabled, var(--custom-dark-switches-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-switch-border-enabled)})));

  --#{$vudTokenPrefix}switches-togglemark-bg: var(--#{$backupVudTokenPrefix}switch-surface-strong, var(--#{$backupVudTokenPrefix}comp-switch-surface-strong, var(--custom-dark-switches-togglemark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-switch-surface-strong)})));
  --#{$vudTokenPrefix}switches-togglemark-border-color: var(--#{$backupVudTokenPrefix}switch-border-strong, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-switch-border-strong)});

  // switches - hover
  --#{$vudTokenPrefix}switches-hover-bg: var(--#{$backupVudTokenPrefix}switch-surface-hover, var(--#{$backupVudTokenPrefix}comp-switch-surface-hover, var(--custom-dark-switches-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-switch-surface-hover)})));
  --#{$vudTokenPrefix}switches-hover-border-color: var(--#{$backupVudTokenPrefix}switch-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-switch-border-hover)});

  // switches - focus
  --#{$vudTokenPrefix}switches-focus-border-color: var(--#{$backupVudTokenPrefix}switch-border-focused, var(--#{$backupVudTokenPrefix}comp-switch-border-focused, var(--custom-dark-switches-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-switch-border-focused)})));
  --#{$vudTokenPrefix}switches-focus-inner-border-color: var(--#{$backupVudTokenPrefix}border-inner-shadow, var(--custom-dark-switches-focus-inner-border-color, #{$dark-border-inner-shadow}));

  // switches - disabled
  --#{$vudTokenPrefix}switches-disabled-text-color: var(--#{$backupVudTokenPrefix}switch-text-disabled, var(--#{$backupVudTokenPrefix}comp-switch-text-disabled, var(--custom-dark-switches-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-switch-text-disabled)})));

  --#{$vudTokenPrefix}switches-disabled-bg: var(--#{$backupVudTokenPrefix}switch-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-dark-switches-disabled-bg, #{$dark-switch-surface-disabled-moderate})));
  --#{$vudTokenPrefix}switches-disabled-border-color: var(--#{$backupVudTokenPrefix}switch-border-disabled, var(--#{$backupVudTokenPrefix}comp-switch-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-switch-border-disabled)}));

  --#{$vudTokenPrefix}switches-disabled-togglemark-bg: var(--#{$backupVudTokenPrefix}switch-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-dark-switches-disabled-togglemark-bg, #{$dark-switch-surface-disabled-moderate})));
  --#{$vudTokenPrefix}switches-disabled-togglemark-border-color: var(--#{$backupVudTokenPrefix}switch-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-switch-border-disabled)});

  // switches - error
  --#{$vudTokenPrefix}switches-error-border-color: var(--#{$backupVudTokenPrefix}switch-border-error, #{$dark-switch-border-error});

  // switches - checked
  --#{$vudTokenPrefix}switches-checked-text-color: var(--#{$backupVudTokenPrefix}switch-text-inverse, var(--#{$backupVudTokenPrefix}comp-switch-text-inverse, var(--custom-dark-switches-checked-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-switch-text-inverse)})));

  --#{$vudTokenPrefix}switches-checked-bg: var(--#{$backupVudTokenPrefix}switch-surface-selected-strong, var(--#{$backupVudTokenPrefix}comp-switch-surface-selected-strong, var(--custom-dark-switches-checked-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $dark-switch-surface-selected-strong)})));
  --#{$vudTokenPrefix}switches-checked-border-color: var(--#{$backupVudTokenPrefix}switch-border-selected-strong, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-50)', $dark-switch-border-selected-strong)});

  --#{$vudTokenPrefix}switches-checked-togglemark-border-color: var(--#{$backupVudTokenPrefix}switch-checked-togglemark-border-color, #{$dark-switch-border-transparent}); // <- add overwite token

  // switches - hover - checked
  --#{$vudTokenPrefix}switches-checked-hover-bg: var(--#{$backupVudTokenPrefix}switch-surface-selected-strong-hover, var(--#{$backupVudTokenPrefix}-comp-switch-surface-selected-strong-hover, var(--custom-dark-switches-checked-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-70)', $dark-switch-surface-selected-strong-hover)})));
  --#{$vudTokenPrefix}switches-checked-hover-border-color: var(--#{$backupVudTokenPrefix}switch-border-selected-strong-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-40)', $dark-switch-border-selected-strong-hover)});

  // switches - disabled - checked
  --#{$vudTokenPrefix}switches-checked-disabled-text-color: var(--#{$backupVudTokenPrefix}switch-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-switch-text-disabled)});

  --#{$vudTokenPrefix}switches-checked-disabled-bg: var(--#{$backupVudTokenPrefix}switch-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-switch-surface-disabled, var(--custom-dark-switches-checked-disabled-bg, #{$dark-switch-surface-disabled-moderate})));


  // radio-checkbox
  --#{$vudTokenPrefix}radio-checkbox-text-color: var(--#{$backupVudTokenPrefix}toggle-text-default, var(--#{$backupVudTokenPrefix}comp-toggle-text-default, var(--custom-dark-radio-checkbox-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-toggle-text-default)})));

  --#{$vudTokenPrefix}radio-checkbox-bg: var(--#{$backupVudTokenPrefix}toggle-surface-enabled, var(--#{$backupVudTokenPrefix}comp-toggle-surface-enabled, var(--custom-dark-radio-checkbox-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-toggle-surface-enabled)})));
  --#{$vudTokenPrefix}radio-checkbox-border-color: var(--#{$backupVudTokenPrefix}toggle-border-enabled, var(--#{$backupVudTokenPrefix}comp-toggle-border-enabled, var(--custom-dark-radio-checkbox-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-toggle-border-enabled)})));

  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, var(--custom-dark-radio-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-toggle-icon-emphasis)}));
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--custom-dark-radio-checkbox-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-icon-inverse)}));

  // radio-checkbox - focus
  --#{$vudTokenPrefix}radio-checkbox-focus-border-color: var(--#{$backupVudTokenPrefix}toggle-border-focused, var(--#{$backupVudTokenPrefix}comp-toggle-border-focused, var(--custom-dark-radio-checkbox-focus-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-border-focused)})));
  --#{$vudTokenPrefix}radio-checkbox-error-focus-border-color: var(--#{$backupVudTokenPrefix}toggle-border-error, var(--#{$backupVudTokenPrefix}comp-toggle-border-error, var(--custom-dark-radio-checkbox-error-focus-border-color, #{$dark-toggle-border-error})));

  // radio-checkbox - disabled
  --#{$vudTokenPrefix}radio-checkbox-disabled-text-color: var(--#{$backupVudTokenPrefix}toggle-text-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-text-disabled, var(--custom-dark-radio-checkbox-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-toggle-text-disabled)})));

  --#{$vudTokenPrefix}radio-checkbox-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-disabled-bg, #{$dark-toggle-surface-disabled-moderate})));
  --#{$vudTokenPrefix}radio-checkbox-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-moderate, var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-dark-radio-checkbox-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-toggle-border-disabled-moderate)})));

  // radio-checkbox - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-bg: var(--#{$backupVudTokenPrefix}toggle-surface-emphasis, var(--#{$backupVudTokenPrefix}comp-toggle-icon-emphasis, var(--custom-dark-radio-checkbox-checked-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $dark-toggle-surface-emphasis)})));
  --#{$vudTokenPrefix}radio-checkbox-checked-border-color: var(--#{$backupVudTokenPrefix}toggle-border-emphasis, var(--#{$backupVudTokenPrefix}comp-toggle-border-emphasis, var(--custom-dark-radio-checkbox-checked-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $dark-toggle-border-emphasis)})));

  --#{$vudTokenPrefix}radio-checkbox-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--custom-dark-radio-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-icon-inverse)}));
  --#{$vudTokenPrefix}radio-checkbox-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-inverse, var(--custom-dark-radio-checkbox-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-icon-inverse)}));

  // radio-checkbox - disabled - checked
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-strong, var(--#{$backupVudTokenPrefix}comp-toggle-surface-disabled, var(--custom-dark-radio-checkbox-checked-disabled-bg, #{$dark-toggle-surface-disabled-strong})));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-strong, var(--#{$backupVudTokenPrefix}comp-toggle-border-disabled, var(--custom-dark-radio-checkbox-checked-disabled-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-toggle-border-disabled-strong)})));

  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-checked-disabled-checkmark-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-toggle-icon-disabled)})));
  --#{$vudTokenPrefix}radio-checkbox-checked-disabled-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-disabled, var(--#{$backupVudTokenPrefix}comp-toggle-icon-disabled, var(--custom-dark-radio-checkbox-checked-disabled-dot-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-toggle-icon-disabled)})));


  // radio-checkbox - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-bg: var(--#{$backupVudTokenPrefix}toggle-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-toggle-surface-enabled)});
  --#{$vudTokenPrefix}radio-checkbox-outline-border-color: var(--#{$backupVudTokenPrefix}toggle-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-toggle-border-enabled)});

  // radio-checkbox disabled - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, #{$dark-toggle-surface-disabled-moderate});
  --#{$vudTokenPrefix}radio-checkbox-outline-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-toggle-border-disabled-moderate)});

  // radio-checkbox - checked - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-checkmark-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-icon-inverse)});
  --#{$vudTokenPrefix}radio-checkbox-outline-dot-bg: var(--#{$backupVudTokenPrefix}toggle-icon-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-toggle-icon-inverse)});

  // radio-checkbox - disabled - checked - outline style
  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-bg: var(--#{$backupVudTokenPrefix}toggle-surface-disabled-moderate, #{$dark-toggle-surface-disabled-moderate});
  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-border-color: var(--#{$backupVudTokenPrefix}toggle-border-disabled-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-toggle-border-disabled-moderate)});

  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-checkmark-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-ref-icon-disabled)});
  --#{$vudTokenPrefix}radio-checkbox-outline-checked-disabled-dot-bg: var(--#{$backupVudTokenPrefix}ref-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-ref-icon-disabled)});


  // selects/dropdowns
  --#{$vudTokenPrefix}select-arrow: var(--customk-dark-select-arrow, url(#{$image-path}/arrow-large-dark.svg));
  --#{$vudTokenPrefix}select-arrow-disabled: var(--custom-dark-select-arrow-disabled,  url(#{$image-path}/arrow-large-disabled-dark.svg));

  --#{$vudTokenPrefix}select-text-color: var(--#{$backupVudTokenPrefix}inputs-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-inputs-text-default)});

  --#{$vudTokenPrefix}select-bg: var(--#{$backupVudTokenPrefix}inputs-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-enabled)});
  --#{$vudTokenPrefix}select-border-color: var(--#{$backupVudTokenPrefix}inputs-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-inputs-border-enabled)});
  --#{$vudTokenPrefix}select-border-radius: var(--#{$backupVudTokenPrefix}radii-xs, #{$dark-radii-xs});

  --#{$vudTokenPrefix}select-menu-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}select-item-menu-text-color: var(--#{$backupVudTokenPrefix}list-text-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-list-text-default)});

  --#{$vudTokenPrefix}select-item-menu-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)});
  --#{$vudTokenPrefix}select-item-menu-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});
  --#{$vudTokenPrefix}select-item-menu-border-radius: var(--#{$backupVudTokenPrefix}radii-sm, #{$dark-radii-sm});

  // hover
  --#{$vudTokenPrefix}select-hover-bg: var(--#{$backupVudTokenPrefix}inputs-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-hover)});
  --#{$vudTokenPrefix}select-hover-border-color: var(--#{$backupVudTokenPrefix}inputs-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-inputs-border-hover)});
  --#{$vudTokenPrefix}select-item-menu-hover-bg: var(--#{$backupVudTokenPrefix}list-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-hover)});

  // focus
  --#{$vudTokenPrefix}select-focus-border-color: var(--#{$backupVudTokenPrefix}inputs-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-inputs-border-focused)});
  --#{$vudTokenPrefix}select-item-menu-focus-border-color: var(--#{$backupVudTokenPrefix}list-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-list-border-focused)});

  // pressed
  --#{$vudTokenPrefix}select-pressed-bg: var(--#{$backupVudTokenPrefix}inputs-surface-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-inputs-surface-pressed)});
  --#{$vudTokenPrefix}select-pressed-border-color: var(--#{$backupVudTokenPrefix}inputs-border-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-inputs-border-pressed)});

  // selected
  --#{$vudTokenPrefix}select-item-menu-selected-bg: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-selected-moderate)});

  // disabled
  --#{$vudTokenPrefix}select-disabled-text-color: var(--#{$backupVudTokenPrefix}inputs-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-inputs-text-disabled)});

  --#{$vudTokenPrefix}select-disabled-bg: var(--#{$backupVudTokenPrefix}inputs-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-inputs-surface-disabled)});
  --#{$vudTokenPrefix}select-disabled-border-color: var(--#{$backupVudTokenPrefix}inputs-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-inputs-border-disabled)});

  --#{$vudTokenPrefix}select-item-menu-disabled-text-color: var(--#{$backupVudTokenPrefix}list-text-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-list-text-disabled)});

  // drop area
  --#{$vudTokenPrefix}drop-area-bg: var(--custom-dark-drop-area-bg, var(--#{$vudTokenPrefix}neutral-80));
  --#{$vudTokenPrefix}drop-area-border-color: var(--custom-dark-drop-area-border-color, var(--#{$vudTokenPrefix}neutral-50));
  --#{$vudTokenPrefix}drop-area-highlight-border-color: var(--custom-dark-drop-area-highlight-border-color, var(--#{$vudTokenPrefix}blue-40));
  --#{$vudTokenPrefix}drop-area-highlight-bg: var(--custom-dark-drop-area-highlight-bg, var(--#{$vudTokenPrefix}neutral-70));
  --#{$vudTokenPrefix}drop-area-focus-border-color: var(--custom-dark-drop-area-focus-border-color, var(--#{$vudTokenPrefix}neutral-05));
  --#{$vudTokenPrefix}drop-area-preview-item-bg: var(--custom-dark-drop-area-preview-item-bg, var(--#{$vudTokenPrefix}neutral-60));
  --#{$vudTokenPrefix}drop-area-preview-item-op: var(--custom-dark-drop-area-preview-item-op, 0.5);
  --#{$vudTokenPrefix}drop-area-preview-item-border-color: var(--custom-dark-drop-area-preview-item-border-color, var(--#{$vudTokenPrefix}neutral-50));


  //=== l. Images
  --#{$vudTokenPrefix}image-no-alt-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-moderate, var(--custom-dark-image-no-alt-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'vud-funct-warning-50)', $dark-situation-funct-border-warning-moderate)}));


  //=== m. Table
  --#{$vudTokenPrefix}table-bg: var(--#{$backupVudTokenPrefix}table-surface-enabled, var(--#{$backupVudTokenPrefix}comp-table-surface-enabled, var(--custom-dark-table-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-table-surface-enabled)})));
  --#{$vudTokenPrefix}table-alt-bg: var(--#{$backupVudTokenPrefix}table-surface-muted, var(--#{$backupVudTokenPrefix}comp-table-surface-muted, var(--custom-dark-table-alt-bg, #{$dark-table-surface-muted})));
  --#{$vudTokenPrefix}table-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-dark-table-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}))); // add to hole table 1px solid 
  --#{$vudTokenPrefix}table-dotted-divider-color: var(--#{$backupVudTokenPrefix}separator-strong, var(--#{$backupVudTokenPrefix}comp-separator-strong, var(--custom-dark-table-dotted-divider-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-separator-strong)})));
  --#{$vudTokenPrefix}table-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$light-shadow-default-elevation-1});

  // table - cell hover
  --#{$vudTokenPrefix}table-hover-bg: var(--#{$backupVudTokenPrefix}table-surface-hover, var(--#{$backupVudTokenPrefix}comp-table-surface-hover, var(--custom-dark-table-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-table-surface-hover)})));
  --#{$vudTokenPrefix}table-hover-border-color: var(--#{$backupVudTokenPrefix}table-border-hover, var(--#{$backupVudTokenPrefix}comp-table-border-hover, var(--custom-dark-table-hover-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-table-border-hover)})));

  // table - cell active/pressed state
  --#{$vudTokenPrefix}table-active-bg: var(--#{$backupVudTokenPrefix}table-surface-selected, var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-dark-table-active-bg, #{$dark-table-surface-selected})));
  --#{$vudTokenPrefix}table-active-border-color: var(--#{$backupVudTokenPrefix}table-border-selected, var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-dark-table-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-table-border-selected)})));

  // table - cell active/selected state
  --#{$vudTokenPrefix}table-selected-bg: var(--#{$backupVudTokenPrefix}table-surface-selected, var(--#{$backupVudTokenPrefix}comp-table-surface-selected, var(--custom-dark-table-selected-bg, #{$dark-table-surface-selected})));
  --#{$vudTokenPrefix}table-selected-border-color: var(--#{$backupVudTokenPrefix}table-border-selected, var(--#{$backupVudTokenPrefix}comp-table-border-selected, var(--custom-dark-table-selected-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-table-border-selected)})));

  // table - error row state
  --#{$vudTokenPrefix}table-error-text-color: var(--#{$backupVudTokenPrefix}table-text-error-moderate, var(--#{$backupVudTokenPrefix}comp-table-text-error-moderate, #{$dark-table-text-error-moderate}));

  --#{$vudTokenPrefix}table-error-bg: color-mix(in oklch, #{$black} 32%, var(--#{$backupVudTokenPrefix}table-surface-error-hover, var(--custom-dark-table-error-bg, #{$dark-table-surface-error-hover})));


  //=== n. Stepper
  --#{$vudTokenPrefix}stepper-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-disabled, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-dark-stepper-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-stepper-text-disabled)})));
  --#{$vudTokenPrefix}stepper-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-weak, var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-weak, var(--custom-dark-stepper-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-40)', $dark-nav-stepper-surface-weak)})));

  // stepper - active/selected state
  --#{$vudTokenPrefix}stepper-active-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-emphasis, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-emphasis, var(--custom-dark-stepper-active-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-nav-stepper-text-emphasis)})));
  --#{$vudTokenPrefix}stepper-active-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-surface-strong, var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-strong, var(--custom-dark-stepper-active-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-nav-stepper-surface-strong)})));
  --#{$vudTokenPrefix}stepper-active-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-inverse, var(--custom-dark-stepper-active-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-stepper-icon-inverse)}));

  // stepper - visited state
  --#{$vudTokenPrefix}stepper-visited-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-success, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-success, var(--custom-dark-stepper-visited-text-color, #{$dark-nav-stepper-text-success})));
  --#{$vudTokenPrefix}stepper-visited-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-success, var(--#{$backupVudTokenPrefix}comp-nav-stepper-icon-success, var(--custom-dark-stepper-visited-bg, #{$dark-nav-stepper-icon-success})));


  //=== o. Progress Bar
  --#{$vudTokenPrefix}progress-bar-label: var(--#{$backupVudTokenPrefix}progress-text-secondary, var(--#{$backupVudTokenPrefix}comp-progress-text-secondary, var(--custom-dark-progress-bar-label, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-progress-text-secondary)})));

  --#{$vudTokenPrefix}progress-bar-bg: var(--#{$backupVudTokenPrefix}progress-secondary-border-strong, var(--#{$backupVudTokenPrefix}comp-progress-secondary-border-strong, var(--custom-dark-progress-bar-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-50)', $dark-progress-secondary-border-strong)})));
  --#{$vudTokenPrefix}progress-bar-wrapper-bg: var(--#{$backupVudTokenPrefix}progress-default-border-weak, var(--#{$backupVudTokenPrefix}comp-progress-default-border-weak, var(--custom-dark-progress-bar-wrapper-bg, #{$dark-progress-default-border-weak})));


  //=== m. Alerts and toasts
  --#{$vudTokenPrefix}alert-success-text: var(--#{$backupVudTokenPrefix}situation-funct-text-success-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-success-moderate, var(--custom-dark-alert-success-text, #{$dark-situation-funct-text-success-moderate})));
  --#{$vudTokenPrefix}alert-success-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-success-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-success-weak, var(--custom-dark-alert-success-bg, #{$dark-situation-funct-surface-success-weak})));
  --#{$vudTokenPrefix}alert-success-border: var(--#{$backupVudTokenPrefix}situation-funct-border-success-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-success-weak, var(--custom-dark-alert-success-border, #{$dark-situation-funct-border-success-weak})));
  --#{$vudTokenPrefix}alert-success-shadow: var(--custom-dark-alert-success-shadow, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-info-text: var(--#{$backupVudTokenPrefix}situation-funct-text-info-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-info-moderate, var(--custom-dark-alert-info-text, #{$dark-situation-funct-text-info-moderate})));
  --#{$vudTokenPrefix}alert-info-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-info-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-info-weak, var(--custom-dark-alert-info-bg, #{$dark-situation-funct-surface-info-weak})));
  --#{$vudTokenPrefix}alert-info-border: var(--#{$backupVudTokenPrefix}situation-funct-border-info-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-info-weak, var(--custom-dark-alert-info-border, #{$dark-situation-funct-border-info-weak})));
  --#{$vudTokenPrefix}alert-info-shadow: var(--custom-dark-alert-info-shadow, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-warning-text: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-warning-moderate, var(--custom-dark-alert-warning-text, #{$dark-situation-funct-text-warning-moderate})));
  --#{$vudTokenPrefix}alert-warning-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-warning-weak, var(--custom-dark-alert-warning-bg, #{$dark-situation-funct-surface-warning-weak})));
  --#{$vudTokenPrefix}alert-warning-border: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-warning-weak, var(--custom-dark-alert-warning-border, #{$dark-situation-funct-border-warning-weak})));
  --#{$vudTokenPrefix}alert-warning-shadow: var(--custom-dark-alert-warning-shadow, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}alert-danger-text: var(--#{$backupVudTokenPrefix}situation-funct-text-error-moderate, var(--#{$backupVudTokenPrefix}comp-situation-funct-text-error-moderate, var(--custom-dark-alert-danger-text, #{$dark-situation-funct-text-error-moderate})));
  --#{$vudTokenPrefix}alert-danger-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-surface-error-weak, var(--custom-dark-alert-danger-bg, #{$dark-situation-funct-surface-error-weak})));
  --#{$vudTokenPrefix}alert-danger-border: var(--#{$backupVudTokenPrefix}situation-funct-border-error-weak, var(--#{$backupVudTokenPrefix}comp-situation-funct-border-error-weak, var(--custom-dark-alert-danger-border, #{$dark-situation-funct-border-error-weak})));
  --#{$vudTokenPrefix}alert-danger-shadow: var(--custom-dark-alert-danger-shadow, #{$dark-shadow-default-elevation-1});



  //=== n. Popovers and tooltips
  --#{$vudTokenPrefix}popover-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-dark-popover-shadow, #{$dark-shadow-default-elevation-1})); // use: --#{$vudTokenPrefix}shadow-10;
  --#{$vudTokenPrefix}popover-arrow-tip: var(--custom-dark-popover-arrow-tip, url(#{$image-path}/popover-tip-dark.svg));



  //Tooltips
  // default variant
  --#{$vudTokenPrefix}tooltip-text: var(--#{$backupVudTokenPrefix}situation-funct-text-warning-moderate, var(--custom-dark-tooltip-primary-text, #{$dark-situation-funct-text-warning-moderate}));

  --#{$vudTokenPrefix}tooltip-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-warning-weak, var(--custom-dark-tooltip-border-color, #{$dark-situation-funct-border-warning-weak}));
  --#{$vudTokenPrefix}tooltip-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-warning-weak, var(--custom-dark-tooltip-bg, #{$dark-situation-funct-surface-warning-weak}));
  --#{$vudTokenPrefix}tooltip-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-2, var(--custom-dark-tooltip-shadow, #{$dark-shadow-default-elevation-2}));

  --#{$vudTokenPrefix}tooltip-arrow-tip: var(--custom-dark-tooltip-arrow-tip, url(#{$image-path}/tooltip-tip-dark.svg));

  // primary variant
  --#{$vudTokenPrefix}tooltip-primary-text: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--custom-dark-tooltip-primary-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-default)}));

  --#{$vudTokenPrefix}tooltip-primary-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--custom-dark-tooltip-primary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)}));
  --#{$vudTokenPrefix}tooltip-primary-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-default, var(--custom-dark-tooltip-primary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-situation-funct-surface-default)}));
  --#{$vudTokenPrefix}tooltip-primary-shadow: var(--custom-dark-tooltip-primary-shadow, var(--#{$vudTokenPrefix}tooltip-shadow));

  --#{$vudTokenPrefix}tooltip-primary-arrow-tip: var(--custom-dark-tooltip-primary-arrow-tip, url(#{$image-path}/tooltip-tip-dark.svg));

  // secondary variant
  --#{$vudTokenPrefix}tooltip-secondary-text: var(--#{$backupVudTokenPrefix}situation-funct-text-inverse, var(--custom-dark-tooltip-secondary-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-situation-funct-text-inverse)}));

  --#{$vudTokenPrefix}tooltip-secondary-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-inverse, var(--custom-dark-tooltip-secondary-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-situation-funct-surface-inverse)}));
  --#{$vudTokenPrefix}tooltip-secondary-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-default, var(--custom-dark-tooltip-secondary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-border-default)}));
  --#{$vudTokenPrefix}tooltip-secondary-shadow: var(--custom-dark-tooltip-secondary-shadow, var(--#{$vudTokenPrefix}tooltip-shadow));

  --#{$vudTokenPrefix}tooltip-secondary-arrow-tip: var(--custom-dark-tooltip-secondary-arrow-tip, url(#{$image-path}/tooltip-tip-dark.svg));

  // error variant
  --#{$vudTokenPrefix}tooltip-error-text: var(--#{$backupVudTokenPrefix}situation-funct-text-error-moderate, var(--custom-dark-tooltip-error-text, #{$dark-situation-funct-text-error-moderate}));

  --#{$vudTokenPrefix}tooltip-error-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-error-weak, var(--custom-dark-tooltip-error-bg, #{$dark-situation-funct-surface-error-weak}));
  --#{$vudTokenPrefix}tooltip-error-border-color: var(--#{$backupVudTokenPrefix}situation-funct-border-error-weak, var(--custom-dark-tooltip-error-border-color, #{$dark-situation-funct-border-error-weak}));
  --#{$vudTokenPrefix}tooltip-error-shadow: var(--custom-dark-tooltip-error-shadow, var(--#{$vudTokenPrefix}tooltip-shadow));

  --#{$vudTokenPrefix}tooltip-error-arrow-tip: var(--custom-dark-tooltip-error-arrow-tip, url(#{$image-path}/tooltip-tip-error-dark.svg));



  //=== o. Datepicker
  --#{$vudTokenPrefix}datepicker-inline-bg: var(--#{$backupVudTokenPrefix}calendar-surface-enabled, var(--#{$backupVudTokenPrefix}comp-calendar-surface-enabled, var(--custom-dark-datepicker-inline-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-calendar-surface-enabled)})));

  --#{$vudTokenPrefix}datepicker-arrow: var(--custom-dark-datepicker-arrow, url(#{$image-path}/arrow-small-blue-dark.svg)); // use poligons with proper color token

  --#{$vudTokenPrefix}datepicker-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--#{$backupVudTokenPrefix}comp-separator-moderate, var(--custom-dark-datepicker-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}))); // vertical divider
  --#{$vudTokenPrefix}datepicker-nonclickable-text-color: var(--#{$backupVudTokenPrefix}calendar-text-disabled, var(--custom-dark-datepicker-nonclickable-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-calendar-text-disabled)}));

  --#{$vudTokenPrefix}datepicker-item-text: var(--#{$backupVudTokenPrefix}calendar-text-default, var(--custom-dark-datepicker-item-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-calendar-text-default)}));

  --#{$vudTokenPrefix}datepicker-item-today-border: var(--#{$backupVudTokenPrefix}calendar-border-focused, var(--custom-dark-datepicker-item-today-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-calendar-border-focused)}));


  --#{$vudTokenPrefix}datepicker-item-hover-bg: var(--#{$backupVudTokenPrefix}calendar-surface-hover, var(--custom-dark-datepicker-item-hover-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-calendar-surface-hover)}));
  --#{$vudTokenPrefix}datepicker-item-hover-range-bg: var(--#{$backupVudTokenPrefix}calendar-surface-hover, var(--custom-dark-datepicker-item-hover-range-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-calendar-surface-hover)}));


  --#{$vudTokenPrefix}datepicker-item-selected-focus-border: var(--#{$backupVudTokenPrefix}calendar-border-focused, var(--custom-dark-datepicker-item-selected-focus-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-calendar-border-focused)}));


  --#{$vudTokenPrefix}datepicker-item-selected-text: var(--#{$backupVudTokenPrefix}calendar-text-inverse, var(--custom-dark-datepicker-item-selected-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-calendar-text-inverse)}));
  --#{$vudTokenPrefix}datepicker-item-selected-bg: var(--#{$backupVudTokenPrefix}calendar-surface-selected-strong, var(--custom-dark-datepicker-item-selected-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-calendar-surface-selected-strong)}));
  --#{$vudTokenPrefix}datepicker-item-selected-range-bg: var(--#{$backupVudTokenPrefix}calendar-surface-muted, var(--custom-dark-datepicker-item-selected-range-bg, #{$dark-calendar-surface-muted}));


  --#{$vudTokenPrefix}datepicker-today-btn-text: var(--#{$backupVudTokenPrefix}calendar-surface-muted, var(--custom-dark-datepicker-today-btn-text, #{$dark-calendar-surface-muted}));
  --#{$vudTokenPrefix}datepicker-today-btn-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-default-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-confirmatory-default-surface-enabled)});
  --#{$vudTokenPrefix}datepicker-today-btn-border: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-border-enabled, var(--custom-dark-datepicker-today-btn-border, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-60)', $dark-button-confirmatory-secondary-tint-border-enabled)}));

  // hover
  --#{$vudTokenPrefix}datepicker-today-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-70)', $dark-button-confirmatory-secondary-tint-surface-hover)});

  // focused
  --#{$vudTokenPrefix}datepicker-today-btn-focus-btn-border: var(--#{$backupVudTokenPrefix}button-confirmatory-secondary-tint-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-confirmatory-secondary-tint-border-focused)});

  --#{$vudTokenPrefix}datepicker-clear-btn-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-default, var(--custom-dark-datepicker-today-btn-text, #{$dark-button-destructive-default-text-default}));
  --#{$vudTokenPrefix}datepicker-clear-btn-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-button-destructive-default-surface-enabled)});
  --#{$vudTokenPrefix}datepicker-clear-btn-border: var(--#{$backupVudTokenPrefix}button-destructive-default-border-enabled, var(--custom-dark-datepicker-today-btn-border, #{$dark-button-destructive-default-border-enabled}));

  // hover
  --#{$vudTokenPrefix}datepicker-clear-btn-hover-text-color: var(--#{$backupVudTokenPrefix}button-destructive-default-text-inverse, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-button-destructive-default-text-inverse)});
  --#{$vudTokenPrefix}datepicker-clear-btn-hover-bg: var(--#{$backupVudTokenPrefix}button-destructive-default-surface-hover, #{$dark-button-destructive-default-surface-hover});

  // focused
  --#{$vudTokenPrefix}datepicker-clear-btn-focus-btn-border: var(--#{$backupVudTokenPrefix}button-destructive-default-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-button-destructive-default-border-focused)});



  //=== p. Tabs
  --#{$vudTokenPrefix}tabs-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-default, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-dark-tabs-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-stepper-text-default)})));
  --#{$vudTokenPrefix}tabs-hover-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-secondary-40)', $dark-nav-stepper-text-emphasis)});
  --#{$vudTokenPrefix}tabs-focused-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-default, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-default, var(--custom-dark-tabs-focused-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-nav-stepper-text-default)}))); // dep ?
  --#{$vudTokenPrefix}tabs-disabled-text-color: var(--#{$backupVudTokenPrefix}nav-stepper-text-disabled, var(--#{$backupVudTokenPrefix}comp-nav-stepper-text-disabled, var(--custom-dark-tabs-disabled-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-stepper-text-disabled)})));

  --#{$vudTokenPrefix}tabs-content-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-dark-tabs-content-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)}));
  --#{$vudTokenPrefix}tabs-content-border-color: var(--#{$backupVudTokenPrefix}separator-emphasis, var(--#{$backupVudTokenPrefix}comp-separator-extreme, var(--custom-dark-tabs-content-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $dark-separator-emphasis)})));
  --#{$vudTokenPrefix}tabs-content-secondary-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--#{$backupVudTokenPrefix}comp-nav-stepper-surface-pressed, var(--custom-dark-tabs-content-secondary-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)})));
  --#{$vudTokenPrefix}tabs-content-shadow-color: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, var(--custom-dark-tabs-content-shadow-color, #{$dark-shadow-default-elevation-1}));

  --#{$vudTokenPrefix}tabs-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-nav-stepper-border-enabled)});
  --#{$vudTokenPrefix}tabs-hover-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-stepper-border-hover)});
  --#{$vudTokenPrefix}tabs-focus-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-focused, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-stepper-border-focused)});
  --#{$vudTokenPrefix}tabs-pressed-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-nav-stepper-border-pressed)});
   --#{$vudTokenPrefix}tabs-selected-border-color: var(--#{$backupVudTokenPrefix}separator-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $dark-separator-emphasis)});
  --#{$vudTokenPrefix}tabs-disabled-border-color: var(--#{$backupVudTokenPrefix}nav-stepper-border-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-stepper-border-disabled)});

  --#{$vudTokenPrefix}tabs-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-stepper-surface-enabled)});
  --#{$vudTokenPrefix}tabs-hover-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-nav-stepper-surface-hover)});
  --#{$vudTokenPrefix}tabs-pressed-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-pressed, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-90)', $dark-nav-stepper-surface-pressed)});
  --#{$vudTokenPrefix}tabs-disabled-bg: var(--#{$backupVudTokenPrefix}nav-stepper-surface-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-nav-stepper-surface-disabled)});

  --#{$vudTokenPrefix}tabs-dots-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-default, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-05)', $dark-nav-stepper-icon-default)});
  --#{$vudTokenPrefix}tabs-dots-disabled-bg: var(--#{$backupVudTokenPrefix}nav-stepper-icon-disabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-nav-stepper-icon-disabled)});




  //=== q. Helper classes
  //Borders
  --#{$vudTokenPrefix}border-color: var(--#{$backupVudTokenPrefix}separator-moderate, var(--custom-dark-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)}));


  //=== r. Login page
  --#{$vudTokenPrefix}mask-bg: var(--custom-dark-mask-bg, rgba(#{hex-to-rgb($blue-90)}, 0.67));

  --#{$vudTokenPrefix}cookies-alert-text: var(--#{$backupVudTokenPrefix}situation-funct-text-default, var(--custom-dark-cookies-alert-text, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-situation-funct-text-default)}));
  --#{$vudTokenPrefix}cookies-alert-bg: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-dark-cookies-alert-bg, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)}));
  --#{$vudTokenPrefix}cookies-alert-secondary-bg: var(--#{$backupVudTokenPrefix}situation-funct-surface-muted, var(--custom-dark-cookies-alert-secondary-bg, #{$dark-situation-funct-surface-muted}));

  --#{$vudTokenPrefix}password-field-icon: var(--custom-dark-password-field-icon, url(#{$image-path}/login/password-icon-dark.svg));

  --#{$vudTokenPrefix}visma-logo: var(--custom-dark-visma-logo, url(#{$image-path}/visma-logo-dark.svg));


  //=== s. Error pages 
  --#{$vudTokenPrefix}error-img-opacity: var(--custom-dark-error-img-opacity, 0.6);


  //=== t. Spinners
  --#{$vudTokenPrefix}spinner-default-blue: var(--custom-dark-spinner-default-blue, url(#{$image-path}/spinners/spinner_doughnut_blue_dark.svg));
  --#{$vudTokenPrefix}spinner-default-grey: var(--custom-dark-spinner-default-grey, url(#{$image-path}/spinners/spinner_doughnut_grey_dark.svg));
  --#{$vudTokenPrefix}spinner-default-green: var(--custom-dark-spinner-default-green, url(#{$image-path}/spinners/spinner_doughnut_green_dark.svg));

  --#{$vudTokenPrefix}spinner-primary-blue: var(--custom-dark-spinner-primary-blue, url(#{$image-path}/spinners/spinner_dotted_blue_dark.svg));
  --#{$vudTokenPrefix}spinner-primary-grey: var(--custom-dark-spinner-primary-grey, url(#{$image-path}/spinners/spinner_dotted_grey_dark.svg));
  --#{$vudTokenPrefix}spinner-primary-green: var(--custom-dark-spinner-primary-green, url(#{$image-path}/spinners/spinner_dotted_green_dark.svg));

  --#{$vudTokenPrefix}spinner-secondary-blue: var(--custom-dark-spinner-secondary-blue, url(#{$image-path}/spinners/spinner_snake_blue_dark.svg));
  --#{$vudTokenPrefix}spinner-secondary-grey: var(--custom-dark-spinner-secondary-grey, url(#{$image-path}/spinners/spinner_snake_grey_dark.svg));
  --#{$vudTokenPrefix}spinner-secondary-green: var(--custom-dark-spinner-secondary-green, url(#{$image-path}/spinners/spinner_snake_green_dark.svg));



  //=== u. Cards list
  --#{$vudTokenPrefix}card-list-text-color: var(--#{$backupVudTokenPrefix}list-text-default, var(--custom-dark-card-list-text-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-10)', $dark-list-text-default)}));

  --#{$vudTokenPrefix}card-list-bg: var(--#{$backupVudTokenPrefix}list-surface-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-list-surface-enabled)});
  --#{$vudTokenPrefix}card-list-border-color: var(--#{$backupVudTokenPrefix}list-border-enabled, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-list-border-enabled)});
  --#{$vudTokenPrefix}card-list-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  --#{$vudTokenPrefix}card-list-secondary-bg: var(--custom-dark-card-list-secondary-bg, var(--#{$vudTokenPrefix}neutral-60)); // dep

  // hover
  --#{$vudTokenPrefix}card-list-hover-bg: var(--#{$backupVudTokenPrefix}list-surface-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-hover)});
  --#{$vudTokenPrefix}card-list-hover-border-color: var(--#{$backupVudTokenPrefix}list-border-hover, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-30)', $dark-list-border-hover)});
  --#{$vudTokenPrefix}card-list-hover-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  // selected
  --#{$vudTokenPrefix}card-list-selected-bg: var(--#{$backupVudTokenPrefix}list-surface-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-60)', $dark-list-surface-selected-moderate)});
  --#{$vudTokenPrefix}card-list-selected-border-color: var(--#{$backupVudTokenPrefix}list-border-selected-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-list-border-selected-moderate)});
  --#{$vudTokenPrefix}card-list-selected-border-accent: var(--#{$backupVudTokenPrefix}separator-emphasis, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-primary-60)', $dark-separator-emphasis)});
  --#{$vudTokenPrefix}card-list-selected-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-1, #{$dark-shadow-default-elevation-1});

  // hero card
  --#{$vudTokenPrefix}hero-card-bg: var(--#{$backupVudTokenPrefix}content-surface-muted, var(--#{$backupVudTokenPrefix}comp-content-surface-muted, var(--custom-dark-hero-card-bg, #{$dark-content-surface-muted})));
  --#{$vudTokenPrefix}hero-card-border-color: var(--#{$backupVudTokenPrefix}content-surface-enabled, var(--custom-dark-hero-card-border-color, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-70)', $dark-content-surface-enabled)}));
  --#{$vudTokenPrefix}hero-card-inner-border-color: var(--#{$backupVudTokenPrefix}separator-moderate, #{brandColorToken('var(--'+$vudTokenPrefix+'brand-palette-neutral-dark-50)', $dark-separator-moderate)});
  --#{$vudTokenPrefix}hero-card-shadow: var(--#{$backupVudTokenPrefix}shadow-default-elevation-3, #{$dark-shadow-default-elevation-3});

  }
