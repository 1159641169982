
// Accordion / Collapsible list

//*** scss local variable ***
$collapse-list-base-space:            $spacing-12;
$collapse-list-hr-space:              $spacing-16;
$collapse-list-main-vt-space:         $spacing-16;
$collapse-list-alt-vt-space:          $spacing-8;
$collapse-list-content-hr-space:      $spacing-12;
$collapse-list-margin-bottom:         $spacing-4;
$collapse-list-border-radius:         var(--#{$vudTokenPrefix}radius-sm);

$collapse-list-base-bg:               var(--#{$vudTokenPrefix}list-group-bg);
$collapse-list-hover-bg:              var(--#{$vudTokenPrefix}module-container-hover-bg-color);
$collapse-list-focus-outline:         var(--#{$vudTokenPrefix}anchor-focus-outline);
$collapse-list-expanded-bg:           var(--#{$vudTokenPrefix}bg-primary);
$collapse-list-expanded-border-color: var(--#{$vudTokenPrefix}list-group-border-color);

$collapse-list-shadow:                var(--#{$vudTokenPrefix}list-group-shadow);

$collapse-list-text-family:           $font-family-base;
$collapse-list-text-weight:           $normal;
$collapse-list-text-color:            var(--#{$vudTokenPrefix}body-font-color);
$collapse-list-text-size:             rem(14px);
$collapse-list-line-height:           rem(19px);

$caret-margin:                        $spacing-12;
$caret-top-offset:                    -$spacing-2;
$caret-blue-bg:                       var(--#{$vudTokenPrefix}caret-primary-bg);
$caret-blue-hover-bg:                 var(--#{$vudTokenPrefix}anchor-hover-color);


//*** element/component code ***
details {
    &.#{$vudClassPrefix}collapse-list {
        summary {
            cursor: pointer;
            list-style: none;
            border-radius: $collapse-list-border-radius;

            &:focus-visible {
                outline: $collapse-list-focus-outline solid rem(2px);
                outline-offset: rem(-1px);
            }
        }
    }
}

.#{$vudClassPrefix}group-list {
    padding: $collapse-list-base-space;

    border-radius: $collapse-list-border-radius;
    background-color: $collapse-list-base-bg;
    box-shadow: $collapse-list-shadow;

    .#{$vudClassPrefix}collapse-list {
        margin-bottom: $collapse-list-margin-bottom;

        &:last-child { margin-bottom: 0; }
    }
}

.#{$vudClassPrefix}collapse-list {
    border-radius: $collapse-list-border-radius;
    background-color: $collapse-list-base-bg;

    &[open], &.open, &.show {
        background-color: $collapse-list-expanded-bg;
        box-shadow: inset 0 0 0 rem(1px) $collapse-list-expanded-border-color;

        > .#{$vudClassPrefix}collapse-list-header {
            &:hover {
                .#{$vudClassPrefix}collapse-list-title {
                    border-radius: $collapse-list-border-radius $collapse-list-border-radius 0 0;
                    box-shadow: inset rem(1px) rem(1px) 0 0 $collapse-list-focus-outline, inset rem(-1px) 0 0 0 $collapse-list-focus-outline;
                }

                + .#{$vudClassPrefix}collapse-list-content  {
                    border-radius: 0 0 $collapse-list-border-radius $collapse-list-border-radius;
                    box-shadow: inset rem(1px) 0 0 0 $collapse-list-focus-outline, inset rem(-1px) rem(-1px) 0 0 $collapse-list-focus-outline;
                }
            }

            .#{$vudClassPrefix}caret {
                transform: rotate(180deg);
            }
        }

        .#{$vudClassPrefix}collapse-list {
            box-shadow: none;

            &:not([open]):not(.open):not(.show) {
                background-color: transparent;
            }

            .#{$vudClassPrefix}collapse-list-content {
                padding: $collapse-list-main-vt-space $collapse-list-hr-space;
            }
        }
    }

    &:not([open]):not(.open):not(.show) {
        > .#{$vudClassPrefix}collapse-list-content {
            display: none;
        }
    }

    .#{$vudClassPrefix}collapse-list-content {
        padding: $collapse-list-margin-bottom $collapse-list-base-space $collapse-list-main-vt-space;
    }

    .#{$vudClassPrefix}collapse-list {
        .#{$vudClassPrefix}collapse-list-title,
        .#{$vudClassPrefix}collapse-list-title.#{$vudClassPrefix}btn-link.#{$vudClassPrefix}btn {
            padding: $collapse-list-alt-vt-space $collapse-list-content-hr-space;
            line-height: 1.3;

            .#{$vudClassPrefix}caret {
                margin: 0 $caret-margin 0 0;
            }
        }
    }
}

.#{$vudClassPrefix}collapse-list-header {
    display: block;

    &:hover {
        cursor: pointer;
        
        .#{$vudClassPrefix}collapse-list-title {
            color: $collapse-list-text-color;
            background-color: $collapse-list-hover-bg;
            box-shadow: inset 0 0 0 rem(1px) $collapse-list-focus-outline;
        }

        + .#{$vudClassPrefix}collapse-list-content  {
            background-color: $collapse-list-hover-bg;
        }
    }

    .#{$vudClassPrefix}collapse-list-title,
    .#{$vudClassPrefix}collapse-list-title.#{$vudClassPrefix}btn-link.#{$vudClassPrefix}btn {
        display: block;
        width: 100%;
        margin-bottom: 0;
        padding: $collapse-list-main-vt-space $collapse-list-hr-space;

        color: $collapse-list-text-color;
        font: {
            family: $collapse-list-text-family;
            size: $collapse-list-text-size;
            weight: $collapse-list-text-weight;
        }
        text: {
            align: left;
            decoration: none;
        }

        border: 0;
        border-radius: $collapse-list-border-radius;

        .#{$vudClassPrefix}caret {
            position: relative;
            top: $caret-top-offset;
            margin-left: $caret-margin;
        }
    }

    a.#{$vudClassPrefix}collapse-list-title {
        &:focus {outline-offset: rem(-1px);}
    }
}


//*** old variant element/component with 'panel-group' class ***
.#{$vudClassPrefix}panel-group {
    details {
        summary {
            cursor: pointer;
            list-style: none;

            &:focus-visible {
                outline: $collapse-list-focus-outline solid rem(2px);
                outline-offset: rem(-1px);
            }

            &.#{$vudClassPrefix}panel-heading {
                .#{$vudClassPrefix}panel-title {
                    font: {
                        family: $collapse-list-text-family;
                        size: $collapse-list-text-size;
                        weight: $collapse-list-text-weight;
                    }
                    line-height: $collapse-list-line-height;
                }
            }

            .#{$vudClassPrefix}caret {
                position: relative;
                top: rem(1px);
                margin-left: $caret-margin;
            }
        }

        &[open] {
            > .#{$vudClassPrefix}panel-heading {
                .#{$vudClassPrefix}caret {
                    transform: rotate(180deg);
                }
            }
        }

        .#{$vudClassPrefix}panel-body {
            summary {
                &.#{$vudClassPrefix}panel-heading {
                    width: calc(100% + calc(2 * $collapse-list-hr-space));
                    margin: 0 (-$collapse-list-hr-space);
                    padding: $collapse-list-alt-vt-space $collapse-list-hr-space;
                }

                .#{$vudClassPrefix}caret {
                    margin: 0 $caret-margin 0 0;
                }
            }

            .#{$vudClassPrefix}panel {
                summary {
                    &.#{$vudClassPrefix}panel-heading {
                        padding: $collapse-list-alt-vt-space $collapse-list-hr-space;
                    }
                }
            }
        }
    }

    .#{$vudClassPrefix}panel-heading {
        [data-toggle="collapse"] {
            font: {
                family: $collapse-list-text-family;
                size: $collapse-list-text-size;
                weight: $collapse-list-text-weight;
            }
            line-height: $collapse-list-line-height;

            &:hover {
                .#{$vudClassPrefix}caret {
                    &.caret-large-blue { background-color: $caret-blue-hover-bg; }
                }
            }

            &:not(.collapsed) {
                .#{$vudClassPrefix}caret {
                    transform: rotate(180deg);
                }
            }

            .#{$vudClassPrefix}caret {
                position: relative;
                top: $caret-top-offset;
                margin-left: $caret-margin;

                &.caret-large-blue { background-color: $caret-blue-bg; }
            }
        }
    }

    .#{$vudClassPrefix}panel-collapse {
        &.collapse {
            display: none;

            &.in { display: block; }
        }

        .#{$vudClassPrefix}panel-heading, .#{$vudClassPrefix}panel-body {
            padding: $collapse-list-alt-vt-space $collapse-list-hr-space;
        }
    }

    .#{$vudClassPrefix}panel-body {
        [data-toggle="collapse"] {
            .#{$vudClassPrefix}caret {
                margin: 0 $caret-margin 0 0;
            }
        }

        .#{$vudClassPrefix}panel-collapse {
            .#{$vudClassPrefix}panel-body {
                padding-bottom: $collapse-list-alt-vt-space;
            }
        }
    }

    > .#{$vudClassPrefix}panel {
        > .#{$vudClassPrefix}panel-heading {
            padding: $collapse-list-main-vt-space $collapse-list-hr-space;
        }

        .#{$vudClassPrefix}panel-body {
            padding-top: $collapse-list-alt-vt-space;

            .#{$vudClassPrefix}panel-heading {
                width: calc(100% + calc(2 * $collapse-list-hr-space));
                margin: 0 (-$collapse-list-hr-space);
                padding: $collapse-list-alt-vt-space $collapse-list-hr-space;
            }
        }
    }
}

