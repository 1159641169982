// Cards list - base styles

//*** scss local variable ***
$card-list-item-bg:                    var(--#{$vudTokenPrefix}card-list-bg);
$card-list-item-hover-bg:              var(--#{$vudTokenPrefix}card-list-hover-bg);
$card-list-item-selected-bg:           var(--#{$vudTokenPrefix}card-list-selected-bg);

$card-list-item-divider-bg:            var(--#{$vudTokenPrefix}module-container-divider);

$card-list-item-border-color:          var(--#{$vudTokenPrefix}card-list-border-color);
$card-list-item-hover-border-color:    var(--#{$vudTokenPrefix}card-list-hover-border-color);
$card-list-item-focus-border-color:    var(--#{$vudTokenPrefix}button-focus-border-color);
$card-list-item-selected-border-color: var(--#{$vudTokenPrefix}card-list-selected-border-color);

$card-list-item-border-radius:         var(--#{$vudTokenPrefix}radius-md);

$card-list-text-color:                 var(--#{$vudTokenPrefix}card-list-text-color);
$card-list-body-text-color:            var(--#{$vudTokenPrefix}body-font-color);

$card-list-hover-shadow:               var(--#{$vudTokenPrefix}card-list-hover-shadow);
$card-list-selected-shadow:            var(--#{$vudTokenPrefix}card-list-selected-shadow);

//*** element/component code ***
.#{$vudClassPrefix}card-list {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;

    margin: 0;
    padding: 0;

    list-style: none;

    &.#{$vudClassPrefix}card-list-hover {
        .#{$vudClassPrefix}card-list-item {
            &:hover, &.hover {
                &:not(:has(a:hover)):not(:has(button:hover)) {
                    cursor: pointer;
                    background-color: $card-list-item-hover-bg;
                    box-shadow: inset 0 0 0 rem(1px) $card-list-item-hover-border-color, $card-list-hover-shadow;
                }
            }
        }
    }

    &.#{$vudClassPrefix}card-list-grid {
        display: grid;
        // grid: none;
        // grid-auto-flow: dense;
        gap: $spacing-16;

        .#{$vudClassPrefix}card-list-item {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

    }

    > a, > button {
        cursor: pointer;

        &.disabled, &:disabled {
            cursor: default;
        }
    }

    .#{$vudClassPrefix}card-list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        gap: $spacing-16;
        padding: $spacing-16;
        margin: 0;

        color: $card-list-body-text-color;
        text-decoration: none;

        border-radius: $card-list-item-border-radius;
        border: 0;
        background-color: $card-list-item-bg;

        box-shadow: inset 0 0 0 rem(1px) $card-list-item-border-color;

        &.selected, &.active {
            background-color: $card-list-item-selected-bg;
            box-shadow: inset 0 0 0 rem(1px) $card-list-item-selected-border-color, $card-list-selected-shadow;
        }

        &:focus, &.focus {
            &, &:hover {
                outline: 0;
                box-shadow: inset 0 0 0 rem(2px) $card-list-item-focus-border-color, $card-list-hover-shadow;
            }
        }

        > * { padding: 0; }

        .#{$vudClassPrefix}btn, p { margin-bottom: 0; }

        .vismaicon {
            display: block;
            top: auto;
            margin-right: rem(12px);
        }

        &,
        .action,
        .icon,
        .title,
        .description {
            align-items: center;
            display: flex;
        }

        .title {
            margin: 0;
            font-size: var(--#{$vudTokenPrefix}font-size-lg);

            .vismaicon { margin-right: rem(24px); }

            h4 { font-size: var(--#{$vudTokenPrefix}font-size-lg); }

            .date { font-size: var(--#{$vudTokenPrefix}font-size-sm); }
        }

        .description {
            flex-grow: 1;
            flex-shrink: 1;
            color: $card-list-text-color;

            .date { font-weight: $normal; }
        }

        .icon,
        .action {
            text-align: center;

            .vismaicon { margin: 0 $spacing-8; }

            > a {
                &:hover { background-color: transparent; }
            }
        }

        .icon {
            justify-content: center;

            > * { min-width: rem(36px); }

            > a {
                display: block;
                padding: rem(6px) rem(2px);

                &:hover {
                    background-color: $card-list-item-hover-bg;
                    box-shadow: 0 0 0 rem(1px) $card-list-item-hover-border-color;
                }
            }

            .vismaicon + * {
                display: block;
                margin-top: rem(3px);
            }
        }

        .date {
            text-align: center;
            line-height: 1.2;
            position: relative;
            color: $card-list-text-color;

            > *:not(span) { color: inherit; }

            span {
                display: block;

                color: $card-list-body-text-color;
                font-size: var(--#{$vudTokenPrefix}font-size-xl);
            }
        }

        .price {
            min-width: rem(126px);

            color: $card-list-text-color;
            font-size: var(--#{$vudTokenPrefix}font-size-lg);
            text-align: right;

            b, strong { color: $card-list-body-text-color; }

            @media (max-width: $screen-md-min) {
                border-top: rem(1px) solid $card-list-item-divider-bg;
                margin-bottom: rem(-16px);
            }
        }

        .divider {
            border: {
                width: 0 rem(1px);
                style: solid;
                color: $card-list-item-divider-bg;
            }

            .vismaicon { margin: 0; }

            + .divider { border-left-width: 0; }

            &:first-child { border-left-width: 0; }

            &:last-child { border-left-width: 0; }
        }
    }
}


.#{$vudClassPrefix}card-list-grid {
    .#{$vudClassPrefix}card-list-item {

        .icon,
        .description,
        .action {
            flex-direction: column;
        }

        .description {
            display: flex;
            gap: rem(4px);
        }

        .date {
            margin-right: 0;

            &::before { display: none; }
        }
    }
}
