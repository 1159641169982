@import '@vismaux/vud-icons';

.icon {
  width: 62px;
  height: 70px;
  background-position: center;
}

.icon-sm {
  width: 12px;
  height: 12px;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.icon-md {
  width: 24px;
  height: 24px;
}

.icon-36 {
  width: 36px;
  height: 36px;
}

.icon-40 {
  width: 40px;
  height: 40px;
}

.icon-lg {
  width: 82px;
  height: 82px;
}

.import-icon {
  background-image: url('/assets/icons/96_data_import.svg');
}

.next-icon {
  background-image: url('/assets/icons/arrows_next_medium.svg');
  position: absolute;
  background-size: auto 100%;
}

.filter-icon {
  background-image: url('/assets/icons/filter_button.svg');
  background-size: auto 100%;
}

.arrow-down {
  background-image: url('/assets/icons/arrows_down_medium.svg');
  width: 10px;
  height: 10px;
  background-position: 10px;
}

.arrow-up {
  background-image: url('/assets/icons/arrows_up_medium.svg');
  width: 10px;
  height: 10px;
  background-position: 10px;
}

.vismaicon-chevron-down::before {
  mask-image: url('/assets/icons/dyn-icon-24-chevron-down.svg');
}

.vismaicon-chevron-up::before {
  mask-image: url('/assets/icons/dyn-icon-24-chevron-up.svg');
}

.logo-icon {
  width: 102px;
  height: 20px;
  background: url('/assets/icons/logo.svg') no-repeat;
}

.logo-icon-dark {
  width: 112px;
  height: 21px;
  background: url('/assets/icons/visma-logo-dark.svg') no-repeat;
}

.upload-arrow-icon {
  background-image: url('/assets/icons/upload_arrow.svg');
  background-size: auto 100%;
}

.baloons-icon {
  background-image: url('/assets/icons/Ballons.svg');
  width: 164px;
  height: 216px;
}

.hero-icon {
  background-image: url('/assets/icons/flying_hero.svg');
  width: 271px;
  height: 242px;
}

.balloon-pop-icon {
  background-image: url('/assets/icons/balloon_pop_icon.svg');
  width: 220px;
  height: 196px;
}

.info-icon {
  background-image: url('/assets/icons/50_info.svg');
  background-size: auto 100%;
}

.error-img {
  background-image: url('/assets/icons/error.svg');
  width: 200px;
  height: 200px;
}

summary::marker {
  content: '';
}

.error-icon {
  display: block;
  background-image: url('/assets/icons/error_icon.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.delete-icon {
  display: block;
  background-image: url('/assets/icons/delete_icon.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.bulk-operations-icon {
  background-image: url('/assets/icons/24_content_edit.svg');
}

.new-import-icon {
  background-image: url('/assets/icons/24_order_imported.svg');
}

.update-salaries-icon {
  background-image: url('/assets/icons/24_expense-3.svg');

  &.disabled {
    opacity: 0.4;
  }
}

.move-left-icon {
  background-image: url('/assets/icons/24_Move_Left.svg');
}

.menu-icon {
  display: inline-block;
  position: relative;
  margin-right: 1rem;

  &.move-left-icon {
    margin-right: 0;
  }
}

.hrm-icon {
  background-image: url('/assets/icons/hrm_icon.png');
  width: 28px;
  height: 26px;
}

.payroll-icon {
  background-image: url('/assets/icons/Payroll_Icon_RGB_Light.svg');
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}

.payroll-icon-rgb {
  background-image: url('/assets/icons/Payroll_Icon_RGB.svg');
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
}

.navbar-default .navbar-header .navbar-brand.open .dropdown-toggle .payroll-icon {
  background-image: url('/assets/icons/Payroll_Icon_RGB.svg');
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}

.icon-down-2 {
  top: 2px;
}

.feedback-icon {
  display: block;
  background-image: url('/assets/icons/24_feedback.svg');
}

.add-green-icon {
  background-image: url('/assets/icons/24_add_green.svg');
}

.grid-vb-icon {
  background-image: url('/assets/icons/Vacation_Beach.svg');
}

.grid-next-icon {
  background-image: url('/assets/icons/arrows_next_medium.svg');
  background-size: auto 100%;
  background-position-x: 84px;
}

.grid-payslip-icon {
  background-image: url('/assets/icons/Mail_Invoice.svg');
}

.grid-wageruns-icon {
  background-image: url('/assets/icons/Payment.svg');
}

.grid-paycodes-icon {
  background-image: url('/assets/icons/Money_Received.svg');
}

.grid-company-icon {
  background-image: url('/assets/icons/Organisation_Building_1.svg');
}

.grid-creditors-icon {
  background-image: url('/assets/icons/Money_Bag.svg');
}

.grid-unions-icon {
  background-image: url('/assets/icons/Users_Organisation.svg');
}

.grid-pension-icon {
  background-image: url('/assets/icons/Piggy_Bank.svg');
}

.grid-accounts-icon {
  background-image: url('/assets/icons/Tax_Payment.svg');
}

.grid-acc-types-icon {
  background-image: url('/assets/icons/Tax_Payment.svg');
}

.grid-acc-dimension-icon {
  background-image: url('/assets/icons/Leaning_Book.svg');
}

.grid-acc-dimension-val-icon {
  background-image: url('/assets/icons/Target.svg');
}

.grid-cars-icon {
  background-image: url('/assets/icons/Car.svg');
}

.grid-worktime-agreements-icon {
  background-image: url('/assets/icons/User_Time.svg');
}

.grid-tags-icon {
  background-image: url('/assets/icons/Price_tag.svg');
}

.bg-size-cover {
  background-size: cover;
}

.card-home-icon {
  background-image: url('/assets/icons/24_Home.svg');
}

.card-phone-icon {
  background-image: url('/assets/icons/24_Platform_mobile.svg');
}

.card-email-icon {
  background-image: url('/assets/icons/24_Email.svg');
}

.file-drag-icon {
  mask-image: url('/assets/icons/file-drag.svg');
  mask-size: cover;
  background-color: var(--icon-bg);
}

.btn.btn-icon.btn-transparent .vismaicon:not(.vismaicon-flags):not(.vismaicon-media)::before,
.btn.btn-icon.btn-transparent:hover .vismaicon:not(.vismaicon-flags):not(.vismaicon-media)::before {
  background-color: var(--blue-60);
}

.bordered-arrow-down-icon {
  mask-image: url('/assets/icons/24_Arrow_down.svg');
}

.import-file-icon {
  background-image: url('/assets/icons/24_import.svg');
  width: 23px;
  height: 24px;
}

.add-icon {
  background-image: url('/assets/icons/wrapper.svg');
  background-size: auto 100%;
}

.disabled .vismaicon-edit:before {
  mask-image: url('/assets/icons/edit_disabled.svg');
  -webkit-mask-image: url('/assets/icons/edit_disabled.svg') !important;
  mask-size: 24px;
  background-size: auto 100%;
}

.navbar.navbar-default .navbar-collapse .first-level .second-level > li > * .vismaicon::before {
  background-color: var(--blue-60);
}
