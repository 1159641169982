// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .grid-cols#{$infix}-none      { grid-template-columns: none !important; }
    .grid-cols#{$infix}-1         { grid-template-columns: repeat(1, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-2         { grid-template-columns: repeat(2, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-3         { grid-template-columns: repeat(3, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-4         { grid-template-columns: repeat(4, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-5         { grid-template-columns: repeat(5, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-6         { grid-template-columns: repeat(6, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-7         { grid-template-columns: repeat(7, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-8         { grid-template-columns: repeat(8, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-9         { grid-template-columns: repeat(9, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-10        { grid-template-columns: repeat(10, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-11        { grid-template-columns: repeat(11, minmax(0, 1fr)) !important; }
    .grid-cols#{$infix}-12        { grid-template-columns: repeat(12, minmax(0, 1fr)) !important; }

    .grid-col#{$infix}-auto         { grid-column: auto !important; }
    .grid-col-span#{$infix}-1         { grid-column: span 1 / span 1 !important; }
    .grid-col-span#{$infix}-2         { grid-column: span 2 / span 2 !important; }
    .grid-col-span#{$infix}-3         { grid-column: span 3 / span 3 !important; }
    .grid-col-span#{$infix}-4         { grid-column: span 4 / span 4 !important; }
    .grid-col-span#{$infix}-5         { grid-column: span 5 / span 5 !important; }
    .grid-col-span#{$infix}-6         { grid-column: span 6 / span 6 !important; }
    .grid-col-span#{$infix}-7         { grid-column: span 7 / span 7 !important; }
    .grid-col-span#{$infix}-8         { grid-column: span 8 / span 8 !important; }
    .grid-col-span#{$infix}-9         { grid-column: span 9 / span 9 !important; }
    .grid-col-span#{$infix}-10        { grid-column: span 10 / span 10 !important; }
    .grid-col-span#{$infix}-11        { grid-column: span 11 / span 11 !important; }
    .grid-col-span#{$infix}-12        { grid-column: span 12 / span 12 !important; }

    .grid-col-span#{$infix}-full      { grid-column: 1 / -1 !important; }

    .grid-col-start#{$infix}-auto      { grid-column-start: auto !important; }
    .grid-col-start#{$infix}-1         { grid-column-start: 1 !important; }
    .grid-col-start#{$infix}-2         { grid-column-start: 2 !important; }
    .grid-col-start#{$infix}-3         { grid-column-start: 3 !important; }
    .grid-col-start#{$infix}-4         { grid-column-start: 4 !important; }
    .grid-col-start#{$infix}-5         { grid-column-start: 5 !important; }
    .grid-col-start#{$infix}-6         { grid-column-start: 6 !important; }
    .grid-col-start#{$infix}-7         { grid-column-start: 7 !important; }
    .grid-col-start#{$infix}-8         { grid-column-start: 8 !important; }
    .grid-col-start#{$infix}-9         { grid-column-start: 9 !important; }
    .grid-col-start#{$infix}-10        { grid-column-start: 10 !important; }
    .grid-col-start#{$infix}-11        { grid-column-start: 11 !important; }
    .grid-col-start#{$infix}-12        { grid-column-start: 12 !important; }
    .grid-col-start#{$infix}-13        { grid-column-start: 13 !important; }
    
    .grid-col-end#{$infix}-auto      { grid-column-end: auto !important; }
    .grid-col-end#{$infix}-1         { grid-column-end: 1 !important; }
    .grid-col-end#{$infix}-2         { grid-column-end: 2 !important; }
    .grid-col-end#{$infix}-3         { grid-column-end: 3 !important; }
    .grid-col-end#{$infix}-4         { grid-column-end: 4 !important; }
    .grid-col-end#{$infix}-5         { grid-column-end: 5 !important; }
    .grid-col-end#{$infix}-6         { grid-column-end: 6 !important; }
    .grid-col-end#{$infix}-7         { grid-column-end: 7 !important; }
    .grid-col-end#{$infix}-8         { grid-column-end: 8 !important; }
    .grid-col-end#{$infix}-9         { grid-column-end: 9 !important; }
    .grid-col-end#{$infix}-10        { grid-column-end: 10 !important; }
    .grid-col-end#{$infix}-11        { grid-column-end: 11 !important; }
    .grid-col-end#{$infix}-12        { grid-column-end: 12 !important; }
    .grid-col-end#{$infix}-13        { grid-column-end: 13 !important; }
    
    .grid-rows#{$infix}-1         { grid-template-rows: repeat(1, minmax(0, 1fr)) !important; }
    .grid-rows#{$infix}-2         { grid-template-rows: repeat(2, minmax(0, 1fr)) !important; }
    .grid-rows#{$infix}-3         { grid-template-rows: repeat(3, minmax(0, 1fr)) !important; }
    .grid-rows#{$infix}-4         { grid-template-rows: repeat(4, minmax(0, 1fr)) !important; }
    .grid-rows#{$infix}-5         { grid-template-rows: repeat(5, minmax(0, 1fr)) !important; }
    .grid-rows#{$infix}-6         { grid-template-rows: repeat(6, minmax(0, 1fr)) !important; }
    .grid-rows#{$infix}-none      { grid-template-rows: none !important; }

    .grid-row#{$infix}-auto         { grid-row: auto !important; }
    .grid-row-span#{$infix}-1         { grid-row: span 1 / span 1 !important; }
    .grid-row-span#{$infix}-2         { grid-row: span 2 / span 2 !important; }
    .grid-row-span#{$infix}-3         { grid-row: span 3 / span 3 !important; }
    .grid-row-span#{$infix}-4         { grid-row: span 4 / span 4 !important; }
    .grid-row-span#{$infix}-5         { grid-row: span 5 / span 5 !important; }
    .grid-row-span#{$infix}-6         { grid-row: span 6 / span 6 !important; }

    .grid-row-span#{$infix}-full      { grid-row: 1 / -1 !important; }

    .grid-row-start#{$infix}-auto      { grid-row-start: auto !important; }
    .grid-row-start#{$infix}-1         { grid-row-start: 1 !important; }
    .grid-row-start#{$infix}-2         { grid-row-start: 2 !important; }
    .grid-row-start#{$infix}-3         { grid-row-start: 3 !important; }
    .grid-row-start#{$infix}-4         { grid-row-start: 4 !important; }
    .grid-row-start#{$infix}-5         { grid-row-start: 5 !important; }
    .grid-row-start#{$infix}-6         { grid-row-start: 6 !important; }
    .grid-row-start#{$infix}-7         { grid-row-start: 7 !important; }
    
    .grid-row-end#{$infix}-auto      { grid-row-end: auto !important; }
    .grid-row-end#{$infix}-1         { grid-row-end: 1 !important; }
    .grid-row-end#{$infix}-2         { grid-row-end: 2 !important; }
    .grid-row-end#{$infix}-3         { grid-row-end: 3 !important; }
    .grid-row-end#{$infix}-4         { grid-row-end: 4 !important; }
    .grid-row-end#{$infix}-5         { grid-row-end: 5 !important; }
    .grid-row-end#{$infix}-6         { grid-row-end: 6 !important; }
    .grid-row-end#{$infix}-7         { grid-row-end: 7 !important; }
    
    
    .grid-flow#{$infix}-row { grid-auto-flow: row !important; }
    .grid-flow#{$infix}-col { grid-auto-flow: column !important; }
    .grid-flow#{$infix}-dense { grid-auto-flow: dense !important; }
    .grid-flow#{$infix}-row-dense { grid-auto-flow: row dense !important; }
    .grid-flow#{$infix}-col-dense { grid-auto-flow: column dense !important; }
    
    
    .grid-auto-cols#{$infix}-auto { grid-auto-columns: auto !important; }
    .grid-auto-cols#{$infix}-min	{ grid-auto-columns: min-content !important; }
    .grid-auto-cols#{$infix}-max	{ grid-auto-columns: max-content !important; }
    .grid-auto-cols#{$infix}-fr { grid-auto-columns: minmax(0, 1fr) !important; }
    
    
    .grid-auto-rows#{$infix}-auto { grid-auto-rows: auto !important; }
    .grid-auto-rows#{$infix}-min	{ grid-auto-rows: min-content !important; }
    .grid-auto-rows#{$infix}-max	{ grid-auto-rows: max-content !important; }
    .grid-auto-rows#{$infix}-fr { grid-auto-rows: minmax(0, 1fr) !important; }
    
    

    

  }
}
