
//# Pictograms

//*** scss local variable ***
$pictogram-width:            rem(64px);
$pictogram-height:           rem(64px);

$pictogram-bg:               var(--#{$vudTokenPrefix}pictogram-bg);

$pictogram-primary-bg:       var(--#{$vudTokenPrefix}pictogram-primary-bg);
$pictogram-secondary-bg:     var(--#{$vudTokenPrefix}pictogram-secondary-bg);
$pictogram-tertiary-bg:      var(--#{$vudTokenPrefix}pictogram-tertiary-bg);
$pictogram-disabled-bg:      var(--#{$vudTokenPrefix}pictogram-disabled-bg);

$pictogram-default-mask-img: url('#{$image-path}/pictograms_monocolor.svg');

//*** element/component code ***
//=== Basic pictogram implementation
.visma-pictogram {
    display: inline-block;
    position: relative;
    user-select: none;

    &::before {
        display: block;
        content: "" !important;
        position: relative;
        width: $pictogram-width;
        height: $pictogram-height;
        margin: 0 auto;

        background: {
            color: $pictogram-bg;
            size: cover; 
            repeat: no-repeat;
        };
        -webkit-mask-image: $pictogram-default-mask-img;
        mask-image: $pictogram-default-mask-img;
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
    }

    &.visma-pictogram-secondary {
        &::before {
            background: {
                color: $pictogram-secondary-bg;
            };
        }
    }

    &.visma-pictogram-tertiary {
        &::before {
            background: {
                color: $pictogram-tertiary-bg;
            };
        }
    }

    // disabled state
    .disabled &, &.disabled,
    :disabled &, &:disabled {
        pointer-events: none;

        &::before { background-color: $pictogram-disabled-bg !important; }
    }
}

