
//# General input styles

//*** scss local variable ***
// inputs/textarea
$input-border-color:               var(--#{$vudTokenPrefix}input-border-color);
$input-hover-border-color:         var(--#{$vudTokenPrefix}input-hover-border-color);
$input-focus-border-color:         var(--#{$vudTokenPrefix}input-focus-border-color);
$input-disabled-border-color:      var(--#{$vudTokenPrefix}input-disabled-border-color);
$input-error-border-color:         var(--#{$vudTokenPrefix}input-error-border-color);

$input-border-radius:              var(--#{$vudTokenPrefix}radius-xs);

$input-bg:                         var(--#{$vudTokenPrefix}input-bg);
$input-hover-bg:                   var(--#{$vudTokenPrefix}input-hover-bg);
$input-disabled-bg:                var(--#{$vudTokenPrefix}input-disabled-bg);
$input-error-bg:                   var(--#{$vudTokenPrefix}input-error-bg);
$input-read-only-bg:               var(--#{$vudTokenPrefix}input-read-only-bg);
$input-read-only-inverted-bg:      var(--#{$vudTokenPrefix}input-read-only-inverted-bg);

$input-shadow:                     var(--#{$vudTokenPrefix}input-shadow);
$input-hover-shadow:               var(--#{$vudTokenPrefix}input-hover-shadow);
$input-focus-shadow:               var(--#{$vudTokenPrefix}input-focus-shadow);

$input-placeholder-color:          var(--#{$vudTokenPrefix}input-placeholder-color);

$input-text-size:                  var(--#{$vudTokenPrefix}font-size-sm);

$input-text-color:                 var(--#{$vudTokenPrefix}input-text-color);
$input-disabled-text-color:        var(--#{$vudTokenPrefix}input-disabled-text-color);
$input-error-text-color:           var(--#{$vudTokenPrefix}input-error-text-color);


// inputs - type button
$input-button-border-color:                   var(--#{$vudTokenPrefix}button-border-color);
$input-button-hover-border-color:             var(--#{$vudTokenPrefix}button-hover-border-color);
$input-button-focus-border-color:             var(--#{$vudTokenPrefix}button-focus-border-color);
$input-button-pressed-border-color:           var(--#{$vudTokenPrefix}button-pressed-border-color);
$input-button-disabled-border-color:          var(--#{$vudTokenPrefix}button-disabled-border-color);

$input-button-border-radius:                  var(--#{$vudTokenPrefix}radius-sm);

$input-button-bg:                             var(--#{$vudTokenPrefix}button-bg);
$input-button-hover-bg:                       var(--#{$vudTokenPrefix}button-hover-bg);
$input-button-pressed-bg:                     var(--#{$vudTokenPrefix}button-pressed-bg);
$input-button-disabled-bg:                    var(--#{$vudTokenPrefix}button-disabled-bg);

$input-button-shadow:                         var(--#{$vudTokenPrefix}button-shadow);

$input-button-text-color:                     var(--#{$vudTokenPrefix}button-text-color);
$input-button-disabled-text-color:            var(--#{$vudTokenPrefix}button-disabled-text-color);


//*** element/component code ***
// Input placeholder style
::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
    color: $input-placeholder-color;
    font-style: italic;
}

.#{$vudClassPrefix}form-control {
    display: block;
    width: 100%;
}

/* Change Autocomplete styles in Chrome*/
input, textarea {
    &:-internal-autofill-selected  {
        color: -internal-light-dark($input-text-color) !important;
    }

    &:-webkit-autofill  {
        -webkit-box-shadow: inset 0 0 0 rem(1000px) $input-bg !important;
        box-shadow: inset 0 0 0 rem(1000px) $input-bg !important;

        &, &:hover, &:focus {
            -webkit-text-fill-color: $input-text-color;
        }

        &:hover {
            box-shadow: inset 0 0 0 rem(1000px) $input-hover-bg, $input-hover-shadow !important;
        }

        &:focus {
            box-shadow: inset 0 0 0 rem(1px) $input-focus-border-color, inset 0 0 0 rem(1000px) $input-bg, $input-focus-shadow !important;
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {
            -webkit-text-fill-color: $input-disabled-text-color;
            box-shadow: inset 0 0 0 rem(1000px) $input-bg !important;
        }
    }
}

// upload button for Firefox
input[type=file]::file-selector-button {
    cursor: pointer;
    height: $spacing-32;
    padding: $spacing-8 $spacing-8 $spacing-8 $spacing-16;

    color: $input-text-color;

    font: {
        size: $input-text-size;
        weight: $normal;
    }
    line-height: $spacing-16;

    outline: none;
    border-radius: $input-border-radius;
    border: rem(1px) solid $input-border-color;
    background-color: $input-bg;
    box-shadow: $input-shadow;

    &:focus {
        outline: none;
        border-color: $input-focus-border-color;
        box-shadow: inset 0 0 0 rem(1px) $input-focus-border-color, $input-focus-shadow;
    }
}

// upload button for Chrome
// &[type=file]::-ms-browse,
input[type=file] {
    &::-webkit-file-upload-button {
        cursor: pointer;
        height: $spacing-32;
        padding: $spacing-4 $spacing-16;

        color: $input-button-text-color;

        font: {
            size: $input-text-size;
            weight: $normal;
        }
        line-height: $spacing-16;

        outline: none;
        border-radius: $input-button-border-radius;
        border: rem(1px) solid $input-button-border-color;
        background-color: $input-button-bg;
        box-shadow: $input-button-shadow;
    }

    &:hover, &.hover {
        &::-webkit-file-upload-button{
            border-color: $input-button-hover-border-color;
            background-color: $input-button-hover-bg;
        }
    }

    &:active::-webkit-file-upload-button, &.active::-webkit-file-upload-button {
        &::-webkit-file-upload-button{
            border-color: $input-button-pressed-border-color;
            background-color: $input-button-pressed-bg;
            box-shadow: none;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &:focus::-webkit-file-upload-button, &::-webkit-file-upload-button:focus {
        outline: none;
        border-color: $input-button-focus-border-color;
        box-shadow: inset 0 0 0 rem(1px) $input-button-focus-border-color, $input-button-shadow;
    }

    &:disabled, &.disabled,
    :disabled &, .disabled & {
        pointer-events: none;

        &::-webkit-file-upload-button{
            color: $input-button-disabled-text-color;

            border-color: $input-button-disabled-border-color;
            background-color: $input-button-disabled-bg;
            box-shadow: none;
        }
    }
}


// Base 'input' tag style
input {
    &, &.#{$vudClassPrefix}form-control {
        box-sizing: border-box;
        height: $spacing-32;

        font: $font-sm;
        line-height: $spacing-16;

        //### input base, focus state
        &.focus, &:focus {
            outline: 0;

            border-color: $input-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $input-focus-border-color, $input-focus-shadow;
        }

        //### input base, disabled state
        .disabled &, :disabled &,
        &.disabled, &:disabled {
            cursor: default; 
            pointer-events: none;
        }

        &[type="search"] {
            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        //### 'default' input style
        &[type="text"],
        &[type="password"],
        &[type="datetime"],
        &[type="datetime-local"],
        &[type="date"],
        &[type="month"],
        &[type="time"],
        &[type="week"],
        &[type="number"],
        &[type="email"],
        &[type="url"],
        &[type="search"],
        &[type="tel"],
        &[type="color"] {
            padding: $spacing-8 $spacing-8 $spacing-8 $spacing-16;

            color: $input-text-color;

            border-radius: $input-border-radius;
            border: rem(1px) solid $input-border-color;
            background-color: $input-button-bg;
            box-shadow: $input-shadow;

            -webkit-appearance: none;
            -moz-appearance: textfield;

            &.hover, &:hover {
                border-color: $input-hover-border-color;
                background-color: $input-hover-bg;
                box-shadow: $input-hover-shadow;
            }

            &.focus, &:focus {
                border-color: $input-focus-border-color;
                box-shadow: inset 0 0 0 rem(1px) $input-focus-border-color, $input-focus-shadow;
            }

            .disabled &, :disabled &,
            &.disabled, &:disabled {
                color: $input-disabled-text-color;

                border-color: $input-disabled-border-color;
                background-color: $input-disabled-bg;
                box-shadow: none;

                &::placeholder,
                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder {
                    color: $input-disabled-text-color;
                }
            }
        }

        // the clear "X" button for text inputs on IE/Edge.
        &:-ms-clear { display: none; }

        // validation state
        .has-error &, &.has-error {
            &, &.hover, &:hover, &.focus, &:focus {
                color: $input-error-text-color;

                border-color: $input-error-border-color;
                background-color: $input-error-bg;
            }

            &.focus, &:focus {
                box-shadow: inset 0 0 0 rem(1px) $input-error-border-color;
            }
        }

        // large inputs
        &.#{$vudClassPrefix}input-lg { height: $spacing-48; }

        // small inputs
        &.#{$vudClassPrefix}input-sm {
            height: $spacing-24;
            padding-bottom: $spacing-4;
            padding-top: $spacing-4;
        }

        // hide controls for inputs type numbers
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }

        //
        &[type="number"] {
            &.right-direction {
                &, .#{$vudClassPrefix}form-group & {
                    padding: $spacing-8 $spacing-16 $spacing-8 $spacing-8;

                    &.#{$vudClassPrefix}input-sm {
                        padding: $spacing-4 $spacing-16 $spacing-4 $spacing-8;
                    }
                }
            }

            &.#{$vudClassPrefix}spinner {
                -moz-appearance: number-input;
            }
        }

        //
        &[type="number"],
        &[type="datetime"],
        &[type="datetime-local"],
        &[type="date"],
        &[type="month"],
        &[type="week"],
        &[type="time"] {
            &.#{$vudClassPrefix}spinner {
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: inner-spin-button;
                }
            }
        }

        // upload input type / file type
        &[type="file"] {
            padding-top: 0;
            padding-left: $spacing-4;
        }
    }
}

// Base 'textarea' tag style
textarea {
    &, &.#{$vudClassPrefix}form-control {
        height: initial;
        padding: $spacing-12 $spacing-2 $spacing-2 $spacing-12;

        color: $input-text-size;
        font: $font-sm;
        line-height: 1.7;

        border-radius: $input-border-radius;
        border: rem(1px) solid $input-border-color;
        background-color: $input-bg;
        box-shadow: $input-shadow;

        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        //### base textarea, hover state
        &.hover, &:hover {
            border-color: $input-hover-border-color;
            box-shadow: $input-hover-shadow;
        }

        //### base textarea, focus state
        &.focus, &:focus {
            outline: 0;
            border-color: $input-focus-border-color;
            box-shadow: inset 0 0 0 rem(1px) $input-focus-border-color, $input-focus-shadow;
        }

        //### base textarea, disabled state
        .disabled &, :disabled &,
        &.disabled, &:disabled {
            cursor: default;
            pointer-events: none;
            resize: none;

            color: $input-disabled-text-color;

            border-color: $input-disabled-border-color;
            box-shadow: none !important;
        }

        //### validation state
        .has-error &, &.has-error {
            &, &.hover, &:hover, &.focus, &:focus {
                color: $input-text-color;

                border-color: $input-error-border-color;
                box-shadow: $input-shadow;
            }

            &.focus, &:focus {
                box-shadow: inset 0 0 0 rem(1px) $input-disabled-border-color, $input-shadow;
            }
        }
    }
}


// full width for inputs when in grid
input, textarea {
    &, &.#{$vudClassPrefix}form-control {
        [class*="col-xs-"] > &,
        [class*="col-sm-"] > &,
        [class*="col-md-"] > &,
        [class*="col-lg-"] > & {
            width: 100%;
        }
    }
}


// read only state for input and textarea
input, textarea {
    &, &.#{$vudClassPrefix}form-control {
        &[readonly] {
            cursor: default;
            resize: none;

            &, &.hover, &:hover,
            &.focus, &:focus,
            .disabled &, :disabled &,
            &.disabled, &:disabled {
                cursor: default;
                outline: 0;
                border: 0;
                background-color: $input-read-only-bg;
                box-shadow: none;

                &.inverted {
                    background-color: $input-read-only-inverted-bg !important;
                    box-shadow: inset 0 0 0 rem(1000px) $input-read-only-inverted-bg !important;
                }
            }
        }
    }
}


// error indicator/icon
.has-error .vismaicon.vismaicon-error,
.has-error ~ .vismaicon.vismaicon-error {
    position: absolute;
    top: 50%;
    right: rem(-20px);
    transform: translateY(rem(-8px));
    cursor: pointer;
}

.checkbox.has-error .vismaicon.vismaicon-error,
.has-error .checkbox .vismaicon.vismaicon-error,
.has-error .checkbox .vismaicon.vismaicon-error,
.has-error .checkbox .vismaicon.vismaicon-error,
.has-error .radio .vismaicon.vismaicon-error,
.has-error .radio .vismaicon.vismaicon-error,
.radio.has-error .vismaicon.vismaicon-error,
.radio.has-error .vismaicon.vismaicon-error {
    right: -$spacing-32;
}

.has-error .vismaicon-error:hover ~ .#{$vudClassPrefix}tooltip.#{$vudClassPrefix}tooltip-error,
.has-error ~ .vismaicon-error:hover ~ .#{$vudClassPrefix}tooltip.#{$vudClassPrefix}tooltip-error {
    display: block;
    cursor: pointer;
}
