// Datepicker Popup

//*** scss local variable ***

$datepicker-icon-mask:         url(#{$image-path}/datepicker.svg);
$datepicker-icon-bg:           var(--#{$vudTokenPrefix}icon-cta-bg);
$datepicker-icon-disabled-bg:  var(--#{$vudTokenPrefix}icon-disabled-bg);

//*** element/component code ***
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.#{$vudClassPrefix}datepicker {
    input[type="date"],
    input[type="text"] {
        padding-right: $input-btn-dropdown-padding-x;

        + .icon {
            display: block;
            width: rem(16px);
            height: rem(16px);
            position: absolute;
            right: $spacing-8;
            top: $spacing-8;
            mask: $datepicker-icon-mask;
            -webkit-mask: $datepicker-icon-mask;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: right 0 top 0;
            -webkit-mask-position: right 0 top 0;
            background-color: $datepicker-icon-bg;
            cursor: pointer;
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {
            + .icon {
                background-color: $datepicker-icon-disabled-bg;
            }
        }
    }

    .control {
        position: relative;

        input[type="date"],
        input[type="text"] {
            width: 100%;
        }
    }

    .#{$vudClassPrefix}dropdown {
        .#{$vudClassPrefix}dropdown-menu {
            display: block;
            top: 100%;
            z-index: 2;
        }
    }
}

// Label
.#{$vudClassPrefix}field {
    label.#{$vudClassPrefix}label {
        background-color: transparent;
        font-weight: normal;
        font: $font-sm;
        min-width: 1%;
        text-align: left;
        margin: 0;
        top: auto;
        border-radius: 0;
        border: 0;
        box-shadow: none;
    }
}