// Squerkle buttons // DEPRECATED Element
//*** scss local variable ***
$button-squerkle-focus-border-color:           var(--#{$vudTokenPrefix}button-focus-border-color);
$button-squerkle-secondary-focus-border-color: var(--#{$vudTokenPrefix}button-primary-focus-inner-shadow-color);

$button-squerkle-secondary-icon-bg:            var(--#{$vudTokenPrefix}button-primary-icon);
$button-squerkle-secondary-icon-hover-bg:      var(--#{$vudTokenPrefix}navigation-default-item-hover-bg);
$button-squerkle-secondary-icon-pressed-bg:    var(--#{$vudTokenPrefix}navigation-default-item-active-bg);

$button-squerkle-primary-icon-hover-bg:        var(--#{$vudTokenPrefix}button-hover-bg) !important;
$button-squerkle-primary-icon-pressed-bg:      var(--#{$vudTokenPrefix}button-pressed-bg) !important;


//*** element/component code *** // DEPRECATED Element
.#{$vudClassPrefix}btn {
    &.#{$vudClassPrefix}btn-squerkle {
        @include img-squerkle();
        min-width: 1%;
        //border: 0;
        height: auto;
        padding: $spacing-4;
        position: relative;
        border: rem(2px) solid transparent;

        .vismaicon { margin: 0; }

        &:before,
        &:after {
            content:'';
            display: block;
            position: absolute;
        }

        &:after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &:before {
            width: 110%;
            height: 110%;
            top: -2px;
            left: -2px;
        }

        &:focus {
            box-shadow: none;
            outline: none; 

            &:after {
                @include img-squerkle-border-thick();
                background-color: $button-squerkle-focus-border-color;
            }
        }

        &.bg-secondary {
            > .vismaicon {
                &:not(.vismaicon-visma):not(.vismaicon-facebook):not(.vismaicon-flickr):not(.vismaicon-google):not(.vismaicon-linkedin):not(.vismaicon-pinterest):not(.vismaicon-skype):not(.vismaicon-tumblr):not(.vismaicon-twitter):not(.vismaicon-vimeo):not(.vismaicon-youtube) {
                    &:before {
                        background-color: $button-squerkle-secondary-icon-bg;
                    }
                }
            }

            &:hover {
                &:before {
                    background-color: $button-squerkle-secondary-icon-hover-bg;
                }

                > .vismaicon {
                    &:not(.vismaicon-visma):not(.vismaicon-facebook):not(.vismaicon-flickr):not(.vismaicon-google):not(.vismaicon-linkedin):not(.vismaicon-pinterest):not(.vismaicon-skype):not(.vismaicon-tumblr):not(.vismaicon-twitter):not(.vismaicon-vimeo):not(.vismaicon-youtube) {
                        &:before {
                            background-color: $button-squerkle-secondary-icon-bg;
                        }
                    }
                }
            }

            &:active {
                &:before {
                    background-color: $button-squerkle-secondary-icon-pressed-bg;
                }
            }

            &:focus {
                
                &:after {
                    @include img-squerkle-border-thick();
                    background-color: $button-squerkle-secondary-focus-border-color;
                }
            }
        } // when has .bg-secondary - blue background

        &.bg-primary {
            &:hover {
                background-color: $button-squerkle-primary-icon-hover-bg;
            }

            &:active {
                background-color: $button-squerkle-primary-icon-pressed-bg;
            }
        } // when has .bg-primary
    }
} // END '.btn'
